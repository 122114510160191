<template>
  <div>
    <YTable
      v-if="capDefaults"
      class="table-striped table-hover overflow-hidden"
      block-style="min-height: 25rem;"
      :scroll-x="false"
      :data="capDefaults.data"
    >
      <template slot="header-row">
        <th>
          Frequency
        </th>
        <th>
          Type
        </th>
        <th>
          Geo
        </th>
        <th>
          Redirect
        </th>
        <th>
          Value
        </th>
        <th></th>
        <th></th>
      </template>
      <template slot="secondHeaderRow">
        <td>
          <Superselect
            title="Frequency"
            v-model="frequency"
            :required="true"
            label="label"
            track-by="value"
            :options="filteredFrequencyOptions"
            class="-mx-3"
          />
        </td>
        <td>
          <Superselect
            title="Type"
            v-model="type"
            :required="true"
            :options="
              type.length > 0
                ? []
                : cappableType == 'Campaign'
                ? campaignTypeOptions
                : typeOptions
            "
            label="label"
            track-by="value"
            class="-mx-3"
          />
        </td>
        <td>
          <Superselect
            title="Geo"
            v-model="country"
            track-by="code"
            label="label"
            :multiple="false"
            :prepend-options="[
              allCountryEntry
            ]"
            :query="COUNTRIES_QUERY"
            :query-variables="{
              filters: {
                countriesOnly: true
              },
              orderBy: [{ column: 'name', order: 'ASC' }]
            }"
            class="-mx-3"
          />
        </td>
        <td>
          <Superselect
            title="Redirects"
            v-model="redirect"
            track-by="id"
            label="label"
            :multiple="false"
            :allow-empty="allowAllRedirectEntry ? false : true"
            :prepend-options="allowAllRedirectEntry ? [allRedirectEntry] : []"
            :query="REDIRECT_OPTIONS_QUERY"
            :query-variables="{
              first: 100,
              filters: {
                campaignId:
                  cappableType == 'Campaign'
                    ? {
                        value: [cappableId]
                      }
                    : undefined,
                label: {
                  value: ['{input}'],
                  modifiers: { matchType: 'contains' }
                }
              }
            }"
            class="-mx-3"
          />
        </td>
        <td>
          <YInput
            label="Value"
            v-model="triggerValue"
            class="-mx-3"
            :required="true"
          />
        </td>
        <td>
          <div class="flex items-center -ml-3 -mr-3">
            <YButton
              @click="createCapDefault"
              color="link"
              class="px-3"
              :is-loading="isUpdating"
              ><Icon name="plus"
            /></YButton>
          </div>
        </td>
        <td>
          <Spinner
            v-if="$apollo.queries.capDefaults.loading"
            color="blue"
            :size="6"
          />
        </td>
      </template>
      <template slot="row" slot-scope="props">
        <td>
          {{ props.rowData.frequency.capitalize() }}
        </td>
        <td>
          {{
            props.rowData.type.capitalize()
          }}
        </td>
        <td>
          {{ getTriggerableValue(props.rowData.triggerablePairs, 'Country') }}
        </td>
        <td>
          {{ getTriggerableValue(props.rowData.triggerablePairs, 'Redirect') }}
        </td>
        <td>
          <EditableValue
            :key="props.rowData.id"
            :isNumberValue="true"
            type="contentEditable"
            :value="props.rowData.triggerValue"
            :mutation="UPDATE_CAP_DEFAULT_MUTATION"
            :variables="{
              input: { id: props.rowData.id, triggerValue: '{value}' }
            }"
            placeholder="0"
            class="-mr-1 -ml-1"
            value-class="px-1"
            :edit-focus-icon-enabled="false"
            @saved="fireSavedEvent"
          />
        </td>
        <td>
          <a
            class="text-gray-400 hover:text-red-500 cursor-pointer inline-block ml-1"
            @click.prevent="deleteCap(props.rowData.id)"
          >
            <Icon name="trashCan" />
          </a>
        </td>
        <td></td>
      </template>
    </YTable>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </div>
</template>

<script>
import Superselect from '../../../components/ui/Superselect.vue'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import DELETE_CAP_DEFAULT_MUTATION from '@/graphql/Caps/DeleteCapDefaultMutation.gql'
import UPDATE_CAP_DEFAULT_MUTATION from '@/graphql/Caps/UpdateCapDefaultMutation.gql'
import CAP_DEFAULTS_QUERY from '@/graphql/Caps/CapDefaultsQuery.gql'
import CREATE_CAP_DEFAULT_MUTATION from '@/graphql/Caps/CreateCapDefaultMutation.gql'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'

export default {
  mixins: [ValidationErrorsMixin],
  components: { Superselect },
  props: {
    cappableId: {
      type: String,
      required: true
    },
    cappableType: {
      type: String,
      required: true
    },
  },
  apollo: {
    capDefaults: {
      query: CAP_DEFAULTS_QUERY,
      variables() {
        return {
          filters: {
            cappableId: { value: [this.cappableId] }
          },
          first: 300
        }
      }
    }
  },
  data() {

    const allCountryEntry = {
      value: 'all',
      label: 'All Geos'
    }
    const allRedirectEntry = {
      value: 'all',
      label: 'All Redirects'
    }
    return {
      UPDATE_CAP_DEFAULT_MUTATION,
      REDIRECT_OPTIONS_QUERY,
      COUNTRIES_QUERY,
      isUpdating: false,
      frequencyOptions: [
        {
          value: 'daily',
          label: 'Daily'
        },
        {
          value: 'monthly',
          label: 'Monthly'
        },
        {
          value: 'total',
          label: 'Total'
        }
      ],
      typeOptions: [
        {
          value: 'actions',
          label: 'Actions'
        },
        {
          value: 'revenue',
          label: 'Revenue'
        }
      ],
      campaignTypeOptions: [
        {
          value: 'publisherActions',
          label: 'Publisher Actions'
        },
        {
          value: 'payout',
          label: 'Payout'
        }
      ],
      frequency: [],
      type: [],
      country: null,
      allCountryEntry,
      allRedirectEntry,
      redirect: this.cappableType == 'Campaign' ? allRedirectEntry : null,
      triggerValue: null
    }
  },
  mounted() {
    this.$events.listen('capDefaultDeleted', () => {
      this.$apollo.queries.capDefaults.refetch()
    })
    this.$events.listen('capDefaultCreated', () => {
      this.$apollo.queries.capDefaults.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('capDefaultDeleted')
    this.$events.remove('capDefaultCreated')
  },
  computed: {
    allowAllRedirectEntry() {
      return this.cappableType == 'Campaign' ? true : false
    },
    filteredFrequencyOptions() {
      return this.frequencyOptions

      // const used = this.capDefaults.data.map(item=>item.frequency)
      
      // return this.frequencyOptions.filter(option=>!used.includes(option.value))
    },
    redirectId() {
      return this.redirect ? this.redirect.id : null
    },
    countryCode() {
      return this.country ? this.country.code : null
    }
  },
  methods: {
    getTriggerableValue(triggerablePairs, type) {
      const triggerablePair = triggerablePairs.find(pair => pair.type === type)
      if (triggerablePair) {
        return triggerablePair?.id || 'All'
      } 

      return '';
    },
    fireSavedEvent() {
      this.$events.fire('capDefaultUpdated')
    },
    clearValues() {
      this.frequency = []
      this.type = []
      this.redirect = null
      this.country = null
      this.triggerValue = null
    },
    createCapDefault() {
      this.isUpdating = true

      this.$apollo
        .mutate({
          mutation: CREATE_CAP_DEFAULT_MUTATION,
          variables: {
            input: {
              cappableId: this.cappableId,
              cappableType: this.cappableType,
              frequency: this.frequency[0].value,
              type: this.type[0].value,
              triggerValue: Number(this.triggerValue),
              triggerablePairs: [
                ...(this.country ? [{
                  type: 'Country',
                  id: this.country.code
                }] : []),
                ...(this.redirect ? [{
                  type: 'Redirect',
                  id: this.redirect.id
                }] : [])
              ]
            }
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.clearValues()
          this.isUpdating = false
          this.$toastr.s('Cap Default Created', 'Success')
          this.$nextTick(() => {
            this.$events.emit('capDefaultCreated')
          })
        })
        .catch(error => {
          this.isUpdating = false
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    deleteCap(id) {
      this.$apollo
        .mutate({
          mutation: DELETE_CAP_DEFAULT_MUTATION,
          variables: {
            id: id
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$toastr.Add({
            title: 'Deleted',
            msg: 'Cap Default deleted',
            preventDuplicates: true
          })
          this.$events.emit('capDefaultDeleted')
        })
        .catch(error => {
          this.setValidationErrors(error)
          console.error(error)
        })
    }
  }
}
</script>

<style scoped></style>
