<template>
  <sticky-content-panel
    @close="
      () => {
        this.$emit('close')
      }
    "
    @key.right="() => {}"
  >
    <template slot="header">
      <div class="sticky-content-header bg-white" ref="header">
        <div class="flex items-center justify-between w-100 px-4 py-4">
          <div class="flex items-center">
            <h4 class="pr-4 mb-0 text-base font-bold">
              Campaign Export Wizard
            </h4>
            <Tag v-if="publisher" color="purple">
              {{ publisher.label }}
            </Tag>
          </div>
          <h4 slot="right" class="mb-0">
            <close-x
              class="link"
              @click="
                () => {
                  this.$emit('close')
                }
              "
            ></close-x>
          </h4>
        </div>
        <Tabs ref="tabs">
          <Tab name="Select Publisher" :active="true">
            <portal to="sticky-content-panel-body">
              <div class="p-6">
                <h4 class="text-base font-bold mb-2">
                  Select Export Type
                </h4>
                <div class="flex flex-col gap-6">
                  <div class="flex gap-4">
                    <input
                      type="radio"
                      name="exportType"
                      id="preview-link"
                      value="preview-link"
                      v-model="previewType"
                    />
                    <label class="capitalize" for="type"
                      >Generate Preview Link & Send Email</label
                    >
                  </div>
                  <div class="flex gap-4">
                    <input
                      type="radio"
                      name="exportType"
                      id="generate-text"
                      value="generate-text"
                      v-model="previewType"
                    />
                    <label class="capitalize" for="type"
                      >Generate Text and Export CSV File</label
                    >
                  </div>
                </div>
              </div>
              <Superselect
                title="Publisher"
                v-model="publisher"
                :multiple="false"
                :options="[]"
                @select="publisherSelected"
                placeholder="Select Publisher"
                track-by="id"
                label="label"
                :close-on-select="false"
                :query="PUBLISHER_OPTIONS_QUERY"
                :query-variables="{
                  first: 20,
                  filters: {
                    label: {
                      value: ['{input}'],
                      modifiers: { matchType: 'contains' }
                    }
                  }
                }"
                edit-placeholder="Paste Publisher"
              ></Superselect>

              <portal
                v-if="exportToolIsPreviewLink"
                to="campaign-export-preview-link-bar"
              >
                <div></div>

                <div>
                  <YButton color="blue" @click="$refs['tabs'].next()">
                    Next</YButton
                  >
                </div>
              </portal>
              <portal v-else to="campaign-export-text-action-bar">
                <div></div>
                <div>
                  <YButton color="blue" @click="$refs['tabs'].next()">
                    Next</YButton
                  >
                </div>
              </portal>
            </portal>
          </Tab>
          <!-- tabs to generate text & document export -->
          <span v-if="!exportToolIsPreviewLink">
            <Tab name="Report Fields" v-if="!exportToolIsPreviewLink">
              <portal to="sticky-content-panel-body">
                <YAlert
                  type="info"
                  v-if="!areAllSubscribed"
                  :allow-close="false"
                >
                  <div class="flex flex-col">
                    <div>
                      User <span class="fw-500">{{ publisher.email }}</span> is
                      not subscribed to all campaigns
                    </div>
                    <div class="mt-4 ml-3 font-semibold">
                      <YCheckbox
                        id="preset-is-public-checkbox"
                        v-model="disableReminderEmails"
                        label="Disable Reminder Emails for this Campaign"
                        :inline="true"
                        class="flex items-center mr-2"
                        type="info"
                      ></YCheckbox>
                    </div>

                    <YButton
                      class="btn-info btn-lg mt-2 w-2/5"
                      color="blue"
                      @click="subscribeToAll()"
                      :is-loading="isSubscribing"
                      >Subscribed to All</YButton
                    >
                  </div>
                </YAlert>

                <span v-else-if="areAllSubscribed">
                  <div class="p-6">
                    <h4 class="text-base font-bold mb-6">
                      Export Format Options
                    </h4>
                    <div class="flex flex-col gap-6">
                      <div class="flex items-start gap-4">
                        <input
                          type="radio"
                          id="ungrouped"
                          value="ungrouped"
                          v-model="exportFormatType"
                          class="mt-1"
                        />
                        <label for="ungrouped">Ungrouped</label>
                        <code class="bg-gray-100 p-2 rounded-lg border"
                          >Campaign: 2903 - Fat Pirate - CPL <br />
                          Tracking link:
                          https://xzy.site.com/o/ABC?kw=SUBID&s1=CLICKID<br />
                          Payout: GB ($10), CA ($5)<br />
                          Cap: 100 Total</code
                        >
                      </div>
                      <div class="flex items-start gap-4">
                        <input
                          type="radio"
                          id="grouped"
                          value="grouped"
                          v-model="exportFormatType"
                          class="mt-1"
                        />
                        <label for="grouped">Grouped</label>
                        <code class="bg-gray-100 p-2 rounded-lg border">
                          United Kingdom <br /><br />
                          Campaign: 2903 - Fat Pirate - CPL <br />
                          Tracking link:
                          https://xzy.site.com/o/ABC?kw=SUBID&s1=CLICKID<br />
                          Payout: $10<br />
                          Cap: 100 Total
                        </code>
                      </div>
                    </div>
                  </div>
                  <div v-if="exportFormatType == 'grouped'" class="p-6 pb-0">
                    <h4 class="text-base font-bold mb-6">
                      Select Countries to include in report
                    </h4>
                    <ul class="country-list">
                      <li v-for="country in uniqueCountryCodes" :key="country">
                        <label>
                          <input
                            type="checkbox"
                            :value="country"
                            v-model="selectedCountryCodes"
                          />
                          {{ country }}
                        </label>
                      </li>
                    </ul>
                  </div>

                  <div class="p-6">
                    <h4 class="text-base font-bold mb-6">
                      Export Fields
                    </h4>
                    <div v-for="field in exportedFields" :key="field">
                      <label class="capitalize flex gap-4">
                        <input
                          type="checkbox"
                          v-model="selectedExportedFields"
                          :value="field"
                        />
                        {{ field }}
                      </label>
                    </div>
                  </div>
                </span>

                <portal to="campaign-export-text-action-bar">
                  <div></div>
                  <div>
                    <YButton color="blue" @click="generateExportText"
                      >Next</YButton
                    >
                  </div>
                </portal>
              </portal>
            </Tab>

            <Tab name="File Export" v-if="!exportToolIsPreviewLink">
              <portal to="sticky-content-panel-body">
                <textarea
                  ref="generatedTextArea"
                  v-model="generatedText"
                  style="height: 460px"
                  class="w-full bg-gray-100 p-4 rounded-lg border font-mono"
                ></textarea>
                <div class="w-full flex justify-between mt-4">
                  <YButton @click="copyToClipboard" color="gray"
                    >Copy To Clipboard</YButton
                  >
                  <YButton @click="downloadText" color="blue"
                    >Download File</YButton
                  >
                </div>
                <div class="w-full flex justify-end mt-8">
                  <YButton
                    @click="$emit('close')"
                    color="inverse"
                    class="self-end"
                    >Close Window</YButton
                  >
                </div>
              </portal></Tab
            ></span
          >

          <!-- tabs to generate preview-link -->
          <span v-if="exportToolIsPreviewLink">
            <Tab name="Campaigns" :disabled="!publisher">
              <portal to="sticky-content-panel-body">
                <YAlert
                  type="info"
                  v-if="!areAllSubscribed"
                  :allow-close="false"
                >
                  <div class="flex flex-col">
                    <div>
                      User <span class="fw-500">{{ publisher.email }}</span> is
                      not subscribed to all campaigns
                    </div>
                    <div class="mt-4 ml-3 font-semibold">
                      <YCheckbox
                        id="preset-is-public-checkbox"
                        v-model="disableReminderEmails"
                        label="Disable Reminder Emails for this Campaign"
                        :inline="true"
                        class="flex items-center mr-2"
                        type="info"
                      ></YCheckbox>
                    </div>

                    <YButton
                      class="btn-info btn-lg mt-2 w-2/5"
                      color="blue"
                      @click="subscribeToAll()"
                      :is-loading="isSubscribing"
                      >Subscribed to All</YButton
                    >
                  </div>
                </YAlert>
                <CampaignPreviews
                  v-else-if="areAllSubscribed"
                  v-model="publisherCampaigns"
                  :publisherOptions="publisher"
                  :key="publisherCampaignsKey"
                  :editable="true"
                ></CampaignPreviews>
                <portal to="campaign-export-preview-link-bar">
                  <div>
                    <YButton
                      class="btn-link btn-lg mr-2"
                      @click="$refs['tabs'].previous()"
                      >Back</YButton
                    >
                  </div>
                  <div>
                    <YButton
                      v-if="!areAllSubscribed"
                      color="blue"
                      @click="subscribeToAll()"
                      :is-loading="isSubscribing"
                      >Subscribe To All</YButton
                    >
                    <YButton
                      v-if="areAllSubscribed"
                      color="blue"
                      :disabled="!areAllSubscribed"
                      @click="$refs['tabs'].next()"
                      >Next</YButton
                    >
                  </div>
                </portal>
              </portal>
            </Tab>
            <Tab name="Message">
              <portal to="sticky-content-panel-body">
                <Superselect
                  title="Email To"
                  v-model="emailTo"
                  :multiple="false"
                  :options="[]"
                  @select="$refs['tabs'].next()"
                  placeholder="Select Publisher"
                  track-by="id"
                  label="label"
                  class="mb-4"
                  :close-on-select="false"
                  :query="PUBLISHER_OPTIONS_QUERY"
                  :query-variables="{
                    first: 20,
                    filters: {
                      id: {
                        value: ['{input}'],
                        modifiers: { matchType: 'contains' }
                      }
                    }
                  }"
                  edit-placeholder="Paste Domains"
                ></Superselect>

                <Superselect
                  v-model="emailFrom"
                  title="From"
                  :multiple="false"
                  track-by="id"
                  label="name"
                  :options="admins ? admins.data : []"
                  class="mb-1"
                ></Superselect>
                <Superselect
                  title="CC"
                  v-model="emailCc"
                  editPlaceholder
                  :forceEdit="true"
                  track-by="email"
                  label="email"
                  class="mb-1"
                ></Superselect>
                <Superselect
                  title="BCC"
                  v-model="emailBcc"
                  editPlaceholder
                  :forceEdit="true"
                  track-by="id"
                  label="name"
                  class="mb-1"
                ></Superselect>

                <div class="mb-1 mt-4 text-indigo-600 text-xs">Message</div>
                <ckeditor
                  :editor="editor"
                  v-model="emailMessage"
                  :config="editorConfig"
                  style="width: 100%; min-height: 5rem;"
                  class="border border-gray-500"
                ></ckeditor>

                <portal to="campaign-export-preview-link-bar">
                  <div>
                    <YButton
                      class="btn-light btn-lg mr-2"
                      @click="$refs['tabs'].previous()"
                      >Back</YButton
                    >
                  </div>
                  <div>
                    <YButton
                      v-if="
                        (!previewId && areAllSubscribed) ||
                          (!localDataSynced && areAllSubscribed)
                      "
                      color="blue"
                      class="mr-2"
                      @click="generatePreviewId"
                      :is-loading="isCreatingPreview"
                      >Generate Preview ID</YButton
                    >
                    <YButton
                      v-else
                      color="blue"
                      class=" mr-2"
                      :disabled="!areAllSubscribed || !previewId"
                      @click="$refs['tabs'].next()"
                      >Next</YButton
                    >
                  </div>
                </portal>
              </portal>
            </Tab>
            <Tab
              name="Preview"
              :disabled="
                publisherCampaigns.length <= 0 ||
                  !areAllSubscribed ||
                  !previewId ||
                  !localDataSynced
              "
            >
              <portal to="sticky-content-panel-body">
                <div>
                  <h4 class="text-lg font-bold mb-4">
                    Hi{{
                      campaignPreviewData.mail.toName
                        ? ` ${campaignPreviewData.mail.toName},`
                        : ','
                    }}
                  </h4>
                  <p class="mb-4" v-html="campaignPreviewData.mail.message"></p>
                  <p class="mb-4">
                    <a
                      class="link"
                      :href="`${appUrl}/campaigns/preview/${previewId}`"
                      target="_blank"
                      >View Campaign Details</a
                    >
                  </p>
                  <!-- <CampaignPreviews
                  :value="campaignPreviewData.campaigns"
                ></CampaignPreviews> -->
                </div>
                <portal to="campaign-export-preview-link-bar">
                  <div>
                    <YButton
                      class="btn-light btn-lg mr-2"
                      @click="$refs['tabs'].previous()"
                      >Back</YButton
                    >
                  </div>
                  <div>
                    <YButton
                      color="blue"
                      @click="sendEmail"
                      :is-loading="isSending"
                      >Send Email</YButton
                    >
                  </div>
                </portal>
              </portal>
            </Tab>
          </span>
        </Tabs>
      </div>
    </template>

    <template slot="action-bar">
      <portal-target
        v-if="exportToolIsPreviewLink"
        name="campaign-export-preview-link-bar"
        class="flex items-center  justify-between w-full"
      >
      </portal-target>
      <portal-target
        v-if="!exportToolIsPreviewLink"
        name="campaign-export-text-action-bar"
        class="flex items-center  justify-between w-full"
      />
    </template>
  </sticky-content-panel>
</template>

<script>
import gql from 'graphql-tag'
import CampaignPreviews from './CampaignPreviews'
//import PublisherCampaignPreviews from '@/views/Publisher/CampaignPreviews/CampaignPreviews'
import StickyContentPanel from '../StickyContentPanel'
import PUBLISHER_CAMPAIGNS_QUERY from '@/graphql/PublisherCampaign/PublisherCampaignsQuery.gql'
import USERS_QUERY from '@/graphql/User/UsersQuery.gql'
import PUBLISHER_OPTIONS_QUERY from '@/graphql/Publisher/PublisherOptionsQuery.gql'
import SUBSCRIBE_USERS_TO_CAMPAIGNS_MUTATION from '@/graphql/Campaign/SubscribeUsersToCampaignsMutation.gql'
import CKEditor from '@ckeditor/ckeditor5-vue'
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon'

export default {
  components: {
    CampaignPreviews,
    //PublisherCampaignPreviews,
    StickyContentPanel,
    //ActionBar,
    ckeditor: CKEditor.component
  },
  props: {
    campaigns: {
      type: Array,
      default: null
    }
  },

  apollo: {
    admins: {
      query: USERS_QUERY,
      update: data => data.users,
      variables() {
        return {
          filters: {
            roles: {
              name: { value: 'admin' }
            }
          },
          first: 25
        }
      }
    },
    me: {
      query: gql`
        {
          me {
            id
            label
            email
            name
          }
        }
      `,
      result(result) {
        let me = this._.cloneDeep(this.cleanApolloData(result.data.me))
        this.emailFrom = me
        this.emailBcc = [me]
      }
    },
    publisherCampaigns: {
      query: PUBLISHER_CAMPAIGNS_QUERY,
      variables() {
        return {
          publisherId: this.publisher.id,
          filters: {
            id: { value: this.campaignIds }
          },
          orderBy: [{ column: 'id', order: 'DESC' }],
          page: 1,
          first: 100
        }
      },
      skip() {
        return !this.publisher?.id
      },
      result(result) {
        this.publisherCampaigns = this._.cloneDeep(
          this.cleanApolloData(result.data.publisherCampaigns.data)
        )
        this.refreshPublisherCampaignsKey()

        this.handleUniqueCountryCodes(this.publisherCampaigns)

        this.publisherCampaigns = this.publisherCampaigns.map(campaign => {
          //filter zero campaigns and select all valid payouts
          const validPayouts = campaign.payouts.filter(
            payout => payout.value > 0
          )
          const removeZeroValuePayouts = new Array(validPayouts.length).fill(
            true
          )

          campaign.previewOptions = {
            allowed: true,
            restricted: true,
            description: true,
            creativeUrl: true,
            previewCampaignImage: true,
            payouts: removeZeroValuePayouts
          }

          campaign.payouts = validPayouts
          //generate previewCampaignImage for the first offer image only
          campaign.previewCampaignImage = this.campaignPreviewImageLink(
            campaign.offers[0].legacyImageUrl
          )

          return campaign
        })
      },
      deep: true
    }
  },
  data() {
    return {
      PUBLISHER_OPTIONS_QUERY,
      previewType: 'preview-link', //'preview-link', 'generate-text'
      exportFormatType: 'ungrouped', //'ungrouped', 'grouped'
      generatedText: null,
      exportedFields: [
        'link',
        'payout',
        'cap',
        'creativeUrl',
        'previewCampaignImage'
      ],
      selectedExportedFields: [
        'link',
        'payout',
        'cap',
        'creativeUrl',
        'previewCampaignImage'
      ],
      csvText: null,
      latestSavedJson: null,
      isCreatingPreview: false,
      isSending: false,
      publisher: {},
      users: [],
      admins: [],
      publisherCampaigns: [],
      campaignLandingPages: [],
      redirects: [],
      isSubscribing: false,
      publisherCampaignsKey: 0,
      editor: BalloonEditor,
      editorConfig: {
        toolbar: {
          items: ['bold', 'italic', 'link', 'undo', 'redo']
        }
      },
      emailTo: {},
      emailToName: '',
      emailToEmail: '',
      //emailFrom: this.$store.getters.authId,
      emailFrom: '',
      emailBcc: [],
      emailCc: [],
      emailMessage:
        '<p>We are sending along some campaign details for you to review.</p><p>If you have any questions about the campaigns or the details included, never hesitate to reach out.</p>',
      previewId: null,
      disableReminderEmails: true,
      uniqueCountryCodes: [],
      selectedCountryCodes: []
    }
  },
  computed: {
    appUrl() {
      return this.publisher?.allInId
        ? 'https://app.allinaffiliates.com'
        : 'https://ytrack.io/'
    },
    exportToolIsPreviewLink() {
      return this.previewType === 'preview-link'
    },
    areAllSubscribed() {
      let output = true
      if (this.publisherCampaigns) {
        this.publisherCampaigns.some(campaign => {
          if (
            !campaign.redirect ||
            (campaign.redirect && campaign.redirect.status != 'subscribed')
          ) {
            output = false
            return true
          }
        })
      }
      return output
    },
    campaignIds() {
      return this.campaigns
    },
    campaignIdLabels() {
      return this._.map(this.campaigns, campaignId => {
        return { id: campaignId, label: campaignId }
      })
    },
    previewUrl() {
      return `/admin/preview-mail/mailable/campaigns-preview/${this.previewId}`
    },
    campaignPreviewData() {
      return {
        mail: {
          toName: this.emailToName,
          toEmail: this.emailToEmail,
          from: this.emailFrom.id,
          cc: this.emailCc.map(user => user.email),
          bcc: this.emailBcc.map(user => user.id),
          message: this.emailMessage
        },
        campaigns: this.publisherCampaigns.map(campaign => {
          return {
            id: campaign.id,
            name: campaign.name,
            description: campaign.description,
            creativeUrl: campaign.creativeUrl,
            previewCampaignImage: campaign.previewCampaignImage,
            images: campaign.images,
            allowedRestrictedTags: campaign.allowedRestrictedTags.map(tag => ({
              name: tag.name,
              isNot: tag.isNot
            })),
            payouts: campaign.payouts,
            payoutDescription: campaign.payoutDescription,
            payoutType: campaign.payoutType,
            verticalTags: campaign.verticalTags.map(tag => ({
              name: tag.name,
              color: tag.color
            })),
            offers: campaign.offers.map(offer => {
              return {
                id: offer.id,
                name: offer.name,
                encodedId: offer.encodedId,
                legacyImageUrl: offer.legacyImageUrl,
                legacyImageThumbnailUrl: offer.legacyImageThumbnailUrl,
                destinationType: offer.destinationType,
                template: offer.template ? offer.template : undefined
              }
            }),
            caps: campaign.caps,
            capDefaults: campaign.capDefaults,
            redirect: campaign.redirect,
            previewOptions: campaign.previewOptions
          }
        })
      }
    },
    campaignPreviewDataJson() {
      return JSON.stringify(this.campaignPreviewData)
    },
    localDataSynced() {
      return true // temp solution, need to figure out why these don't show as equal on production (but do on local)
      // if (!this.latestSavedJson) {
      //   return false
      // }
      // return this._.isEqual(
      //   JSON.parse(this.campaignPreviewDataJson),
      //   JSON.parse(this.latestSavedJson)
      // )
    }
  },

  mounted() {
    this.setDefaultPublisher()
  },
  updated() {},
  watch: {
    publisher(newValue) {
      this.emailTo = newValue
      this.emailToSelected(newValue)
    }
  },
  methods: {
    campaignPreviewImageLink(image) {
      if (!image) {
        return ''
      }
      const extractedPath = image.replace(
        /^\/\/ytz-cloud-production\.s3\.amazonaws\.com\/ytrack\/media\//,
        ''
      )
      return `https://media.allinaffiliates.com/${extractedPath}`
    },
    handleUniqueCountryCodes(publisherCampaigns) {
      if (!publisherCampaigns) {
        return []
      }

      const allCountryCodes = publisherCampaigns.flatMap(campaign => {
        let $result = campaign.payouts.map(payout => payout)
        let $nextResult = $result.map(x => x.countryCode)

        return $nextResult
      })
      let countryList = [...new Set(allCountryCodes)].sort()

      this.uniqueCountryCodes = countryList
      this.selectedCountryCodes = countryList
    },
    refreshPublisherCampaignsKey() {
      this.publisherCampaignsKey = Math.floor(Math.random() * 1000000)
    },
    publisherSelected() {
      this.$refs['tabs'].next()
      this.refreshPublisherCampaignsKey()
    },
    cleanFilter(filter) {
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    },
    cleanObjectFilter(filter, pluck) {
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }

      // pluck key from value
      return {
        value: this.cleanFilter(filter).value.map(value => value[pluck]),
        modifiers: filter.modifiers
      }
    },
    cleanApolloData(data) {
      let output = data
      output = this.$omitDeep(output, '__typename')
      output = this.$omitDeep(output, 'Symbol(id)')
      output = JSON.parse(JSON.stringify(output))
      return output
    },
    setDefaultPublisher() {
      let defaultPublisher = {
        id: 27499,
        label: '27499 - internal@allinaffiliates.com',
        login: 'internal@allinaffiliates.com',
        email: 'internal@allinaffiliates.com',
        allInId: 27499
      }
      this.publisher = defaultPublisher
    },
    emailToSelected(user) {
      this.emailToName = user.primaryContact ? user.primaryContact.name : ''
      this.emailToEmail = user.email
    },
    sendEmail() {
      this.isSending = true

      this.$apollo
        .mutate({
          mutation: gql`
            mutation SendCampaignsPreviewEmail($id: ID!, $userId: ID) {
              sendCampaignsPreviewEmail(id: $id, userId: $userId) {
                id
                data
              }
            }
          `,
          variables: {
            id: this.previewId,
            userId: this.publisher.id
          },
          deep: true
        })
        .then(() => {
          this.isSending = false
          this.$emit('close')
        })
        .catch(error => {
          this.isSending = false

          if (Object.entries(this.errors).length <= 0) {
            this.$toastr.e(error, 'Oops!')
          }
        })
    },
    subscribeToAll() {
      this.isSubscribing = true

      this.$apollo
        .mutate({
          mutation: SUBSCRIBE_USERS_TO_CAMPAIGNS_MUTATION,
          variables: {
            input: {
              campaigns: this.campaigns,
              users: [this.publisher.id],
              reason: 'Subscribed via campaign export tool.',
              disableReminderEmails: this.disableReminderEmails
            }
          },
          deep: true
        })
        .then(() => {
          this.isSubscribing = false
          this.$apollo.queries.publisherCampaigns.refetch()
        })
        .catch(error => {
          this.isSubscribing = false
          if (Object.entries(this.errors).length <= 0) {
            this.$toastr.e(error, 'Oops!')
          }
        })
    },
    copyToClipboard() {
      navigator.clipboard
        .writeText(this.generatedText)
        .then(() => {
          this.$toastr.s('Text copied to clipboard')
        })
        .catch(err => {
          console.error('Failed to copy:', err)
          this.$toastr.e('Something went wrong. Text not copied.')
        })
    },
    generateExportText() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createCampaignExportPreview(
              $input: CampaignExportPreviewInput
            ) {
              createCampaignExportPreview(input: $input) {
                text
                csvData
              }
            }
          `,
          variables: {
            input: {
              id: this.publisher.id,
              filterType: this.exportFormatType,
              fields: this.selectedExportedFields,
              groupedCountryCodes: this.selectedCountryCodes,
              data: this.campaignPreviewDataJson
            }
          }
        })
        .then(result => {
          this.generatedText = result.data.createCampaignExportPreview.text
          this.csvText = result.data.createCampaignExportPreview.csvData

          this.$nextTick().then(() => {
            this.$refs['tabs'].next()
          })
        })
        .catch(error => {
          this.$toastr.e(error.message || 'An error occurred', 'Oops!')
        })
    },
    downloadText() {
      const csvData = this.csvText

      const csvContent = csvData.join('\r\n')
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

      const link = document.createElement('a')
      link.download = 'campaign_export.csv'
      link.href = URL.createObjectURL(blob)

      link.click()
    },
    generatePreviewId() {
      this.isCreatingPreview = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation CreateCampaignsPreview($data: String!) {
              createCampaignsPreview(data: $data) {
                id
                data
              }
            }
          `,
          variables: {
            data: this.campaignPreviewDataJson
          }
        })
        .then(result => {
          this.isCreatingPreview = false
          this.previewId = result.data.createCampaignsPreview.id
          this.latestSavedJson = result.data.createCampaignsPreview.data

          this.$nextTick().then(() => {
            this.$refs['tabs'].next()
          })
        })
        .catch(error => {
          this.isCreatingPreview = false
          this.$toastr.e(error, 'Oops!')
          if (Object.entries(this.errors).length <= 0) {
            this.$toastr.e(error, 'Oops!')
          }
        })
    }
  }
}
</script>
<style>
.country-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
}
</style>
