var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.caps ? _c('YTable', {
    staticClass: "table-striped table-hover overflow-hidden",
    attrs: {
      "block-style": "min-height: 25rem;",
      "scroll-x": false,
      "data": _vm.caps.data,
      "selectable": true,
      "selected": _vm.selectedRowIds
    },
    on: {
      "update:selected": function ($event) {
        _vm.selectedRowIds = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        var _vm$getTriggerable, _vm$getTriggerable2, _vm$getTriggerable3, _vm$getTriggerable3$u, _vm$getTriggerable4, _vm$getTriggerable4$r, _vm$getTriggerable5, _vm$getTriggerable9;
        return [_c('td', [_vm._v(" " + _vm._s(props.rowData.frequency.capitalize()) + " ")]), _c('td', [_vm._v(" " + _vm._s(props.rowData.type.capitalize()) + " ")]), _c('td', [_c('div', {
          staticClass: "flex flex-col"
        }, [_c('span', [_vm._v(" " + _vm._s(((_vm$getTriggerable = _vm.getTriggerable(props.rowData.triggerables, 'Country')) === null || _vm$getTriggerable === void 0 ? void 0 : _vm$getTriggerable.code) || _vm.getTriggerable(props.rowData.triggerables, 'Country')) + " ")])])]), _c('td', [_c('div', {
          staticClass: "flex flex-col"
        }, [_c('span', [_vm._v(" " + _vm._s(((_vm$getTriggerable2 = _vm.getTriggerable(props.rowData.triggerables, 'Redirect')) === null || _vm$getTriggerable2 === void 0 ? void 0 : _vm$getTriggerable2.label) || _vm.getTriggerable(props.rowData.triggerables, 'Redirect')) + " ")]), _c('span', {
          staticClass: "text-xs italic text-gray-700"
        }, [_vm._v(" " + _vm._s(((_vm$getTriggerable3 = _vm.getTriggerable(props.rowData.triggerables, 'Redirect')) === null || _vm$getTriggerable3 === void 0 ? void 0 : (_vm$getTriggerable3$u = _vm$getTriggerable3.user) === null || _vm$getTriggerable3$u === void 0 ? void 0 : _vm$getTriggerable3$u.label) || '') + " ")]), _c('span', {
          staticClass: "text-xs italic text-gray-700 block"
        }, [_vm._v(" " + _vm._s(((_vm$getTriggerable4 = _vm.getTriggerable(props.rowData.triggerables, 'Source')) === null || _vm$getTriggerable4 === void 0 ? void 0 : (_vm$getTriggerable4$r = _vm$getTriggerable4.redirect) === null || _vm$getTriggerable4$r === void 0 ? void 0 : _vm$getTriggerable4$r.label) || '') + " ")])])]), _c('td', [_vm._v(" " + _vm._s(((_vm$getTriggerable5 = _vm.getTriggerable(props.rowData.triggerables, 'Source')) === null || _vm$getTriggerable5 === void 0 ? void 0 : _vm$getTriggerable5.id) || _vm.getTriggerable(props.rowData.triggerables, 'Source')) + " ")]), _c('td', [_c('div', {
          staticClass: "flex items-center"
        }, [_c('span', {
          staticClass: "-mr-1"
        }, [_vm._v(" " + _vm._s(props.rowData.type === 'revenue' ? '$' : '') + " "), _c('EditableValue', {
          key: props.rowData.id,
          attrs: {
            "type": "contentEditable",
            "isNumberValue": true,
            "value": props.rowData.currentValue,
            "mutation": _vm.UPDATE_CAP_MUTATION,
            "variables": {
              input: {
                id: props.rowData.id,
                currentValue: '{value}'
              }
            },
            "placeholder": "0",
            "value-class": "px-1",
            "edit-focus-icon-enabled": false
          }
        })], 1), _vm._v(" / "), _c('div', {
          staticClass: "flex"
        }, [_c('div', {
          ref: "contentEditable",
          staticClass: "focus:bg-indigo-200 inline px-1",
          attrs: {
            "contenteditable": ""
          },
          on: {
            "input": function ($event) {
              return _vm.handleInput(props.rowData, $event);
            },
            "focus": function ($event) {
              return _vm.focusEvent(props.rowData, $event);
            },
            "blur": function ($event) {
              return _vm.captureTriggerValue(props.rowData, $event);
            },
            "keydown": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              return $event.target.blur();
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.triggerValue) + " ")]), _vm.pendingRowId === props.rowData.id ? _c('div', [_c('span', {
          staticClass: "inline-flex items-center rounded-lg shadow-md ml-2 overflow-hidden"
        }, [_c('span', {
          ref: "saveAction",
          staticClass: "cursor-pointer px-1 bg-green-300 hover:bg-green-400 focus:bg-green-400 text-green-700 outline-none-important",
          attrs: {
            "tabindex": "0"
          },
          on: {
            "click": _vm.updateTriggerValueModal,
            "keydown": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              return _vm.updateTriggerValueModal.apply(null, arguments);
            }
          }
        }, [_c('Icon', {
          attrs: {
            "name": "contentSave"
          }
        })], 1), _c('span', {
          staticClass: "cursor-pointer bg-red-300 hover:bg-red-400 focus:bg-red-400 text-red-700 px-1 outline-none-important",
          attrs: {
            "tabindex": "0"
          },
          on: {
            "click": _vm.cancelTriggerValueModal,
            "keydown": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              return _vm.cancelTriggerValueModal.apply(null, arguments);
            }
          }
        }, [_c('Icon', {
          attrs: {
            "name": "close"
          }
        })], 1)])]) : _vm._e()])]), _vm._v(" " + _vm._s(_vm.capPercentage(props.rowData.currentValue, props.rowData.triggerValue)) + " ")]), _c('td', [!props.rowData.deletedAt ? _c('v-popover', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: {
              content: props.rowData.isIgnored ? 'Paused' : !props.rowData.isCapped ? 'Uncapped' : props.rowData.cappedBy ? 'Manually Capped' : 'Capped',
              delay: {
                show: 1000
              }
            },
            expression: "{\n            content: props.rowData.isIgnored\n              ? 'Paused'\n              : !props.rowData.isCapped\n              ? 'Uncapped'\n              : props.rowData.cappedBy\n              ? 'Manually Capped'\n              : 'Capped',\n            delay: { show: 1000 }\n          }"
          }],
          staticClass: "inline-block hover:bg-blue-200 rounded cursor-pointer"
        }, [_c('div', {
          staticClass: "flex items-center"
        }, [_c('span', {
          staticClass: "h-2 w-2 m-2 rounded-full",
          class: props.rowData.isIgnored ? 'bg-gray-500' : !props.rowData.isCapped ? 'bg-green-500' : props.rowData.cappedBy ? 'bg-yellow-500' : 'bg-red-500'
        })]), _c('template', {
          slot: "popover"
        }, [_c('div', {
          staticClass: "flex flex-col"
        }, [!props.rowData.isIgnored && !props.rowData.isCapped ? _c('YButton', {
          attrs: {
            "color": "link"
          },
          on: {
            "click": function ($event) {
              return _vm.toggleManualCap(props.rowData);
            }
          }
        }, [_vm._v("Cap")]) : _vm._e(), !props.rowData.isIgnored && props.rowData.cappedBy ? _c('YButton', {
          attrs: {
            "color": "link"
          },
          on: {
            "click": function ($event) {
              return _vm.toggleManualCap(props.rowData);
            }
          }
        }, [_vm._v("Uncap")]) : _vm._e(), _c('YButton', {
          attrs: {
            "color": "link"
          },
          on: {
            "click": function ($event) {
              return _vm.togglePauseCap(props.rowData);
            }
          }
        }, [_vm._v(_vm._s(!props.rowData.isIgnored ? 'Pause' : 'Unpause'))])], 1)])], 2) : _c('a', {
          staticClass: "flex items-center text-gray-500 hover:text-gray-900 rounded cursor-pointer",
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.restoreCap(props.rowData.id);
            }
          }
        }, [_c('Icon', {
          attrs: {
            "name": "restore"
          }
        })], 1)], 1), _vm.cappableType === 'Campaign' ? _c('td', [_c('a', {
          staticClass: "link",
          on: {
            "click": function ($event) {
              var _vm$getTriggerable6, _vm$getTriggerable7, _vm$getTriggerable8;
              $event.preventDefault();
              _vm.openEditFallbackModal(((_vm$getTriggerable6 = _vm.getTriggerable(props.rowData.triggerables, 'Redirect')) === null || _vm$getTriggerable6 === void 0 ? void 0 : _vm$getTriggerable6.id) || '', ((_vm$getTriggerable7 = _vm.getTriggerable(props.rowData.triggerables, 'Redirect')) === null || _vm$getTriggerable7 === void 0 ? void 0 : _vm$getTriggerable7.fallbackCampaignId) || '', ((_vm$getTriggerable8 = _vm.getTriggerable(props.rowData.triggerables, 'Redirect')) === null || _vm$getTriggerable8 === void 0 ? void 0 : _vm$getTriggerable8.fallbackOfferId) || '');
            }
          }
        }, [_c('Icon', {
          class: (_vm$getTriggerable9 = _vm.getTriggerable(props.rowData.triggerables, 'Redirect')) !== null && _vm$getTriggerable9 !== void 0 && _vm$getTriggerable9.fallbackCampaignId ? 'text-blue-400 hover:text-blue-500' : 'text-gray-400 hover:text-blue-400',
          attrs: {
            "name": "shield"
          }
        })], 1)]) : _vm._e(), _c('td', [!props.rowData.deletedAt ? _c('a', {
          staticClass: "text-gray-400 hover:text-red-500 cursor-pointer",
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.deleteCap(props.rowData.id);
            }
          }
        }, [_c('Icon', {
          attrs: {
            "name": "trashCan"
          }
        })], 1) : _vm._e()])];
      }
    }], null, false, 3940064919)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v(" Frequency ")]), _c('th', [_vm._v(" Type ")]), _c('th', [_vm._v(" Geo ")]), _c('th', [_vm._v(" Redirect ")]), _c('th', [_vm._v(" Source ")]), _c('th', [_vm._v(" Value ")]), _c('th'), _c('th', [_c('v-popover', {
    staticClass: "flex items-center rounded cursor-pointer -ml-3 -mr-3"
  }, [_c('div', {
    staticClass: "flex items-center hover:text-blue-500"
  }, [_c('YButton', {
    staticClass: "px-3"
  }, [_c('Icon', {
    attrs: {
      "name": "eye"
    }
  })], 1)], 1), _c('template', {
    slot: "popover"
  }, [_c('div', {
    staticClass: "flex flex-col"
  }, [_c('YButton', {
    staticClass: "rounded-b-none",
    attrs: {
      "color": _vm.trashedInput === 'WITHOUT' ? 'red' : 'link'
    },
    on: {
      "click": _vm.showActiveCaps
    }
  }, [_vm._v("Show Active")]), _c('YButton', {
    staticClass: "rounded-none",
    attrs: {
      "color": _vm.trashedInput === 'ONLY' ? 'red' : 'link'
    },
    on: {
      "click": _vm.showDeletedCaps
    }
  }, [_vm._v("Show Deleted")]), _c('YButton', {
    staticClass: "rounded-t-none",
    attrs: {
      "color": _vm.trashedInput === 'WITH' ? 'red' : 'link'
    },
    on: {
      "click": _vm.showAllCaps
    }
  }, [_vm._v("Show All")])], 1)])], 2)], 1), _c('th', [_c('YButton', {
    staticClass: "text-red px-3",
    attrs: {
      "disabled": !_vm.rowsAreSelected
    },
    on: {
      "click": _vm.openDeleteModal
    }
  }, [_c('Icon', {
    attrs: {
      "name": "trashCan"
    }
  })], 1)], 1)]), _c('template', {
    slot: "secondHeaderRow"
  }, [_c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Frequency",
      "required": true,
      "label": "label",
      "track-by": "value",
      "options": _vm.frequency.length == 0 ? _vm.frequencyOptions : []
    },
    model: {
      value: _vm.frequency,
      callback: function ($$v) {
        _vm.frequency = $$v;
      },
      expression: "frequency"
    }
  })], 1), _c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Type",
      "required": true,
      "options": _vm.type.length > 0 ? [] : _vm.cappableType == 'Campaign' ? _vm.campaignTypeOptions : _vm.typeOptions,
      "label": "label",
      "track-by": "value"
    },
    model: {
      value: _vm.type,
      callback: function ($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  })], 1), _c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Geo",
      "track-by": "code",
      "label": "label",
      "query": _vm.COUNTRIES_QUERY,
      "query-variables": {
        filters: {
          countriesOnly: true
        },
        orderBy: [{
          column: 'name',
          order: 'ASC'
        }]
      }
    },
    model: {
      value: _vm.countries,
      callback: function ($$v) {
        _vm.countries = $$v;
      },
      expression: "countries"
    }
  })], 1), _c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Redirects",
      "track-by": "id",
      "label": "label",
      "disabled": _vm.sourceIds.length > 0,
      "query": _vm.REDIRECT_OPTIONS_QUERY,
      "query-variables": {
        first: 100,
        filters: {
          campaignId: _vm.cappableType == 'Campaign' ? {
            value: [_vm.cappableId]
          } : undefined,
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      }
    },
    model: {
      value: _vm.redirects,
      callback: function ($$v) {
        _vm.redirects = $$v;
      },
      expression: "redirects"
    }
  })], 1), _c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Sources",
      "options": [],
      "forceEdit": true,
      "disabled": _vm.redirects.length !== 1
    },
    model: {
      value: _vm.sourceIds,
      callback: function ($$v) {
        _vm.sourceIds = $$v;
      },
      expression: "sourceIds"
    }
  })], 1), _c('td', [_c('YInput', {
    staticClass: "-mx-3",
    attrs: {
      "label": "Value",
      "required": true
    },
    model: {
      value: _vm.triggerValue,
      callback: function ($$v) {
        _vm.triggerValue = $$v;
      },
      expression: "triggerValue"
    }
  })], 1), _c('td', [_c('div', {
    staticClass: "flex items-center -ml-3 -mr-3"
  }, [_c('YButton', {
    staticClass: "px-3",
    attrs: {
      "color": "link",
      "disabled": _vm.frequency.length === 0 || _vm.type.length === 0 || _vm.triggerValue === null,
      "is-loading": _vm.isUpserting
    },
    on: {
      "click": function ($event) {
        return _vm.upsertCaps(_vm.upsertCapObjects);
      }
    }
  }, [_c('Icon', {
    attrs: {
      "name": "plus"
    }
  })], 1)], 1)]), _c('td', [_vm.$apollo.queries.caps.loading ? _c('Spinner', {
    attrs: {
      "color": "blue",
      "size": 6
    }
  }) : _vm._e()], 1), _c('td')])], 2) : _vm._e(), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e(), _c('modal', {
    attrs: {
      "name": "confirm-delete-caps-modal",
      "width": 364,
      "height": 200
    }
  }, [_c('Widget', {
    staticClass: "bg-gray-200 border-gray-500"
  }, [_c('WidgetHeader', {
    staticClass: "bg-gray-200"
  }, [_vm._v("Confirm Delete Caps")]), _c('WidgetBody', [_c('p', [_vm._v("Are you sure you want to delete the selected caps?")])]), _c('WidgetFooter', {
    staticClass: "bg-dark-transparent-1 flex justify-between gap-6 py-2 px-4"
  }, [_c('YButton', {
    attrs: {
      "color": "inverse"
    },
    on: {
      "click": _vm.cancelBulkDelete
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    attrs: {
      "color": "red",
      "is-loading": _vm.isBulkDeleting
    },
    on: {
      "click": _vm.bulkDeleteCaps
    }
  }, [_vm._v("Delete")])], 1)], 1)], 1), _c('modal', {
    attrs: {
      "name": "confirm-cap-email-modal",
      "width": 364,
      "height": 100
    }
  }, [_c('Widget', {
    staticClass: "bg-gray-200 border-gray-500"
  }, [_c('WidgetHeader', {
    staticClass: "bg-gray-200"
  }, [_vm._v("Send Cap Email Nofication?")]), _c('WidgetFooter', {
    staticClass: "bg-dark-transparent-1 flex justify-between gap-6 py-2 px-4"
  }, [_c('YButton', {
    attrs: {
      "color": "inverse"
    },
    on: {
      "click": function ($event) {
        return _vm.saveTriggerValue(true);
      }
    }
  }, [_vm._v("Do Not Send")]), _c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": function ($event) {
        return _vm.saveTriggerValue(false);
      }
    }
  }, [_vm._v("Send Emails")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }