<template>
  <widget>
    <widget-header class="background-gradient-green-blue text-white tiny-forms">
      <i class="zmdi zmdi-file-plus pr-2"></i> Convert Lead to Activer User
      <h4 slot="right">
        <close-x @click="$emit('close')" class="text-white"></close-x>
      </h4>
    </widget-header>
    <widget-body>
      <form @submit.prevent="convertLead" ref="form" class="x-form">
        <div class="grid grid-cols-1 gap-2">
          <Superselect
            title="Manager"
            v-model="account.accountManager"
            :multiple="false"
            track-by="id"
            label="label"
            :query="ACCOUNT_MANAGER_OPTIONS_QUERY"
            :query-variables="{
              filters: {
                label: {
                  value: [],
                  modifiers: { matchType: 'contains' }
                }
              },
              first: 25
            }"
            :required="true"
          ></Superselect>
          <Superselect
            title="Role"
            v-model="account.role"
            :multiple="false"
            :options="['publisher', 'advertiser']"
            class="capitalize"
            :required="true"
          ></Superselect>
          <YInput
            v-if="account.role == 'publisher'"
            label="Email"
            v-model="account.email"
          />

          <div v-if="account.role == 'advertiser'" class="grid gap-2">
            <YInput
              v-model="account.billingName"
              label="Billing Name"
              :required="true"
            ></YInput>
          </div>
        </div>
      </form>
    </widget-body>
    <widget-footer class="px-4 py-2 bg-gray-100 text-right">
      <y-button
        class="btn btn-blue"
        :is-loading="isSubmitting"
        @click="convertLead"
        @keydown.enter="convertLead"
        >Convert Lead</y-button
      >
    </widget-footer>

    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import ACCOUNT_MANAGER_OPTIONS_QUERY from '@/graphql/AccountManager/AccountManagerOptionsQuery.gql'
import TRANSFORM_LEAD from '@/graphql/User/TransformLeadToActive.gql'

export default {
  mounted() {},
  mixins: [ValidationErrorsMixin],
  props: {
    lead: {
      type: Object
    }
  },
  data() {
    return {
      ACCOUNT_MANAGER_OPTIONS_QUERY,
      isSubmitting: false,
      account: {
        email: this.lead.email,
        role: this.lead.roles?.some(role => role.name == 'advertiser')
          ? 'advertiser'
          : 'publisher',
        accountManager: this.lead.accountManager
          ? this.lead.accountManager
          : null,
        billingName: this.lead.billingContact?.name || ''
      }
    }
  },
  computed: {},
  methods: {
    convertLead() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: TRANSFORM_LEAD,
          variables: {
            input: {
              id: this.lead.id,
              role: this.account.role,
              email: this.account.email,
              accountManager: this.account.accountManager.id,
              billingName: this.account.billingName
                ? this.account.billingName
                : undefined
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.clearValidationErrors()
          this.$emit('close')
          this.$router.push({
            name: 'Users'
          })
        })
        .catch(error => {
          console.log(error)
          this.setValidationErrors(error)
          this.isSubmitting = false
        })
    }
  }
}
</script>
