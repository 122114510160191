<template>
  <div>
    <Widget>
      <WidgetHeader class="bg-gray-200 p-1 border-b">
        <template slot="rawContent">
          <RuleFilters
            @rulesFetched="updateRules"
            @loading="updateLoading"
            :order-by="orderBy"
            :per-page="perPage"
            :current-page="currentPage"
            :default-values="defaultFilterValues"
            :hide="hideFilters"
            :hide-all-offer-filters="hideAllOfferFilters"
          ></RuleFilters>
        </template>
      </WidgetHeader>
    </Widget>
    <slot name="beforeTable"></slot>
    <RulesTable
      v-if="rules && rules.data"
      :rules="rules.data || []"
      :order-by="orderBy"
      :rulesParentComponent="rulesParentComponent"
      :bottom-loading="loading"
      class="pt-4"
    ></RulesTable>

    <YPagination
      v-if="rules && rules.paginatorInfo"
      :data="rules.paginatorInfo"
      @load-page="loadPage"
      @next-page="nextPage"
      @previous-page="previousPage"
      :key="rules.paginatorInfo.lastPage"
      @per-page="perPageSelection"
    >
    </YPagination>
  </div>
</template>

<script>
import RuleFilters from '@/views/Admin/Rules/RuleFilters'
import RulesTable from '@/views/Admin/Rules/RulesTable'
import BackToTop from '@/mixins/BackToTopMixin.js'
import YPagination from '@/components/ui/YPagination.vue'

export default {
  mixins: [BackToTop],
  components: {
    RuleFilters,
    RulesTable,
    YPagination
  },
  props: {
    rulesParentComponent: {
      type: String
    },
    defaultFilterValues: {
      type: Object
    },
    hideFilters: {
      type: Array
    },
    hideAllOfferFilters: {
      type: Boolean
    }
  },
  apollo: {},
  data() {
    return {
      rules: null,
      loading: false,
      orderBy: [
        {
          column: 'id',
          order: 'DESC'
        }
      ],
      perPage: 20,
      currentPage: 1
    }
  },
  mounted() {},
  updated() {
    this.rules
      ? this.$emit('totalRulesTab', this.rules.paginatorInfo)
      : undefined
  },
  methods: {
    updateLoading(loadingState) {
      this.loading = loadingState
    },
    updateRules(rules) {
      this.rules = rules
    },
    loadPage(page) {
      this.currentPage = page
      this.backToTop()
    },
    nextPage() {
      this.currentPage++
      this.backToTop()
    },
    previousPage() {
      this.currentPage--
      this.backToTop()
    },
    perPageSelection(value) {
      this.perPage = value
    }
  }
}
</script>

<style scoped></style>
