var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "top"
    }
  }, [_c('portal', {
    attrs: {
      "to": "top-bar-right"
    }
  }, [_c('div', {
    staticClass: "flex justify-right"
  }, [_c('YButton', {
    staticClass: "btn btn-blue",
    on: {
      "click": _vm.openCreateAdvertiserModal
    }
  }, [_vm._v(" New Advertiser ")])], 1)]), _c('widget', {
    staticClass: "mb-2"
  }, [_c('widget-header', {
    staticClass: "bg-gray-200 p-1 border-b"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('AdvertiserFilters')], 1)], 2)], 1), _vm.advertisers ? _c('YTable', {
    attrs: {
      "data": _vm.advertisers.data || [],
      "bottom-loading": _vm.$apolloData.queries.advertisers.loading,
      "row-classes": function (row) {
        return row.userDetails.followUpDue ? ['follow-up-row'] : [];
      }
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        var _props$rowData$billin;
        return [_c('td', {
          staticStyle: {
            "padding-right": "0",
            "width": "20px"
          }
        }, [props.rowData.userDetails.followUpDue ? _c('span', [_c('Icon', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: 'Follow Up Due',
            expression: "'Follow Up Due'"
          }],
          staticClass: "text-pink-500 rounded m-1",
          attrs: {
            "name": "exclamationThick",
            "size": 4
          }
        })], 1) : _vm._e()]), _c('td', {
          staticStyle: {
            "padding-left": "0.5rem",
            "width": "210px"
          }
        }, [_c('div', {
          staticClass: "flex flex-row gap-1"
        }, [_c('div', {
          staticClass: "flex items-center gap-2 flex-grow"
        }, [_c('div', [_c('router-link', {
          staticClass: "link block",
          attrs: {
            "to": {
              name: 'Advertiser',
              params: {
                id: props.rowData.advertiserId
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.advertiserId) + " - " + _vm._s(props.rowData.primaryContact.name ? props.rowData.primaryContact.name : props.rowData.primaryContact.company) + " ")])], 1)])])]), _c('td', [_c('div', [props.rowData.tags.some(function (tag) {
          return tag.type === 'leadType';
        }) && !props.rowData.accountManager ? _c('Tag', {
          staticClass: "flex-end",
          attrs: {
            "color": "gray"
          }
        }, [_vm._v("lead")]) : _c('Tag', {
          staticClass: "flex-end",
          attrs: {
            "color": "darkGreen"
          }
        }, [_vm._v("active")])], 1)]), _c('td', {
          staticStyle: {
            "width": "210px",
            "place-content": "center"
          }
        }, [_vm._v(" " + _vm._s((_props$rowData$billin = props.rowData.billingContact) === null || _props$rowData$billin === void 0 ? void 0 : _props$rowData$billin.name) + " ")]), _c('td', [_c('div', {
          staticClass: "flex items-center"
        }, [_c('button', {
          staticClass: "hover:bg-gray-100 p-1 rounded",
          on: {
            "click": function ($event) {
              return _vm.openPriorityStatusModal(props.rowData);
            }
          }
        }, [_c('Icon', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.priorityFollowUp(props.rowData.userDetails.followUpAt),
            expression: "\n                priorityFollowUp(props.rowData.userDetails.followUpAt)\n              "
          }],
          class: _vm.priorityIconColor(props.rowData.userDetails.priorityStatus),
          attrs: {
            "name": _vm.priorityIconType(props.rowData.userDetails.priorityStatus),
            "size": 6
          }
        })], 1)])]), _c('td', {
          staticClass: "truncate",
          staticStyle: {
            "max-width": "10rem"
          }
        }, _vm._l(props.rowData.tags.filter(function (tag) {
          return tag.type === 'trackingPlatform';
        }), function (tag) {
          return _c('ul', {
            key: tag.id,
            staticClass: "mr-1 my-1 text-xs text-gray-800"
          }, [_c('li', [_vm._v(_vm._s(tag.name))])]);
        }), 0), _c('td', [props.rowData.accountManager ? [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'User',
              params: {
                id: props.rowData.accountManager.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.accountManager.primaryContact.name.split(' ')[0]) + " ")])] : _vm._e()], 2)];
      }
    }, {
      key: "after",
      fn: function () {
        return [_vm.advertisers.paginatorInfo ? _c('YPagination', {
          key: _vm.totalPages,
          attrs: {
            "data": _vm.advertisers.paginatorInfo
          },
          on: {
            "load-page": _vm.loadPage,
            "next-page": _vm.nextPage,
            "previous-page": _vm.previousPage,
            "per-page": _vm.perPageSelection
          }
        }) : _vm._e()];
      },
      proxy: true
    }], null, false, 1380409103)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th'), _c('th', [_vm._v("Advertiser")]), _c('th', {
    staticStyle: {
      "width": "80px"
    }
  }), _c('th', [_vm._v("Billing Name")]), _c('th'), _c('th', [_vm._v("Tracking Platform")]), _c('th', [_vm._v("Manager")])])], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }