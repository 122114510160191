<template>
  <div id="top">
    <Widget class="mb-2">
      <WidgetHeader class="bg-gray-200 p-1 border-b">
        <template slot="rawContent">
          <UserFilters></UserFilters>
        </template>
      </WidgetHeader>
    </Widget>
    <YButton color="blue" @click.prevent="openOptimizeModal()"
      >Create Offer Studio User</YButton
    >
    <YTable
      v-if="users"
      :data="users.data || []"
      :bottom-loading="$apolloData.queries.users.loading"
      :selectable="true"
      :selected.sync="selectedRowIds"
      :row-classes="
        row => (row.userDetails.followUpDue ? ['follow-up-row'] : [])
      "
    >
      <template slot="header-row">
        <th></th>
        <th>
          <OrderByItem column="id" order="DESC" v-model="orderBy"
            >User</OrderByItem
          >
        </th>
        <th>Status</th>
        <th>Manager</th>
        <th>Referrer</th>
        <th>
          <OrderByItem column="created_at" order="DESC" v-model="orderBy"
            >Active Since</OrderByItem
          >
        </th>
        <th>Traffic Type</th>
        <th>Verticals</th>
        <th>Last contact</th>
        <th>
          <div class="flex justify-end">
            <YButton
              type="button"
              class="border border-blue-600 hover:bg-blue-600 focus:bg-blue-600 text-blue-600 hover:text-white focus:text-white mr-3 outline-none-important"
              :isLoading="isExporting"
              @click="exportUsers"
            >
              Export
            </YButton>
          </div>
        </th>
      </template>
      <template slot="row" slot-scope="props">
        <td style="padding: 0; width: 20px;">
          <span v-if="props.rowData.userDetails.followUpDue">
            <Icon
              name="exclamationThick"
              class="text-pink-500 rounded m-1"
              :size="4"
              v-tooltip="'Follow Up Due'"
            />
          </span>
        </td>
        <td style="padding-left:0">
          <div class="flex flex-row justify-between gap-1">
            <div class="flex flex-col" v-if="props.rowData.primaryContact">
              <span
                class="inline-flex items-center"
                v-if="props.rowData.advertiserId"
              >
                <div>
                  <router-link
                    :to="{
                      name: 'Advertiser',
                      params: { id: props.rowData.advertiserId }
                    }"
                    class="link block"
                  >
                    {{ props.rowData.name }}
                  </router-link>
                </div>
              </span>

              <span v-else>
                <div class="flex flex-row items-center justify-between ">
                  <router-link
                    :to="{
                      name: 'User',
                      params: { id: props.rowData.id }
                    }"
                    class="link block"
                    >{{
                      props.rowData.email.replace('allIn_', '')
                    }}</router-link
                  >
                </div>
              </span>

              <div class="text-gray-600 text-xs">
                <span class="mr-2">{{ props.rowData.id }}</span>
                <span
                  v-if="
                    props.rowData.primaryContact.company &&
                      props.rowData.isAPublisher
                  "
                  class="font-semibold"
                >
                  {{ props.rowData.primaryContact.company }}
                </span>
              </div>

              <div
                v-if="props.rowData.primaryContact.messenger"
                class="flex items-center text-xs"
              >
                <Icon
                  :name="
                    JSON.parse(
                      props.rowData.primaryContact.messenger.toLowerCase()
                    ).app
                  "
                  class="w-4 h-4 text-gray-600 mr-1"
                />
                <span class="text-gray-600">
                  {{
                    JSON.parse(props.rowData.primaryContact.messenger).handle
                  }}
                </span>
              </div>
              <div class="mt-1 flex flex-row items-center">
                <Flag
                  v-if="props.rowData.primaryContact.country"
                  v-tooltip="`${props.rowData.primaryContact.country.label}`"
                  :country="props.rowData.primaryContact.country.code"
                  class="mr-4 rounded-sm overflow-hidden"
                  :size="5"
                ></Flag>
                <tag
                  v-for="role in props.rowData.roles"
                  :key="role.name"
                  class="mr-2"
                  >{{ role.title }}</tag
                >
              </div>
            </div>
            <div class="flex items-center">
              <div
                v-if="duplicateIpHistoryAlert(props.rowData.confidenceScores)"
              >
                <v-popover offset="0" class="p-0">
                  <button class="hover:bg-gray-200 p-2 rounded">
                    <Icon
                      name="bell"
                      :size="5"
                      class="text-orange-600"
                      v-tooltip="`Duplicate IP History Detected`"
                    />
                  </button>

                  <!-- This will be the content of the popover -->
                  <template slot="popover">
                    <div
                      class="p-6 bg-white rounded-lg"
                      style="width:18rem; max-height: 300px; overflow-y: auto;"
                    >
                      <div
                        v-for="(history, index) in duplicateIpHistoryLog(
                          props.rowData.confidenceScores
                        )[0]"
                        :key="index"
                        class="mb-2"
                      >
                        <h3 class="font-semibold mb-1">{{ history.ip }}</h3>
                        <table class="w-full bg-gray-100">
                          <thead>
                            <tr class="text-gray-700">
                              <th>User Id</th>
                              <th>Action Type</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="match in history.matches"
                              :key="match.causerId"
                              style="border-bottom: 1px solid #efefef;"
                            >
                              <td class="pl-2">
                                <router-link
                                  :to="{
                                    name: 'User',
                                    params: { id: match.causerId }
                                  }"
                                  class="link block"
                                  >{{ match.causerId }}</router-link
                                >
                              </td>
                              <td class="text-center">{{ match.type }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </template>
                </v-popover>
              </div>
              <button
                @click="openPriorityStatusModal(props.rowData)"
                class="hover:bg-gray-200 p-1 rounded"
              >
                <Icon
                  :name="
                    priorityIconType(props.rowData.userDetails.priorityStatus)
                  "
                  :size="6"
                  :class="
                    priorityIconColor(props.rowData.userDetails.priorityStatus)
                  "
                  v-tooltip="
                    priorityFollowUp(props.rowData.userDetails.followUpAt)
                  "
                />
              </button>
            </div>
          </div>
        </td>
        <td>
          <div class="flex flex-col gap-1">
            <div v-if="hasAdminTag(props.rowData.tags)">
              <div v-for="tag in adminTags(props.rowData.tags)" :key="tag.id">
                <div class="bg-transparent rounded-full group flex">
                  <Tag
                    v-if="props.rowData.status != 'active'"
                    class="inline-flex"
                    :color="
                      tag.name.includes('rejected') ? 'darkRed' : 'inverse'
                    "
                  >
                    {{ tag.name }}
                  </Tag>
                </div>
              </div>
            </div>
            <div>
              <Tag
                v-tooltip="
                  props.rowData.statusInfo
                    ? props.rowData.statusInfo.reason
                    : ''
                "
                :after-icon="
                  props.rowData.statusInfo && props.rowData.statusInfo.reason
                    ? 'helpCircle'
                    : null
                "
                @click="
                  openSetStatusWidget({
                    modelId: props.rowData.id,
                    currentStatus: props.rowData.statusInfo,
                    userWasAutoRejected: userAutoRejected(props.rowData.tags)
                  })
                "
                >{{ props.rowData.status }}</Tag
              >
            </div>
            <div
              v-if="
                props.rowData.isQuarantined && props.rowData.status == 'active'
              "
            >
              <Tag color="orange"
                >Quarantined<span class="bg-white rounded-lg px-1 ml-1 -mr-1">{{
                  props.rowData.quarantine.level
                }}</span></Tag
              >
            </div>
          </div>
        </td>

        <td>
          <span
            v-if="props.rowData.accountManager"
            class="text-gray-900 text-sm"
          >
            {{ props.rowData.accountManager.primaryContact.name.split(' ')[0] }}
          </span>
        </td>

        <td style="max-width: 10rem;" class="truncate">
          <template v-if="props.rowData.referrer">
            <a
              class="link"
              :href="`/admin/user/${props.rowData.referrer.id}`"
              v-tooltip="props.rowData.referrer.email"
            >
              {{ props.rowData.referrer.id }} -
              {{ props.rowData.referrer.email }}
            </a>
          </template>
        </td>

        <td>
          <span v-if="props.rowData.status == 'active'">{{
            props.rowData.statusInfo.updatedAt | luxon
          }}</span>
          <span v-if="props.rowData.status !== 'active'">
            {{ props.rowData.createdAt | luxon }}
          </span>
        </td>

        <td style="max-width: 10rem;" class="truncate">
          <ul
            v-for="tag in props.rowData.tags.filter(
              item => item.type == 'trafficType'
            )"
            class="mr-1 my-1 text-xs text-gray-800"
            :key="tag.id"
          >
            <li>{{ tag.name }}</li>
          </ul>
        </td>

        <td style="max-width: 10rem;" class="truncate">
          <ul
            v-for="tag in props.rowData.tags.filter(
              item => item.type == 'vertical'
            )"
            class="mr-1 my-1 text-xs text-gray-800"
            :key="tag.id"
          >
            <li>{{ tag.name }}</li>
          </ul>
        </td>
        <td>
          <div
            class="flex flex-row text-xs flex-grow text-left text-gray-800 "
            v-if="props.rowData.commentsLead.length > 0"
          >
            <UserAvatar
              class="mb-1 mr-2"
              height="5"
              :id="props.rowData.commentsLead[0].commenter.id"
            />
            {{ props.rowData.commentsLead[0].createdAt | luxon }}
          </div>
        </td>
        <td class="text-right p-0">
          <v-popover offset="0" class="p-0">
            <button
              class="bg-white-600 hover:bg-gray-100 text-blue-600 font-bold py-3 px-6 rounded truncate"
            >
              <Icon class="w-6 h-6 text-blue-600" name="dotsVertical" />
            </button>

            <!-- This will be the content of the popover -->
            <template slot="popover">
              <ul>
                <li>
                  <Impersonate
                    :userId="props.rowData.id"
                    path="/{scope}/dashboard"
                  >
                    <a
                      @click.prevent
                      :href="`/admin/impersonate/${props.rowData.id}`"
                      class="link py-3 px-4 hover:bg-gray-100 block"
                      >Impersonate</a
                    >
                  </Impersonate>
                </li>
              </ul>
            </template>
          </v-popover>
        </td>
      </template>
    </YTable>

    <YPagination
      v-if="users.paginatorInfo"
      :data="users.paginatorInfo"
      @load-page="loadPage"
      @next-page="nextPage"
      @previous-page="previousPage"
      :key="totalPages"
      @per-page="perPageSelection"
    >
    </YPagination>
  </div>
</template>

<script>
import UserFilters from '@/views/Admin/Users/UserFilters'
import Impersonate from '@/views/Auth/Impersonate'
import USERS_LIGHT_QUERY from '@/graphql/User/UsersLightQuery.gql'
import USER_OPTIONS_QUERY from '@/graphql/User/UserOptionsQuery.gql'
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'
import EXPORT_USERS from '@/graphql/User/ExportUsers.gql'
import { mapState, mapActions } from 'vuex'
import BackToTop from '@/mixins/BackToTopMixin.js'
import YPagination from '@/components/ui/YPagination.vue'
import SetDomainStatusWidget from '@/views/Admin/Status/SetDomainStatusWidget.vue'
import PriorityStyleMixin from '@/mixins/PriorityStyleMixin.js'
import PriorityStatusModal from '@/views/Admin/User/PriorityStatusModal.vue'
import CreateOfferStudioUser from '@/views/Admin/Users/CreateOfferStudioUser.vue'

export default {
  mixins: [BackToTop, PriorityStyleMixin],
  components: {
    UserFilters,
    Impersonate,
    YPagination
  },
  props: {
    filterValues: {
      type: Object
    }
  },
  apollo: {
    countries: {
      query: COUNTRIES_QUERY
    },
    referrerOptions: {
      query: USER_OPTIONS_QUERY,
      variables() {
        return {
          filters: {},
          first: this.perPage
        }
      },
      update: data => data.userOptions
    },
    users: {
      query: USERS_LIGHT_QUERY,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          filters: {
            //id: this.cleanFilter(this.filters.id),
            userAdvertiserId: this.cleanFilter(this.filters.id),
            email: this.cleanFilter(this.filters.email),
            contacts: {
              email: this.cleanFilter(this.filters.contactEmail),
              messenger: this.cleanFilter(this.filters.instantMessengerHandle),
              countryCode: this.cleanObjectFilter(this.filters.country, 'code')
            },
            accountManagers: {
              id: this.cleanObjectFilter(this.filters.accountManager, 'id')
            },
            roles: {
              name: this.cleanFilter(this.filters.role)
            },
            referrer: {
              id: this.cleanObjectFilter(this.filters.referrer, 'id')
            },
            status: this.filters.status.value
              ? [this.filters.status.value]
              : undefined,
            trafficType: this.cleanObjectFilter(
              this.filters.trafficType,
              'name'
            ),
            verticalTypeTags: this.cleanObjectFilter(
              this.filters.verticals,
              'name'
            ),
            referredOption: this.cleanFilter(this.filters.referredOption),
            trackingPlatformTags: this.cleanObjectFilter(
              this.filters.trackingPlatform,
              'name'
            ),
            userPlatform: this.cleanObjectFilter(
              this.filters.userPlatform,
              'id'
            ),
            qualityMetrics: this.filters.qualityMetrics
              ? this.filters.qualityMetrics?.value?.map(item => item.id)
              : [],
            priorityStatus: this.cleanFilter(this.filters.priorityStatus),
            ipHistory: this.filters.ipHistory.value
              ? {
                  value: [''],
                  modifiers: this.filters.ipHistory.modifiers
                }
              : undefined,
            autoRejected: this.filters.autoRejected.value
              ? {
                  value: ['auto-rejected'],
                  modifiers: this.filters.autoRejected.modifiers
                }
              : undefined,
            autoApproved: this.filters.autoApproved.value
              ? {
                  value: ['auto-approved'],
                  modifiers: this.filters.autoApproved.modifiers
                }
              : undefined
          },
          orderBy: this.orderBy,
          first: this.perPage,
          page: this.currentPage
        }
      },
      debounce: 250,
      watchLoading(isLoading, countModifier) {
        this.$store.dispatch('updateGlobalLoader', countModifier)
      },
      result({ data }, key) {
        data ? (this.totalPages = data[key].paginatorInfo.lastPage) : null
      },
      deep: true
    }
  },
  data() {
    return {
      selectedRowIds: [],
      isExporting: false,
      users: {},
      referrerOptions: [],
      currentPage: 1,
      perPage: 20,
      totalPages: 0
    }
  },
  computed: {
    ...mapState({
      filters: state => state.user.filters
    }),
    orderBy: {
      get() {
        return this.$store.state.user.orderBy
      },
      set(value) {
        this.$store.state.user.orderBy = JSON.parse(JSON.stringify(value))
      }
    }
  },
  mounted() {
    this.$events.listen('refetchUsers', () => {
      this.$apollo.queries.users.refetch()
    })
    // If Filter Values are passed in, we reset the defailt user filters and update with new values
    if (this.filterValues) {
      this.$store.dispatch('user/resetFilters')
      for (const [key, value] of Object.entries(this.filterValues)) {
        this.$store.state.user.filters[key].value = value
      }
    }
  },
  updated() {},
  watch: {
    totalPages() {
      this.currentPage = 1
    }
  },
  methods: {
    userAutoRejected(tags) {
      return tags.some(item => item.name == 'auto-rejected')
    },
    hasAdminTag(tags) {
      return tags.some(tag => tag.type === 'admin')
    },
    adminTags(tags) {
      return tags.filter(tag => tag.type === 'admin')
    },
    openOptimizeModal() {
      this.$modal.show(
        CreateOfferStudioUser,
        {},
        {
          height: 'auto',
          width: '80%',
          maxWidth: 1200,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openPriorityStatusModal(data) {
      this.$modal.show(
        PriorityStatusModal,
        { user: data },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 600,

          adaptive: true,
          scrollable: true
        }
      )
    },
    exportUsers() {
      this.$apollo
        .mutate({
          mutation: EXPORT_USERS,
          variables: {
            input: {
              //id: this.cleanFilter(this.filters.id),
              userAdvertiserId: this.cleanFilter(this.filters.id),
              email: this.cleanFilter(this.filters.email),
              contacts: {
                email: this.cleanFilter(this.filters.contactEmail),
                messenger: this.cleanFilter(
                  this.filters.instantMessengerHandle
                ),
                countryCode: this.cleanObjectFilter(
                  this.filters.country,
                  'code'
                )
              },
              accountManagers: {
                id: this.cleanObjectFilter(this.filters.accountManager, 'id')
              },
              roles: {
                name: this.cleanFilter(this.filters.role)
              },
              referrer: {
                id: this.cleanObjectFilter(this.filters.referrer, 'id')
              },
              status: this.filters.status.value
                ? [this.filters.status.value]
                : undefined,
              trafficType: this.cleanObjectFilter(
                this.filters.trafficType,
                'name'
              ),
              verticalTypeTags: this.cleanObjectFilter(
                this.filters.verticals,
                'name'
              ),
              referredOption: this.cleanObjectFilter(
                this.filters.referredOption,
                'name'
              ),
              priorityStatus: this.cleanFilter(this.filters.priorityStatus),
              userPlatform: this.cleanObjectFilter(
                this.filters.userPlatform,
                'id'
              )
            }
          }
        })
        .then(() => {
          this.$toastr.Add({
            type: 'success',
            title: 'File is exporting',
            msg:
              'File will be sent to user email and should be available shortly.',
            position: 'toast-bottom-right',
            timeout: '8000'
          })
        })
        .catch(error => this.$toastr.e(error, 'Something went wrong'))
    },
    iconStyle(score) {
      if (score == 'Low Risk') {
        return 'checkCircle'
      } else if (score == 'Moderate Risk') {
        return 'alert'
      } else {
        return 'alertOctagon'
      }
    },
    iconColor(score) {
      if (score == 'Low Risk') {
        return 'text-blue-500'
      } else if (score == 'Moderate Risk') {
        return 'text-yellow-500'
      } else {
        return 'text-red-500'
      }
    },
    duplicateIpHistoryAlert(values) {
      return values.some(
        value =>
          value.type === 'quality' &&
          value.ipHistory &&
          value.ipHistory.length > 0
      )
    },
    duplicateIpHistoryLog(values) {
      return values
        .filter(value => value.type === 'quality' && value.ipHistory !== null)
        .map(value => value.ipHistory)
    },
    loadPage(page) {
      this.currentPage = page
      this.backToTop()
    },
    nextPage() {
      this.currentPage++
      this.backToTop()
    },
    previousPage() {
      this.currentPage--
      this.backToTop()
    },
    perPageSelection(value) {
      this.perPage = value
    },
    ...mapActions(['updateGlobalLoader']),
    openSetStatusWidget({ modelId, currentStatus, userWasAutoRejected }) {
      this.$modal.show(
        SetDomainStatusWidget,
        {
          modelName: 'User',
          modelIds: [parseInt(modelId)],
          currentStatus: currentStatus ? currentStatus : undefined,
          enumName: 'UserStatusEnum',
          userWasAutoRejected: userWasAutoRejected
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true,
          classes: ['overflow-visible-important']
        }
      )
    },
    cleanFilter(filter) {
      // Return undefined on null values
      // And make sure value is always an array
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    },
    cleanObjectFilter(filter, pluck) {
      // defer to cleanFilter for undefined check and array cast
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }
      // pluck key from value
      return {
        value: this.cleanFilter(filter).value.map(value => value[pluck]),
        modifiers: filter.modifiers
      }
    }
  }
}
</script>
<style></style>
