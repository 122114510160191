<template>
  <div id="top">
    <portal to="top-bar-right">
      <div class="flex justify-right">
        <YButton class="btn btn-blue" @click="openUpsertModal('create')">
          New Conversion Type
        </YButton>
      </div>
    </portal>
    <Widget class="mb-1 overflow-visible">
      <WidgetHeader class="bg-gray-200 p-1 border-b">
        <template slot="rawContent">
          <ConversionTypeFilters></ConversionTypeFilters>
        </template>
      </WidgetHeader>
    </Widget>
    <YTable
      v-if="conversionTypes"
      :data="conversionTypes.data || []"
      :bottom-loading="$apolloData.queries.conversionTypes.loading"
      class="mt-2"
    >
      <template slot="header-row">
        <th>conversion Type</th>
        <th>payout type</th>
        <th></th>
      </template>
      <template slot="row" slot-scope="props">
        <td>
          {{ props.rowData.name }}
        </td>
        <td class="uppercase">
          {{ props.rowData.payoutType }}
        </td>
        <td class="text-right">
          <div class="inline-flex items-center">
            <a
              href="#"
              class="ml-2 text-blue-500 hover:text-blue-600"
              @click.prevent="openUpsertModal('update', props.rowData)"
            >
              <Icon name="pencil" class="w-4 h-4" />
            </a>
          </div>
          <div class="inline-flex items-center">
            <a
              href="#"
              class="ml-2 text-red-500 hover:text-red-600"
              @click.prevent="openDeleteModal(props.rowData)"
            >
              <Icon name="trashCan" class="w-4 h-4" />
            </a>
          </div>
        </td>
      </template>
    </YTable>
    <div class="w-100 py-4">
      <YPagination
        v-if="conversionTypes.paginatorInfo"
        :data="conversionTypes.paginatorInfo"
        @load-page="loadPage"
        @next-page="nextPage"
        @previous-page="previousPage"
        :key="totalPages"
        :onEachSide="2"
        @per-page="perPageSelection"
      ></YPagination>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ConversionTypeFilters from '@/views/Admin/ConversionTypes/ConversionTypesFilter.vue'
import CONVERSION_TYPE_QUERY from '@/graphql/ConversionType/ConversionTypesQuery.gql'
import DeleteConversionType from '@/views/Admin/ConversionTypes/DeleteConversionType.vue'
import UpsertConversionTypeModal from '@/views/Admin/ConversionTypes/UpsertConversionType.vue'
import BackToTop from '@/mixins/BackToTopMixin.js'
import YPagination from '@/components/ui/YPagination.vue'

export default {
  mixins: [BackToTop],
  components: {
    ConversionTypeFilters,
    YPagination
  },
  apollo: {
    conversionTypes: {
      query: CONVERSION_TYPE_QUERY,
      variables() {
        return {
          first: this.perPage,
          page: this.currentPage,
          orderBy: [{ column: 'slug', order: 'ASC' }],
          filters: {
            name: this.cleanFilter(this.filters.conversionType),
            type: this.cleanFilter(this.filters.type)
          }
        }
      },
      result({ data }, key) {
        this.totalPages = data[key].paginatorInfo.lastPage
      },
      deep: true
    }
  },
  data() {
    return {
      conversionTypes: [],
      currentPage: 1,
      perPage: 20,
      totalPages: 1,
      filterName: null
    }
  },
  computed: {
    ...mapState({
      filters: state => state.conversionType.filters
    }),
    orderBy: {
      get() {
        return this.$store.state.conversionType.orderBy
      },
      set(value) {
        this.$store.state.conversionType.orderBy = JSON.parse(
          JSON.stringify(value)
        )
      }
    }
  },
  updated() {},
  mounted() {
    this.$events.listen('refreshConversionTypes', () => {
      this.$apollo.queries.conversionTypes.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('refreshConversionTypes')
  },
  watch: {
    totalPages() {
      this.currentPage = 1
    }
  },
  methods: {
    loadPage(page) {
      this.currentPage = page
      this.backToTop()
    },
    nextPage() {
      this.currentPage++
      this.backToTop()
    },
    previousPage() {
      this.currentPage--
      this.backToTop()
    },
    perPageSelection(value) {
      this.perPage = value
    },
    ...mapActions(['updateGlobalLoader']),
    cleanFilter(filter) {
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    },
    cleanObjectFilter(filter, pluck) {
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }

      // pluck key from value
      return {
        value: this.cleanFilter(filter).value.map(value => value[pluck]),
        modifiers: filter.modifiers
      }
    },
    openDeleteModal(data) {
      this.$modal.show(
        DeleteConversionType,
        { data: data },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openUpsertModal(value, rowData) {
      this.$modal.show(
        UpsertConversionTypeModal,
        { mutationType: value, conversionTypeObject: rowData || undefined },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    }
  }
}
</script>

<style scoped></style>
