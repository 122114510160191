var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Widget', [_c('WidgetHeader', {
    staticClass: "bg-gray-200 p-1 border-b"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('RuleFilters', {
    attrs: {
      "order-by": _vm.orderBy,
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "default-values": _vm.defaultFilterValues,
      "hide": _vm.hideFilters,
      "filterable-polarity": false,
      "hide-all-offer-filters": true
    },
    on: {
      "rulesFetched": _vm.updateRules,
      "loading": _vm.updateLoading
    }
  })], 1)], 2)], 1), _c('div', {
    staticClass: "flex justify-between items-center mt-4 px-4 py-4 rounded-lg bg-gray-200"
  }, [_c('div', {
    staticClass: "text-muted"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.selectedCount) + " " + _vm._s(_vm.selectedCount == 1 ? 'item' : 'items') + " selected ")])]), _c('div', {
    staticClass: "flex gap-4"
  }, [_c('YButton', {
    attrs: {
      "color": "red",
      "disabled": !_vm.rowsAreSelected
    },
    on: {
      "click": _vm.openDeleteRulesModal
    }
  }, [_vm._v("Delete")]), _c('YButton', {
    attrs: {
      "color": "blue",
      "disabled": _vm.rowsAreSelected
    },
    on: {
      "click": _vm.openCreateModal
    }
  }, [_vm._v("Create Rules")])], 1)]), _vm.rules && _vm.rules.data ? _c('OfferPerspectiveRulesTable', {
    staticClass: "pt-0",
    attrs: {
      "rules": _vm.rules.data || [],
      "order-by": _vm.orderBy,
      "bottom-loading": _vm.loading,
      "rulesParentComponent": _vm.rulesParentComponent,
      "hide": ['filterable']
    }
  }) : _vm._e(), _vm.rules && _vm.rules.paginatorInfo ? _c('YPagination', {
    key: _vm.rules.paginatorInfo.lastPage,
    attrs: {
      "data": _vm.rules.paginatorInfo
    },
    on: {
      "load-page": _vm.loadPage,
      "next-page": _vm.nextPage,
      "previous-page": _vm.previousPage,
      "per-page": _vm.perPageSelection
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }