<template>
  <portal to="sidebar-right">
    <SideBarRightItem v-if="userIsLead" title="Convert to Active User">
      <YButton
        color="pink"
        @click="openConvertToActiveModal(advertiser)"
        class="mx-4"
        >Convert to Active</YButton
      >
    </SideBarRightItem>
    <SideBarRightItem>
      <template v-slot:root>
        <div
          class="x-header group cursor-pointer gap-4"
          :key="advertiser.accountManager ? advertiser.accountManager.id : 0"
          @click="openSyncAccountManagerWidget()"
        >
          <div class="x-icon-container">
            <UserAvatar
              v-if="advertiser.accountManager"
              :id="advertiser.accountManager.id"
              height="10"
            ></UserAvatar>
            <div
              v-else
              class="
                  text-white
                  w-10
                  h-10
                  text-center
                  leading-10
                  rounded-full
                  font-extrabold
                  bg-gray-300
                "
            >
              <Icon name="accountQuestion" :size="10" class="p-2" />
            </div>
          </div>
          <div class="x-header-text flex-1">
            <div class="text-xs font-semibold text-gray-500">
              Account Manager
            </div>
            <div class="text-gray-700">
              <template
                v-if="
                  advertiser.accountManager &&
                    advertiser.accountManager.primaryContact
                "
              >
                {{ advertiser.accountManager.primaryContact.name }}
              </template>
              <template v-else>
                <span class="text-gray-400"> Not Assigned</span>
              </template>
            </div>
          </div>
          <Icon
            name="pencil"
            class="text-blue-600 mx-2 invisible group-hover:visible"
          />
        </div>
      </template>
    </SideBarRightItem>

    <SideBarRightItem title="Traffic Sources">
      <EditableValue
        ref="trafficSourcesEditable"
        class="px-3"
        :value="advertiser.tags.filter(tag => tag.type === 'trafficType')"
        type="slot"
        :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
        sortBy="id"
        :transformValueForQuery="
          value => {
            return value.map(tag => tag.name)
          }
        "
        :variables="{
          input: {
            taggableId: advertiser.id,
            type: 'trafficType',
            taggableType: 'user',
            tags: '{value}'
          }
        }"
      >
        <template v-slot:default="slotProps">
          <div v-if="slotProps.value && slotProps.value.length > 0">
            <Tag
              v-for="tag in slotProps.value"
              :key="tag.id"
              class="mr-1 my-1"
              color="orange"
              tabindex="0"
              @focus="
                () => {
                  slotProps.focus()
                }
              "
            >
              {{ tag.name }}
            </Tag>
          </div>

          <div
            v-else
            @click="
              () => {
                slotProps.focus()
              }
            "
          >
            <span class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
              >None</span
            >
          </div>
        </template>
        <template v-slot:focusSlot="slotProps">
          <Superselect
            title="Tags"
            :value="slotProps.value"
            @input="
              value => {
                slotProps.updateInternalValue(value)
              }
            "
            :focusOnMount="true"
            :multiple="true"
            track-by="slug"
            label="name"
            placeholder="Select"
            class="box-width min-width-full"
            :query="TAGS_QUERY"
            :query-variables="{
              filters: {
                type: { value: ['trafficType'] }
              },
              first: 100
            }"
          ></Superselect>
        </template>
      </EditableValue>
    </SideBarRightItem>

    <SideBarRightItem title="Verticals">
      <EditableValue
        ref="verticalsEditable"
        class="px-3"
        :value="advertiser.tags.filter(tag => tag.type === 'vertical')"
        type="slot"
        :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
        sortBy="id"
        :transformValueForQuery="
          value => {
            return value.map(tag => tag.name)
          }
        "
        :variables="{
          input: {
            taggableId: advertiser.id,
            type: 'vertical',
            taggableType: 'user',
            tags: '{value}'
          }
        }"
      >
        <template v-slot:default="slotProps">
          <div v-if="slotProps.value && slotProps.value.length > 0">
            <Tag
              v-for="tag in slotProps.value"
              :key="tag.id"
              class="mr-1 my-1"
              color="blue"
              tabindex="0"
              @focus="
                () => {
                  slotProps.focus()
                }
              "
            >
              {{ tag.name }}
            </Tag>
          </div>

          <div
            v-else
            @click="
              () => {
                slotProps.focus()
              }
            "
          >
            <span class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
              >None</span
            >
          </div>
        </template>
        <template v-slot:focusSlot="slotProps">
          <Superselect
            title="Tags"
            :value="slotProps.value"
            @input="
              value => {
                slotProps.updateInternalValue(value)
              }
            "
            :focusOnMount="true"
            :multiple="true"
            track-by="slug"
            label="name"
            placeholder="Select"
            class="box-width min-width-full"
            :query="TAGS_QUERY"
            :query-variables="{
              filters: {
                type: { value: ['vertical'] }
              },
              first: 100
            }"
          ></Superselect>
        </template>
      </EditableValue>
    </SideBarRightItem>

    <SideBarRightItem title="Preferred Geos">
      <EditableValue
        ref="geosEditable"
        class="px-3"
        :value="advertiser.tags.filter(tag => tag.type === 'countryCode')"
        type="slot"
        :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
        sortBy="id"
        :transformValueForQuery="
          value => {
            return value.map(tag => tag.name)
          }
        "
        :variables="{
          input: {
            taggableId: advertiser.id,
            type: 'countryCode',
            taggableType: 'user',
            tags: '{value}'
          }
        }"
      >
        <template v-slot:default="slotProps">
          <div v-if="slotProps.value && slotProps.value.length > 0">
            <Tag
              v-for="tag in slotProps.value"
              :key="tag.id"
              class="mr-1 my-1"
              color="green"
              tabindex="0"
              @focus="
                () => {
                  slotProps.focus()
                }
              "
            >
              {{ tag.name }}
            </Tag>
          </div>

          <div
            v-else
            @click="
              () => {
                slotProps.focus()
              }
            "
          >
            <span class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
              >None</span
            >
          </div>
        </template>
        <template v-slot:focusSlot="slotProps">
          <Superselect
            title="Tags"
            :value="slotProps.value"
            @input="
              value => {
                slotProps.updateInternalValue(value)
              }
            "
            :focusOnMount="true"
            :multiple="true"
            track-by="slug"
            label="name"
            placeholder="Select"
            class="box-width min-width-full"
            :query="TAGS_QUERY"
            :query-variables="{
              filters: {
                type: { value: ['countryCode'] }
              },
              first: 100
            }"
          ></Superselect>
        </template>
      </EditableValue>
    </SideBarRightItem>

    <SideBarRightItem title="Regional Geos">
      <EditableValue
        ref="geosEditable"
        class="px-3"
        :value="advertiser.tags.filter(tag => tag.type === 'geoLocation')"
        type="slot"
        :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
        sortBy="id"
        :transformValueForQuery="
          value => {
            return value.map(tag => tag.name)
          }
        "
        :variables="{
          input: {
            taggableId: advertiser.id,
            type: 'geoLocation',
            taggableType: 'user',
            tags: '{value}'
          }
        }"
      >
        <template v-slot:default="slotProps">
          <div v-if="slotProps.value && slotProps.value.length > 0">
            <Tag
              v-for="tag in slotProps.value"
              :key="tag.id"
              class="mr-1 my-1"
              color="green"
              tabindex="0"
              @focus="
                () => {
                  slotProps.focus()
                }
              "
            >
              {{ tag.name }}
            </Tag>
          </div>

          <div
            v-else
            @click="
              () => {
                slotProps.focus()
              }
            "
          >
            <span class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
              >None</span
            >
          </div>
        </template>
        <template v-slot:focusSlot="slotProps">
          <Superselect
            title="Tags"
            :value="slotProps.value"
            @input="
              value => {
                slotProps.updateInternalValue(value)
              }
            "
            :focusOnMount="true"
            :multiple="true"
            track-by="slug"
            label="name"
            placeholder="Select"
            class="box-width min-width-full"
            :query="TAGS_QUERY"
            :query-variables="{
              filters: {
                type: { value: ['geoLocation'] }
              },
              first: 100
            }"
          ></Superselect>
        </template>
      </EditableValue>
    </SideBarRightItem>

    <SideBarRightItem title="Tracking Platform">
      <EditableValue
        ref="trackingPlatformEditable"
        class="px-3"
        :value="advertiser.tags.filter(tag => tag.type === 'trackingPlatform')"
        type="slot"
        :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
        sortBy="id"
        :transformValueForQuery="
          value => {
            return value.map(tag => tag.name)
          }
        "
        :variables="{
          input: {
            taggableId: advertiser.id,
            type: 'trackingPlatform',
            taggableType: 'user',
            tags: '{value}'
          }
        }"
      >
        <template v-slot:default="slotProps">
          <div v-if="slotProps.value && slotProps.value.length > 0">
            <Tag
              v-for="tag in slotProps.value"
              :key="tag.id"
              class="mr-1 my-1"
              color="purple"
              tabindex="0"
              @focus="
                () => {
                  slotProps.focus()
                }
              "
            >
              {{ tag.name }}
            </Tag>
          </div>

          <div
            v-else
            @click="
              () => {
                slotProps.focus()
              }
            "
          >
            <span class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
              >None</span
            >
          </div>
        </template>
        <template v-slot:focusSlot="slotProps">
          <Superselect
            title="Tags"
            :value="slotProps.value"
            @input="
              value => {
                slotProps.updateInternalValue(value)
              }
            "
            :focusOnMount="true"
            :multiple="true"
            track-by="slug"
            label="name"
            placeholder="Select"
            class="box-width min-width-full"
            :query="TAGS_QUERY"
            :query-variables="{
              filters: {
                type: { value: ['trackingPlatform'] }
              },
              first: 100
            }"
          ></Superselect>
        </template>
      </EditableValue>
    </SideBarRightItem>

    <SideBarRightItem
      title="Comments"
      icon="message"
      :count="advertiser.comments ? advertiser.comments.length : null"
      class="flex-1"
    >
      <SideBarComments
        :comments="advertiser.commentsAdmin"
        :modelId="advertiser.id"
        modelClass="User"
        cas
      ></SideBarComments>
    </SideBarRightItem>
  </portal>
</template>

<script>
import LeadToActive from '@/views/Admin/Leads/LeadToActive.vue'
import SyncAccountManagerWidget from '@/views/Admin/User/SyncAccountManagerWidget'
import SYNC_TAGS_WITH_TYPE_MUTATION from '@/graphql/Tag/SyncTagsWithTypeMutation.gql'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'
export default {
  components: {},
  props: {
    advertiser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      TAGS_QUERY,
      SYNC_TAGS_WITH_TYPE_MUTATION
    }
  },
  computed: {
    userIsLead() {
      return this.advertiser?.roles.some(role => role.name === 'lead') || false
    }
  },
  methods: {
    openConvertToActiveModal(data) {
      this.$modal.show(
        LeadToActive,
        { lead: data },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openSyncAccountManagerWidget(autoSubmit = false) {
      this.$modal.show(
        SyncAccountManagerWidget,
        {
          defaultManager: this.advertiser.accountManager
            ? this.advertiser.accountManager
            : null,
          userId: parseInt(this.advertiser.id),
          lead: this.advertiser,
          autoSubmit: autoSubmit
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true,
          classes: ['overflow-visible-important']
        }
      )
    }
  }
}
</script>
