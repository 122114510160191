<template>
  <div
    class="m-auto bg-white rounded-b-xl mb-12 border-l border-r border-b"
    style="max-width: 24rem;"
  >
    <transition name="fade">
      <form v-on:submit.prevent="register" class="w-full" v-if="isLoading">
        <div class="flex flex-col px-6">
          <div class="mt-6 font-bold text-lg text-inverse-700">
            Publisher Registration
          </div>
          <!-- login details -->
          <div class="mt-3">
            <p class="pb-2 font-semibold uppercase text-inverse-700">
              Login Details
            </p>
            <div class="grid grid-rows-1 gap-3">
              <YInput label="Email" v-model="email" :required="true" />
              <YInput
                label="Password"
                v-model="password"
                type="password"
                :required="true"
              />
              <YInput
                label="Confirm Password"
                v-model="passwordConfirmation"
                type="password"
                :required="true"
              />
            </div>
          </div>
          <!-- userdetails -->
          <div class="mt-4">
            <p class="pt-5 pb-2 font-semibold uppercase text-inverse-700">
              User Details
            </p>
            <div class="grid grid-rows-1 gap-3">
              <YInput label="Full Name" v-model="name" :required="true" />
              <YInput label="Company" v-model="company" />
              <Superselect
                v-model="country"
                name="country"
                title="Country"
                label="name"
                track-by="code"
                :options="countries"
                :multiple="false"
                :required="true"
              />
              <YInput
                label="Region"
                v-model="region"
                :disabled="isDisabled"
                :required="true"
              />
              <div class="flex">
                <Superselect
                  v-model="messengerType"
                  name="Messenger Type"
                  title="Type"
                  label="name"
                  track-by="id"
                  :options="types"
                  :multiple="false"
                  select-class="rounded rounded-r-none border-r-0"
                  :required="true"
                />
                <YInput
                  label="Messenger Handle"
                  v-model="messengerHandle"
                  class="w-full"
                  input-class="rounded-l-none border-l-0"
                  :required="true"
                />
              </div>
              <YInput label="Phone" v-model="phone" />
            </div>
          </div>
          <!-- referrals -->
          <div class="mt-8">
            <p class="x-question-header">Where did you hear about YTZ?</p>
            <div>
              <Superselect
                title="Referred by"
                :options="referredTypeOptions.map(ref => ref.type)"
                v-model="referredByQuestion"
                :multiple="false"
                :required="true"
              ></Superselect>
            </div>
            <div
              class="hidden"
              :class="{ referralOptionsHidden: referralOptionsHidden }"
            >
              <div v-if="!referralInput" class="mt-2">
                <Superselect
                  title="Referral Options"
                  :options="referralOptions"
                  v-model="referredByResponse"
                  :disabled="!referredByQuestion"
                  :multiple="false"
                  class="mt-1"
                  :required="true"
                />
              </div>
              <div
                v-if="referralInput || referredByResponse == 'Other'"
                class="mt-2"
              >
                <YTextarea
                  v-model="newReferral"
                  label="How did you hear about us"
                  required
                ></YTextarea>
              </div>
            </div>

            <p class="x-question-header">
              What type of offers / verticals are you interested in?
            </p>

            <Superselect
              title="Offers / Verticals"
              :options="verticalTagsOptions"
              v-model="verticalTypeTags"
              :required="true"
            ></Superselect>

            <p class="x-question-header">What geos are you interested in?</p>

            <Superselect
              title="Geos"
              :options="geoTagsOptions"
              v-model="geoLocations"
              :required="true"
            ></Superselect>

            <p
              class="x-question-header"
              v-text="question1"
              style="display: inline"
            ></p>
            <span class="x-asterisk">*</span>
            <YTextarea
              v-model="response1"
              required
              label="List other networks"
            ></YTextarea>

            <p class="x-question-header">Traffic Sources</p>

            <Superselect
              title="Traffic Sources"
              :options="trafficTagsOptions"
              v-model="trafficType"
              :required="true"
            ></Superselect>
          </div>

          <!-- submit -->
          <div class="mt-10">
            <YButton
              color="pink"
              :is-loading="isSubmitting"
              style="width: 100%"
              :disabled="fieldsCheck"
              >Register</YButton
            >
            <div
              class="flex justify-between border-t border-gray-500 pt-4 pb-8 px-2 mt-3"
            >
              <p class="text-gray-700">Already have an account?</p>
              <router-link :to="{ name: 'Login' }">
                <p class="link">Sign In</p>
              </router-link>
            </div>
          </div>
        </div>

        <div class="px-10">
          <portal-target name="registration-errors"></portal-target>
        </div>

        <ValidationErrors
          v-if="validationErrors"
          :errors="validationErrors"
          portal-to="registration-errors"
          alert-class="text-xs"
          :icon-size="12"
          :icon="null"
          color="yellow"
        ></ValidationErrors>
      </form>
    </transition>
    <div class="w-full" v-if="!isLoading">
      <div class="flex flex-col gap-3 rounded-2xl p-5 pt-8">
        <div class="h-6 w-4/5 rounded-lg is-loading"></div>
        <div class="skeleton-label is-loading"></div>
        <div class="skeleton-input is-loading"></div>
        <div class="skeleton-input is-loading"></div>
        <div class="skeleton-input is-loading"></div>
        <div class="skeleton-label is-loading mt-6"></div>
        <div class="skeleton-input is-loading"></div>
        <div class="skeleton-input is-loading"></div>
        <div class="skeleton-input is-loading"></div>
        <div class="skeleton-input is-loading"></div>
        <div class="skeleton-input is-loading"></div>
        <div class="skeleton-input is-loading"></div>
        <div class="skeleton-label is-loading mt-6"></div>
        <div class="skeleton-input is-loading"></div>
        <div class="h-20 w-full rounded-lg is-loading mt-6"></div>
      </div>
    </div>
  </div>
</template>

<script>
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'
import REGISTER_MUTATION from '@/graphql/Auth/RegisterMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'
import ReferredTypeTagMixin from '@/mixins/ReferredTypeTagMixin.js'

export default {
  mixins: [ValidationErrorsMixin, ReferredTypeTagMixin],
  apollo: {
    countries: {
      query: COUNTRIES_QUERY,
      variables() {
        return {
          orderBy: [
            {
              column: 'name',
              order: 'ASC'
            }
          ]
        }
      },
      result({ data }) {
        this.countries = data.countries.filter(country => {
          const exclude = ['?', 'A1', 'A2']
          return !exclude.includes(country.code)
        })
      }
    },
    tags: {
      query: TAGS_QUERY,
      variables() {
        return {
          first: 999,
          filters: {}
        }
      }
    }
  },
  data() {
    return {
      isSubmitting: false,
      email: '',
      password: '',
      passwordConfirmation: '',
      name: '',
      company: '',
      country: '',
      region: '',
      messengerType: '',
      messengerHandle: '',
      phone: '',
      referredByQuestion: '',
      referredByResponse: '',
      question1: 'Which other networks do you work with?',
      response1: '',
      trafficType: null,
      verticalTypeTags: null,
      types: [
        { id: 1, name: 'Skype' },
        { id: 2, name: 'Telegram' }
      ],
      referrerId: null,
      newReferral: '',
      geoLocations: null
    }
  },
  updated() {},
  mounted() {
    if (this.$route.query.ref) {
      localStorage.setItem('referrerId', this.$route.query.ref)
    }
    this.referrerId = localStorage.getItem('referrerId')
  },
  methods: {
    register(e) {
      e.preventDefault()
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: REGISTER_MUTATION,
          variables: {
            input: {
              email: this.email,
              password: this.password,
              password_confirmation: this.passwordConfirmation,
              name: this.name,
              company: this.company,
              country: this.country.code,
              region: this.region,
              messengerType: this.messengerType.name,
              messengerHandle: this.messengerHandle,
              phone: this.phone,
              questions: {
                question1: {
                  question: this.question1,
                  answer: this.response1
                }
              },
              trafficType: this.trafficType,
              verticalTypeTags: this.verticalTypeTags,
              referrerId: this.referrerId,
              referredOption: this.referralTagComputed(),
              geoLocation: this.geoLocations
            }
          }
        })
        .then(response => {
          this.isSubmitting = false
          this.clearValidationErrors()

          response.data.register.status == 'USER_APPROVED'
            ? this.$router.push('/registered')
            : this.$router.push('/registration-complete')
        })
        .catch(error => {
          this.isSubmitting = false
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    referralTagComputed() {
      let selection = this.referredByQuestion
      switch (selection) {
        case 'Conference':
          return this.referredByResponse
        case 'YTZ Employee':
          if (this.referredByQuestion && this.referredByResponse) {
            return this.referredByQuestion + ' - ' + this.referredByResponse
          } else {
            return null
          }
        case 'YouTube':
        case 'Facebook':
        case 'LinkedIn':
        case 'Newsletter':
        case 'Online Search':
          return this.referredByQuestion
        case 'Referred by YTZ User':
          return 'YTZ User'
        case 'Affiliate Forum':
        case 'Other':
          return this.referredByQuestion + ' - ' + this.newReferral
        default:
          return null
      }
    }
  },
  computed: {
    isLoading() {
      return this.tags && this.referredTypeOptions
    },

    fieldsCheck() {
      let result = this.referralTagComputed()
      let allTagReferralsFilled =
        this.verticalTypeTags?.length > 0 &&
        this.geoLocations?.length > 0 &&
        this.response1 !== undefined &&
        this.response1 !== null &&
        this.response1 !== '' &&
        this.trafficType?.length > 0

      return !(result && allTagReferralsFilled)
    },
    isDisabled() {
      return this.country ? false : true
    },
    trafficTagsOptions() {
      let tags = this.tags.data
        .filter(tag => tag.type === 'trafficType')
        .map(tag => tag.name)

      return tags
    },
    verticalTagsOptions() {
      let tags = this.tags.data
        .filter(tag => tag.type === 'vertical')
        .map(tag => tag.name)

      return tags
    },
    referralOptions() {
      let result = this.referredTypeOptions
        .filter(referredBy => referredBy.type == this.referredByQuestion)
        .map(referredBy => referredBy.options)
      return result[0]
    },
    geoTagsOptions() {
      let tags = this.tags.data
        .filter(tag => tag.type === 'geoLocation')
        .map(tag => tag.name)

      return tags
    },
    referralInput() {
      let type = this.referredByQuestion
      switch (type) {
        case 'Other':
        case 'Affiliate Forum':
          return true
        default:
          return false
      }
    },
    referralOptionsHidden() {
      let selection = this.referredByQuestion
      switch (selection) {
        case 'Conference':
        case 'YTZ Employee':
        case 'Affiliate Forum':
        case 'Other':
          return true
        default:
          return false
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.8s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.skeleton-input {
  @apply h-12 w-full rounded-lg;
}
.skeleton-label {
  @apply h-6 w-3/5 rounded-lg;
}
.is-loading {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 75% 100%;
  animation-duration: 1500ms;
  animation-name: headerShine;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  animation-timing-function: ease;
  background-position: 0 0;
  background-color: #e4e4e7;
  background-blend-mode: overlay;
}
@keyframes headerShine {
  0% {
    background-position: -300% 0;
  }
  100% {
    background-position: 500% 0;
  }
}

.referralOptionsHidden {
  @apply block;
}
.x-question-header {
  @apply pb-2 text-inverse-700 pt-3 leading-4;
}
.x-question-header:first-child {
  @apply pt-0;
}
.x-asterisk {
  @apply text-lg text-red-500 text-2xl inline-block;
  transform: translate(0, 0.2rem);
}
</style>
