<template>
  <widget>
    <widget-header @close="$emit('close')">
      {{ mutationType == 'create' ? 'Create New' : 'Update' }} Conversion Type
    </widget-header>
    <widget-body>
      <form class="row" @submit.prevent="update" ref="form">
        <div class="col">
          <div class="row">
            <div class="input-group flex flex-col gap-2">
              <YInput
                v-model="conversionType.name"
                label="Name"
                :disabled="mutationType == 'update'"
                :required="true"
              ></YInput>
              <Superselect
                v-model="conversionType.payoutType"
                title="Payout Type"
                :options="payoutTypeOptions"
                :multiple="false"
                :required="true"
              ></Superselect>
            </div>
          </div>
        </div>
      </form>
    </widget-body>
    <widget-footer class="bg-gray-100 py-2 px-4 text-right">
      <YButton
        class="btn btn-blue"
        :is-loading="isSubmitting"
        @click="save(mutationType)"
        @keydown.enter="save(mutationType)"
        >{{ mutationType == 'create' ? 'Create' : 'Update' }} Conversion
        Type</YButton
      >
    </widget-footer>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </widget>
</template>

<script>
import gql from 'graphql-tag'
import CREATE_CONVERSION_TYPE_MUTATION from '@/graphql/ConversionType/CreateConversionType.gql'
import UPDATE_CONVERSION_TYPE_MUTATION from '@/graphql/ConversionType/UpdateConversionType.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    mutationType: {
      type: String,
      required: true
    },
    conversionTypeObject: {
      type: Object
    }
  },
  apollo: {
    payoutTypeOptions: {
      query: gql`
        {
          __type(name: "CampaignPayoutTypeEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      update(data) {
        return data.__type.enumValues.map(item => item.name) || []
      }
    }
  },
  data() {
    return {
      conversionType: {
        name: '',
        slug: '',
        payoutType: ''
      },
      isSubmitting: false
    }
  },
  watch: {
    conversionTypeObject: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.conversionType.name = newVal.name || ''
          this.conversionType.slug = newVal.slug || ''
          this.conversionType.payoutType = newVal.payoutType || ''
        }
      }
    }
  },
  methods: {
    save(value) {
      value === 'create' ? this.create() : this.update()
    },
    create() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: CREATE_CONVERSION_TYPE_MUTATION,
          variables: {
            input: {
              name: this.conversionType.name,
              payoutType: this.conversionType.payoutType
            }
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$events.emit('refreshConversionTypes')
          this.$toastr.s('Conversion Type Created', 'Success!')
          this.$emit('close')
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.isSubmitting = false

          console.log(error)
        })
    },
    update() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: UPDATE_CONVERSION_TYPE_MUTATION,
          variables: {
            slug: this.conversionType.slug,
            payoutType: this.conversionType.payoutType
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$events.emit('refreshConversionTypes')
          this.$toastr.s('Conversion Type Updated', 'Success!')
          this.$emit('close')
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.isSubmitting = false

          console.log(error)
        })
    }
  },
  mounted() {},
  updated() {}
}
</script>
