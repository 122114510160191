<template>
  <span>
    <ToggleButton
      v-model="toggleStatus"
      :sync="true"
      :color="{ checked: '#41b883', unchecked: '#888888', disabled: '#CCCCCC' }"
      :labels="{ checked: 'Active', unchecked: 'Paused' }"
      :width="width"
      :height="height"
      :font-size="fontSize"
      @change="updateStatus"
      style="margin: 0px;"
    />
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </span>
</template>

<script>
//import AdminCampaignPausedEmailPrompt from './AdminCampaignPausedEmailPrompt'
import AdminCampaignPausedActionsPrompt from './AdminCampaignPausedActionsPrompt'
import AdminCampaignReActivateActionsPrompt from './AdminCampaignReActivateActionsPrompt'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import HasStatusMixin from '@/mixins/HasStatusMixin'
import { ToggleButton } from 'vue-js-toggle-button'

export default {
  mixins: [ValidationErrorsMixin, HasStatusMixin],
  components: {
    ToggleButton
  },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    height: {
      cast: Number,
      default: 35
    },
    width: {
      cast: Number,
      default: 90
    },
    fontSize: {
      cast: Number,
      default: 12
    }
  },
  data() {
    return {
      toggleStatus: this.campaign.status == 'active' ? true : false
    }
  },
  computed: {},
  mounted() {},
  updated() {},
  methods: {
    updateStatus(event) {
      if (!this.campaign.showFrontend) {
        this.$toastr.e('Show Front-end is required to activate this campaign')
        this.toggleStatus = this.campaign.status == 'active' ? true : false
        return
      }

      let state = event.value
      this.setStatus({
        modelName: 'Campaign',
        modelIds: [this.campaign.id],
        name: state ? 'active' : 'paused',
        reason: `Updated by ${this.$store.state.auth.authId}`
      })
    },
    onSetStatusSuccess(resultModels) {
      resultModels.forEach(resultModel => {
        if (resultModel.status == 'paused') {
          this.showCampaignPausedActionsModal()
        } else {
          this.showCampaignReActivateActionsModal()
        }
      })
    },
    showCampaignPausedActionsModal() {
      this.$modal.show(
        AdminCampaignPausedActionsPrompt,
        { campaign: this.campaign, id: this.campaign.id },
        { height: 'auto', scrollable: true }
      )
    },
    showCampaignReActivateActionsModal() {
      this.$modal.show(
        AdminCampaignReActivateActionsPrompt,
        { campaign: this.campaign, id: this.campaign.id },
        { height: 'auto', scrollable: true }
      )
    }
  }
}
</script>
<style scoped></style>
