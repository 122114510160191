var render = function render(){
  var _vm$userGlobalPayouts;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "relative border border-gray-400"
  }, [_c('div', {
    staticClass: "bg-gray-100 pt-2 pl-4 pb-0 uppercase text-gray-800 font-bold"
  }, [_vm._v(" Global Level Payouts ")]), _c('div', {
    staticClass: "bg-gray-100 flex gap-4 px-4 py-2 border-b"
  }, [_c('Superselect', {
    staticClass: "flex-grow",
    attrs: {
      "title": "Country",
      "options": _vm.countries,
      "required": true,
      "multiple": false,
      "track-by": "code",
      "label": "label"
    },
    model: {
      value: _vm.globalCountry,
      callback: function ($$v) {
        _vm.globalCountry = $$v;
      },
      expression: "globalCountry"
    }
  }), _c('Superselect', {
    staticClass: "w-32",
    attrs: {
      "title": "Type",
      "required": true,
      "multiple": false,
      "options": ['cpa', 'cpl']
    },
    model: {
      value: _vm.globalType,
      callback: function ($$v) {
        _vm.globalType = $$v;
      },
      expression: "globalType"
    }
  }), _c('YInput', {
    staticClass: "w-32",
    attrs: {
      "label": "Value",
      "required": true
    },
    model: {
      value: _vm.globalValue,
      callback: function ($$v) {
        _vm.globalValue = $$v;
      },
      expression: "globalValue"
    }
  }), _c('div', {
    staticClass: "flex items-center justify-center w-12"
  }, [_c('YButton', {
    staticClass: "px-3",
    attrs: {
      "color": "link",
      "is-loading": _vm.isUpsertingGlobal,
      "disabled": _vm.isUpsertingGlobal
    },
    on: {
      "click": _vm.createGlobalPayout
    }
  }, [!_vm.isUpsertingGlobal ? _c('Icon', {
    attrs: {
      "name": "plus"
    }
  }) : _vm._e(), _vm.isUpsertingGlobal ? _c('Icon', {
    staticClass: "animate-spin",
    attrs: {
      "name": "loading"
    }
  }) : _vm._e()], 1)], 1)], 1), _c('YTable', {
    staticClass: "table-striped table-hover overflow-hidden",
    attrs: {
      "scroll-x": false,
      "data": ((_vm$userGlobalPayouts = _vm.userGlobalPayouts) === null || _vm$userGlobalPayouts === void 0 ? void 0 : _vm$userGlobalPayouts.data) || [],
      "isLoading": !_vm.userGlobalPayouts
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_vm._v(" " + _vm._s(props.rowData.countryCode) + " ")]), _c('td', [_vm._v(" " + _vm._s(props.rowData.type) + " ")]), _c('td', [_vm._v(_vm._s(props.rowData.globalValue))]), _c('td', [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.deleteGlobalPayout(props.rowData.id);
            }
          }
        }, [_c('Icon', {
          staticClass: "inline text-red-500",
          attrs: {
            "name": "trashCan",
            "size": 4
          }
        })], 1)])];
      }
    }])
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', {
    staticStyle: {
      "min-width": "9rem",
      "max-width": "9rem"
    }
  }, [_vm._v(" Country ")]), _c('th', {
    staticStyle: {
      "width": "5rem"
    }
  }, [_vm._v(" Payout Type ")]), _c('th', [_vm._v(" Global Value ")]), _c('th')])], 2), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }