<template>
  <Widget v-show="!autoSubmit">
    <WidgetHeader @close="$emit('close')">
      <template v-if="newStatus && newStatus.name">
        <span class="font-bold">
          <template v-if="hasMultipleIds">
            Set
            <span v-tooltip="modelIds.join(', ')" class="cursor-pointer">{{
              modelIds.length
            }}</span>
            {{ modelName.capitalize() }}s
          </template>
          <template v-else>
            Set {{ modelName.capitalize() }} <sup>#</sup>{{ modelIds[0] }}
          </template>
          to
          <span
            class="font-extrabold uppercase"
            :class="[`text-${statusColor}-500`]"
            >{{ newStatus.name }}</span
          >
        </span>
      </template>
      <template v-else>
        <div class="flex items-center">
          {{ header }}
          <Tag
            v-if="hasMultipleIds"
            v-tooltip="`${modelName.capitalize()} ${modelIds.join(', ')}`"
            class="hover:bg-blue-500 hover:text-white ml-2 cursor-pointer"
          >
            {{ modelIds.length }}
          </Tag>
          <Icon
            v-else
            name="helpCircle"
            class="text-gray-400 hover:text-blue-500 ml-2 cursor-pointer"
            v-tooltip="`${modelName.capitalize()} ${modelIds.join(', ')}`"
          ></Icon>
        </div>
      </template>
    </WidgetHeader>
    <WidgetBody class="pt-0">
      <Superselect
        v-model="statusName"
        v-if="!newStatus || (newStatus && !newStatus.name)"
        :multiple="false"
        class="mb-2"
        title="Status"
        :options="statusOptions"
      />
      <textarea
        v-model="reason"
        class="w-full p-4 bg-gray-100 rounded-md border border-gray-200"
        placeholder="Write a reason..."
      ></textarea>
      <div
        v-if="disableEmailNotificationOption"
        class="text-gray-800 font-bold pt-3"
      >
        <YCheckbox
          id="preset-is-public-checkbox"
          v-model="disableEmailNotification"
          label="Disable Email Notification"
          :inline="true"
          class="flex items-center mr-2"
          type="info"
        ></YCheckbox>
      </div>
    </WidgetBody>
    <WidgetFooter class="bg-gray-100 px-4 py-2">
      <div class="flex justify-between">
        <YButton class="btn btn-link" @click="$emit('close')">
          Cancel
        </YButton>
        <YButton
          class="btn btn-blue"
          @click="setStatus"
          :is-loading="isSubmitting"
        >
          Set Status
        </YButton>
      </div>
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import SET_STATUS_MUTATION from '@/graphql/Status/SetStatusMutation.gql'
import INTROSPECTION_QUERY from '@/graphql/Introspection/IntrospectionTypeQuery.gql'
import UPDATE_USER_MUTATION from '@/graphql/User/UpdateUserMutation.gql'
import UPDATE_AUTO_REJECTED_USER from '@/graphql/User/UpdateAutoRejectedUser.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import TextColorMap from '@/assets/js/TextColorMap'
export default {
  mixins: [ValidationErrorsMixin],
  apollo: {
    statusOptions: {
      query: INTROSPECTION_QUERY,
      update: data => data.__type,
      variables() {
        return {
          name: this.enumName
        }
      },
      skip() {
        return !this.enumName
      },
      result(data) {
        this.statusOptions = data.data.__type.enumValues.map(
          value => value.name
        )
      }
    }
  },
  props: {
    header: {
      type: String,
      default: 'Set Status'
    },
    modelName: {
      type: String,
      required: true
    },
    modelIds: {
      type: Array,
      required: true
    },
    modelType: {
      type: String
    },
    currentStatus: {
      type: Object
    },
    newStatus: {
      type: Object
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    autoSubmit: {
      type: Boolean,
      default: false
    },
    enumName: {
      type: String
    },
    onSuccess: {
      type: Function,
      default: () => {}
    },
    userWasAutoRejected: {
      type: Boolean
    },
    disableEmailNotificationOption: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      disableEmailNotification: false,
      isSubmitting: false,
      statusName:
        this.newStatus && this.newStatus.name
          ? this.newStatus.name
          : this.currentStatus && this.currentStatus.name
          ? this.currentStatus.name
          : '',
      reason:
        this.newStatus && this.newStatus.reason
          ? this.newStatus.reason
          : this.currentStatus && this.currentStatus.reason
          ? this.currentStatus.reason
          : null,
      statusOptions: this.options,
      colors: TextColorMap
    }
  },
  computed: {
    hasMultipleIds() {
      return this.modelIds.length > 1
    },
    statusColor() {
      return this.colors[this.statusName.toLowerCase()]
        ? this.colors[this.statusName.toLowerCase()]
        : 'blue'
    }
  },
  mounted() {
    if (this.autoSubmit) {
      this.setStatus()
    }
  },
  updated() {},
  methods: {
    async setStatus() {
      try {
        this.isSubmitting = true

        const result = await this.$apollo.mutate({
          mutation: SET_STATUS_MUTATION,
          variables: {
            input: {
              modelName: this.modelName,
              modelIds: this.modelIds,
              name: this.statusName,
              reason: this.reason
            }
          }
        })

        if (this.modelName === 'User') {
          for (const user of result.data.setStatus) {
            if (!user.accountManager && user.status === 'active') {
              await this.updateUser(user.id)
            }
          }
        }

        this.isSubmitting = false
        this.$toastr.s('Status Updated')
        this.$emit('success', this.statusName)
        this.$emit('close')
        this.clearValidationErrors()

        const onSuccessPayload = { result }
        if (this.disableEmailNotification) {
          onSuccessPayload.disableEmailNotification = true
        }
        this.onSuccess(onSuccessPayload)
      } catch (error) {
        this.setValidationErrors(error)
      }
    },

    async updateUser(userId) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: {
            input: {
              id: userId,
              accountManagers: {
                sync: [this.$store.getters.authId]
              }
            }
          }
        })

        if (this.userWasAutoRejected) {
          await this.updateRejectedUser(userId)
        }
      } catch (error) {
        this.setValidationErrors(error)
      }
    },

    async updateRejectedUser(userId) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_AUTO_REJECTED_USER,
          variables: {
            id: userId
          }
        })
        this.$events.emit('refetchUsers')
      } catch (error) {
        this.setValidationErrors(error)
      }
    }
  }
}
</script>

<style></style>
