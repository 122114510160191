<template>
  <div class="relative border border-gray-400 ">
    <div class="bg-gray-100 pt-2 pl-4 pb-0  uppercase text-gray-800 font-bold">
      Global Level Payouts
    </div>
    <div class="bg-gray-100 flex gap-4 px-4 py-2 border-b">
      <Superselect
        title="Country"
        v-model="globalCountry"
        :options="countries"
        :required="true"
        :multiple="false"
        track-by="code"
        label="label"
        class="flex-grow"
      />
      <Superselect
        title="Type"
        v-model="globalType"
        :required="true"
        :multiple="false"
        :options="['cpa', 'cpl']"
        class="w-32"
      />
      <YInput
        label="Value"
        v-model="globalValue"
        :required="true"
        class="w-32"
      />
      <div class="flex items-center justify-center w-12">
        <YButton
          @click="createGlobalPayout"
          color="link"
          class="px-3"
          :is-loading="isUpsertingGlobal"
          :disabled="isUpsertingGlobal"
        >
          <Icon v-if="!isUpsertingGlobal" name="plus" />
          <Icon v-if="isUpsertingGlobal" name="loading" class="animate-spin" />
        </YButton>
      </div>
    </div>
    <YTable
      class="table-striped table-hover overflow-hidden"
      :scroll-x="false"
      :data="userGlobalPayouts?.data || []"
      :isLoading="!userGlobalPayouts"
    >
      <template slot="header-row">
        <th style="min-width:9rem; max-width: 9rem;">
          Country
        </th>
        <th style="width: 5rem">
          Payout Type
        </th>
        <th>
          Global Value
        </th>
        <th></th>
      </template>

      <template slot="row" slot-scope="props">
        <td>
          {{ props.rowData.countryCode }}
        </td>
        <td>
          {{ props.rowData.type }}
        </td>
        <td>{{ props.rowData.globalValue }}</td>
        <td>
          <a href="#" @click.prevent="deleteGlobalPayout(props.rowData.id)">
            <Icon name="trashCan" :size="4" class="inline text-red-500"
          /></a>
        </td>
      </template>
    </YTable>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </div>
</template>

<script>
import gql from 'graphql-tag'

import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  components: {},
  props: {
    userId: {
      type: Number
    }
  },
  apollo: {
    countries: {
      query: gql`
        {
          countries {
            code
            name
            label
          }
        }
      `
    },
    userGlobalPayouts: {
      query: gql`
        query GetUserGlobalPayouts(
          $filters: UserGlobalPayoutFiltersInput
          $first: Int!
          $orderBy: [OrderByClause!]
        ) {
          userGlobalPayouts(
            filters: $filters
            first: $first
            orderBy: $orderBy
          ) {
            data {
              id
              userId
              countryCode
              type
              globalValue
            }
          }
        }
      `,
      variables() {
        return {
          filters: {
            userId: {
              value: this.userId
            }
          },
          first: 300,
          orderBy: [
            {
              column: 'created_at',
              order: 'DESC'
            }
          ]
        }
      }
    }
  },
  data() {
    return {
      globalCountry: null,
      globalType: null,
      globalValue: null,
      isUpsertingGlobal: false
    }
  },
  methods: {
    clearGlobalForm() {
      this.globalCountry = null
      this.globalType = null
      this.globalValue = null
    },
    createGlobalPayout() {
      this.isUpsertingGlobal = true

      this.$apollo
        .mutate({
          mutation: gql`
            mutation GlobalPayout($input: UpsertGlobalPayoutInput!) {
              upsertGlobalPayout(input: $input) {
                id
                userId
                countryCode
                type
                globalValue
              }
            }
          `,
          variables: {
            input: {
              userId: this.userId,
              countryCode: this.globalCountry.code,
              type: this.globalType,
              globalValue: Number(this.globalValue)
            }
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.isUpsertingGlobal = false
          this.clearGlobalForm()
          this.$apollo.queries.userGlobalPayouts.refetch()
        })
        .catch(error => {
          this.isUpsertingGlobal = false
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    deleteGlobalPayout(id) {
      this.isUpsertingGlobal = true

      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteGlobalPayout($id: ID!) {
              deleteGlobalPayout(id: $id) {
                id
              }
            }
          `,
          variables: {
            id: id
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.isUpsertingGlobal = false
          this.clearGlobalForm()
          this.$apollo.queries.userGlobalPayouts.refetch()
        })
        .catch(error => {
          this.isUpsertingGlobal = false
          this.setValidationErrors(error)
          console.error(error)
        })
    }
  }
}
</script>

<style scoped></style>
