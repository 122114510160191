var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.advertiser ? _c('div', [_c('portal', {
    attrs: {
      "to": "top-bar-left-header"
    }
  }, [_vm._v(" " + _vm._s(_vm.advertiser.advertiserId) + " - " + _vm._s(_vm.advertiser.primaryContact.company) + " ")]), _c('div', [_c('Tabs', {
    attrs: {
      "show-type": "show",
      "route": _vm.tabRoute
    }
  }, [_c('Tab', {
    attrs: {
      "name": "Details",
      "active": true
    }
  }, [_c('AdvertiserDetails', {
    attrs: {
      "advertiser": _vm.advertiser
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": "Communications"
    }
  }, [_c('div', {
    staticClass: "pt-3 w-2/3"
  }, [_vm.user ? _c('LeadComments', {
    attrs: {
      "modelId": _vm.user.id,
      "comments": _vm.user.commentsLead,
      "modelClass": "User",
      "user": _vm.user
    }
  }) : _vm._e()], 1)]), _c('Tab', {
    attrs: {
      "name": "Accounts"
    }
  }, [_c('AdvertiserAccounts', {
    attrs: {
      "id": _vm.advertiser.id
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": "Caps"
    }
  }, [_vm.advertiser ? _c('Caps', {
    attrs: {
      "cappableId": this.advertiser.id,
      "cappableType": "Advertiser"
    }
  }) : _vm._e()], 1), _c('Tab', {
    attrs: {
      "name": "Caps Defaults"
    }
  }, [_vm.advertiser ? _c('CapDefaults', {
    attrs: {
      "cappableId": _vm.advertiser.id,
      "cappableType": "Advertiser"
    }
  }) : _vm._e()], 1), _c('Tab', {
    attrs: {
      "name": _vm.ruleTotalTabName
    }
  }, [_c('OfferPerspectiveRulesTableWrapper', {
    attrs: {
      "default-filter-values": _vm.defaultFilterValues,
      "hide-all-offer-filters": true,
      "defaults-for-create": _vm.defaultsForCreate,
      "ruleOrigin": "advertiser_rule",
      "rulesParentComponent": "advertiser_rule"
    },
    on: {
      "totalRulesTab": _vm.totalRulesTab
    }
  })], 1)], 1)], 1), _c('AdvertiserSideBar', {
    attrs: {
      "advertiser": _vm.advertiser
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }