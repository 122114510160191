<template>
  <portal :to="portalTo">
    <YAlert
      :color="color"
      class="my-1"
      :class="alertClass"
      :icon="icon"
      :iconSize="iconSize"
      style="z-index: 90000; position: relative;"
      :allow-close="allowClose"
      :key="uniqueKey"
    >
      <ul v-if="validationErrors">
        <template v-for="fieldErrors in validationErrors">
          <li v-for="(error, index) in fieldErrors" :key="index">
            {{ error }}
          </li>
        </template>
      </ul>
      <div v-else-if="extentionsErrors">
        {{ extentionsErrors }}
      </div>
      <div v-else-if="errors">
        {{ message }}
      </div>
    </YAlert>
  </portal>
</template>

<script>
import md5 from 'blueimp-md5'

export default {
  props: {
    errors: {
      type: Object,
      required: true
    },
    portalTo: {
      type: String,
      default: 'sticky-footer'
    },
    alertClass: {
      type: [String, Array]
    },
    icon: {
      type: String,
      default: 'alert'
    },
    iconSize: {
      type: Number,
      default: 4
    },
    color: {
      type: String,
      default: 'red'
    },
    allowClose: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    console.log('validation errors', this.errors)
  },
  computed: {
    uniqueKey() {
      return md5(JSON.stringify(this.errors) + Date.now())
    },
    validationErrors() {
      return this.errors.validation
    },
    extentionsErrors() {
      if (
        !this.errors.extentions ||
        (this.errors.extentions && !this.errors.extentions.reason)
      ) {
        return null
      }

      // const category = this.errors.extentions.category
      //   ? this.errors.extentions.category.capitalize()
      //   : ''
      const reason = this.errors.extentions.reason

      return `${reason}`
    },
    message() {
      const validation = this.errors.extentions.validation

      if (validation) {
        const messages = Object.values(validation).flat()
        return messages.length > 0 ? messages[0] : ''
      }

      return (
        this.errors?.message || 'Something went wrong. Please contact support.'
      )
    }
  }
}
</script>

<style></style>
