<template>
  <div v-if="publisherCampaign" class="widget-b-edge">
    <div class="flex" style="min-height: 4rem;">
      <!-- campaign preview generated from image repo -->
      <div
        v-if="publisherCampaign.images?.length > 0 && !editable"
        class="mr-5 ml-3 hidden sm:flex justify-center items-start rounded-md w-48 h-auto overflow-hidden"
      >
        <YImage
          :src="
            publisherCampaign.images[0].conversions.filter(
              image => image.conversion === 'thumb'
            )[0].storageUrl
          "
          :width="32"
          :height="32"
          v-img="{
            src: `${
              publisherCampaign.images[0].conversions.filter(
                image => image.conversion === 'thumb'
              )[0].storageUrl
            }`
          }"
        />
      </div>
      <div>
        <h4 class="mb-1">
          <span class="pr-2 text-base font-bold">{{
            publisherCampaign.name
          }}</span>
          <sup class="text-gray-600 text-sm">{{ publisherCampaign.id }}</sup>
        </h4>

        <div>
          <ul>
            <template
              v-if="publisherCampaign.caps && publisherCampaign.caps.length > 0"
            >
              <li v-for="cap in publisherCampaign.caps" :key="`Cap:${cap.id}`">
                <strong>{{ cap.frequency.capitalize() }} Cap:</strong>
                <span class="italic">
                  {{ cap.type != 'publisherActions' ? '$' : ''
                  }}{{ cap.triggerValue }}
                  {{ cap.type == 'publisherActions' ? 'actions' : '' }}
                </span>
              </li>
            </template>
            <template
              v-else-if="
                publisherCampaign.capDefaults && publisherCampaign.capDefaults
              "
            >
              <li
                v-for="cap in publisherCampaign.capDefaults"
                :key="`CapDefault:${cap.id}`"
              >
                <strong>{{ cap.frequency.capitalize() }} Cap:</strong>
                <span class="italic">
                  {{ cap.type != 'publisherActions' ? '$' : ''
                  }}{{ cap.triggerValue }}
                  {{ cap.type == 'publisherActions' ? 'actions' : '' }}
                </span>
              </li>
            </template>
          </ul>
        </div>

        <div
          v-if="!editable && publisherCampaign.previewOptions.description"
          v-html="publisherCampaign.description"
          class="mt-5 mb-5 campaign-description"
        ></div>
      </div>
    </div>

    <div v-if="editable" class="x-row flex items-start">
      <div class="x-col pt-4">
        <small class="text-muted fw-500">DESCRIPTION:</small>
      </div>
      <div v-if="editable" class="x-col flex items-start">
        <YCheckbox
          v-model="publisherCampaign.previewOptions.description"
          class="mr-2 mt-4"
        />
        <ckeditor
          v-model="publisherCampaign.description"
          style="min-width: 20rem;"
          :editor="editor"
          :config="editorConfig"
        ></ckeditor>
      </div>
    </div>

    <div class="x-row flex items-center">
      <div class="x-col">
        <small class="text-muted fw-500">CREATIVE URL:</small>
      </div>
      <div class="x-col flex ">
        <YCheckbox
          v-model="publisherCampaign.previewOptions.creativeUrl"
          class="mr-2 "
        />
        <p
          v-html="publisherCampaign.creativeUrl"
          class="truncate"
          style="max-width:29rem"
        ></p>
      </div>
    </div>

    <div class="mt-4 py-2">
      <template v-if="publisherCampaign.redirect.status != 'subscribed'">
        <y-alert class="mt-3" type="info" :allow-close="false">
          User is not subscribed
        </y-alert>
      </template>
      <template v-else>
        <div class="x-row">
          <div class="x-col">
            <small class="text-muted fw-500">PAYOUTS:</small>
          </div>
          <div class="x-col">
            <YTable
              :data="visiblePayouts || []"
              class="table table-sm table-striped mb-0 w-100"
            >
              <template slot="header-row">
                <th v-if="editable"></th>
                <th>Geo</th>
                <th>Rate</th>
              </template>
              <template slot="row" slot-scope="props">
                <td v-if="editable && publisherCampaign.previewOptions">
                  <y-checkbox
                    v-model="
                      publisherCampaign.previewOptions.payouts[props.rowIndex]
                    "
                    type="info"
                  ></y-checkbox>
                </td>
                <td>
                  <template v-if="props.rowData.countryCode == null">
                    ALL
                  </template>
                  <template v-else>
                    {{ props.rowData.country.code }} -
                    {{ props.rowData.country.name }}
                  </template>
                </td>
                <td>
                  {{ props.rowData.rate }}
                  <template v-if="props.rowData.prettyType == 'Variable CPA'">
                    prettytype: {{ props.rowData.prettyType }}
                  </template>
                  <template v-else-if="props.rowData.prettyType == 'CPA'">
                    payouttype: {{ publisherCampaign.payoutType }}
                  </template>
                </td>
              </template>
            </YTable>
            <div class="pt-1 flex justify-between">
              <template v-if="payoutsAreExpanded">
                <a class="link" @click.prevent="togglePayoutsAreExpanded"
                  >Collapse</a
                >
              </template>
              <template v-else>
                <div class="flex flex-col">
                  <span class="text-gray-600"
                    >+ {{ invisibilePayoutsLength }} more geos</span
                  >
                  <a
                    class="link block"
                    @click.prevent="togglePayoutsAreExpanded"
                    >View All</a
                  >
                </div>
              </template>
              <template>
                <a class="link" @click.prevent="unselectAllGeos"
                  >Unselect All</a
                ></template
              >
            </div>
          </div>
        </div>
        <div
          class="x-row"
          v-if="
            (!editable && publisherCampaign.previewOptions.allowed) || editable
          "
        >
          <div class="x-col">
            <small class="text-muted fw-500">ALLOWED:</small>
          </div>
          <div class="x-col flex items-center">
            <YCheckbox
              v-if="editable"
              v-model="publisherCampaign.previewOptions.allowed"
              class="mr-2"
            />
            <div
              :class="
                !publisherCampaign.previewOptions.allowed ? 'opacity-50' : ''
              "
            >
              <Tag
                v-for="tag of publisherCampaign.allowedRestrictedTags.filter(
                  tag => !tag.isNot
                )"
                :key="tag.name"
                :color="'green'"
                class="mr-1 mb-1"
                >{{ tag.name }}</Tag
              >
            </div>
          </div>
        </div>

        <div
          class="x-row"
          v-if="
            (!editable && publisherCampaign.previewOptions.restricted) ||
              editable
          "
        >
          <div class="x-col">
            <small class="text-muted fw-500">RESTRICTED:</small>
          </div>
          <div class="x-col flex items-center">
            <YCheckbox
              v-if="editable"
              v-model="publisherCampaign.previewOptions.restricted"
              class="mr-2"
            />
            <div
              :class="
                !publisherCampaign.previewOptions.restricted ? 'opacity-50' : ''
              "
            >
              <Tag
                v-for="tag of publisherCampaign.allowedRestrictedTags.filter(
                  tag => tag.isNot
                )"
                :key="tag.name"
                :color="'red'"
                class="mr-1 my-1"
                >{{ tag.name }}</Tag
              >
            </div>
          </div>
        </div>

        <div class="x-row">
          <div class="x-col">
            <small class="text-muted fw-500">LINKS:</small>
          </div>
          <div class="x-col">
            <div
              v-if="
                publisherCampaign.offers && publisherCampaign.offers.length > 0
              "
            >
              <div
                class="flex items-center mb-2"
                v-for="offer in publisherCampaign.offers"
                :key="offer.id"
              >
                <!-- campaign preview generated after legacyImage implimented, and is using a legacy image, not from image repo -->
                <div
                  v-if="offer.legacyImageThumbnailUrl"
                  class="mr-4 w-20 h-20"
                >
                  <YImage
                    :src="offer.legacyImageThumbnailUrl"
                    :width="20"
                    :height="20"
                    class="rounded w-20 h-20 block"
                    v-img="{
                      src: offer.legacyImageUrl
                    }"
                  />
                </div>
                <!-- campaign preview generated before legacyImage conversion -->
                <div v-if="offer.imageUrl" class="mr-4 w-20 h-20">
                  <YImage
                    :src="offer.imageThumbnailUrl"
                    :width="20"
                    :height="20"
                    class="rounded w-20 h-20 block"
                    v-img="{
                      src: offer.imageUrl
                    }"
                  />
                </div>
                <div>
                  <p class="mb-1 fw-500">
                    <template
                      v-if="
                        offer.destinationType == 'direct' ||
                          (offer.template && offer.template.path == 'redirect')
                      "
                    >
                      Direct
                    </template>
                    <template v-else-if="offer.template">
                      {{
                        offer.template.name
                          ? offer.template.name
                          : 'Missing Name'
                      }}
                    </template>
                  </p>
                  <div
                    v-if="
                      publisherCampaign.redirect &&
                        publisherCampaign.redirect.status == 'subscribed'
                    "
                  >
                    <a
                      :href="
                        `${publisherCampaign.redirect.url}/o/${offer.encodedId}${redirectParametersString}`
                      "
                      class="link without-truncate break-all"
                      target="_blank"
                      >{{ publisherCampaign.redirect.url }}/o/{{
                        offer.encodedId
                      }}{{ redirectParametersString }}</a
                    >
                  </div>
                </div>
              </div>
            </div>

            <div
              v-else-if="
                publisherCampaign.redirect &&
                  publisherCampaign.redirect.status == 'subscribed'
              "
              class="flex items-center mb-2"
            >
              <div>
                <div class="font-semibold text-gray-600">Your Link</div>
                <a
                  class="link without-truncate break-all"
                  :href="
                    `${publisherCampaign.redirect.url}/${redirectParametersString}`
                  "
                  target="_blank"
                  >{{ publisherCampaign.redirect.url }}/{{
                    redirectParametersString
                  }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue'
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon'

export default {
  components: {
    ckeditor: CKEditor.component
  },
  apollo: {},
  props: {
    value: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    },
    publisherOptions: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      editor: BalloonEditor,
      editorConfig: {
        toolbar: {
          items: ['bold', 'italic', 'link', 'undo', 'redo']
        }
      },
      payoutsAreExpanded: false,
      publisherCampaign: this._.cloneDeep(this.value)
    }
  },
  watch: {
    value: {
      handler: function(newValue) {
        if (!this._.isEqual(this.publisherCampaign, newValue)) {
          this.publisherCampaign = this._.cloneDeep(newValue)
        }
      },
      deep: true
    },
    publisherCampaign: {
      handler: function(newValue) {
        if (!this._.isEqual(newValue, this.value)) {
          this.$emit('input', newValue)
        }
      },
      deep: true
    }
  },
  computed: {
    redirectParametersString() {
      let defaultParams = '?kw={source}&s1={clickid}'
      if (this.publisherOptions.userDetails?.defaultRedirectParameters) {
        let userParams = JSON.parse(
          this.publisherOptions.userDetails.defaultRedirectParameters
        )

        let setUserParams = Object.entries(userParams)
          .filter(param => param[1])
          .map(param => `${param[0]}=${param[1]}`)
          .join('&')

        return `?${setUserParams}`
      }
      return defaultParams
    },
    visiblePayouts() {
      let output = []
      let allPayouts = this._.cloneDeep(this.publisherCampaign.payouts)
      let removeZeroValuePayouts = allPayouts.filter(
        payout => payout.rate != '$0'
      )
      let condensedPayouts = removeZeroValuePayouts
        .filter((payout, index) => {
          return this.publisherCampaign.previewOptions.payouts[index]
        })
        .slice(0, 3)

      if (this.payoutsAreExpanded) {
        output = removeZeroValuePayouts
      } else {
        output = condensedPayouts
      }
      return output
    },
    selectedPayouts() {
      return this.publisherCampaign.previewOptions.payouts.filter(
        payout => payout == true
      )
    },
    invisibilePayoutsLength() {
      let removeZeroValues = this.publisherCampaign.payouts.filter(
        payout => payout.value !== 0
      )
      return removeZeroValues.length - this.visiblePayouts.length
    }
  },
  mounted() {},
  updated() {},
  methods: {
    unselectAllGeos() {
      this.payoutsAreExpanded = true
      let results = this.selectedPayouts.map(() => false)
      return (this.publisherCampaign.previewOptions.payouts = results)
    },
    onEditorReady() {
      //this.$refs.editor.$el.focus()
    },
    toggleAllowed() {
      this.campaign.previewOptions.allowed = !this.campaign.previewOptions
        .allowed
    },
    toggleRestricted() {
      this.campaign.previewOptions.restricted = !this.campaign.previewOptions
        .restricted
    },
    togglePayoutsAreExpanded() {
      this.payoutsAreExpanded = !this.payoutsAreExpanded
    }
  }
}
</script>
<style scoped>
.campaign-description p:last-child {
  margin-bottom: 0 !important;
}

.x-row {
  @apply flex pb-4;
}

.x-row .x-col:first-child {
  @apply text-gray-600 text-right w-32 pr-4;
}
</style>
