var render = function render(){
  var _vm$user$name, _vm$user$name2, _vm$user$primaryConta, _vm$user$primaryConta2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('div', {
    staticStyle: {
      "height": "100vh"
    }
  }, [_c('portal', {
    attrs: {
      "to": "top-bar-left-header"
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('div', {
    staticClass: "mr-2 rounded-full h-2 w-2 inline-flex md:hidden text-opacity-0",
    class: `bg-${_vm.TextColorMap[_vm.user.status]}-500 hover:bg-${_vm.TextColorMap[_vm.user.status]}-600`
  }), _c('div', {
    staticClass: "x-topbar-username break-all"
  }, [_vm._v(_vm._s(_vm.user.label))])])]), _c('div', {
    staticClass: "flex items-center justify-between w-full h-full"
  }, [_c('div', {
    staticClass: "flex flex-col lg:flex-row w-full items-start gap-4 h-full"
  }, [_c('div', {
    staticClass: "w-full lg:w-1/3 rounded-lg p-2 flex flex-col h-full"
  }, [_c('div', {
    staticClass: "flex flex-col text-center items-center text-gray-900"
  }, [_vm.user.primaryContact.company ? _c('Icon', {
    attrs: {
      "name": "accountMultiple",
      "size": "12",
      "color": "gray"
    }
  }) : _c('Icon', {
    attrs: {
      "name": "account",
      "size": "12",
      "color": "gray"
    }
  }), _vm.user.primaryContact.company ? _c('span', [_c('span', {
    staticClass: "block font-semibold text-gray-800 text-lg"
  }, [_vm._v(" " + _vm._s(_vm.user.primaryContact.company))]), _vm.user.primaryContact.company ? _c('span', {
    staticClass: "block"
  }, [_vm._v(_vm._s((_vm$user$name = _vm.user.name) !== null && _vm$user$name !== void 0 ? _vm$user$name : ''))]) : _vm._e()]) : _vm._e(), !_vm.user.primaryContact.company ? _c('span', [_c('span', {
    staticClass: "block font-semibold text-gray-800 text-lg"
  }, [_vm._v(" " + _vm._s((_vm$user$name2 = _vm.user.name) !== null && _vm$user$name2 !== void 0 ? _vm$user$name2 : ''))])]) : _vm._e(), _c('div', {
    staticClass: "flex flex-row items-center mt-3"
  }, [_c('button', {
    staticClass: "hover:bg-gray-200 p-1 rounded",
    on: {
      "click": function ($event) {
        return _vm.openLeadPriorityStatusModal(_vm.user);
      }
    }
  }, [_c('Icon', {
    class: _vm.priorityIconColor(_vm.user.userDetails.priorityStatus),
    attrs: {
      "name": _vm.priorityIconType(_vm.user.userDetails.priorityStatus),
      "size": 6
    }
  })], 1), _vm._v(" Priority Status: "), _c('span', {
    staticClass: "capitalize ml-2"
  }, [_vm._v(" " + _vm._s(_vm.user.userDetails.priorityStatus ? _vm.user.userDetails.priorityStatus : 'Regular'))])]), _vm.user.commentsLead.length > 0 ? _c('div', {
    staticClass: "flex flex-row items-center"
  }, [_c('Icon', {
    staticClass: "text-green-500 mr-2 text-sm",
    attrs: {
      "name": "circle",
      "size": "2"
    }
  }), _vm._v(" Last Contacted: " + _vm._s(_vm._f("luxon")(_vm.user.commentsLead[0].createdAt)) + " ")], 1) : _vm._e()], 1), _c('div', {
    staticClass: "mt-5 bg-gray-100 rounded-lg shadow"
  }, [_c('div', {
    staticClass: "grid grid-cols-2",
    staticStyle: {
      "grid-template-columns": "auto 40px"
    }
  }, [_c('div', {
    staticClass: "flex flex-col gap-y-3 text-gray-700 p-4"
  }, [_vm.user.status == 'lead' ? _c('div', [_c('span', {
    staticClass: "block flex flex-row"
  }, [_vm._v("Email "), _c('Icon', {
    staticClass: "ml-1",
    staticStyle: {
      "margin-top": "2px"
    },
    attrs: {
      "name": "pencil",
      "size": 3
    }
  })], 1), _c('span', {
    staticClass: "flex flex-row align-center text-black mt-1"
  }, [_c('EditableValue', {
    staticClass: "-mr-1 -ml-1",
    attrs: {
      "type": "contentEditable",
      "value": _vm.user.email,
      "mutation": _vm.UPDATE_USER_EMAIL_MUTATION,
      "variables": {
        input: {
          id: _vm.user.id,
          email: '{value}'
        }
      },
      "placeholder": "0",
      "value-class": "px-1",
      "edit-focus-icon-enabled": false
    }
  })], 1)]) : _c('div', [_c('span', {
    staticClass: "block flex flex-row"
  }, [_vm._v("Email")]), _c('span', {
    staticClass: "text-black"
  }, [_vm._v(_vm._s(_vm.accountEmail(_vm.user)))])]), _vm.user.primaryContact.messenger ? _c('div', [_vm._v(" Messenger "), _c('span', {
    staticClass: "text-black block mt-1"
  }, [_c('Icon', {
    staticClass: "w-4 h-4 text-gray-600 inline",
    attrs: {
      "name": _vm.handleMessenger.app
    }
  }), _vm._v(" " + _vm._s(_vm.handleMessenger.handle))], 1)]) : _vm._e(), _vm.user.primaryContact.country ? _c('div', [_vm._v(" Country "), _c('span', {
    staticClass: "text-black block mt-1"
  }, [_vm._v(_vm._s((_vm$user$primaryConta = (_vm$user$primaryConta2 = _vm.user.primaryContact) === null || _vm$user$primaryConta2 === void 0 ? void 0 : _vm$user$primaryConta2.country.name) !== null && _vm$user$primaryConta !== void 0 ? _vm$user$primaryConta : ''))])]) : _vm._e(), JSON.parse(_vm.user.userDetails.meta) ? _c('div', [_vm._v(" Lead Comments "), JSON.parse(_vm.user.userDetails.meta).offerTag.length > 0 ? _c('span', {
    staticClass: "text-black block mt-1"
  }, [_vm._v(" " + _vm._s(JSON.parse(_vm.user.userDetails.meta).offerTag.join(', ')) + " ")]) : _c('span', {
    staticClass: "text-black block mt-1"
  }, [_vm._v(" " + _vm._s(JSON.parse(_vm.user.userDetails.meta)))])]) : _vm._e()]), _vm.user.status == 'lead' ? _c('div', {
    staticClass: "pt-2 pr-2"
  }, [_c('YButton', {
    staticStyle: {
      "padding": "0.25rem 0.5rem 0.5rem 0.5rem"
    },
    on: {
      "click": function ($event) {
        return _vm.openLeadDetailsModal(_vm.user);
      }
    }
  }, [_c('Icon', {
    staticClass: "text-inverse-400 hover:text-inverse-500",
    attrs: {
      "name": "accountEdit",
      "size": "6"
    }
  })], 1)], 1) : _vm._e()]), _vm.user.status == 'lead' && _vm.user.secondaryContact.length < 1 ? _c('div', {
    staticClass: "p-2"
  }, [_c('YButton', {
    attrs: {
      "color": "inverse"
    },
    on: {
      "click": _vm.openSecondaryContactModal
    }
  }, [_vm._v("Merge User")])], 1) : _vm._e(), _c('div', {
    staticClass: "bg-gray-200 m-1 rounded px-3 pt-2 pb-3 text-gray-900"
  }, [_c('div', {
    staticClass: "mb-3 flex justify-between"
  }, [_c('div', {
    staticClass: "widget-title"
  }, [_vm._v(" Secondary Contacts ")]), _c('button', {
    on: {
      "click": _vm.openCreateSeconaryContactModal
    }
  }, [_c('Icon', {
    staticClass: "hover:bg-gray-300 rounded",
    attrs: {
      "name": "plus",
      "size": 6
    }
  })], 1)]), _vm._l(_vm.user.secondaryContact, function (user) {
    var _user$name;
    return _c('ul', {
      key: user.id,
      staticClass: "mb-3 py-1 flex flex-row justify-between hover:bg-gray-300 rounded showElementParent"
    }, [_c('div', {}, [_c('li', {
      staticClass: "font-semibold text-sm"
    }, [_vm._v(_vm._s((_user$name = user.name) !== null && _user$name !== void 0 ? _user$name : ''))]), _c('li', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: user.email,
        expression: "user.email"
      }],
      staticClass: "truncate"
    }, [_vm._v(" " + _vm._s(user.email) + " ")]), user.messenger ? _c('li', [_c('Icon', {
      staticClass: "w-4 h-4 text-gray-600 inline",
      attrs: {
        "name": JSON.parse(user.messenger.toLowerCase()).app
      }
    }), _vm._v(" " + _vm._s(JSON.parse(user.messenger).handle) + " ")], 1) : _vm._e()]), _c('div', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: `Delete Secondary Contact`,
        expression: "`Delete Secondary Contact`"
      }],
      staticClass: "py-1 px-2 cursor-pointer showElement",
      on: {
        "click": function ($event) {
          return _vm.deleteContact(user.id);
        }
      }
    }, [_c('Icon', {
      staticClass: "hover:text-red-800",
      attrs: {
        "name": "close",
        "size": 6,
        "color": "red"
      }
    })], 1)]);
  })], 2)])]), _c('div', {
    staticClass: "w-full lg:w-2/3 rounded-lg bg-gray-100"
  }, [_c('LeadComments', {
    attrs: {
      "modelId": _vm.user.id,
      "comments": _vm.user.commentsLead,
      "modelClass": "User",
      "user": _vm.user
    }
  })], 1)])]), _vm.user.status == 'lead' ? _c('div', [_c('LeadRightSideBar', {
    attrs: {
      "user": _vm.user
    }
  })], 1) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }