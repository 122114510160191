<template>
  <div v-if="redirect">
    <portal to="top-bar-left-header">
      {{ redirect.id }} - {{ savedRedirect.subdomain }}.{{
        savedRedirect.domain
      }}
      <Tag class="ml-3"> {{ redirect.status }}</Tag>
      <div
        v-if="
          alfListings?.data?.length > 0 &&
            alfListings?.data[0].destination == '127.0.0.1'
        "
        class="inline-block ml-3"
      >
        <Tag color="indigo">Desktop Killed</Tag>
      </div>
    </portal>
    <div class="flex flex-row align-items-center mb-2">
      <div v-if="savedRedirect.isAutoAbyssed">
        <YButton color="gray" @click="resetAutoAbyssed">
          Undo Auto Abyss
        </YButton>
      </div>
      <div v-else>
        <YButton
          :color="savedRedirect.isAbyssed ? 'green' : 'red'"
          @click="abyssRedirect"
          >{{ savedRedirect.isAbyssed ? 'Undo Abyss' : 'Abyss' }}</YButton
        >
      </div>
      <YButton :color="'blue'" @click="openGenerateSubdomainModal" class="mx-2"
        >New Subdomain</YButton
      >
    </div>

    <div class="flex flex-row"></div>
    <div class="row">
      <div class="col">
        <Tabs show-type="show" :route="tabRoute">
          <Tab name="Details" :active="true">
            <Widget class="rounded-t-none border-t-0">
              <WidgetBody class="bg-gray-100 flex justify-between p-4">
                <div></div>
                <div>
                  <YButton
                    class="btn btn-blue"
                    :is-loading="isSubmitting"
                    @click="saveRedirect"
                    @keydown.enter="saveRedirect"
                    >Save</YButton
                  >
                </div>
              </WidgetBody>
              <WidgetBody v-observe-visibility="visibilityChanged">
                <div>
                  <div class="mb-1">
                    <div class="text-xs text-indigo-600">Campaign</div>
                    <router-link
                      :to="{
                        name: 'Campaign',
                        params: { id: redirect.campaign.id }
                      }"
                      class="link py-1 inline-block"
                    >
                      {{ redirect.campaign.label }}
                    </router-link>
                  </div>
                  <div class="mb-1">
                    <div class="text-xs text-indigo-600">Publisher</div>
                    <router-link
                      :to="{
                        name: 'User',
                        params: { id: redirect.user.id }
                      }"
                      class="link py-1 inline-block"
                    >
                      {{ redirect.user.label }}
                    </router-link>
                  </div>
                  <!--<div class="mb-1">
                    <div class="text-xs text-indigo-600">Subdomain</div>
                    <div>{{ redirect.subdomain }}</div>
                  </div>-->
                  <div class="mb-2 mt-6">
                    <YCheckbox
                      class="mb-1"
                      label=" Don't Use Status Cake Tests"
                      type="checkbox"
                      v-model="redirect.ignoreStatusCake"
                    />
                  </div>
                  <div class="mt-4 mb-1">
                    <Superselect
                      title="Domain"
                      v-model="redirectDomain"
                      :multiple="false"
                      :options="domainOptions || []"
                    ></Superselect>
                  </div>
                  <div class="mb-2">
                    <YCheckbox
                      v-show="domainChanged"
                      label="Send Domain Update Notification Email"
                      type="checkbox"
                      v-model="shouldSendDomainReplacementEmailOption"
                    />
                  </div>
                  <br />
                  <div class="text-xs text-indigo-600">Subdomain History</div>
                  <SubdomainHistory :redirect="redirect"></SubdomainHistory>
                </div>
              </WidgetBody>
            </Widget>
          </Tab>
          <Tab name="Stats" :active="true">
            <simple-report :filters="reportFilters" class="col-span-4 mt-4">
              <h4 slot="table-header" class="widget-title">Daily Summary</h4>
            </simple-report>
          </Tab>
          <Tab name="Payouts">
            <Payouts
              v-if="redirect && redirect.payouts"
              :payouts="redirect.payouts"
              :redirectId="parseInt(id)"
              :userId="parseInt(redirect.user.id)"
              level="redirect"
            ></Payouts>
          </Tab>
          <Tab name="Postbacks">
            <PostbacksTable
              :redirectId="parseInt(id)"
              :userId="parseInt(redirect.user.id)"
            ></PostbacksTable>
          </Tab>
          <Tab :name="ruleTotalTabName">
            <RulesTableWrapper
              :default-filter-values="{ redirects: [redirect] }"
              rulesParentComponent="publisher_rule"
              :hide-filters="[
                'types',
                'redirects',
                'campaigns',
                'publishers',
                'sources'
              ]"
              @totalRulesTab="totalRulesTab"
            >
              <template v-slot:beforeTable>
                <div
                  class="flex justify-between items-center mt-4 px-4 py-4 rounded-lg bg-gray-200 "
                >
                  <div class="text-muted">
                    <span>
                      {{ selectedCount }}
                      {{ selectedCount == 1 ? 'item' : 'items' }} selected
                    </span>
                  </div>
                  <div class="flex gap-4">
                    <YButton
                      color="red"
                      :disabled="!rowsAreSelected"
                      @click="openDeleteRulesModal"
                      >Delete</YButton
                    >
                    <YButton
                      color="blue"
                      @click="openCreateRulesModal"
                      :disabled="rowsAreSelected"
                      >Create Rules</YButton
                    >
                  </div>
                </div>
              </template>
            </RulesTableWrapper>
          </Tab>
        </Tabs>
      </div>
    </div>

    <portal to="sidebar-right">
      <SideBarRightItem title="Traffic Sources">
        <EditableValue
          @saved="refreshAssignedTags"
          ref="trafficSourcesEditable"
          class="px-3"
          :value="savedRedirect.tags.filter(tag => tag.type === 'trafficType')"
          type="slot"
          :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
          sortBy="id"
          :transformValueForQuery="
            value => {
              return value.map(tag => tag.name)
            }
          "
          :variables="{
            input: {
              taggableId: id,
              type: 'trafficType',
              taggableType: 'redirect',
              tags: '{value}'
            }
          }"
        >
          <template v-slot:default="slotProps">
            <div v-if="slotProps.value && slotProps.value.length > 0">
              <Tag
                v-for="tag in slotProps.value"
                :key="tag.id"
                class="mr-1 my-1"
                color="orange"
                tabindex="0"
                @focus="
                  () => {
                    slotProps.focus()
                  }
                "
              >
                {{ tag.name }}
              </Tag>
            </div>

            <div
              v-else
              @click="
                () => {
                  slotProps.focus()
                }
              "
            >
              <span
                class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
                >None</span
              >
            </div>
          </template>
          <template v-slot:focusSlot="slotProps">
            <Superselect
              title="Tags"
              :focusOnMount="true"
              :multiple="true"
              track-by="slug"
              label="name"
              placeholder="Select"
              class="box-width min-width-full"
              :value="slotProps.value"
              :options="
                savedRedirect.user.tags.filter(
                  tag => tag.type === 'trafficType'
                )
              "
              @input="
                value => {
                  slotProps.updateInternalValue(value)
                }
              "
            ></Superselect>
          </template>
        </EditableValue>
      </SideBarRightItem>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PostbacksTable from '@/views/Admin/Postbacks/Postbacks'
import RulesTableWrapper from '@/views/Admin/Rules/RulesTableWrapper'
import Payouts from '@/views/Admin/Payouts/Payouts'
import SubdomainHistory from '@/views/Admin/Redirect/SubdomainHistory'
import REDIRECT_QUERY from '@/graphql/Redirect/RedirectQuery.gql'
import UPDATE_REDIRECT_MUTATION from '@/graphql/Redirect/UpdateRedirectMutation.gql'
import SEND_REDIRECT_DOMAIN_REPLACEMENT_EMAIL_MUTATION from '@/graphql/Redirect/SendRedirectDomainReplacementEmailMutation.gql'
import AVAILABLE_REDIRECT_DOMAINS_FOR_USER_QUERY from '@/graphql/Domain/AvailableRedirectDomainsForUserQuery.gql'
import ABYSS_REDIRECT_MUTATION from '@/graphql/Redirect/AbyssRedirectMutation.gql'
import GenerateSubdomain from '@/views/Admin/Redirect/GenerateSubdomain.vue'
import CreateRules from '@/views/Admin/Rules/CreateRules'
import DeleteRules from '@/views/Admin/Rules/DeleteRules'
import RESET_AUTO_ABYSSED_MUTATION from '@/graphql/Redirect/ResetAutoAbyssedRedirectMutation.gql'
import SYNC_TAGS_WITH_TYPE_MUTATION from '@/graphql/Tag/SyncTagsWithTypeMutation.gql'
import SimpleReport from '@/views/Admin/Reports/SimpleReport'
import ALF_LISTINGS_QUERY from '@/graphql/Alf/AlfListingsQuery.gql'

export default {
  components: {
    PostbacksTable,
    Payouts,
    SubdomainHistory,
    RulesTableWrapper,
    SimpleReport
  },
  apollo: {
    savedRedirect: {
      query: REDIRECT_QUERY,
      update: data => data.redirect,
      variables() {
        return {
          id: this.id
        }
      },
      result({ data }) {
        this.redirect = JSON.parse(JSON.stringify(data.redirect))
      }
    },
    availableRedirectDomainsForUser: {
      query: AVAILABLE_REDIRECT_DOMAINS_FOR_USER_QUERY,
      variables() {
        return {
          userId: this.redirect ? this.redirect.user.id : undefined
        }
      },
      skip() {
        return !this.redirect
      }
    },
    alfListings: {
      query: ALF_LISTINGS_QUERY,
      variables() {
        return {
          first: 10,
          filters: {
            redirect: this.redirect
              ? { value: this.redirect.subdomain }
              : undefined,
            type: { value: 'listing' }
          }
        }
      },
      skip() {
        return !this.redirect
      }
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    tab: {
      type: String,
      default: 'details'
    }
  },
  data() {
    return {
      SYNC_TAGS_WITH_TYPE_MUTATION,
      domainUserPermissions: [],
      activeTab: this.tab,
      isVisible: true,
      shouldSendDomainReplacementEmailOption: true,
      savedRedirect: null,
      redirect: null,
      isSubmitting: false,
      dailyGraphFilters: {
        redirect: {
          value: [{ id: this.id }],
          modifiers: {
            exclude: false
          }
        }
      },
      reportFilters: {
        redirect: {
          value: [{ label: this.id, id: this.id }],
          modifiers: { exclude: false, edit: true }
        }
      },
      ruleTotalTabName: null
    }
  },
  computed: {
    ...mapState('rule', ['selectedRowIds']),
    domainChanged() {
      return this.redirect.domain != this.savedRedirect.domain
    },
    domainOptions() {
      return this.availableRedirectDomainsForUser
        ? this.availableRedirectDomainsForUser.map(domain => domain.id)
        : []
    },
    redirectDomain: {
      get: function() {
        if (this.redirect.domain) {
          return this.redirect.domain
        } else {
          return null
        }
      },
      set: function(value) {
        this.redirect.domain = value
        this.redirect = JSON.parse(JSON.stringify(this.redirect))
      }
    },
    tabRoute() {
      return { name: 'Redirect', params: { id: this.id, tab: this.tab } }
    },
    selectedCount() {
      return this.selectedRowIds.length
    },
    rowsAreSelected() {
      return this.selectedRowIds.length > 0
    }
  },
  mounted() {
    this.$events.listen('refetchPayouts', () => {
      this.$apollo.queries.savedRedirect.refetch()
    })
    this.$events.listen('refetchRedirects', () => {
      this.$apollo.queries.savedRedirect.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('refetchPayouts')
    this.$events.remove('refetchRedirects')
  },
  updated() {},
  methods: {
    totalRulesTab(value) {
      return (this.ruleTotalTabName = 'Rules (' + value.total + ')')
    },
    refreshAssignedTags() {
      this.$apollo.queries.savedRedirect.refetch()
    },
    ...mapState({
      filters: state => state.redirect.filters
    }),
    openCreateRulesModal() {
      this.$modal.show(
        CreateRules,
        {
          defaults: {
            type: {
              name: 'redirect',
              label: 'Redirect'
            },
            ruleables: [this.redirect]
          },
          hide: ['ruleables'],
          origin: 'publisher_rule'
        },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openDeleteRulesModal() {
      this.$modal.show(
        DeleteRules,
        { selectedRowIds: this.selectedRowIds },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 650,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openGenerateSubdomainModal() {
      this.$modal.show(
        GenerateSubdomain,
        { redirect: this.redirect },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true,
          classes: ['overflow-visible-important']
        }
      )
    },
    abyssRedirect() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: ABYSS_REDIRECT_MUTATION,
          variables: {
            input: {
              id: this.id,
              action: this.savedRedirect.isAbyssed ? 'unabyss' : 'abyss'
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$events.emit('refetchRedirects')
          this.$toastr.s('Redirect Abyssed')
        })
        .catch(error => {
          console.log(error)
          this.$toastr.e('Abyss Failed')
          this.isSubmitting = false
        })
    },
    resetAutoAbyssed() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: RESET_AUTO_ABYSSED_MUTATION,
          variables: {
            input: {
              redirect: { value: this.id }
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$events.emit('refetchRedirects')
          this.$toastr.s('Redirect Auto Abyss Reset')
        })
        .catch(error => {
          this.isSubmitting = false
          console.log(error)
          this.$toastr.e(error)
        })
    },
    visibilityChanged(isVisible) {
      this.isVisible = isVisible
    },
    sendDomainReplacementEmail(oldDomain) {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: SEND_REDIRECT_DOMAIN_REPLACEMENT_EMAIL_MUTATION,
          variables: {
            input: {
              id: this.id,
              oldDomain: oldDomain
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$toastr.s('Email Sent')
        })
        .catch(error => {
          console.log(error)
          this.$toastr.e('Email Failed')
          this.isSubmitting = false
        })
    },
    saveRedirect() {
      this.isSubmitting = true
      let currentDomain = this.savedRedirect.domain
      let input = {
        domain: this.redirectDomain,
        ignoreStatusCake: this.redirect.ignoreStatusCake
      }
      this.$apollo
        .mutate({
          mutation: UPDATE_REDIRECT_MUTATION,
          variables: {
            input: input,
            id: this.id
          }
        })
        .then(({ data }) => {
          this.isSubmitting = false
          if (currentDomain !== data.updateRedirect.domain) {
            if (this.shouldSendDomainReplacementEmailOption) {
              this.sendDomainReplacementEmail(currentDomain)
            }
          }
          this.$toastr.s('Redirect Updated')
        })
        .catch(error => {
          console.log(error)
          this.$toastr.e('Redirect Update Failed')
          this.isSubmitting = false
        })
    }
  }
}
</script>
