<template>
  <div
    class="my-2 p-3"
    style="border: 1px solid rgba(0,0,0,0.1); border-radius: 3px; box-shadow: 1px 1px 4px rgba(0,0,0,0.06)"
  >
    <div class="flex w-full">
      <!-- <div class="ts-6 text-gray-600 p-3 pr-6 none block-md">
        <Icon name="dns" :size="4" />
      </div> -->
      <div class="flex-grow-md-1 w-full w-md-auto">
        <div class="flex justify-between">
          <div>
            <div class="flex items-center mb-2">
              <div class="mr-3">
                <h6 class="mb-0 font-bold">
                  
                  <a v-if="server.type == 'proxy'" v-bind:href="'http://'  +  server.serverIp + '/haproxy?stats'">
                    <Icon
                      name="information"
                      :size="6"
                      class="inline -mx-1"
                    />
                  </a>
                  <template v-if="server.hostname">
                    {{ server.hostname }}
                  </template>
                  <template v-else>
                    {{ server.provider.name }} - {{ server.id }}
                  </template>
                </h6>
              </div>
            </div>
            <template v-if="server.ipSummary">
              <div class="text-gray-600 text-xs">
                Server IP:
                <EditableValue
                  :key="server.id"
                  type="contentEditable"
                  :value="server.serverIp"
                  :mutation="UPDATE_SERVERS_MUTATION"
                  :variables="{
                    input: [{ id: server.id, serverIp: '{value}' }]
                  }"
                  class="text-blue-600 font-semibold"
                  :edit-focus-icon-enabled="false"
                />
              </div>
              <div v-if="server.type != 'nameserver'" class="text-gray-600 text-xs">
                Total IPs:
                <span class="text-blue-600 font-semibold">{{
                  server.ipSummary.count
                }}</span>
              </div>
              <div v-if="server.type != 'nameserver'" class="text-gray-600 text-xs">
                Enabled IPs:
                <span class="text-green-600 font-semibold">{{
                  server.ipSummary.enabledCount
                }}</span>
              </div>
              <div class="text-gray-600 text-xs">
                Server Type:
                <span v-if="server.type == null" class="text-blue-600 font-semibold">proxy</span>
                <span v-else class="text-blue-600 font-semibold">{{
                  server.type
                }}</span>
              </div>
            </template>
          </div>
          <div>
            <div class="block text-right">
              <server-actions
                ref="serverActions"
                :servers="[server]"
                :provider="provider"
              ></server-actions>
            </div>
          </div>
        </div>
        <div v-if="server.type != 'nameserver'">
          <ips
            :show-ips-by-default="showIpsByDefault"
            :server="server"
            :value="server.ips"
          ></ips>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import Ips from './Ips'
import ServerActions from './ServerActions'
import UPDATE_SERVERS_MUTATION from '@/graphql/DomainProvider/UpdateServersMutation.gql'

export default {
  components: {
    Ips,
    ServerActions
  },
  props: {
    value: {
      type: Object
    },
    showIpsByDefault: {
      type: Boolean,
      default: false
    },
    provider: {
      type: Object
    }
  },
  apollo: {},
  data() {
    return {
      UPDATE_SERVERS_MUTATION,
      isLoading: false
    }
  },
  computed: {
    server() {
      return this.value
    },
    serverIsUp() {
      var numberOfSuccessfulChecks = this.value.checks.filter(
        check => check['status'] === 'success'
      ).length
      return this.value.checks.length === numberOfSuccessfulChecks
    }
  },
  watch: {},
  mounted() {},

  updated() {},

  methods: {}
}
</script>

<style scoped></style>
