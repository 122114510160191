var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "grid grid-cols-1 sm:grid-cols-5 gap-1"
  }, [_c('YInput', {
    attrs: {
      "label": "ID"
    },
    model: {
      value: _vm.filters.id.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.id, "value", $$v);
      },
      expression: "filters.id.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Advertiser",
      "tooltip": "Searches primary, billing, secondary contacts"
    },
    model: {
      value: _vm.filters.company.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.company, "value", $$v);
      },
      expression: "filters.company.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Tracking Platform",
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['trackingPlatform']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.trackingPlatform.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.trackingPlatform, "value", $$v);
      },
      expression: "filters.trackingPlatform.value"
    }
  }), _c('Superselect', {
    staticClass: "w-full",
    attrs: {
      "title": "Status",
      "multiple": false,
      "placeholder": "Select",
      "options": ['Active', 'Lead']
    },
    model: {
      value: _vm.filters.status.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.status, "value", $$v);
      },
      expression: "filters.status.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Manager",
      "multiple": false,
      "track-by": "id",
      "label": "label",
      "query": _vm.ACCOUNT_MANAGER_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: [],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      }
    },
    model: {
      value: _vm.filters.manager.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.manager, "value", $$v);
      },
      expression: "filters.manager.value"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }