var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sticky-content-panel', {
    on: {
      "close": function () {
        _this.$emit('close');
      },
      "key": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "right", 39, $event.key, ["Right", "ArrowRight"])) return null;
        if ('button' in $event && $event.button !== 2) return null;
        return function () {}.apply(null, arguments);
      }
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('div', {
    ref: "header",
    staticClass: "sticky-content-header bg-white"
  }, [_c('div', {
    staticClass: "flex items-center justify-between w-100 px-4 py-4"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('h4', {
    staticClass: "pr-4 mb-0 text-base font-bold"
  }, [_vm._v(" Campaign Export Wizard ")]), _vm.publisher ? _c('Tag', {
    attrs: {
      "color": "purple"
    }
  }, [_vm._v(" " + _vm._s(_vm.publisher.label) + " ")]) : _vm._e()], 1), _c('h4', {
    staticClass: "mb-0",
    attrs: {
      "slot": "right"
    },
    slot: "right"
  }, [_c('close-x', {
    staticClass: "link",
    on: {
      "click": function () {
        _this.$emit('close');
      }
    }
  })], 1)]), _c('Tabs', {
    ref: "tabs"
  }, [_c('Tab', {
    attrs: {
      "name": "Select Publisher",
      "active": true
    }
  }, [_c('portal', {
    attrs: {
      "to": "sticky-content-panel-body"
    }
  }, [_c('div', {
    staticClass: "p-6"
  }, [_c('h4', {
    staticClass: "text-base font-bold mb-2"
  }, [_vm._v(" Select Export Type ")]), _c('div', {
    staticClass: "flex flex-col gap-6"
  }, [_c('div', {
    staticClass: "flex gap-4"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.previewType,
      expression: "previewType"
    }],
    attrs: {
      "type": "radio",
      "name": "exportType",
      "id": "preview-link",
      "value": "preview-link"
    },
    domProps: {
      "checked": _vm._q(_vm.previewType, "preview-link")
    },
    on: {
      "change": function ($event) {
        _vm.previewType = "preview-link";
      }
    }
  }), _c('label', {
    staticClass: "capitalize",
    attrs: {
      "for": "type"
    }
  }, [_vm._v("Generate Preview Link & Send Email")])]), _c('div', {
    staticClass: "flex gap-4"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.previewType,
      expression: "previewType"
    }],
    attrs: {
      "type": "radio",
      "name": "exportType",
      "id": "generate-text",
      "value": "generate-text"
    },
    domProps: {
      "checked": _vm._q(_vm.previewType, "generate-text")
    },
    on: {
      "change": function ($event) {
        _vm.previewType = "generate-text";
      }
    }
  }), _c('label', {
    staticClass: "capitalize",
    attrs: {
      "for": "type"
    }
  }, [_vm._v("Generate Text and Export CSV File")])])])]), _c('Superselect', {
    attrs: {
      "title": "Publisher",
      "multiple": false,
      "options": [],
      "placeholder": "Select Publisher",
      "track-by": "id",
      "label": "label",
      "close-on-select": false,
      "query": _vm.PUBLISHER_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      },
      "edit-placeholder": "Paste Publisher"
    },
    on: {
      "select": _vm.publisherSelected
    },
    model: {
      value: _vm.publisher,
      callback: function ($$v) {
        _vm.publisher = $$v;
      },
      expression: "publisher"
    }
  }), _vm.exportToolIsPreviewLink ? _c('portal', {
    attrs: {
      "to": "campaign-export-preview-link-bar"
    }
  }, [_c('div'), _c('div', [_c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs['tabs'].next();
      }
    }
  }, [_vm._v(" Next")])], 1)]) : _c('portal', {
    attrs: {
      "to": "campaign-export-text-action-bar"
    }
  }, [_c('div'), _c('div', [_c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs['tabs'].next();
      }
    }
  }, [_vm._v(" Next")])], 1)])], 1)], 1), !_vm.exportToolIsPreviewLink ? _c('span', [!_vm.exportToolIsPreviewLink ? _c('Tab', {
    attrs: {
      "name": "Report Fields"
    }
  }, [_c('portal', {
    attrs: {
      "to": "sticky-content-panel-body"
    }
  }, [!_vm.areAllSubscribed ? _c('YAlert', {
    attrs: {
      "type": "info",
      "allow-close": false
    }
  }, [_c('div', {
    staticClass: "flex flex-col"
  }, [_c('div', [_vm._v(" User "), _c('span', {
    staticClass: "fw-500"
  }, [_vm._v(_vm._s(_vm.publisher.email))]), _vm._v(" is not subscribed to all campaigns ")]), _c('div', {
    staticClass: "mt-4 ml-3 font-semibold"
  }, [_c('YCheckbox', {
    staticClass: "flex items-center mr-2",
    attrs: {
      "id": "preset-is-public-checkbox",
      "label": "Disable Reminder Emails for this Campaign",
      "inline": true,
      "type": "info"
    },
    model: {
      value: _vm.disableReminderEmails,
      callback: function ($$v) {
        _vm.disableReminderEmails = $$v;
      },
      expression: "disableReminderEmails"
    }
  })], 1), _c('YButton', {
    staticClass: "btn-info btn-lg mt-2 w-2/5",
    attrs: {
      "color": "blue",
      "is-loading": _vm.isSubscribing
    },
    on: {
      "click": function ($event) {
        return _vm.subscribeToAll();
      }
    }
  }, [_vm._v("Subscribed to All")])], 1)]) : _vm.areAllSubscribed ? _c('span', [_c('div', {
    staticClass: "p-6"
  }, [_c('h4', {
    staticClass: "text-base font-bold mb-6"
  }, [_vm._v(" Export Format Options ")]), _c('div', {
    staticClass: "flex flex-col gap-6"
  }, [_c('div', {
    staticClass: "flex items-start gap-4"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.exportFormatType,
      expression: "exportFormatType"
    }],
    staticClass: "mt-1",
    attrs: {
      "type": "radio",
      "id": "ungrouped",
      "value": "ungrouped"
    },
    domProps: {
      "checked": _vm._q(_vm.exportFormatType, "ungrouped")
    },
    on: {
      "change": function ($event) {
        _vm.exportFormatType = "ungrouped";
      }
    }
  }), _c('label', {
    attrs: {
      "for": "ungrouped"
    }
  }, [_vm._v("Ungrouped")]), _c('code', {
    staticClass: "bg-gray-100 p-2 rounded-lg border"
  }, [_vm._v("Campaign: 2903 - Fat Pirate - CPL "), _c('br'), _vm._v(" Tracking link: https://xzy.site.com/o/ABC?kw=SUBID&s1=CLICKID"), _c('br'), _vm._v(" Payout: GB ($10), CA ($5)"), _c('br'), _vm._v(" Cap: 100 Total")])]), _c('div', {
    staticClass: "flex items-start gap-4"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.exportFormatType,
      expression: "exportFormatType"
    }],
    staticClass: "mt-1",
    attrs: {
      "type": "radio",
      "id": "grouped",
      "value": "grouped"
    },
    domProps: {
      "checked": _vm._q(_vm.exportFormatType, "grouped")
    },
    on: {
      "change": function ($event) {
        _vm.exportFormatType = "grouped";
      }
    }
  }), _c('label', {
    attrs: {
      "for": "grouped"
    }
  }, [_vm._v("Grouped")]), _c('code', {
    staticClass: "bg-gray-100 p-2 rounded-lg border"
  }, [_vm._v(" United Kingdom "), _c('br'), _c('br'), _vm._v(" Campaign: 2903 - Fat Pirate - CPL "), _c('br'), _vm._v(" Tracking link: https://xzy.site.com/o/ABC?kw=SUBID&s1=CLICKID"), _c('br'), _vm._v(" Payout: $10"), _c('br'), _vm._v(" Cap: 100 Total ")])])])]), _vm.exportFormatType == 'grouped' ? _c('div', {
    staticClass: "p-6 pb-0"
  }, [_c('h4', {
    staticClass: "text-base font-bold mb-6"
  }, [_vm._v(" Select Countries to include in report ")]), _c('ul', {
    staticClass: "country-list"
  }, _vm._l(_vm.uniqueCountryCodes, function (country) {
    return _c('li', {
      key: country
    }, [_c('label', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedCountryCodes,
        expression: "selectedCountryCodes"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "value": country,
        "checked": Array.isArray(_vm.selectedCountryCodes) ? _vm._i(_vm.selectedCountryCodes, country) > -1 : _vm.selectedCountryCodes
      },
      on: {
        "change": function ($event) {
          var $$a = _vm.selectedCountryCodes,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = country,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_vm.selectedCountryCodes = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.selectedCountryCodes = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.selectedCountryCodes = $$c;
          }
        }
      }
    }), _vm._v(" " + _vm._s(country) + " ")])]);
  }), 0)]) : _vm._e(), _c('div', {
    staticClass: "p-6"
  }, [_c('h4', {
    staticClass: "text-base font-bold mb-6"
  }, [_vm._v(" Export Fields ")]), _vm._l(_vm.exportedFields, function (field) {
    return _c('div', {
      key: field
    }, [_c('label', {
      staticClass: "capitalize flex gap-4"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedExportedFields,
        expression: "selectedExportedFields"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "value": field,
        "checked": Array.isArray(_vm.selectedExportedFields) ? _vm._i(_vm.selectedExportedFields, field) > -1 : _vm.selectedExportedFields
      },
      on: {
        "change": function ($event) {
          var $$a = _vm.selectedExportedFields,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = field,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_vm.selectedExportedFields = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.selectedExportedFields = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.selectedExportedFields = $$c;
          }
        }
      }
    }), _vm._v(" " + _vm._s(field) + " ")])]);
  })], 2)]) : _vm._e(), _c('portal', {
    attrs: {
      "to": "campaign-export-text-action-bar"
    }
  }, [_c('div'), _c('div', [_c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.generateExportText
    }
  }, [_vm._v("Next")])], 1)])], 1)], 1) : _vm._e(), !_vm.exportToolIsPreviewLink ? _c('Tab', {
    attrs: {
      "name": "File Export"
    }
  }, [_c('portal', {
    attrs: {
      "to": "sticky-content-panel-body"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.generatedText,
      expression: "generatedText"
    }],
    ref: "generatedTextArea",
    staticClass: "w-full bg-gray-100 p-4 rounded-lg border font-mono",
    staticStyle: {
      "height": "460px"
    },
    domProps: {
      "value": _vm.generatedText
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.generatedText = $event.target.value;
      }
    }
  }), _c('div', {
    staticClass: "w-full flex justify-between mt-4"
  }, [_c('YButton', {
    attrs: {
      "color": "gray"
    },
    on: {
      "click": _vm.copyToClipboard
    }
  }, [_vm._v("Copy To Clipboard")]), _c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.downloadText
    }
  }, [_vm._v("Download File")])], 1), _c('div', {
    staticClass: "w-full flex justify-end mt-8"
  }, [_c('YButton', {
    staticClass: "self-end",
    attrs: {
      "color": "inverse"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Close Window")])], 1)])], 1) : _vm._e()], 1) : _vm._e(), _vm.exportToolIsPreviewLink ? _c('span', [_c('Tab', {
    attrs: {
      "name": "Campaigns",
      "disabled": !_vm.publisher
    }
  }, [_c('portal', {
    attrs: {
      "to": "sticky-content-panel-body"
    }
  }, [!_vm.areAllSubscribed ? _c('YAlert', {
    attrs: {
      "type": "info",
      "allow-close": false
    }
  }, [_c('div', {
    staticClass: "flex flex-col"
  }, [_c('div', [_vm._v(" User "), _c('span', {
    staticClass: "fw-500"
  }, [_vm._v(_vm._s(_vm.publisher.email))]), _vm._v(" is not subscribed to all campaigns ")]), _c('div', {
    staticClass: "mt-4 ml-3 font-semibold"
  }, [_c('YCheckbox', {
    staticClass: "flex items-center mr-2",
    attrs: {
      "id": "preset-is-public-checkbox",
      "label": "Disable Reminder Emails for this Campaign",
      "inline": true,
      "type": "info"
    },
    model: {
      value: _vm.disableReminderEmails,
      callback: function ($$v) {
        _vm.disableReminderEmails = $$v;
      },
      expression: "disableReminderEmails"
    }
  })], 1), _c('YButton', {
    staticClass: "btn-info btn-lg mt-2 w-2/5",
    attrs: {
      "color": "blue",
      "is-loading": _vm.isSubscribing
    },
    on: {
      "click": function ($event) {
        return _vm.subscribeToAll();
      }
    }
  }, [_vm._v("Subscribed to All")])], 1)]) : _vm.areAllSubscribed ? _c('CampaignPreviews', {
    key: _vm.publisherCampaignsKey,
    attrs: {
      "publisherOptions": _vm.publisher,
      "editable": true
    },
    model: {
      value: _vm.publisherCampaigns,
      callback: function ($$v) {
        _vm.publisherCampaigns = $$v;
      },
      expression: "publisherCampaigns"
    }
  }) : _vm._e(), _c('portal', {
    attrs: {
      "to": "campaign-export-preview-link-bar"
    }
  }, [_c('div', [_c('YButton', {
    staticClass: "btn-link btn-lg mr-2",
    on: {
      "click": function ($event) {
        return _vm.$refs['tabs'].previous();
      }
    }
  }, [_vm._v("Back")])], 1), _c('div', [!_vm.areAllSubscribed ? _c('YButton', {
    attrs: {
      "color": "blue",
      "is-loading": _vm.isSubscribing
    },
    on: {
      "click": function ($event) {
        return _vm.subscribeToAll();
      }
    }
  }, [_vm._v("Subscribe To All")]) : _vm._e(), _vm.areAllSubscribed ? _c('YButton', {
    attrs: {
      "color": "blue",
      "disabled": !_vm.areAllSubscribed
    },
    on: {
      "click": function ($event) {
        return _vm.$refs['tabs'].next();
      }
    }
  }, [_vm._v("Next")]) : _vm._e()], 1)])], 1)], 1), _c('Tab', {
    attrs: {
      "name": "Message"
    }
  }, [_c('portal', {
    attrs: {
      "to": "sticky-content-panel-body"
    }
  }, [_c('Superselect', {
    staticClass: "mb-4",
    attrs: {
      "title": "Email To",
      "multiple": false,
      "options": [],
      "placeholder": "Select Publisher",
      "track-by": "id",
      "label": "label",
      "close-on-select": false,
      "query": _vm.PUBLISHER_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          id: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      },
      "edit-placeholder": "Paste Domains"
    },
    on: {
      "select": function ($event) {
        return _vm.$refs['tabs'].next();
      }
    },
    model: {
      value: _vm.emailTo,
      callback: function ($$v) {
        _vm.emailTo = $$v;
      },
      expression: "emailTo"
    }
  }), _c('Superselect', {
    staticClass: "mb-1",
    attrs: {
      "title": "From",
      "multiple": false,
      "track-by": "id",
      "label": "name",
      "options": _vm.admins ? _vm.admins.data : []
    },
    model: {
      value: _vm.emailFrom,
      callback: function ($$v) {
        _vm.emailFrom = $$v;
      },
      expression: "emailFrom"
    }
  }), _c('Superselect', {
    staticClass: "mb-1",
    attrs: {
      "title": "CC",
      "editPlaceholder": "",
      "forceEdit": true,
      "track-by": "email",
      "label": "email"
    },
    model: {
      value: _vm.emailCc,
      callback: function ($$v) {
        _vm.emailCc = $$v;
      },
      expression: "emailCc"
    }
  }), _c('Superselect', {
    staticClass: "mb-1",
    attrs: {
      "title": "BCC",
      "editPlaceholder": "",
      "forceEdit": true,
      "track-by": "id",
      "label": "name"
    },
    model: {
      value: _vm.emailBcc,
      callback: function ($$v) {
        _vm.emailBcc = $$v;
      },
      expression: "emailBcc"
    }
  }), _c('div', {
    staticClass: "mb-1 mt-4 text-indigo-600 text-xs"
  }, [_vm._v("Message")]), _c('ckeditor', {
    staticClass: "border border-gray-500",
    staticStyle: {
      "width": "100%",
      "min-height": "5rem"
    },
    attrs: {
      "editor": _vm.editor,
      "config": _vm.editorConfig
    },
    model: {
      value: _vm.emailMessage,
      callback: function ($$v) {
        _vm.emailMessage = $$v;
      },
      expression: "emailMessage"
    }
  }), _c('portal', {
    attrs: {
      "to": "campaign-export-preview-link-bar"
    }
  }, [_c('div', [_c('YButton', {
    staticClass: "btn-light btn-lg mr-2",
    on: {
      "click": function ($event) {
        return _vm.$refs['tabs'].previous();
      }
    }
  }, [_vm._v("Back")])], 1), _c('div', [!_vm.previewId && _vm.areAllSubscribed || !_vm.localDataSynced && _vm.areAllSubscribed ? _c('YButton', {
    staticClass: "mr-2",
    attrs: {
      "color": "blue",
      "is-loading": _vm.isCreatingPreview
    },
    on: {
      "click": _vm.generatePreviewId
    }
  }, [_vm._v("Generate Preview ID")]) : _c('YButton', {
    staticClass: "mr-2",
    attrs: {
      "color": "blue",
      "disabled": !_vm.areAllSubscribed || !_vm.previewId
    },
    on: {
      "click": function ($event) {
        return _vm.$refs['tabs'].next();
      }
    }
  }, [_vm._v("Next")])], 1)])], 1)], 1), _c('Tab', {
    attrs: {
      "name": "Preview",
      "disabled": _vm.publisherCampaigns.length <= 0 || !_vm.areAllSubscribed || !_vm.previewId || !_vm.localDataSynced
    }
  }, [_c('portal', {
    attrs: {
      "to": "sticky-content-panel-body"
    }
  }, [_c('div', [_c('h4', {
    staticClass: "text-lg font-bold mb-4"
  }, [_vm._v(" Hi" + _vm._s(_vm.campaignPreviewData.mail.toName ? ` ${_vm.campaignPreviewData.mail.toName},` : ',') + " ")]), _c('p', {
    staticClass: "mb-4",
    domProps: {
      "innerHTML": _vm._s(_vm.campaignPreviewData.mail.message)
    }
  }), _c('p', {
    staticClass: "mb-4"
  }, [_c('a', {
    staticClass: "link",
    attrs: {
      "href": `${_vm.appUrl}/campaigns/preview/${_vm.previewId}`,
      "target": "_blank"
    }
  }, [_vm._v("View Campaign Details")])])]), _c('portal', {
    attrs: {
      "to": "campaign-export-preview-link-bar"
    }
  }, [_c('div', [_c('YButton', {
    staticClass: "btn-light btn-lg mr-2",
    on: {
      "click": function ($event) {
        return _vm.$refs['tabs'].previous();
      }
    }
  }, [_vm._v("Back")])], 1), _c('div', [_c('YButton', {
    attrs: {
      "color": "blue",
      "is-loading": _vm.isSending
    },
    on: {
      "click": _vm.sendEmail
    }
  }, [_vm._v("Send Email")])], 1)])], 1)], 1)], 1) : _vm._e()], 1)], 1)]), _c('template', {
    slot: "action-bar"
  }, [_vm.exportToolIsPreviewLink ? _c('portal-target', {
    staticClass: "flex items-center justify-between w-full",
    attrs: {
      "name": "campaign-export-preview-link-bar"
    }
  }) : _vm._e(), !_vm.exportToolIsPreviewLink ? _c('portal-target', {
    staticClass: "flex items-center justify-between w-full",
    attrs: {
      "name": "campaign-export-text-action-bar"
    }
  }) : _vm._e()], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }