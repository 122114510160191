var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sidebar fixed md:relative h-full z-20 bg-inverse-900 text-gray-200 none flex transition-all duration-500 ease-in-out flex-col overflow-y-auto",
    class: _vm.isCollapsed === true ? ['x-collapsed'] : ['']
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "absolute top-0 right-0 sidebar-toggle"
  }, [_c('button', {
    staticClass: "x-collapse-button focus:outline-none px-1 py-4 bg-inverse-900 hover:bg-inverse-700 text-gray-200 rounded-full",
    on: {
      "click": function ($event) {
        return _vm.toggleSidebar();
      }
    }
  }, [_c('Icon', {
    staticClass: "x-collapse-icon",
    attrs: {
      "name": "chevronLeft",
      "size": "5"
    }
  })], 1)]), _c('a', {
    staticClass: "mx-auto block pt-1",
    attrs: {
      "href": "#"
    }
  }, [_vm.me && _vm.me.roles.some(function (x) {
    return x.name == 'admin';
  }) ? _c('span', [_c('img', {
    staticClass: "h-12 block mx-auto mb-6 mt-4",
    attrs: {
      "src": _vm.YtrackLogo
    }
  })]) : _vm._e(), _vm.me && _vm.me.roles.some(function (x) {
    return x.name == 'publisher';
  }) ? _c('span', [_c('img', {
    staticClass: "h-12 block mx-auto mb-6 mt-4",
    attrs: {
      "src": require("@/assets/images/logo/logo_ytz.png")
    }
  })]) : _vm._e()])]), _vm.$apollo.queries.me.loading ? _c('Spinner', {
    attrs: {
      "color": "white",
      "size": 8
    }
  }) : _vm._e(), _c('div', {
    staticClass: "py-4 px-3 h-screen"
  }, _vm._l(_vm.listRoutes, function (route, index) {
    return _c('ul', {
      key: index
    }, [route.child.length > 0 && route.name != 'Settings' ? _c('span', [_c('dropdown-menu', {
      attrs: {
        "title": route.meta.publicName || route.name,
        "height": _vm.dropdownHeight(route.child),
        "parent": route,
        "currentRoute": _vm.currentRoute.name
      }
    }, _vm._l(route.child, function (child, index) {
      return _c('router-link', {
        key: index,
        attrs: {
          "to": {
            name: child.name
          },
          "target": child.meta.newTab ? '_blank' : ''
        },
        nativeOn: {
          "click": function ($event) {
            return _vm.collapseSidebar();
          }
        }
      }, [_c('li', {
        staticClass: "flex items-center justify-between mb-2 cursor-pointer py-2 pl-4 pr-1 rounded-md hover:bg-inverse-700",
        class: child.name == _vm.currentRoute.name ? ['bg-inverse-500'] : ['bg-inverse-900']
      }, [_vm._t("default", function () {
        return [_vm._v(_vm._s(child.name))];
      })], 2)]);
    }), 1)], 1) : _vm._e(), route.name == 'Settings' ? _c('span', [_c('dropdown-menu', {
      attrs: {
        "title": route.meta.publicName || route.name,
        "height": _vm.dropdownHeight(route.child),
        "parent": route
      }
    }, _vm._l(route.child, function (child, index) {
      return _c('router-link', {
        key: index,
        attrs: {
          "to": {
            name: child.name
          },
          "target": child.meta.newTab ? '_blank' : ''
        },
        nativeOn: {
          "click": function ($event) {
            return _vm.collapseSidebar();
          }
        }
      }, [_c('li', {
        staticClass: "flex items-center justify-between mb-2 cursor-pointer py-2 pl-4 pr-1 rounded-md hover:bg-inverse-700",
        class: child.name == _vm.currentRoute.name ? ['bg-inverse-500'] : ['bg-inverse-900']
      }, [_vm._t("default", function () {
        return [_vm._v(_vm._s(child.name))];
      })], 2)]);
    }), 1)], 1) : _vm._e(), route.child.length == 0 ? _c('span', [_c('li', {
      staticClass: "font-semibold text-sm list-none block"
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: route.name
        },
        "target": route.meta.newTab ? '_blank' : ''
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.collapseSidebar();
        }
      }
    }, [_c('div', {
      staticClass: "flex items-center justify-between mb-2 cursor-pointer py-2 pl-4 pr-1 rounded-md hover:bg-inverse-700",
      class: route.name == _vm.currentRoute.name ? ['bg-inverse-500'] : ['bg-inverse-900']
    }, [_vm._v(" " + _vm._s(route.meta.publicName || route.name) + " "), route.name == 'yLeader' && _vm.notify == true ? _c('div', {
      staticClass: "w-full flex justify-start ml-3"
    }, [_c('div', [_c('Icon', {
      staticClass: "fill-current mx-auto text-pink-500 w-3 h-3",
      attrs: {
        "name": "alert"
      }
    })], 1)]) : _vm._e()])])], 1)]) : _vm._e()]);
  }), 0), _c('div', {
    staticClass: "flex flex-col items-center mb-6 gap-4"
  }, [_vm.me && _vm.me.roles.some(function (x) {
    return x.name == 'admin';
  }) ? _c('span', [_c('img', {
    staticClass: "h-12 w-auto mb-2",
    attrs: {
      "src": require("@/assets/images/logo/allin-logo.png")
    }
  }), _c('img', {
    staticClass: "h-12 w-auto",
    attrs: {
      "src": require("@/assets/images/logo/logo_ytz.png")
    }
  })]) : _vm._e(), _vm.me && _vm.me.roles.some(function (x) {
    return x.name == 'publisher';
  }) ? _c('span', [_c('img', {
    staticClass: "h-12 block mx-auto mb-6 mt-4",
    attrs: {
      "src": _vm.YtrackLogo
    }
  })]) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }