<template>
  <div v-if="advertiser">
    <portal to="top-bar-left-header">
      {{ advertiser.advertiserId }} -
      {{ advertiser.primaryContact.company }}
    </portal>

    <div>
      <Tabs show-type="show" :route="tabRoute">
        <Tab name="Details" :active="true">
          <AdvertiserDetails :advertiser="advertiser" />
        </Tab>
        <Tab name="Communications">
          <div class="pt-3 w-2/3">
            <LeadComments
              v-if="user"
              :modelId="user.id"
              :comments="user.commentsLead"
              modelClass="User"
              :user="user"
            ></LeadComments>
          </div>
        </Tab>
        <Tab name="Accounts">
          <AdvertiserAccounts :id="advertiser.id"></AdvertiserAccounts>
        </Tab>
        <Tab name="Caps">
          <Caps
            v-if="advertiser"
            :cappableId="this.advertiser.id"
            cappableType="Advertiser"
          />
        </Tab>
        <Tab name="Caps Defaults">
          <CapDefaults
            v-if="advertiser"
            :cappableId="advertiser.id"
            cappableType="Advertiser"
          ></CapDefaults>
        </Tab>
        <Tab :name="ruleTotalTabName">
          <OfferPerspectiveRulesTableWrapper
            :default-filter-values="defaultFilterValues"
            :hide-all-offer-filters="true"
            :defaults-for-create="defaultsForCreate"
            ruleOrigin="advertiser_rule"
            rulesParentComponent="advertiser_rule"
            @totalRulesTab="totalRulesTab"
          />
        </Tab>
      </Tabs>
    </div>

    <AdvertiserSideBar :advertiser="advertiser" />
  </div>
</template>

<script>
import ADVERTISER_QUERY from '@/graphql/Advertiser/AdvertiserQuery.gql'
import USER_QUERY from '@/graphql/User/UserQuery.gql'
import AdvertiserSideBar from '@/views/Admin/Advertiser/AdvertiserSideBar.vue'
import LeadComments from '@/views/Admin/Leads/LeadComments.vue'
import OfferPerspectiveRulesTableWrapper from '@/views/Admin/Rules/OfferPerspectiveRulesTableWrapper.vue'
import Caps from '@/views/Admin/Caps/Caps'
import AdvertiserAccounts from '@/views/Admin/Advertiser/AdvertiserAccounts.vue'
import AdvertiserDetails from '@/views/Admin/Advertiser/AdvertiserDetails.vue'
import CapDefaults from '@/views/Admin/Caps/CapDefaults.vue'

export default {
  mixins: [],
  components: {
    CapDefaults,
    AdvertiserSideBar,
    LeadComments,
    OfferPerspectiveRulesTableWrapper,
    Caps,
    AdvertiserAccounts,
    AdvertiserDetails
  },
  apollo: {
    advertiser: {
      query: ADVERTISER_QUERY,
      variables() {
        return {
          advertiser_id: this.id
        }
      }
    },
    user: {
      query: USER_QUERY,
      variables() {
        return {
          id: this.id
        }
      },
      deep: false
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    tab: {
      type: String,
      default: 'details'
    }
  },
  data() {
    return {
      activeTab: this.tab,
      advertiser: null,
      ruleTotalTabName: null
    }
  },
  computed: {
    tabRoute() {
      return { name: 'Advertiser', params: { id: this.id, tab: this.tab } }
    },
    defaultsForCreate() {
      return {
        filterType: { name: 'advertiser', label: 'Advertiser' },
        filterables: [this.advertiser]
      }
    },
    defaultFilterValues() {
      return {
        advertisers: [this.advertiser]
      }
    }
  },
  mounted() {},
  updated() {},
  methods: {
    totalRulesTab(value) {
      return (this.ruleTotalTabName = 'Rules (' + value.total + ')')
    }
  }
}
</script>
