export const state = {
  filters: {
    id: {
      value: null,
      modifiers: null,
      title: 'ID',
      type: 'input'
    },
    name: {
      value: null,
      modifiers: {
        matchType: 'contains'
      },
      title: 'Name',
      type: 'input'
    },
    company: {
      value: null,
      modifiers: {
        matchType: 'contains'
      },
      title: 'Advertiser',
      type: 'input'
    },
    billingName: {
      value: null,
      modifiers: {
        matchType: 'contains'
      },
      title: 'Company Name',
      type: 'input'
    },
    manager: {
      title: 'Manager',
      type: 'select',
      multiple: false,
      optionLabelKey: 'label',
      trackByKey: 'id',
      value: null
    },
    trackingPlatform: {
      value: null,
      title: 'Tracking Platform',
      type: 'select',
      multiple: true,
      trackByKey: 'name'
    },
    status: {
      title: 'Status',
      type: 'select',
      multiple: false,
      optionLabelKey: 'name',
      trackByKey: 'id',
      value: 'Active'
    }
  },
  orderBy: []
}
