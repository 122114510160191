var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.publisherCampaigns ? _c('div', [_vm._l(_vm.publisherCampaigns, function (publisherCampaign, index) {
    return _c('div', {
      key: publisherCampaign.id
    }, [_c('Widget', {
      staticClass: "mb-2"
    }, [_c('WidgetBody', [_c('CampaignPreviewCard', {
      attrs: {
        "editable": _vm.editable,
        "publisherOptions": _vm.publisherOptions
      },
      model: {
        value: _vm.publisherCampaigns[index],
        callback: function ($$v) {
          _vm.$set(_vm.publisherCampaigns, index, $$v);
        },
        expression: "publisherCampaigns[index]"
      }
    })], 1)], 1)], 1);
  })], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }