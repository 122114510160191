<template>
  <div>
    <!-- <div class="flex justify-end items-center gap-3 mb-2" v-if="caps">
     
    </div> -->
    <YTable
      v-if="caps"
      class="table-striped table-hover overflow-hidden"
      block-style="min-height: 25rem;"
      :scroll-x="false"
      :data="caps.data"
      :selectable="true"
      :selected.sync="selectedRowIds"
    >
      <template slot="header-row">
        <th>
          Frequency
        </th>
        <th>
          Type
        </th>
        <th>
          Geo
        </th>
        <th>
          Redirect
        </th>
        <th>
          Source
        </th>
        <th>
          Value
        </th>
        <th></th>
        <th>
          <v-popover
            class="flex items-center rounded cursor-pointer -ml-3 -mr-3"
          >
            <div class="flex items-center hover:text-blue-500">
              <YButton class="px-3"><Icon name="eye"/></YButton>
            </div>
            <!-- This will be the content of the popover -->
            <template slot="popover">
              <div class="flex flex-col">
                <YButton
                  @click="showActiveCaps"
                  class="rounded-b-none"
                  :color="trashedInput === 'WITHOUT' ? 'red' : 'link'"
                  >Show Active</YButton
                >
                <YButton
                  @click="showDeletedCaps"
                  class="rounded-none"
                  :color="trashedInput === 'ONLY' ? 'red' : 'link'"
                  >Show Deleted</YButton
                >
                <YButton
                  @click="showAllCaps"
                  class="rounded-t-none"
                  :color="trashedInput === 'WITH' ? 'red' : 'link'"
                  >Show All</YButton
                >
              </div>
            </template>
          </v-popover>
        </th>
        <th>
          <YButton
        class="text-red px-3"
        @click="openDeleteModal"
        :disabled="!rowsAreSelected"
            ><Icon name="trashCan"/></YButton>
        </th>
      </template>
      <template slot="secondHeaderRow">
        <td>
          <Superselect
            title="Frequency"
            v-model="frequency"
            :required="true"
            label="label"
            track-by="value"
            :options="frequency.length == 0 ? frequencyOptions : []"
            class="-mx-3"
          />
        </td>
        <td>
          <Superselect
            title="Type"
            v-model="type"
            :required="true"
            :options="
              type.length > 0
                ? []
                : cappableType == 'Campaign'
                ? campaignTypeOptions
                : typeOptions
            "
            label="label"
            track-by="value"
            class="-mx-3"
          />
        </td>
        <td>
          <Superselect
            title="Geo"
            v-model="countries"
            track-by="code"
            label="label"
            :query="COUNTRIES_QUERY"
            :query-variables="{
              filters: {
                countriesOnly: true
              },
              orderBy: [{ column: 'name', order: 'ASC' }]
            }"
            class="-mx-3"
          />
        </td>
        <td>
          <Superselect
            title="Redirects"
            v-model="redirects"
            track-by="id"
            label="label"
            :disabled="sourceIds.length > 0"
            :query="REDIRECT_OPTIONS_QUERY"
            :query-variables="{
              first: 100,
              filters: {
                campaignId:
                  cappableType == 'Campaign'
                    ? {
                        value: [cappableId]
                      }
                    : undefined,
                label: {
                  value: ['{input}'],
                  modifiers: { matchType: 'contains' }
                }
              }
            }"
            class="-mx-3"
          />
        </td>
        <td>
          <Superselect
            title="Sources"
            v-model="sourceIds"
            :options="[]"
            :forceEdit="true"
            :disabled="redirects.length !== 1"
            class="-mx-3"
          />
        </td>
        <td>
          <YInput
            label="Value"
            v-model="triggerValue"
            class="-mx-3"
            :required="true"
          />
        </td>
        <td>
          <div class="flex items-center -ml-3 -mr-3">
            <YButton
              @click="upsertCaps(upsertCapObjects)"
              color="link"
              class="px-3"
              :disabled="frequency.length === 0 || type.length === 0 || triggerValue === null"
              :is-loading="isUpserting"
              ><Icon name="plus"
            /></YButton>
          </div>
        </td>
        <td>
          <Spinner v-if="$apollo.queries.caps.loading" color="blue" :size="6" />
        </td>
        <td></td>
      </template>
      <template slot="row" slot-scope="props">
        <td>
          {{ props.rowData.frequency.capitalize() }}
        </td>
        <td>
          {{ props.rowData.type.capitalize() }}
        </td>
        <td>
          <div class="flex flex-col">
            <span>
              {{ getTriggerable(props.rowData.triggerables, 'Country')?.code || getTriggerable(props.rowData.triggerables, 'Country') }}
            </span>
          </div>
        </td>
        <td>
          <div class="flex flex-col">
            <span>
              {{ getTriggerable(props.rowData.triggerables, 'Redirect')?.label ||  getTriggerable(props.rowData.triggerables, 'Redirect') }}
            </span>
            <span class="text-xs italic text-gray-700">
              {{ getTriggerable(props.rowData.triggerables, 'Redirect')?.user?.label || '' }}
            </span>
            <span class="text-xs italic text-gray-700 block">
            {{ getTriggerable(props.rowData.triggerables, 'Source')?.redirect?.label || '' }}

            </span>
          </div>
        </td>
        <td>
          {{ getTriggerable(props.rowData.triggerables, 'Source')?.id || getTriggerable(props.rowData.triggerables, 'Source') }}
        </td>
        <td>
          <div class="flex items-center">
            <span class="-mr-1">
              {{ props.rowData.type === 'revenue' ? '$' : '' }}
              <EditableValue
                :key="props.rowData.id"
                type="contentEditable"
                :isNumberValue="true"
                :value="props.rowData.currentValue"
                :mutation="UPDATE_CAP_MUTATION"
                :variables="{
                  input: { id: props.rowData.id, currentValue: '{value}' }
                }"
                placeholder="0"
                class=""
                value-class="px-1"
                :edit-focus-icon-enabled="false"
              />
            </span>
            /
            <div class="flex">
              <div
                contenteditable
                ref="contentEditable"
                class="focus:bg-indigo-200 inline px-1"
                @input="handleInput(props.rowData, $event)"
                @focus="focusEvent(props.rowData, $event)"
                @blur="captureTriggerValue(props.rowData, $event)"
                @keydown.enter="$event.target.blur()"
              >
                {{ props.rowData.triggerValue }}
              </div>

              <div v-if="pendingRowId === props.rowData.id">
                <span
                  class="inline-flex items-center rounded-lg shadow-md ml-2 overflow-hidden"
                >
                  <span
                    ref="saveAction"
                    tabindex="0"
                    class="cursor-pointer px-1 bg-green-300 hover:bg-green-400 focus:bg-green-400 text-green-700 outline-none-important"
                    @click="updateTriggerValueModal"
                    @keydown.enter="updateTriggerValueModal"
                    ><Icon name="contentSave"
                  /></span>
                  <span
                    tabindex="0"
                    class="cursor-pointer bg-red-300 hover:bg-red-400 focus:bg-red-400 text-red-700 px-1 outline-none-important"
                    @click="cancelTriggerValueModal"
                    @keydown.enter="cancelTriggerValueModal"
                    ><Icon name="close"
                  /></span>
                </span>
              </div>
            </div>
          </div>

          {{
            capPercentage(
              props.rowData.currentValue,
              props.rowData.triggerValue
            )
          }}
        </td>
        <td>
          <v-popover
            v-if="!props.rowData.deletedAt"
            v-tooltip="{
              content: props.rowData.isIgnored
                ? 'Paused'
                : !props.rowData.isCapped
                ? 'Uncapped'
                : props.rowData.cappedBy
                ? 'Manually Capped'
                : 'Capped',
              delay: { show: 1000 }
            }"
            class="inline-block hover:bg-blue-200 rounded cursor-pointer"
          >
            <div class="flex items-center">
              <span
                class="h-2 w-2 m-2 rounded-full"
                :class="
                  props.rowData.isIgnored
                    ? 'bg-gray-500'
                    : !props.rowData.isCapped
                    ? 'bg-green-500'
                    : props.rowData.cappedBy
                    ? 'bg-yellow-500'
                    : 'bg-red-500'
                "
              >
              </span>
            </div>

            <!-- This will be the content of the popover -->
            <template slot="popover">
              <div class="flex flex-col">
                <YButton
                  v-if="!props.rowData.isIgnored && !props.rowData.isCapped"
                  @click="toggleManualCap(props.rowData)"
                  color="link"
                  >Cap</YButton
                >
                <YButton
                  v-if="!props.rowData.isIgnored && props.rowData.cappedBy"
                  @click="toggleManualCap(props.rowData)"
                  color="link"
                  >Uncap</YButton
                >
                <YButton @click="togglePauseCap(props.rowData)" color="link">{{
                  !props.rowData.isIgnored ? 'Pause' : 'Unpause'
                }}</YButton>
              </div>
            </template>
          </v-popover>
          <a
            v-else
            class="flex items-center text-gray-500 hover:text-gray-900 rounded cursor-pointer"
            @click.prevent="restoreCap(props.rowData.id)"
          >
            <Icon name="restore" />
          </a>
        </td>
        <td v-if="cappableType === 'Campaign'">
          <a
            class="link"
            @click.prevent="
              openEditFallbackModal(
                getTriggerable(props.rowData.triggerables, 'Redirect')?.id || '',
                getTriggerable(props.rowData.triggerables, 'Redirect')?.fallbackCampaignId || '',
                getTriggerable(props.rowData.triggerables, 'Redirect')?.fallbackOfferId || ''
              )
            "
          >
            <Icon
              :class="
                getTriggerable(props.rowData.triggerables, 'Redirect')?.fallbackCampaignId
                  ? 'text-blue-400 hover:text-blue-500'
                  : 'text-gray-400 hover:text-blue-400'
              "
              name="shield"
            />
          </a>
        </td>
        <td>
          <a
            v-if="!props.rowData.deletedAt"
            class="text-gray-400 hover:text-red-500 cursor-pointer"
            @click.prevent="deleteCap(props.rowData.id)"
          >
            <Icon name="trashCan" />
          </a>
        </td>
      </template>
    </YTable>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>

    <!-- Delete Caps Modal -->
    <modal name="confirm-delete-caps-modal" :width="364" :height="200">
      <Widget class="bg-gray-200 border-gray-500">
        <WidgetHeader class="bg-gray-200">Confirm Delete Caps</WidgetHeader>
        <WidgetBody>
          <p>Are you sure you want to delete the selected caps?</p>
        </WidgetBody>
        <WidgetFooter
          class="bg-dark-transparent-1 flex justify-between gap-6 py-2 px-4"
        >
          <YButton color="inverse" @click="cancelBulkDelete">Cancel</YButton>
          <YButton color="red" @click="bulkDeleteCaps" :is-loading="isBulkDeleting">Delete</YButton>
        </WidgetFooter>
      </Widget>
    </modal>

    <!-- Confirm Send Cap Email Modal -->
    <modal name="confirm-cap-email-modal" :width="364" :height="100">
      <Widget class="bg-gray-200  border-gray-500">
        <WidgetHeader class="bg-gray-200"
          >Send Cap Email Nofication?</WidgetHeader
        >
        <WidgetFooter
          class="bg-dark-transparent-1 flex justify-between gap-6 py-2 px-4"
        >
          <YButton color="inverse" @click="saveTriggerValue(true)"
            >Do Not Send</YButton
          >
          <YButton color="blue" @click="saveTriggerValue(false)"
            >Send Emails</YButton
          >
        </WidgetFooter>
      </Widget>
    </modal>
  </div>
</template>

<script>
import CAPS_QUERY from '@/graphql/Caps/CapsQuery.gql'
import Superselect from '../../../components/ui/Superselect.vue'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'
import UPSERT_CAPS_MUTATION from '@/graphql/Caps/UpsertCapsMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import DELETE_CAPS_MUTATION from '@/graphql/Caps/DeleteCapsMutation.gql'
import DELETE_CAP_MUTATION from '@/graphql/Caps/DeleteCapMutation.gql'
import UPDATE_CAP_MUTATION from '@/graphql/Caps/UpdateCapMutation.gql'
import RESTORE_CAP_MUTATION from '@/graphql/Caps/RestoreCapMutation.gql'
import UpdateCampaignFallbackModal from '@/views/Admin/Campaign/UpdateCampaignFallbackModal'

export default {
  mixins: [ValidationErrorsMixin],
  components: { Superselect },
  props: {
    cappableId: {
      type: String
    },
    cappableType: {
      type: String
    },
    fallbackCampaignId: {
      type: String
    }
  },
  apollo: {
    caps: {
      query: CAPS_QUERY,
      variables() {
        console.log(this.redirects.value)
        return {
          filters: {
            cappableId: { value: [this.cappableId] },
            cappableType: this.cappableType,
            frequency:
              this.frequency.length != 0
                ? { value: this.frequency[0].value }
                : undefined,
            type:
              this.type.length != 0 ? { value: this.type[0].value } : undefined,
            triggerableIds: this.cleanObjectFilter([...this.redirects, ...this.countries.map(country => ({id: country.code})), ...this.sourceIds.map(sourceId => ({id: sourceId}))], 'id')
          },
          first: 300,
          trashed: this.trashedInput,
          orderBy: [
            {
              column: 'capped_at',
              order: 'DESC'
            },
            {
              column: 'capped_by',
              order: 'ASC'
            },
            {
              column: 'ignored_at',
              order: 'ASC'
            }
          ]
        }
      }
    }
  },
  data() {
    return {
      UPDATE_CAP_MUTATION,
      REDIRECT_OPTIONS_QUERY,
      COUNTRIES_QUERY,
      isUpserting: false,
      isBulkDeleting: false,
      selectedRowIds: [],
      frequencyOptions: [
        {
          value: 'daily',
          label: 'Daily'
        },
        {
          value: 'monthly',
          label: 'Monthly'
        },
        {
          value: 'total',
          label: 'Total'
        }
      ],
      typeOptions: [
        {
          value: 'actions',
          label: 'Actions'
        },
        {
          value: 'revenue',
          label: 'Revenue'
        }
      ],
      campaignTypeOptions: [
        {
          value: 'publisherActions',
          label: 'Publisher Actions'
        },
        {
          value: 'payout',
          label: 'Payout'
        }
      ],
      frequency: [],
      type: [],
      countries: [],
      redirects: [],
      sourceIds: [],
      triggerValue: null,
      trashedInput: 'WITHOUT',
      pendingRowId: null,
      pendingValue: null
    }
  },
  mounted() {
    this.$events.listen('capDeleted', () => {
      this.$apollo.queries.caps.refetch()
    })
    this.$events.listen('capUpserted', () => {
      this.$apollo.queries.caps.refetch()
    })
    this.$events.listen('capDefaultCreated', () => {
      this.$apollo.queries.caps.refetch()
    })
    this.$events.listen('capDefaultUpdated', () => {
      this.$apollo.queries.caps.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('CapDefaultCreated')
    this.$events.remove('CapDefaultUpdated')
    this.$events.remove('capDeleted')
    this.$events.remove('capUpserted')
  },
  computed: {
    redirectIds() {
      return this.redirects.map(redirect => redirect.id)
    },
    redirectKeys() {
      return this.redirects.map(redirect => 'Redirect_' + redirect.id)
    },
    sourceKeys() {
      return this.sourceIds.map(source => 'Source_' + source)
    },
    countryKeys() {
      return this.countries.map(country => 'Country_' + country.code)
    },
    upsertCapKeys() {
      return this.getPermutations(
        this.getPermutations(
          this.getPermutations(
            this.getPermutations(
              this.getPermutations(
                [this.cappableId + '-' + this.cappableType],
                [this.frequency[0].value]
              ),
              [this.type[0].value]
            ),
            this.redirectKeys,
          ),
          this.sourceKeys, '__'
        ),
        this.countryKeys, '__'
      )
    },
    upsertCapObjects() {
      let keyOrder = [
        'cappableId',
        'cappableType',
        'frequency',
        'type',
        'triggerables',
      ]
      const capObjects = this.upsertCapKeys.map(key => {
        let values = key.split('-')
        let output = {}
        values.forEach((value, index) => {
          if (keyOrder[index] === 'triggerables') {
            // set value to an array of objects with triggerableType and triggerableId
            output['triggerablePairs'] = value.split('__')
              .map(triggerable => {
                const parts = triggerable.split('_');
                return {
                  type: parts[0],
                  id: parts[1]
                };
              })
              .filter(triggerable => triggerable.id && triggerable.id !== '')
          } else {
            output[keyOrder[index]] = value !== '' ? value : null
          }
        })
        output.triggerValue = Number(this.triggerValue)
        return output
      })

      return capObjects
    },
    rowsAreSelected() {
      return this.selectedRowIds.length > 0
    }
  },
  methods: {
    handleInput(rowData, event) {
      const newValue = event.target.innerText.trim()

      if (newValue !== rowData.triggerValue.toString().trim()) {
        this.pendingRowId = rowData.id
        this.pendingValue = newValue
      } else {
        this.pendingRowId = null
        this.pendingValue = null
      }
    },
    captureTriggerValue(rowData, event) {
      this.pendingRowId = rowData.id
      this.pendingValue = event.target.innerText.trim()
    },
    focusEvent(row, e) {
      return (e.textContent = e.target.textContent.trim())
    },
    updateTriggerValueModal() {
      this.$modal.show('confirm-cap-email-modal')
    },
    cancelTriggerValueModal() {
      this.pendingRowId = null
      this.pendingValue = null
    },
    saveTriggerValue(sendEmail) {
      this.$apollo
        .mutate({
          mutation: UPDATE_CAP_MUTATION,
          variables: {
            input: {
              id: this.pendingRowId,
              triggerValue: Number(this.pendingValue),
              disableNotificationEmail: sendEmail
            }
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$events.emit('capUpserted')
          this.$events.emit('refreshCampaigns')

          this.pendingRowId = null
          this.pendingValue = null
          this.$modal.hide('confirm-cap-email-modal')
        })
        .catch(error => {
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    openEditFallbackModal(id, fallbackCampaignId, fallbackOfferId) {
      this.$modal.show(
        UpdateCampaignFallbackModal,
        {
          id: id,
          fallbackCampaignId: fallbackCampaignId,
          fallbackOfferId: fallbackOfferId
        },
        {
          height: 'auto',
          width: '100%',
          maxWidth: 1000,
          adaptive: true,
          scrollable: true
        }
      )
    },
    getTriggerable(triggerables, typeName) {
      if (!triggerables || !Array.isArray(triggerables) || triggerables.length === 0) {
        return null;
      }
      const foundTriggerableEntry = triggerables.find(triggerable => triggerable.triggerableType === typeName)
      if (foundTriggerableEntry) {
        return foundTriggerableEntry?.triggerable || `Missing ${foundTriggerableEntry.triggerableType} for ${foundTriggerableEntry.triggerableId}`
      }
      return null
    },
    showDeletedCaps() {
      this.trashedInput = 'ONLY'
    },
    showAllCaps() {
      this.trashedInput = 'WITH'
    },
    showActiveCaps() {
      this.trashedInput = 'WITHOUT'
    },
    toggleShowSoftDeletedCaps() {
      if (this.trashedInput === 'WITHOUT') {
        this.trashedInput = 'WITH'
      } else {
        this.trashedInput = 'WITHOUT'
      }

      this.$apollo.queries.caps.refetch()
    },
    cleanObjectFilter(filter, pluck) {
      if (filter.length === 0) {
        return undefined
      }
      return {
        value: filter.map(value => value[pluck]),
        modifiers: filter.modifiers
      }
    },
    capPercentage(currentValue, triggerValue) {
      return `(${((currentValue / triggerValue) * 100).toFixed(1)}%)`
    },
    getPermutations(array1, array2, delimiter = '-') {
      if (array2.length > 0) {
        var result = array1.reduce((a, v) => {
          return [...a, ...array2.map(x => v + delimiter + x)]
        }, [])
        return result
      } else {
        return array1.map(v => v + delimiter)
      }
    },
    clearUpsertValues() {
      this.frequency = []
      this.type = []
      this.countries = []
      this.redirects = []
      this.sourceIds = []
      this.triggerValue = null
    },
    upsertCaps(input) {
      this.isUpserting = true

      this.$apollo
        .mutate({
          mutation: UPSERT_CAPS_MUTATION,
          variables: {
            input: input
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.clearUpsertValues()
          this.isUpserting = false
          this.$toastr.s('Offer Caps Updated', 'Success')
          this.$nextTick(() => {
            this.$events.emit('capUpserted')
          })
        })
        .catch(error => {
          this.isUpserting = false
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    deleteCap(id) {
      this.$apollo
        .mutate({
          mutation: DELETE_CAP_MUTATION,
          variables: {
            id: id
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$toastr.Add({
            title: 'Deleted',
            msg: 'Cap deleted',
            preventDuplicates: true
          })
          this.$events.emit('capDeleted')
        })
        .catch(error => {
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    toggleManualCap(capData) {
      this.$apollo
        .mutate({
          mutation: UPDATE_CAP_MUTATION,
          variables: {
            input: {
              id: capData.id,
              isCapped: !capData.isCapped
            }
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$toastr.s('Offer Cap Updated', 'Success')
          this.$events.emit('capUpserted')
          this.$events.emit('refreshCampaigns')
        })
        .catch(error => {
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    togglePauseCap(capData) {
      this.$apollo
        .mutate({
          mutation: UPDATE_CAP_MUTATION,
          variables: {
            input: {
              id: capData.id,
              isIgnored: !capData.isIgnored
            }
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$toastr.s('Offer Cap Paused', 'Success')
          this.$events.emit('capUpserted')
        })
        .catch(error => {
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    restoreCap(id) {
      this.$apollo
        .mutate({
          mutation: RESTORE_CAP_MUTATION,
          variables: {
            id: id
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$toastr.s('Offer Cap Restored', 'Success')
          this.$events.emit('capUpserted')
        })
        .catch(error => {
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    openDeleteModal() {
      this.$modal.show('confirm-delete-caps-modal')
    },
    cancelBulkDelete() {
      this.$modal.hide('confirm-delete-caps-modal')
    },
    bulkDeleteCaps() {
      this.isBulkDeleting = true

      this.$apollo.mutate({
        mutation: DELETE_CAPS_MUTATION,
        variables: {
          input: {
            ids: this.selectedRowIds
          }
        }
      })
      .then(() => {
        this.clearValidationErrors()
        this.$toastr.Add({
          title: 'Deleted',
          msg: `${this.selectedRowIds.length} caps deleted`,
          preventDuplicates: true
        })
        this.$events.emit('capDeleted')
        this.selectedRowIds = []
        this.isBulkDeleting = false
        this.$modal.hide('confirm-delete-caps-modal')
      })
      .catch(error => {
        this.isBulkDeleting = false
        this.setValidationErrors(error)
        console.error(error)
      })
    }
  }
}
</script>

<style scoped></style>
