<template>
  <div>
    <span class="uppercase text-xs font-semibold text-gray-600">
      <template
        v-if="
          filterableItem.filterableType == 'tag' && filterableItem.filterable
        "
      >
        {{ filterableItem.filterable.type }}
      </template>
      <template v-else>
        {{ filterableItem.filterableType }}
      </template>
    </span>
    
    <div v-if="filterableItem.filterableType == 'offer'">
      <router-link
        v-if="filterableItem.filterable"
        :to="{
          name: 'Offer Edit',
          params: { id: filterableItem.filterableId }
        }"
        class="link-wrap"
      >
        <template>{{ filterableItem.filterable.label }}</template>
      </router-link>
      <template v-else>
        <i class="text-gray-500"
          >No record found for ID {{ filterableItem.filterableId }}</i
        >
      </template>
    </div>
    <div v-if="filterableItem.filterableType == 'advertiser'">
      <router-link
        v-if="filterableItem.filterable"
        :to="{
          name: 'Advertiser',
          params: { id: filterableItem.filterableId }
        }"
        class="link-wrap"
      >
        <template>{{ filterableItem.filterable.label }}</template>
      </router-link>
      <template v-else>
        <i class="text-gray-500"
          >No record found for ID {{ filterableItem.filterableId }}</i
        >
      </template>
    </div>
    <div v-if="filterableItem.filterableType == 'advertiserAccount'">
      <router-link
        v-if="filterableItem.filterable"
        :to="{
          name: 'Advertiser',
          params: { id: filterableItem.filterable.userId }
        }"
        class="link-wrap"
      >
        <template>{{ filterableItem.filterable.label }}</template>
      </router-link>
      <template v-else>
        <i class="text-gray-500"
          >No record found for ID {{ filterableItem.filterableId }}</i
        >
      </template>
    </div>
    <div v-if="filterableItem.filterableType == 'template'">
      <div
        v-if="filterableItem.filterable"
        :class="
          filterableItem.filterablePolarity ? 'text-blue-600' : 'text-red-600'
        "
      >
        {{ filterableItem.filterable.path }}
      </div>
      <template v-else>
        <i class="text-gray-500"
          >No record found for ID {{ filterableItem.filterableId }}</i
        >
      </template>
    </div>
    <div v-if="filterableItem.filterableType == 'tag'">
      <template v-if="filterableItem.filterable">
        <Tag :color="filterableItem.filterablePolarity ? 'blue' : 'red'">{{
          filterableItem.filterable.name
        }}</Tag>
      </template>
      <template v-else>
        <i class="text-gray-500"
          >No record found for Tag ID {{ filterableItem.filterableId }}</i
        >
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filterableItem: Object
  },
  computed: {
    isTag() {
      return this.filterableType === 'tag' && this.filterable
    }
  }
}
</script>
