var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.autoSubmit,
      expression: "!autoSubmit"
    }]
  }, [_c('WidgetHeader', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm.newStatus && _vm.newStatus.name ? [_c('span', {
    staticClass: "font-bold"
  }, [_vm.hasMultipleIds ? [_vm._v(" Set "), _c('span', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.modelIds.join(', '),
      expression: "modelIds.join(', ')"
    }],
    staticClass: "cursor-pointer"
  }, [_vm._v(_vm._s(_vm.modelIds.length))]), _vm._v(" " + _vm._s(_vm.modelName.capitalize()) + "s ")] : [_vm._v(" Set " + _vm._s(_vm.modelName.capitalize()) + " "), _c('sup', [_vm._v("#")]), _vm._v(_vm._s(_vm.modelIds[0]) + " ")], _vm._v(" to "), _c('span', {
    staticClass: "font-extrabold uppercase",
    class: [`text-${_vm.statusColor}-500`]
  }, [_vm._v(_vm._s(_vm.newStatus.name))])], 2)] : [_c('div', {
    staticClass: "flex items-center"
  }, [_vm._v(" " + _vm._s(_vm.header) + " "), _vm.hasMultipleIds ? _c('Tag', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: `${_vm.modelName.capitalize()} ${_vm.modelIds.join(', ')}`,
      expression: "`${modelName.capitalize()} ${modelIds.join(', ')}`"
    }],
    staticClass: "hover:bg-blue-500 hover:text-white ml-2 cursor-pointer"
  }, [_vm._v(" " + _vm._s(_vm.modelIds.length) + " ")]) : _c('Icon', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: `${_vm.modelName.capitalize()} ${_vm.modelIds.join(', ')}`,
      expression: "`${modelName.capitalize()} ${modelIds.join(', ')}`"
    }],
    staticClass: "text-gray-400 hover:text-blue-500 ml-2 cursor-pointer",
    attrs: {
      "name": "helpCircle"
    }
  })], 1)]], 2), _c('WidgetBody', {
    staticClass: "pt-0"
  }, [!_vm.newStatus || _vm.newStatus && !_vm.newStatus.name ? _c('Superselect', {
    staticClass: "mb-2",
    attrs: {
      "multiple": false,
      "title": "Status",
      "options": _vm.statusOptions
    },
    model: {
      value: _vm.statusName,
      callback: function ($$v) {
        _vm.statusName = $$v;
      },
      expression: "statusName"
    }
  }) : _vm._e(), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.reason,
      expression: "reason"
    }],
    staticClass: "w-full p-4 bg-gray-100 rounded-md border border-gray-200",
    attrs: {
      "placeholder": "Write a reason..."
    },
    domProps: {
      "value": _vm.reason
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.reason = $event.target.value;
      }
    }
  }), _vm.disableEmailNotificationOption ? _c('div', {
    staticClass: "text-gray-800 font-bold pt-3"
  }, [_c('YCheckbox', {
    staticClass: "flex items-center mr-2",
    attrs: {
      "id": "preset-is-public-checkbox",
      "label": "Disable Email Notification",
      "inline": true,
      "type": "info"
    },
    model: {
      value: _vm.disableEmailNotification,
      callback: function ($$v) {
        _vm.disableEmailNotification = $$v;
      },
      expression: "disableEmailNotification"
    }
  })], 1) : _vm._e()], 1), _c('WidgetFooter', {
    staticClass: "bg-gray-100 px-4 py-2"
  }, [_c('div', {
    staticClass: "flex justify-between"
  }, [_c('YButton', {
    staticClass: "btn btn-link",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Cancel ")]), _c('YButton', {
    staticClass: "btn btn-blue",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.setStatus
    }
  }, [_vm._v(" Set Status ")])], 1)]), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }