import { render, staticRenderFns } from "./AdvertiserDetails.vue?vue&type=template&id=c497488e"
import script from "./AdvertiserDetails.vue?vue&type=script&lang=js"
export * from "./AdvertiserDetails.vue?vue&type=script&lang=js"
import style0 from "./AdvertiserDetails.vue?vue&type=style&index=0&id=c497488e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports