var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Widget', [_c('WidgetHeader', {
    staticClass: "bg-gray-200 p-1 border-b"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('RuleFilters', {
    attrs: {
      "order-by": _vm.orderBy,
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "default-values": _vm.defaultFilterValues,
      "hide": _vm.hideFilters,
      "hide-all-offer-filters": _vm.hideAllOfferFilters
    },
    on: {
      "rulesFetched": _vm.updateRules,
      "loading": _vm.updateLoading
    }
  })], 1)], 2)], 1), _vm._t("beforeTable"), _vm.rules && _vm.rules.data ? _c('RulesTable', {
    staticClass: "pt-4",
    attrs: {
      "rules": _vm.rules.data || [],
      "order-by": _vm.orderBy,
      "rulesParentComponent": _vm.rulesParentComponent,
      "bottom-loading": _vm.loading
    }
  }) : _vm._e(), _vm.rules && _vm.rules.paginatorInfo ? _c('YPagination', {
    key: _vm.rules.paginatorInfo.lastPage,
    attrs: {
      "data": _vm.rules.paginatorInfo
    },
    on: {
      "load-page": _vm.loadPage,
      "next-page": _vm.nextPage,
      "previous-page": _vm.previousPage,
      "per-page": _vm.perPageSelection
    }
  }) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }