var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.capDefaults ? _c('YTable', {
    staticClass: "table-striped table-hover overflow-hidden",
    attrs: {
      "block-style": "min-height: 25rem;",
      "scroll-x": false,
      "data": _vm.capDefaults.data
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_vm._v(" " + _vm._s(props.rowData.frequency.capitalize()) + " ")]), _c('td', [_vm._v(" " + _vm._s(props.rowData.type.capitalize()) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.getTriggerableValue(props.rowData.triggerablePairs, 'Country')) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.getTriggerableValue(props.rowData.triggerablePairs, 'Redirect')) + " ")]), _c('td', [_c('EditableValue', {
          key: props.rowData.id,
          staticClass: "-mr-1 -ml-1",
          attrs: {
            "isNumberValue": true,
            "type": "contentEditable",
            "value": props.rowData.triggerValue,
            "mutation": _vm.UPDATE_CAP_DEFAULT_MUTATION,
            "variables": {
              input: {
                id: props.rowData.id,
                triggerValue: '{value}'
              }
            },
            "placeholder": "0",
            "value-class": "px-1",
            "edit-focus-icon-enabled": false
          },
          on: {
            "saved": _vm.fireSavedEvent
          }
        })], 1), _c('td', [_c('a', {
          staticClass: "text-gray-400 hover:text-red-500 cursor-pointer inline-block ml-1",
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.deleteCap(props.rowData.id);
            }
          }
        }, [_c('Icon', {
          attrs: {
            "name": "trashCan"
          }
        })], 1)]), _c('td')];
      }
    }], null, false, 3182841729)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v(" Frequency ")]), _c('th', [_vm._v(" Type ")]), _c('th', [_vm._v(" Geo ")]), _c('th', [_vm._v(" Redirect ")]), _c('th', [_vm._v(" Value ")]), _c('th'), _c('th')]), _c('template', {
    slot: "secondHeaderRow"
  }, [_c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Frequency",
      "required": true,
      "label": "label",
      "track-by": "value",
      "options": _vm.filteredFrequencyOptions
    },
    model: {
      value: _vm.frequency,
      callback: function ($$v) {
        _vm.frequency = $$v;
      },
      expression: "frequency"
    }
  })], 1), _c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Type",
      "required": true,
      "options": _vm.type.length > 0 ? [] : _vm.cappableType == 'Campaign' ? _vm.campaignTypeOptions : _vm.typeOptions,
      "label": "label",
      "track-by": "value"
    },
    model: {
      value: _vm.type,
      callback: function ($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  })], 1), _c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Geo",
      "track-by": "code",
      "label": "label",
      "multiple": false,
      "prepend-options": [_vm.allCountryEntry],
      "query": _vm.COUNTRIES_QUERY,
      "query-variables": {
        filters: {
          countriesOnly: true
        },
        orderBy: [{
          column: 'name',
          order: 'ASC'
        }]
      }
    },
    model: {
      value: _vm.country,
      callback: function ($$v) {
        _vm.country = $$v;
      },
      expression: "country"
    }
  })], 1), _c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Redirects",
      "track-by": "id",
      "label": "label",
      "multiple": false,
      "allow-empty": _vm.allowAllRedirectEntry ? false : true,
      "prepend-options": _vm.allowAllRedirectEntry ? [_vm.allRedirectEntry] : [],
      "query": _vm.REDIRECT_OPTIONS_QUERY,
      "query-variables": {
        first: 100,
        filters: {
          campaignId: _vm.cappableType == 'Campaign' ? {
            value: [_vm.cappableId]
          } : undefined,
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      }
    },
    model: {
      value: _vm.redirect,
      callback: function ($$v) {
        _vm.redirect = $$v;
      },
      expression: "redirect"
    }
  })], 1), _c('td', [_c('YInput', {
    staticClass: "-mx-3",
    attrs: {
      "label": "Value",
      "required": true
    },
    model: {
      value: _vm.triggerValue,
      callback: function ($$v) {
        _vm.triggerValue = $$v;
      },
      expression: "triggerValue"
    }
  })], 1), _c('td', [_c('div', {
    staticClass: "flex items-center -ml-3 -mr-3"
  }, [_c('YButton', {
    staticClass: "px-3",
    attrs: {
      "color": "link",
      "is-loading": _vm.isUpdating
    },
    on: {
      "click": _vm.createCapDefault
    }
  }, [_c('Icon', {
    attrs: {
      "name": "plus"
    }
  })], 1)], 1)]), _c('td', [_vm.$apollo.queries.capDefaults.loading ? _c('Spinner', {
    attrs: {
      "color": "blue",
      "size": 6
    }
  }) : _vm._e()], 1)])], 2) : _vm._e(), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }