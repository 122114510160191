var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "top"
    }
  }, [_c('Widget', {
    staticClass: "mb-2"
  }, [_c('WidgetHeader', {
    staticClass: "bg-gray-200 p-1 border-b"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('UserFilters')], 1)], 2)], 1), _c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openOptimizeModal();
      }
    }
  }, [_vm._v("Create Offer Studio User")]), _vm.users ? _c('YTable', {
    attrs: {
      "data": _vm.users.data || [],
      "bottom-loading": _vm.$apolloData.queries.users.loading,
      "selectable": true,
      "selected": _vm.selectedRowIds,
      "row-classes": function (row) {
        return row.userDetails.followUpDue ? ['follow-up-row'] : [];
      }
    },
    on: {
      "update:selected": function ($event) {
        _vm.selectedRowIds = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', {
          staticStyle: {
            "padding": "0",
            "width": "20px"
          }
        }, [props.rowData.userDetails.followUpDue ? _c('span', [_c('Icon', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: 'Follow Up Due',
            expression: "'Follow Up Due'"
          }],
          staticClass: "text-pink-500 rounded m-1",
          attrs: {
            "name": "exclamationThick",
            "size": 4
          }
        })], 1) : _vm._e()]), _c('td', {
          staticStyle: {
            "padding-left": "0"
          }
        }, [_c('div', {
          staticClass: "flex flex-row justify-between gap-1"
        }, [props.rowData.primaryContact ? _c('div', {
          staticClass: "flex flex-col"
        }, [props.rowData.advertiserId ? _c('span', {
          staticClass: "inline-flex items-center"
        }, [_c('div', [_c('router-link', {
          staticClass: "link block",
          attrs: {
            "to": {
              name: 'Advertiser',
              params: {
                id: props.rowData.advertiserId
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.name) + " ")])], 1)]) : _c('span', [_c('div', {
          staticClass: "flex flex-row items-center justify-between"
        }, [_c('router-link', {
          staticClass: "link block",
          attrs: {
            "to": {
              name: 'User',
              params: {
                id: props.rowData.id
              }
            }
          }
        }, [_vm._v(_vm._s(props.rowData.email.replace('allIn_', '')))])], 1)]), _c('div', {
          staticClass: "text-gray-600 text-xs"
        }, [_c('span', {
          staticClass: "mr-2"
        }, [_vm._v(_vm._s(props.rowData.id))]), props.rowData.primaryContact.company && props.rowData.isAPublisher ? _c('span', {
          staticClass: "font-semibold"
        }, [_vm._v(" " + _vm._s(props.rowData.primaryContact.company) + " ")]) : _vm._e()]), props.rowData.primaryContact.messenger ? _c('div', {
          staticClass: "flex items-center text-xs"
        }, [_c('Icon', {
          staticClass: "w-4 h-4 text-gray-600 mr-1",
          attrs: {
            "name": JSON.parse(props.rowData.primaryContact.messenger.toLowerCase()).app
          }
        }), _c('span', {
          staticClass: "text-gray-600"
        }, [_vm._v(" " + _vm._s(JSON.parse(props.rowData.primaryContact.messenger).handle) + " ")])], 1) : _vm._e(), _c('div', {
          staticClass: "mt-1 flex flex-row items-center"
        }, [props.rowData.primaryContact.country ? _c('Flag', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `${props.rowData.primaryContact.country.label}`,
            expression: "`${props.rowData.primaryContact.country.label}`"
          }],
          staticClass: "mr-4 rounded-sm overflow-hidden",
          attrs: {
            "country": props.rowData.primaryContact.country.code,
            "size": 5
          }
        }) : _vm._e(), _vm._l(props.rowData.roles, function (role) {
          return _c('tag', {
            key: role.name,
            staticClass: "mr-2"
          }, [_vm._v(_vm._s(role.title))]);
        })], 2)]) : _vm._e(), _c('div', {
          staticClass: "flex items-center"
        }, [_vm.duplicateIpHistoryAlert(props.rowData.confidenceScores) ? _c('div', [_c('v-popover', {
          staticClass: "p-0",
          attrs: {
            "offset": "0"
          }
        }, [_c('button', {
          staticClass: "hover:bg-gray-200 p-2 rounded"
        }, [_c('Icon', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Duplicate IP History Detected`,
            expression: "`Duplicate IP History Detected`"
          }],
          staticClass: "text-orange-600",
          attrs: {
            "name": "bell",
            "size": 5
          }
        })], 1), _c('template', {
          slot: "popover"
        }, [_c('div', {
          staticClass: "p-6 bg-white rounded-lg",
          staticStyle: {
            "width": "18rem",
            "max-height": "300px",
            "overflow-y": "auto"
          }
        }, _vm._l(_vm.duplicateIpHistoryLog(props.rowData.confidenceScores)[0], function (history, index) {
          return _c('div', {
            key: index,
            staticClass: "mb-2"
          }, [_c('h3', {
            staticClass: "font-semibold mb-1"
          }, [_vm._v(_vm._s(history.ip))]), _c('table', {
            staticClass: "w-full bg-gray-100"
          }, [_c('thead', [_c('tr', {
            staticClass: "text-gray-700"
          }, [_c('th', [_vm._v("User Id")]), _c('th', [_vm._v("Action Type")])])]), _c('tbody', _vm._l(history.matches, function (match) {
            return _c('tr', {
              key: match.causerId,
              staticStyle: {
                "border-bottom": "1px solid #efefef"
              }
            }, [_c('td', {
              staticClass: "pl-2"
            }, [_c('router-link', {
              staticClass: "link block",
              attrs: {
                "to": {
                  name: 'User',
                  params: {
                    id: match.causerId
                  }
                }
              }
            }, [_vm._v(_vm._s(match.causerId))])], 1), _c('td', {
              staticClass: "text-center"
            }, [_vm._v(_vm._s(match.type))])]);
          }), 0)])]);
        }), 0)])], 2)], 1) : _vm._e(), _c('button', {
          staticClass: "hover:bg-gray-200 p-1 rounded",
          on: {
            "click": function ($event) {
              return _vm.openPriorityStatusModal(props.rowData);
            }
          }
        }, [_c('Icon', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.priorityFollowUp(props.rowData.userDetails.followUpAt),
            expression: "\n                  priorityFollowUp(props.rowData.userDetails.followUpAt)\n                "
          }],
          class: _vm.priorityIconColor(props.rowData.userDetails.priorityStatus),
          attrs: {
            "name": _vm.priorityIconType(props.rowData.userDetails.priorityStatus),
            "size": 6
          }
        })], 1)])])]), _c('td', [_c('div', {
          staticClass: "flex flex-col gap-1"
        }, [_vm.hasAdminTag(props.rowData.tags) ? _c('div', _vm._l(_vm.adminTags(props.rowData.tags), function (tag) {
          return _c('div', {
            key: tag.id
          }, [_c('div', {
            staticClass: "bg-transparent rounded-full group flex"
          }, [props.rowData.status != 'active' ? _c('Tag', {
            staticClass: "inline-flex",
            attrs: {
              "color": tag.name.includes('rejected') ? 'darkRed' : 'inverse'
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]) : _vm._e()], 1)]);
        }), 0) : _vm._e(), _c('div', [_c('Tag', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: props.rowData.statusInfo ? props.rowData.statusInfo.reason : '',
            expression: "\n                props.rowData.statusInfo\n                  ? props.rowData.statusInfo.reason\n                  : ''\n              "
          }],
          attrs: {
            "after-icon": props.rowData.statusInfo && props.rowData.statusInfo.reason ? 'helpCircle' : null
          },
          on: {
            "click": function ($event) {
              _vm.openSetStatusWidget({
                modelId: props.rowData.id,
                currentStatus: props.rowData.statusInfo,
                userWasAutoRejected: _vm.userAutoRejected(props.rowData.tags)
              });
            }
          }
        }, [_vm._v(_vm._s(props.rowData.status))])], 1), props.rowData.isQuarantined && props.rowData.status == 'active' ? _c('div', [_c('Tag', {
          attrs: {
            "color": "orange"
          }
        }, [_vm._v("Quarantined"), _c('span', {
          staticClass: "bg-white rounded-lg px-1 ml-1 -mr-1"
        }, [_vm._v(_vm._s(props.rowData.quarantine.level))])])], 1) : _vm._e()])]), _c('td', [props.rowData.accountManager ? _c('span', {
          staticClass: "text-gray-900 text-sm"
        }, [_vm._v(" " + _vm._s(props.rowData.accountManager.primaryContact.name.split(' ')[0]) + " ")]) : _vm._e()]), _c('td', {
          staticClass: "truncate",
          staticStyle: {
            "max-width": "10rem"
          }
        }, [props.rowData.referrer ? [_c('a', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: props.rowData.referrer.email,
            expression: "props.rowData.referrer.email"
          }],
          staticClass: "link",
          attrs: {
            "href": `/admin/user/${props.rowData.referrer.id}`
          }
        }, [_vm._v(" " + _vm._s(props.rowData.referrer.id) + " - " + _vm._s(props.rowData.referrer.email) + " ")])] : _vm._e()], 2), _c('td', [props.rowData.status == 'active' ? _c('span', [_vm._v(_vm._s(_vm._f("luxon")(props.rowData.statusInfo.updatedAt)))]) : _vm._e(), props.rowData.status !== 'active' ? _c('span', [_vm._v(" " + _vm._s(_vm._f("luxon")(props.rowData.createdAt)) + " ")]) : _vm._e()]), _c('td', {
          staticClass: "truncate",
          staticStyle: {
            "max-width": "10rem"
          }
        }, _vm._l(props.rowData.tags.filter(function (item) {
          return item.type == 'trafficType';
        }), function (tag) {
          return _c('ul', {
            key: tag.id,
            staticClass: "mr-1 my-1 text-xs text-gray-800"
          }, [_c('li', [_vm._v(_vm._s(tag.name))])]);
        }), 0), _c('td', {
          staticClass: "truncate",
          staticStyle: {
            "max-width": "10rem"
          }
        }, _vm._l(props.rowData.tags.filter(function (item) {
          return item.type == 'vertical';
        }), function (tag) {
          return _c('ul', {
            key: tag.id,
            staticClass: "mr-1 my-1 text-xs text-gray-800"
          }, [_c('li', [_vm._v(_vm._s(tag.name))])]);
        }), 0), _c('td', [props.rowData.commentsLead.length > 0 ? _c('div', {
          staticClass: "flex flex-row text-xs flex-grow text-left text-gray-800"
        }, [_c('UserAvatar', {
          staticClass: "mb-1 mr-2",
          attrs: {
            "height": "5",
            "id": props.rowData.commentsLead[0].commenter.id
          }
        }), _vm._v(" " + _vm._s(_vm._f("luxon")(props.rowData.commentsLead[0].createdAt)) + " ")], 1) : _vm._e()]), _c('td', {
          staticClass: "text-right p-0"
        }, [_c('v-popover', {
          staticClass: "p-0",
          attrs: {
            "offset": "0"
          }
        }, [_c('button', {
          staticClass: "bg-white-600 hover:bg-gray-100 text-blue-600 font-bold py-3 px-6 rounded truncate"
        }, [_c('Icon', {
          staticClass: "w-6 h-6 text-blue-600",
          attrs: {
            "name": "dotsVertical"
          }
        })], 1), _c('template', {
          slot: "popover"
        }, [_c('ul', [_c('li', [_c('Impersonate', {
          attrs: {
            "userId": props.rowData.id,
            "path": "/{scope}/dashboard"
          }
        }, [_c('a', {
          staticClass: "link py-3 px-4 hover:bg-gray-100 block",
          attrs: {
            "href": `/admin/impersonate/${props.rowData.id}`
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
            }
          }
        }, [_vm._v("Impersonate")])])], 1)])])], 2)], 1)];
      }
    }], null, false, 1771940416)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th'), _c('th', [_c('OrderByItem', {
    attrs: {
      "column": "id",
      "order": "DESC"
    },
    model: {
      value: _vm.orderBy,
      callback: function ($$v) {
        _vm.orderBy = $$v;
      },
      expression: "orderBy"
    }
  }, [_vm._v("User")])], 1), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Manager")]), _c('th', [_vm._v("Referrer")]), _c('th', [_c('OrderByItem', {
    attrs: {
      "column": "created_at",
      "order": "DESC"
    },
    model: {
      value: _vm.orderBy,
      callback: function ($$v) {
        _vm.orderBy = $$v;
      },
      expression: "orderBy"
    }
  }, [_vm._v("Active Since")])], 1), _c('th', [_vm._v("Traffic Type")]), _c('th', [_vm._v("Verticals")]), _c('th', [_vm._v("Last contact")]), _c('th', [_c('div', {
    staticClass: "flex justify-end"
  }, [_c('YButton', {
    staticClass: "border border-blue-600 hover:bg-blue-600 focus:bg-blue-600 text-blue-600 hover:text-white focus:text-white mr-3 outline-none-important",
    attrs: {
      "type": "button",
      "isLoading": _vm.isExporting
    },
    on: {
      "click": _vm.exportUsers
    }
  }, [_vm._v(" Export ")])], 1)])])], 2) : _vm._e(), _vm.users.paginatorInfo ? _c('YPagination', {
    key: _vm.totalPages,
    attrs: {
      "data": _vm.users.paginatorInfo
    },
    on: {
      "load-page": _vm.loadPage,
      "next-page": _vm.nextPage,
      "previous-page": _vm.previousPage,
      "per-page": _vm.perPageSelection
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }