<template>
  <div>
    <portal to="top-bar-right" v-if="enablePresets">
      <div class="flex">
        <Superselect
          title="Presets"
          class="admin-report-multiselect w-30 md:w-64"
          v-model="reportPresetFilter"
          :options="reportPresetOptions"
          placeholder="Select Preset"
          track-by="id"
          label="name"
          selectedLabel=""
          selectLabel=""
          deselectLabel=""
          :allow-empty="false"
          :multiple="false"
          :loading="
            $apollo.queries.requestedPreset.loading ||
              $apollo.queries.reportPresetOptions.loading
          "
          :disabled="$apollo.queries.requestedPreset.loading"
          @select="requestReportPreset"
          @remove="clearReportPreset"
        >
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <div class="option__title">
                <div class="flex justify-between items-center">
                  <span>{{ props.option.name }}</span>
                  <Icon
                    v-if="!props.option.public"
                    name="eye"
                    :size="4"
                    style="opacity: 0.6"
                    v-tooltip="`PRIVATE`"
                  />
                </div>
              </div>
            </div>
          </template>
        </Superselect>
        <div class="report-preset-buttons flex items-stretch overflow-hidden">
          <div class="btn-group ml-2" role="group">
            <transition
              @before-enter="animatePresetButtonsBeforeEnter"
              @enter="animatePresetButtonsEnter"
              @leave="animatePresetButtonsLeave"
              :css="false"
            >
              <div
                class="btn-wrapper"
                v-if="
                  reportPresetFilter.id != 'default' && presetStatus == 'active'
                "
              >
                <YButton
                  v-tooltip="tooltipOptions('Copy To Clipboard', '')"
                  class="btn-inverse"
                  @click="copyPresetLinkToClipboard"
                  :disabled="$apollo.queries.requestedPreset.loading"
                >
                  <Icon name="link" :size="4" />
                </YButton>
              </div>
            </transition>
            <transition
              @before-enter="animatePresetButtonsBeforeEnter"
              @enter="animatePresetButtonsEnter"
              @leave="animatePresetButtonsLeave"
              :css="false"
            >
              <div
                class="btn-wrapper"
                v-if="
                  reportPresetFilter.id != 'default' && presetStatus == 'active'
                "
              >
                <YButton
                  v-tooltip="tooltipOptions('Delete', 'danger')"
                  class="btn-inverse"
                  @click="$modal.show('delete-preset')"
                  :disabled="$apollo.queries.requestedPreset.loading"
                >
                  <Icon name="trashCan" :size="4" />
                </YButton>
              </div>
            </transition>
            <transition
              @before-enter="animatePresetButtonsBeforeEnter"
              @enter="animatePresetButtonsEnter"
              @leave="animatePresetButtonsLeave"
              :css="false"
            >
              <div class="btn-wrapper" v-if="presetStatus == 'modified'">
                <YButton
                  v-tooltip="tooltipOptions('Save As', '')"
                  class="btn btn-inverse"
                  @click="$modal.show('save-preset')"
                  :disabled="$apollo.queries.requestedPreset.loading"
                >
                  <Icon name="contentSave" :size="4" />
                </YButton>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </portal>
    <Widget class="reports-form">
      <WidgetHeader class="bg-gray-200">
        <template slot="rawContent">
          <div class="block lg:flex items-center lg:justify-between w-full">
            <YDateTimeRangePicker
              v-model="filters.dateTimeRange"
            ></YDateTimeRangePicker>
            <div class="flex align-items-center mt-2 lg:mt-0">
              <div>
                <div
                  v-tooltip="presetStatus == 'modified' ? 'Modified' : null"
                  class="status-ball mr-3"
                  :class="
                    presetStatus == 'active' ? 'bg-success' : 'bg-warning'
                  "
                ></div>
              </div>
            </div>
            <modal
              name="delete-preset"
              width="95%"
              height="auto"
              :max-width="600"
              :adaptive="true"
            >
              <AdminReportsPresetDialogDelete
                :preset-filter="reportPresetFilter"
                @close="$modal.hide('delete-preset')"
                @success="presetDeleted"
              ></AdminReportsPresetDialogDelete>
            </modal>
            <modal
              name="save-preset"
              width="95%"
              height="auto"
              :max-width="600"
              :adaptive="true"
            >
              <AdminReportsPresetDialogSave
                :preset-filter="reportPresetFilter"
                :report="JSON.stringify(report)"
                @close="$modal.hide('save-preset')"
                @success="presetSaved"
              ></AdminReportsPresetDialogSave>
            </modal>
          </div>
        </template>
      </WidgetHeader>
      <transition
        @before-enter="animateShowFiltersBeforeEnter"
        @enter="animateShowFiltersEnter"
        @leave="animateShowFiltersLeave"
        :css="false"
      >
        <WidgetBody
          v-show="showFilters"
          class="pt-4 bg-gray-100"
          style="border-top: 1px solid #D9D9D9;"
        >
          <div class="grid grid-cols-1 md:grid-cols-6 gap-1">
            <div v-show="columnData.publisher">
              <Superselect
                title="Publisher"
                v-model="filters.publisher.value"
                :modifiers.sync="filters.publisher.modifiers"
                :options="options.publisher"
                track-by="id"
                label="label"
                edit-placeholder="Paste IDs / Logins"
                :optionRequiredOnTag="true"
                :query="queries.PUBLISHER_OPTIONS_QUERY"
                :query-variables="{
                  filters: {
                    label: {
                      value: ['{input}'],
                      modifiers: { matchType: 'contains' }
                    }
                  },
                  first: 25
                }"
                :query-result-map="{
                  id: 'id',
                  label: 'label'
                }"
              ></Superselect>
            </div>
            <div
              v-show="
                columnData.advertiser &&
                  masterColumnData.advertiser.filter.visible
              "
            >
              <Superselect
                title="Advertiser"
                v-model="filters.advertiser.value"
                :modifiers.sync="filters.advertiser.modifiers"
                :options="options.advertisers"
                track-by="id"
                label="label"
              ></Superselect>
              <Superselect
                v-if="filters.advertiser.value.length > 0"
                title="Advertiser Account"
                class="mt-1"
                v-model="filters.advertiserAccount.value"
                :modifiers.sync="filters.advertiserAccount.modifiers"
                :options="advertiserAccountOptions"
                track-by="id"
                label="label"
              ></Superselect>
            </div>
            <div v-show="columnData.country">
              <Superselect
                title="Country"
                editPlaceholder="Paste Country Codes"
                v-model="filters.country.value"
                :modifiers.sync="filters.country.modifiers"
                :options="options.country"
                track-by="id"
                label="label"
                :query="queries.COUNTRIES_QUERY"
                :query-result-map="{
                  id: 'code',
                  label: 'label'
                }"
              ></Superselect>
            </div>
            <div v-show="columnData.offer">
              <Superselect
                title="Direct Offer"
                v-model="filters.offer.value"
                :modifiers.sync="filters.offer.modifiers"
                :options="options.offer"
                placeholder="Select Offer"
                :options-limit="100"
                track-by="id"
                label="label"
                :query="queries.OFFER_OPTIONS_QUERY"
                :query-variables="{
                  filters: {
                    label: {
                      value: ['{input}'],
                      modifiers: { matchType: 'contains' }
                    },
                    destinationType: {
                      value: ['direct']
                    }
                  },
                  first: 100
                }"
                :query-result-map="{
                  id: 'id',
                  label: 'label'
                }"
              ></Superselect>
            </div>
            <div v-show="columnData.template">
              <Superselect
                title="Template Offer"
                v-model="filters.template.value"
                :modifiers.sync="filters.template.modifiers"
                :options="options.template"
                placeholder="Select Offer"
                :options-limit="100"
                track-by="id"
                label="label"
                :query="queries.OFFER_OPTIONS_QUERY"
                :query-variables="{
                  filters: {
                    label: {
                      value: ['{input}'],
                      modifiers: { matchType: 'contains' }
                    },
                    destinationType: {
                      value: ['template']
                    }
                  },
                  first: 100
                }"
                :query-result-map="{
                  id: 'id',
                  label: 'label'
                }"
              ></Superselect>
            </div>
            <div v-show="columnData.source">
              <Superselect
                title="Source"
                v-model="filters.source.value"
                :modifiers.sync="filters.source.modifiers"
                :options="options.source"
                track-by="id"
                label="label"
              ></Superselect>
            </div>
            <!-- 
              DO WE STILL HAVE TEMPLATES
              <div v-show="columnData.template">
              <Superselect
                title="Template"
                v-model="filters.template.value"
                :modifiers.sync="filters.template.modifiers"
                :options="options.template"
                placeholder="Select Template"
                track-by="id"
                label="label"
                :query="queries.TEMPLATE_OPTIONS_QUERY"
                :query-variables="{
                  filters: {
                    label: {
                      value: ['{input}'],
                      modifiers: { matchType: 'contains' }
                    }
                  },
                  first: 100
                }"                
              ></Superselect>
            </div> -->

            <div v-show="columnData.redirect">
              <Superselect
                title="Redirect"
                v-model="filters.redirect.value"
                :modifiers.sync="filters.redirect.modifiers"
                :options="options.redirect"
                placeholder="Select Redirect"
                track-by="id"
                label="label"
                :query="queries.REDIRECT_OPTIONS_QUERY"
                :query-variables="{
                  filters: {
                    label: {
                      value: ['{input}'],
                      modifiers: { matchType: 'contains' }
                    }
                  },
                  first: 25
                }"
                :queryResultMap="{
                  id: 'id',
                  label: 'label'
                }"
              ></Superselect>
            </div>
            <div v-show="columnData.redirectLink">
              <Superselect
                v-if="filters.redirectLink"
                title="Redirect Link"
                v-model="filters.redirectLink.value"
                :modifiers.sync="filters.redirectLink.modifiers"
                :forceEdit="true"
                editPlaceholder=""
                track-by="id"
                label="label"
              >
              </Superselect>
            </div>
            <div v-show="columnData.miniDomain">
              <Superselect
                v-if="filters.miniDomain"
                title="Mini Domain"
                v-model="filters.miniDomain.value"
                :modifiers.sync="filters.miniDomain.modifiers"
                :forceEdit="true"
                editPlaceholder=""
                track-by="id"
                label="label"
              >
              </Superselect>
            </div>
            <!-- <div v-show="columnData.groupService">
              <Superselect
                title="Group Service"
                v-model="filters.groupService.value"
                :modifiers.sync="filters.groupService.modifiers"
                :options="options.groupService"
                track-by="id"
                label="label"
              ></Superselect>
            </div> -->
            <!-- <div v-show="columnData.mini">
              <Superselect
                title="Mini"
                v-model="filters.mini.value"
                editPlaceholder=""
                :modifiers.sync="filters.mini.modifiers"
                :forceEdit="true"
                track-by="id"
                label="label"
              >
                <YRadios
                  slot="footer"
                  name="mini-filter-type"
                  
                  :radios="[
                    { label: 'Exact', value: 'exact' },
                    { label: 'Contains', value: 'contains' }
                  ]"
                  v-model="filters.mini.modifiers.matchType"
                  
                  :radios-inline="true"
                  color="success"
                ></YRadios>
              </Superselect>
            </div> -->
            <div v-show="columnData.vertical">
              <Superselect
                title="Verticals"
                v-model="filters.vertical.value"
                :modifiers.sync="filters.vertical.modifiers"
                :options="options.vertical"
                editPlaceholder="Paste Verticals"
                track-by="id"
                label="label"
                :query="queries.TAGS_QUERY"
                :query-variables="{
                  filters: {
                    type: {
                      value: ['vertical'],
                      modifiers: { matchType: 'contains' }
                    }
                  },
                  first: 100
                }"
                :query-result-map="{
                  id: 'slug',
                  label: 'name'
                }"
              ></Superselect>
            </div>
            <div v-show="columnData.campaign">
              <Superselect
                title="Campaign"
                v-model="filters.campaign.value"
                :modifiers.sync="filters.campaign.modifiers"
                :options="options.campaign"
                track-by="id"
                label="label"
                :query="queries.CAMPAIGN_OPTIONS_QUERY"
                :queryResultMap="{
                  id: 'id',
                  label: 'label'
                }"
                :query-variables="{
                  filters: {
                    label: {
                      value: ['{input}'],
                      modifiers: { matchType: 'contains' }
                    }
                  },
                  first: 25
                }"
              ></Superselect>
            </div>
            <div v-show="columnData.mobileDesktop">
              <Superselect
                title="Mobile / Desktop"
                v-model="filters.mobileDesktop.value"
                placeholder="Select One"
                :modifiers.sync="filters.mobileDesktop.modifiers"
                :multiple="false"
                :options="options.mobileDesktop"
                track-by="id"
                label="label"
              ></Superselect>
            </div>
            <div v-show="columnData.algorithmId">
              <Superselect
                title="Algorithm Id"
                v-model="filters.algorithmId.value"
                :modifiers.sync="filters.algorithmId.modifiers"
                placeholder="Select One"
                :options="options.algorithmId"
                track-by="id"
                label="label"
              ></Superselect>
            </div>
            <div v-show="columnData.browser">
              <Superselect
                title="Browser"
                v-model="filters.browser.value"
                editPlaceholder=""
                :modifiers.sync="filters.browser.modifiers"
                :forceEdit="true"
                track-by="id"
                label="label"
              >
              </Superselect>
            </div>
            <div v-show="columnData.os">
              <Superselect
                title="OS"
                v-model="filters.os.value"
                :modifiers.sync="filters.os.modifiers"
                editPlaceholder=""
                :forceEdit="true"
                track-by="id"
                label="label"
              >
              </Superselect>
            </div>
            <div v-show="columnData.device">
              <Superselect
                title="Device"
                v-model="filters.device.value"
                :modifiers.sync="filters.device.modifiers"
                editPlaceholder=""
                :forceEdit="true"
                track-by="id"
                label="label"
              >
              </Superselect>
            </div>
            <div v-show="columnData.ip">
              <Superselect
                title="IP Address"
                v-model="filters.ip.value"
                :modifiers.sync="filters.ip.modifiers"
                editPlaceholder=""
                :forceEdit="true"
                track-by="id"
                label="label"
              >
              </Superselect>
            </div>
            <div v-show="columnData.forwardedForIp">
              <Superselect
                title="Forwarded For IP"
                v-model="filters.forwardedForIp.value"
                :modifiers.sync="filters.forwardedForIp.modifiers"
                editPlaceholder=""
                :forceEdit="true"
                track-by="id"
                label="label"
              >
              </Superselect>
            </div>
            <div v-show="columnData.usageType">
              <Superselect
                title="Usage Type"
                v-model="filters.usageType.value"
                :modifiers.sync="filters.usageType.modifiers"
                editPlaceholder=""
                :forceEdit="true"
                track-by="id"
                label="label"
              >
              </Superselect>
            </div>
            <div v-show="columnData.connectionType">
              <Superselect
                title="Connection Type"
                v-model="filters.connectionType.value"
                :modifiers.sync="filters.connectionType.modifiers"
                :options="options.connectionType"
                track-by="id"
                label="label"
              >
              </Superselect>
            </div>
            <div v-show="columnData.isp">
              <Superselect
                title="ISP"
                v-model="filters.isp.value"
                :modifiers.sync="filters.isp.modifiers"
                editPlaceholder=""
                :forceEdit="true"
                track-by="id"
                label="label"
              >
              </Superselect>
            </div>
            <div v-show="columnData.language">
              <Superselect
                title="Language"
                v-model="filters.language.value"
                :modifiers.sync="filters.language.modifiers"
                editPlaceholder=""
                :forceEdit="true"
                track-by="id"
                label="label"
              >
              </Superselect>
            </div>
            <div v-show="columnData.keyword">
              <Superselect
                v-if="filters.keyword"
                title="Keyword"
                v-model="filters.keyword.value"
                :modifiers.sync="filters.keyword.modifiers"
                :forceEdit="true"
                editPlaceholder=""
                track-by="id"
                label="label"
              >
              </Superselect>
            </div>
            <div v-show="columnData.subId">
              <Superselect
                title="Sub ID"
                v-model="filters.subId.value"
                :modifiers.sync="filters.subId.modifiers"
                :forceEdit="true"
                editPlaceholder=""
                track-by="id"
                label="label"
              >
              </Superselect>
            </div>
            <div v-show="columnData.referer">
              <Superselect
                title="Referer"
                v-model="filters.referer.value"
                :modifiers.sync="filters.referer.modifiers"
                :forceEdit="true"
                editPlaceholder=""
                track-by="id"
                label="label"
              >
              </Superselect>
            </div>
            <div v-show="columnData.impressionId">
              <Superselect
                title="Impression ID"
                v-model="filters.impressionId.value"
                :modifiers.sync="filters.impressionId.modifiers"
                :forceEdit="true"
                editPlaceholder=""
                track-by="id"
                label="label"
              >
              </Superselect>
            </div>
            <div v-show="columnData.clickId">
              <Superselect
                title="Click ID"
                v-model="filters.clickId.value"
                :modifiers.sync="filters.clickId.modifiers"
                :forceEdit="true"
                editPlaceholder=""
                track-by="id"
                label="label"
              >
              </Superselect>
            </div>
            <div :class="colClass">
              <Superselect
                title="Domain Mismatch"
                v-model="filters.domainMismatch.value"
                placeholder="Select One"
                :modifiers.sync="filters.domainMismatch.modifiers"
                :multiple="false"
                :options="options.domainMismatch"
                track-by="id"
                label="label"
              ></Superselect>
            </div>
            <div :class="colClass">
              <Superselect
                title="yLeader"
                v-model="filters.isYleader.value"
                placeholder="Select One"
                :modifiers.sync="filters.isYleader.modifiers"
                :multiple="false"
                :options="options.isYleader"
                track-by="id"
                label="label"
              ></Superselect>
            </div>
            <div :class="colClass">
              <Superselect
                title="Smart Link"
                v-model="filters.campaignSmartLink.value"
                placeholder="Select One"
                :modifiers.sync="filters.campaignSmartLink.modifiers"
                :multiple="false"
                :options="options.campaignSmartLink"
                track-by="id"
                label="label"
              ></Superselect>
            </div>
            <div :class="colClass">
              <Superselect
                title="Advertiser Account Manager"
                v-model="filters.advertiserAccountManager.value"
                placeholder="Select One"
                :multiple="true"
                :modifiers.sync="filters.advertiserAccountManager.modifiers"
                track-by="id"
                label="label"
                :query="queries.ACCOUNT_MANAGER_OPTIONS_QUERY"
                :query-variables="{
                  filters: {
                    label: {
                      value: [],
                      modifiers: { matchType: 'contains' }
                    }
                  },
                  first: 25
                }"
                :query-result-map="{
                  id: 'id',
                  label: 'label'
                }"
              ></Superselect>
            </div>
            <div :class="colClass">
              <Superselect
                title="Publisher Account Manager"
                v-model="filters.publisherAccountManager.value"
                placeholder="Select One"
                :multiple="true"
                :modifiers.sync="filters.publisherAccountManager.modifiers"
                track-by="id"
                label="label"
                :query="queries.ACCOUNT_MANAGER_OPTIONS_QUERY"
                :query-variables="{
                  filters: {
                    label: {
                      value: [],
                      modifiers: { matchType: 'contains' }
                    }
                  },
                  first: 25
                }"
                :query-result-map="{
                  id: 'id',
                  label: 'label'
                }"
              ></Superselect>
            </div>
            <div :class="colClass">
              <Superselect
                title="Advertiser Tracking Platform"
                v-model="filters.advertiserTrackingPlatform.value"
                placeholder="Select One"
                :multiple="true"
                :modifiers.sync="filters.advertiserTrackingPlatform.modifiers"
                track-by="id"
                label="label"
                :query="queries.TAGS_QUERY"
                :query-variables="{
                  filters: {
                    type: {
                      value: ['trackingPlatform'],
                      modifiers: { matchType: 'exact' }
                    }
                  },
                  first: 100
                }"
                :query-result-map="{
                  id: 'name',
                  label: 'name'
                }"
              ></Superselect>
            </div>
            <div :class="colClass">
              <Superselect
                title="Publisher Tracking Platform"
                v-model="filters.publisherTrackingPlatform.value"
                placeholder="Select One"
                :multiple="true"
                :modifiers.sync="filters.publisherTrackingPlatform.modifiers"
                track-by="id"
                label="label"
                :query="queries.TAGS_QUERY"
                :query-variables="{
                  filters: {
                    type: {
                      value: ['trackingPlatform'],
                      modifiers: { matchType: 'exact' }
                    }
                  },
                  first: 100
                }"
                :query-result-map="{
                  id: 'slug',
                  label: 'name'
                }"
              ></Superselect>
            </div>
            <div :class="colClass">
              <Superselect
                title="Browser Family"
                v-model="filters.browserFamily.value"
                :options="options.browserFamily"
                :modifiers.sync="filters.browserFamily.modifiers"
                track-by="id"
                label="label"
              ></Superselect>
            </div>
            <div :class="colClass">
              <Superselect
                title="User Platform"
                v-model="filters.userPlatform.value"
                :options="options.userPlatforms"
                :modifiers.sync="filters.userPlatform.modifiers"
                track-by="id"
                label="label"
              ></Superselect>
            </div>
            <div :class="colClass">
              <Superselect
                title="Region"
                v-model="filters.regionCode.value"
                :options="options.regionCode"
                :modifiers.sync="filters.regionCode.modifiers"
                track-by="id"
                label="label"
              ></Superselect>
            </div>

            <div :class="colClass">
              <Superselect
                title="Conversion Type"
                v-model="filters.conversionType.value"
                placeholder="Select One"
                :multiple="true"
                :modifiers.sync="filters.conversionType.modifiers"
                track-by="id"
                label="label"
                :query="queries.CONVERSION_TYPES_QUERY"
                queryDefaultInputText=""
                :query-variables="{
                  filters: {
                    name: {
                      value: ['{input}'],
                      modifiers: { matchType: 'contains' }
                    }
                  },
                  first: 25
                }"
                :query-result-map="{
                  id: 'name',
                  label: 'name'
                }"
              ></Superselect>
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-3 pt-5 gap-1">
            <div>
              <Superselect
                title="Show"
                v-model="showColumnsFilter"
                :options="showColumnsOptions"
                :multiple="true"
                placeholder="Select Columns"
                track-by="id"
                label="label"
                selectedLabel=""
                selectLabel=""
                deselectLabel=""
                :close-on-select="false"
                @select="columnSelected"
                @remove="columnRemoved"
              >
                <span slot="noResult">No Columns Found</span>
              </Superselect>
            </div>
            <div>
              <Superselect
                title="Sort By"
                v-model="sortByFilter"
                :options="sortByOptions"
                :multiple="true"
                placeholder="Sort By"
                track-by="value"
                :allow-empty="false"
                label="label"
                selectedLabel=""
                selectLabel=""
                deselectLabel=""
              >
                <span slot="noResult">No Columns Found</span>
              </Superselect>
            </div>
            <div v-show="true">
              <Superselect
                title="Options"
                v-model="configFilter"
                :options="configOptions"
                :multiple="true"
                placeholder="Select Options"
                track-by="id"
                label="label"
                selectedLabel=""
                selectLabel=""
                deselectLabel=""
                :close-on-select="false"
              >
                <span slot="noResult">No Option Found</span>
              </Superselect>

              <div class="mt-2 flex gap-2 justify-end text-gray-800">
                <YCheckbox
                  id="preset-is-public-checkbox"
                  v-model="isLargeDataExport"
                  label="Export Large Data"
                  class="flex items-center mr-2"
                  type="info"
                ></YCheckbox>
              </div>
            </div>
          </div>
        </WidgetBody>
      </transition>
      <WidgetHeader
        class="bg-opacity-faded flex-wrap"
        style="border-top: 1px solid #D9D9D9;"
      >
        <template slot="rawContent">
          <div class="mb-1 sm:mb-0">
            <YButton
              @click.prevent="showFilters = !showFilters"
              class="hover:bg-gray-100 focus:bg-blue-100 text-blue-600 hover:text-blue-700 flex items-center outline-none-important"
            >
              <Icon
                :name="showFilters ? 'minus' : 'plus'"
                :size="4"
                class="inline mr-1"
              />
              {{ showFilters ? 'Hide' : 'Show Filters' }}</YButton
            >
          </div>
          <div class="flex items-center flex-row-reverse">
            <YButton
              class="hover:bg-gray-100 focus:bg-blue-100 text-blue-600 hover:text-blue-700 flex items-center outline-none-important mt-2 px-3 md:px-6"
              @click="resetFilters()"
              :disabled="formButtonsDisabled"
              >Reset Filters</YButton
            >
            <div class="flex mt-2 md:mt-0">
              <YButton
                class="bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 text-white mr-1 md:mr-3 outline-none-important"
                @click="doFilter()"
                :disabled="formButtonsDisabled"
                :is-loading="isSubmitting"
                >Submit</YButton
              >
            </div>
          </div>
        </template>
      </WidgetHeader>
    </Widget>
    <YAlert v-if="responseMessage" :type="responseMessage.type">{{
      responseMessage.content
    }}</YAlert>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
    <!-- <ApolloQuery
      v-if="reportQuery"
      :query="reportQuery"
      :variables="reportVariables"
    >
    </ApolloQuery> -->
  </div>
</template>

<script>
//import VueTimepicker from 'vue2-timepicker'
import AdminReportsPresetDialogDelete from '@/components/legacy/AdminReportsPresetDialogDelete'
import AdminReportsPresetDialogSave from '@/components/legacy/AdminReportsPresetDialogSave'
import YDateTimeRangePicker from '@/components/ui/YDateTimeRangePicker'
import Velocity from 'velocity-animate'
import gql from 'graphql-tag'
//Query Imports
import PUBLISHER_OPTIONS_QUERY from '@/graphql/Publisher/PublisherOptionsQuery.gql'
import ACCOUNT_MANAGER_OPTIONS_QUERY from '@/graphql/AccountManager/AccountManagerOptionsQuery.gql'
import ADVERTISER_OPTIONS_WITH_ACCOUNTS_QUERY from '@/graphql/Advertiser/AdvertiserOptionsWithAccountsQuery.gql'
import ADVERTISER_ACCOUNT_OPTIONS_QUERY from '@/graphql/AdvertiserAccount/AdvertiserAccountOptionsQuery.gql'
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'
import OFFER_OPTIONS_QUERY from '@/graphql/Offer/OfferOptionsQuery.gql'
import CONVERSION_TYPES_QUERY from '@/graphql/ConversionType/ConversionTypesQuery.gql'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'
import CAMPAIGN_OPTIONS_QUERY from '@/graphql/Campaign/CampaignOptionsQuery.gql'
import DOMAIN_OPTIONS_QUERY from '@/graphql/Domain/DomainOptionsQuery.gql'
import REPORT_PRESET_OPTIONS_QUERY from '@/graphql/ReportPreset/ReportPresetOptionsQuery.gql'
import REPORT_PRESET_QUERY from '@/graphql/ReportPreset/ReportPresetQuery.gql'
import BROADCAST_ADMIN_REPORT_MUTATION from '@/graphql/Report/BroadcastAdminReportMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import ELASTIC_VALUE_SET_QUERY from '@/graphql/ElasticSearch/ElasticValueSetQuery.gql'
import CREATE_REPORT_EXPORT_MUTATION from '@/graphql/Report/CreateReportExport.gql'

export default {
  mixins: [ValidationErrorsMixin],
  components: {
    //VueTimepicker,
    AdminReportsPresetDialogDelete,
    AdminReportsPresetDialogSave,
    YDateTimeRangePicker
  },
  props: {
    link: {
      type: Object
    },
    masterColumnData: {
      type: Object
    },
    createVuetableColumns: {
      type: Function
    },
    persistState: {
      type: Boolean,
      default: false
    },
    enablePresets: {
      type: Boolean,
      default: false
    },
    autoLoad: {
      type: Boolean,
      default: false
    },
    defaultDateRange: {
      type: Object,
      default: function() {
        return {
          start: this.$dateTime.local().toFormat('yyyy-LL-dd'),
          end: this.$dateTime.local().toFormat('yyyy-LL-dd')
        }
      }
    },
    defaultFilters: {
      type: Object
    },
    initialPresetId: {
      type: String,
      default: null
    },
    reportId: {
      type: String,
      default: null
    }
  },
  apollo: {
    reportPresetOptions: {
      query: REPORT_PRESET_OPTIONS_QUERY,
      variables() {
        return {
          first: 1000,
          filters: {
            publicOrOwn: true
          }
        }
      },
      result({ data }) {
        this.reportPresetOptions = data.reportPresetOptions.data
      }
    },
    requestedPreset: {
      query: REPORT_PRESET_QUERY,
      update: data => data.reportPreset,
      variables() {
        return {
          id: this.requestedPresetId
        }
      },
      result({ data }) {
        this.updatePresetFilter(data.reportPreset)
      },
      skip() {
        return !this.requestedPresetId
      }
    },
    advertiserOptions: {
      query: ADVERTISER_OPTIONS_WITH_ACCOUNTS_QUERY,
      variables() {
        return { filters: { status: ['Active'] }, first: 1000 }
      },
      result({ data }) {
        this.options.advertisers = data.advertiserOptions.data.map(
          advertiser => ({
            id: advertiser.compositeId,
            label: advertiser.label
          })
        )
        data.advertiserOptions.data.forEach(advertiser => {
          this.advertiserAccountsByAdvCompositeId[advertiser.compositeId] =
            advertiser.advertiserAccounts
        })
      }
    },
    regionCodeOptions: {
      query: ELASTIC_VALUE_SET_QUERY,
      variables() {
        return {
          input: {
            field: 'geo_region'
          }
        }
      },
      result(data) {
        this.options.regionCode = data.data.elasticValueSet.values.map(
          region => ({
            id: region,
            label: region
          })
        )
      },
      update: data => data.elasticValueSet
    },
    browserFamilyOption: {
      query: ELASTIC_VALUE_SET_QUERY,
      variables() {
        return {
          input: {
            field: 'browser_family'
          }
        }
      },
      result(data) {
        this.options.browserFamily = data.data.elasticValueSet.values.map(
          region => ({
            id: region,
            label: region
          })
        )
      },
      update: data => data.elasticValueSet
    }
  },
  data() {
    return {
      isLargeDataExport: false,
      activeOptions: {},
      queries: {
        PUBLISHER_OPTIONS_QUERY,
        ADVERTISER_ACCOUNT_OPTIONS_QUERY,
        COUNTRIES_QUERY,
        OFFER_OPTIONS_QUERY,
        REDIRECT_OPTIONS_QUERY,
        TAGS_QUERY,
        CAMPAIGN_OPTIONS_QUERY,
        DOMAIN_OPTIONS_QUERY,
        ACCOUNT_MANAGER_OPTIONS_QUERY,
        ELASTIC_VALUE_SET_QUERY,
        CONVERSION_TYPES_QUERY
      },
      advertiserOptions: [],
      advertiserAccountsByAdvCompositeId: {},
      reportQuery: null,
      reportVariables: {},
      requestedPresetId: this.initialPresetId,
      searchedTemplateId: null,
      searchedOfferId: null,
      searchedRedirect: null,
      responseMessage: null,
      isSubmitting: false,
      columnData: this.masterColumnData,
      defaultPreset: null,
      presetStatus: 'active',
      cachedPreset: {
        id: 'default',
        name: 'Default',
        author: '',
        data: null
      },
      reportPresetFilter: {
        id: 'default',
        name: 'Default',
        author: ''
      },
      reportPresetOptions: [],
      colClass: 'col-xl-2 col-lg-6',
      dateTimeStart: null,
      dateTimeEnd: null,
      filters: {
        dateTimeRange: {
          start: this.defaultDateRange.start + ' 12:00 am',
          end: this.defaultDateRange.end + ' 11:59 pm'
        },
        conversionType: {
          value: [],
          modifiers: {
            exclude: false,
            edit: false
          }
        },
        regionCode: {
          value: [],
          modifiers: {
            exclude: false,
            edit: false
          }
        },
        browserFamily: {
          value: [],
          modifiers: {
            exclude: false,
            edit: false
          }
        },
        country: {
          value: [],
          modifiers: {
            exclude: false,
            edit: false
          }
        },
        mobileDesktop: {
          value: null,
          modifiers: {
            exclude: false,
            type: 'exists'
          }
        },
        isYleader: {
          value: null,
          modifiers: {
            exclude: false,
            type: 'exists'
          }
        },
        campaignSmartLink: {
          value: null,
          modifiers: {
            exclude: false,
            type: 'exists'
          }
        },
        publisher: {
          value: [],
          modifiers: {
            exclude: false,
            edit: false
          }
        },
        advertiserAccountManager: {
          value: [],
          modifiers: {
            exclude: false
          }
        },
        publisherAccountManager: {
          value: [],
          modifiers: {
            exclude: false
          }
        },
        advertiserTrackingPlatform: {
          value: [],
          modifiers: {
            exclude: false
          }
        },
        publisherTrackingPlatform: {
          value: [],
          modifiers: {
            exclude: false
          }
        },
        advertiser: {
          value: [],
          modifiers: {
            exclude: false,
            edit: false
          }
        },
        advertiserAccount: {
          value: [],
          modifiers: {
            exclude: false,
            edit: false
          }
        },
        offer: {
          value: [],
          modifiers: {
            exclude: false,
            edit: false
          }
        },
        template: {
          value: [],
          modifiers: {
            exclude: false,
            edit: false
          }
        },
        source: {
          value: [],
          modifiers: {
            exclude: false,
            edit: true
          }
        },
        redirect: {
          value: [],
          modifiers: {
            exclude: false,
            edit: true
          }
        },
        redirectLink: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: true
          }
        },
        miniDomain: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: true
          }
        },
        groupService: {
          value: [],
          modifiers: {
            exclude: false,
            edit: false
          }
        },
        mini: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: false
          }
        },
        vertical: {
          value: [],
          modifiers: {
            exclude: false,
            edit: false
          }
        },
        keyword: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: true
          }
        },
        subId: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: true
          }
        },
        campaign: {
          value: [],
          modifiers: {
            exclude: false,
            edit: false
          }
        },
        browser: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: false
          }
        },
        os: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: false
          }
        },
        device: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: false
          }
        },
        ip: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: false
          }
        },
        forwardedForIp: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: false
          }
        },
        usageType: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: false
          }
        },
        connectionType: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact'
          }
        },
        isp: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: false
          }
        },
        carrier: {
          value: [],
          modifiers: {
            exclude: false,
            edit: false
          }
        },
        language: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: false
          }
        },
        impressionId: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: false
          }
        },
        algorithmId: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: false
          }
        },
        userPlatform: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact'
          }
        },
        clickId: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: false
          }
        },
        referer: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: false
          }
        },
        domainMismatch: {
          value: null,
          modifiers: {}
        }
      },
      options: {
        regionCode: [],
        browserFamily: [],
        country: [],
        publisher: [],
        advertisers: [],
        advertiserAccount: [],
        offer: [],
        source: [],
        template: [],
        redirect: [],
        groupService: [],
        mini: [],
        vertical: [],
        subId: [],
        campaign: [],
        browser: [],
        os: [],
        device: [],
        carrier: [],
        mobileDesktop: [
          {
            id: 'true',
            label: 'Mobile'
          }
          /*{
		      			id: 'false',
		      			label: 'Desktop'
		      		}	*/
        ],
        isYleader: [
          {
            id: 'true',
            label: 'yLeader'
          }
        ],
        campaignSmartLink: [
          {
            id: 'true',
            label: 'Smart Link'
          }
        ],
        algorithmId: [
          {
            id: '11',
            label: 'Version 11 - Id 11'
          }
        ],
        userPlatforms: [
          {
            id: 'allin',
            label: 'All In'
          },
          {
            id: 'ytrack',
            label: 'yTrack'
          }
        ],
        domainMismatch: [
          {
            id: 'true',
            label: 'Show Mismatches Only'
          }
        ],
        connectionType: [
          {
            id: 'Dialup',
            label: 'Dialup'
          },
          {
            id: 'Cable/DSL',
            label: 'Cable/DSL'
          },
          {
            id: 'Corporate',
            label: 'Corporate'
          },
          {
            id: 'Cellular',
            label: 'Cellular'
          }
        ]
      },
      configFilter: [],
      configOptions: [
        {
          id: 'STAGING_DATA',
          label: 'View Staging Data'
        },
        {
          id: 'EXCLUDE_CLICKLESS_ACTIONS',
          label: 'Exclude Clickless Actions'
        }
      ],
      sortByFilter: [],
      showFilters: true,
      cachedTableData: {
        filters: null,
        sortOrder: null,
        fields: null,
        configFilter: null
      },
      originalState: {
        filters: null,
        sortOrder: null,
        fields: null,
        configFilter: null
      },
      queryVariables: null
    }
  },
  computed: {
    advertiserAccountOptions() {
      let advertiserAccountOptions = []
      this.filters.advertiser.value.forEach(item => {
        if (this.advertiserAccountsByAdvCompositeId[item.id]) {
          this.advertiserAccountsByAdvCompositeId[item.id].forEach(account => {
            advertiserAccountOptions.push({
              id: account.id,
              label: account.label
            })
          })
        }
      })
      return advertiserAccountOptions
    },
    gqlColumns() {
      if (this.queryVariables) {
        return this.queryVariables.fields.join(' ')
      }
      return ''
    },
    sortByOptions() {
      let output = Object.values(this.showFiltersData)
      output = output
        .map(column => {
          if (column.filter.state) {
            return {
              label: column.filter.label,
              value: {
                field: column.column.name,
                sortField: column.column.sortField,
                direction: column.column.defSortDirection
                  ? column.column.defSortDirection
                  : 'asc'
              }
            }
          }
        })
        .filter(Boolean)
      return output
    },
    formButtonsDisabled() {
      return this.isSubmitting ? true : false
    },
    showFiltersData() {
      let columns = this.masterColumnData
      var visibleFilters = {}
      let filterImpressionTypes =
        this.filters.advertiser.value.length > 0 ||
        this.filters.offer.value.length > 0
      let impressionTypes = ['ecpm', 'cpm', 'ctr', 'impressions']
      for (var column in columns) {
        if (filterImpressionTypes) {
          if (!impressionTypes.includes(column)) {
            if (columns[column].filter.visible == true) {
              visibleFilters[column] = columns[column]
            }
          }
        } else {
          if (columns[column].filter.visible == true) {
            visibleFilters[column] = columns[column]
          }
        }
      }
      return visibleFilters
    },
    showColumnsFilter: {
      get: function() {
        let columns = this.showFiltersData
        var activeColumns = []
        for (var column in columns) {
          if (
            columns[column].filter.visible == true &&
            columns[column].filter.state == true
          ) {
            activeColumns.push({
              id: column,
              label: columns[column].filter.label
            })
          }
        }
        return activeColumns
      },
      set: function(columns) {
        const activeStateColumns = columns.map(column => column.id)
        for (var key in this.showFiltersData) {
          this.showFiltersData[key].filter.state = activeStateColumns.includes(
            key
          )
            ? true
            : false
        }
      }
    },
    report: {
      get: function() {
        return {
          filters: this.filters,
          sortByFilter: this.sortByFilter,
          configFilter: this.configFilter,
          showColumnsFilter: this.showColumnsFilter
        }
      },
      set: function(newValue) {
        console.log('setting report', newValue)
        this.filters = newValue.filters
        this.sortByFilter = newValue.sortByFilter
        this.configFilter = newValue.configFilter
        this.showColumnsFilter = newValue.showColumnsFilter
        console.log('done setting')
      }
    },
    moreParamsFilters() {
      // get filters from data
      let filters = this.filters
      // modify filters as needed for API
      // add addtional filters
      filters.show = this.formattedShowFilters
      return filters
    },
    showColumnsOptions() {
      let values = []

      this._.each(this.showFiltersData, function(value, key) {
        let option = {
          id: key,
          label: value.filter.label
        }
        values.push(option)
      })
      return values
    },
    formattedShowFilters() {
      // remove all properties but 'state'
      const filters = this.showFiltersData
      let formattedFilters = {}
      for (var key in filters) {
        formattedFilters[key] = filters[key].filter.state
      }
      return formattedFilters
    },
    showFiltersChunks() {
      // Create chunks for use in dynamically generating rows & columns (each row is a chunk)
      return this._.chunkObj(this.showFiltersData, 3)
    },
    presetIsModified() {
      let cachedPreset = this._.cloneDeep(this.cachedPreset.data)
      let report = this._.cloneDeep(this.report)
      if (!this.cachedPreset.data.filters.dateTimeRange) {
        delete report.filters.dateTimeRange
      }
      cachedPreset.filters = this.sortByKeys(cachedPreset.filters)
      report.filters = this.sortByKeys(report.filters)
      return JSON.stringify(cachedPreset) != JSON.stringify(report)
    }
  },
  watch: {
    'link.isLoading': function(value) {
      if (value == false) {
        this.isSubmitting = false
      }
    },
    report: {
      handler: function(value) {
        if (this.persistState) {
          this.$store.dispatch('report/updateAdminFilters', value)
        }
        if (this.cachedPreset.data) {
          if (this.presetIsModified) {
            this.presetStatus = 'modified'
            this.reportPresetFilter = {
              name: this.cachedPreset.name + ' (modified)',
              id: 'modified',
              author: this.cachedPreset.author,
              originalId: this.cachedPreset.id,
              originalName: this.cachedPreset.name
            }
          } else {
            this.presetStatus = 'active'
            this.reportPresetFilter = {
              name: this.cachedPreset.name,
              id: this.cachedPreset.id,
              author: this.cachedPreset.author
            }
          }
        }
      },
      deep: true
    },
    'filters.domainMismatch.value': function(newValue) {
      if (newValue) {
        this.showFiltersData.redirect.filter.state = true
        this.showFiltersData.redirectLink.filter.state = true
      }
    },
    'showFiltersData.day.filter.state': function(value) {
      if (value == true) {
        this.showFiltersData.month.filter.state = false
        this.showFiltersData.hour.filter.state = false
      }
    },
    'showFiltersData.month.filter.state': function(value) {
      if (value == true) {
        this.showFiltersData.day.filter.state = false
        this.showFiltersData.hour.filter.state = false
      }
    },
    'showFiltersData.hour.filter.state': function(value) {
      if (value == true) {
        this.showFiltersData.day.filter.state = false
        this.showFiltersData.month.filter.state = false
      }
    },
    sortByFilter: function() {}
  },
  mounted() {
    // Set default sortByFilter
    //this.sortByFilter = [this.sortByOptions.filter(option => option.label == 'Revenue')[0]] || [this.sortByOptions[0]]
    this.filters = this._.merge(this.filters, this.defaultFilters)
    if (this.autoLoad) {
      this.doFilter()
    }
    // Set default preset and assign to cache
    this.defaultPreset = {
      filters: this._.cloneDeep(this.filters),
      sortByFilter: this._.cloneDeep(this.sortByFilter),
      configFilter: this._.cloneDeep(this.configFilter),
      showColumnsFilter: this._.cloneDeep(this.showColumnsFilter)
    }
    this.cachedPreset.data = this._.cloneDeep(this.defaultPreset)
    this.$events.listen('changeReportPage', page => {
      this.changePage(page)
    })
    this.$events.listen('sortReportRows', sortBy => {
      this.updateCachedTableData({ sortBy: sortBy })
      this.loadRows()
    })
    this.$events.listen('adminReportReady', data => {
      console.log('app instance', data.appInstance, this.$appInstance)
      if (data.appInstance == this.$appInstance) {
        this.getStoredReport(data.reportId)
      }
    })
    this.saveOriginalState()
    if (this.persistState) {
      this.getPersistedState()
    }

    if (this.reportId) {
      this.getStoredReport(this.reportId)
    }
  },
  beforeDestroy() {
    this.$events.remove('changeReportPage')
    this.$events.remove('sortReportRows')
  },
  updated() {},
  methods: {
    getStoredReportData(id) {
      console.log('entering getStoredReportData')
      console.log(this.gqlColumns)
      if (this.gqlColumns == '') {
        return
      }
      console.log('getting stored report data')

      this.$apollo
        .query({
          query: gql`
            query GetStoredReport($id: ID!) {
              getStoredReport(id: $id) {
                report{
                  name
                  data{
                    ${this.gqlColumns}
                  }
                  totals{
                    ${this.gqlColumns}
                  }
                  message{
                    type
                    content
                  }
                  loadTimes
                }
              }
            }
          `,
          variables: {
            id: id
          }
        })
        .then(({ data }) => {
          console.log('got data')
          data = data.getStoredReport.report
          this.link.isLoading = true
          this.responseMessage = data.message ? data.message : null
          this.link.vuetableData = data.data
          this.link.totals = data.totals
          this.link.loadTimes = data.loadTimes
          this.link.startDateTime = this.cachedTableData.filters.dateTimeRange.start
          this.link.endDateTime = this.cachedTableData.filters.dateTimeRange.end
          this.refreshVuetable()
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.isSubmitting = true
        })
    },
    getStoredReport(id) {
      console.log('getting stored report', id)
      this.$apollo
        .query({
          query: gql`
            query GetStoredReport($id: ID!) {
              getStoredReport(id: $id) {
                state
              }
            }
          `,
          variables: {
            id: id
          }
        })
        .then(({ data }) => {
          if (data.getStoredReport == null) {
            return
          }
          if (data.getStoredReport.state) {
            console.log('parsing', JSON.parse(data.getStoredReport.state))
            this.report = JSON.parse(data.getStoredReport.state)
            this.prepareLoad()
            console.log('done preparing')
            this.getStoredReportData(id)
          }
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.isSubmitting = true
        })
    },
    broadcastReport() {
      if (this.isLargeDataExport) {
        this.broadcastReportExport()
      } else {
        this.broadcastReportToTable()
      }
    },
    broadcastReportToTable() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: BROADCAST_ADMIN_REPORT_MUTATION,
          variables: {
            input: {
              state: JSON.stringify(this.report),
              report: this.queryVariables
            }
          }
        })
        .then(({ data }) => {
          console.log('broadcasted', data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    broadcastReportExport() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: CREATE_REPORT_EXPORT_MUTATION,
          variables: {
            input: {
              name: 'Report Name',
              outputFormat: 'csv',
              chunkSize: 'daily',
              report: this.queryVariables
            }
          }
        })
        .then(({ data }) => {
          console.log('broadcasted create report export', data)
          this.$toastr.s('Report Export Created', 'Success')
          this.isSubmitting = false
        })
        .catch(error => {
          console.error(error)
        })
    },
    presetDeleted() {
      this.$apollo.queries.reportPresetOptions.refetch()
      this.resetFilters()
    },
    presetSaved(preset) {
      this.$apollo.queries.reportPresetOptions.refetch()
      this.updatePresetFilter(preset)
    },
    asyncTemplateSearch(value) {
      if (value.length > 0) {
        this.searchedTemplateId = value
      } else {
        this.options.template = []
        this.searchedTemplateId = null
      }
    },
    asyncOfferSearch(value) {
      if (value.length > 3) {
        this.searchedOfferId = value
      } else {
        this.options.offer = []
        this.searchedOfferId = null
      }
    },
    asyncRedirectSearch(value) {
      if (value.length > 2) {
        this.searchedRedirect = value
      } else {
        this.options.redirect = []
        this.searchedRedirect = null
      }
    },
    changePage(page) {
      this.loadRows({ currentPage: page })
    },
    cleanApolloData(data) {
      let output = data
      output = this.$omitDeep(output, '__typename')
      output = this.$omitDeep(output, 'Symbol(id)')
      output = JSON.parse(JSON.stringify(output))
      return output
    },
    updatePresetFilter(preset) {
      this.cachedPreset.id = preset.id
      this.cachedPreset.name = preset.name
      this.cachedPreset.author = preset.author
      this.cachedPreset.data = JSON.parse(preset.data)
      let configFilter = this._.cloneDeep(this.cachedPreset.data.configFilter)
      let filters = this._.assign(
        this.report.filters,
        this._.cloneDeep(this.cachedPreset.data.filters)
      )
      let showColumnsFilter = this._.cloneDeep(
        this.cachedPreset.data.showColumnsFilter
      )
      let sortByFilter = this._.cloneDeep(this.cachedPreset.data.sortByFilter)
      this.report = {
        configFilter: configFilter,
        filters: filters,
        showColumnsFilter: showColumnsFilter,
        sortByFilter: sortByFilter
      }
    },
    saveOriginalState() {
      this.originalState = this._.cloneDeep(this.report)
    },
    getPersistedState() {
      //Get Persisted Session State
      this.report = this.$store.getters['report/adminFilters']
        ? this.$store.getters['report/adminFilters']
        : this.report
    },
    resetFilters() {
      this.report = this._.cloneDeep(this.originalState)
      this.clearReportPreset()
    },
    columnSelected(selectedOption) {
      this.showFiltersData[selectedOption.id].filter.state = true
    },
    columnRemoved(selectedOption) {
      this.showFiltersData[selectedOption.id].filter.state = false
    },
    getCountryOptions(countries) {
      let geoList = countries
      geoList = this._.filter(geoList, function(o) {
        return !(o.id == '?') && !(o.id == 'XX')
      })
      geoList = this._.partition(geoList, function(o) {
        return this._.includes(['US', 'CA', 'AU', 'GB', 'IE', 'NZ'], o.id)
      })
      var geoOptions = [
        {
          label: 'Top Geos',
          group: []
        },
        {
          label: 'Other',
          group: []
        }
      ]
      this._.forEach(geoList[0], function(value) {
        var geo = {
          label: value.id + ' - ' + value.label,
          id: value.id
        }
        geoOptions[0].group.push(geo)
      })
      this._.forEach(geoList[1], function(value) {
        var geo = {
          label: value.id + ' - ' + value.label,
          id: value.id
        }
        geoOptions[1].group.push(geo)
      })
      return geoOptions
    },
    getCampaignOptions() {
      var campaignOptions = []
      this._.forEach(campaignOptions, function(value) {
        value.name = value.value + ' - ' + value.name
      })
      return campaignOptions
    },
    animateShowFiltersBeforeEnter() {},
    animateShowFiltersEnter(el, done) {
      Velocity(el, 'slideDown', {
        duration: 300,
        easing: 'easeOutQuad',
        complete: done
      })
    },
    animateShowFiltersLeave(el, done) {
      Velocity(el, 'slideUp', {
        duration: 300,
        easing: 'easeOutQuad',
        complete: done
      })
    },
    animatePresetButtonsBeforeEnter(el) {
      el.style.width = '0rem'
    },
    animatePresetButtonsEnter(el, done) {
      Velocity(
        el,
        { width: '4rem' },
        { duration: 1000, easing: 'easeOutQuad', complete: done }
      )
    },
    animatePresetButtonsLeave(el, done) {
      Velocity(
        el,
        { width: '0rem' },
        { duration: 1000, easing: 'easeOutQuad', complete: done }
      )
    },
    getObjectKeyByIndex(obj, i) {
      var key = Object.keys(obj)[i]
      return key
    },
    getObjectByIndex(obj, i) {
      var key = Object.keys(obj)[i]
      return obj[key]
    },
    forceConditions() {
      // Force campaign to be checked when landing page is checked
      // this.showFiltersData.campaign.filter.state = this.showFiltersData.landingPage.filter.state ? true : this.showFiltersData.campaign.filter.state
    },
    doFilter(options = {}) {
      this.prepareLoad(options)
      this.loadRows(options)
    },
    updateCachedTableData({
      fields = this.cachedTableData.fields,
      filters = this.cachedTableData.filters,
      sortOrder = this.cachedTableData.sortOrder,
      configFilter = this.cachedTableData.configFilter
    } = {}) {
      this.cachedTableData.fields = this._.cloneDeep(fields)
      this.cachedTableData.filters = this._.cloneDeep(filters)
      this.cachedTableData.sortOrder = this._.cloneDeep(sortOrder)
      this.cachedTableData.configFilter = this._.cloneDeep(configFilter)
    },
    refreshVuetable() {
      this.link.fields = this.cachedTableData.fields
      this.link.sortOrder = this.cachedTableData.sortOrder
      this.$nextTick(() => {
        this.link.$refs.vuetable.normalizeFields()
        this.link.isLoading = false
        //this.link.$refs.vuetable.refresh();
      })
    },
    tooltipOptions(label, color) {
      return {
        content: label,
        delay: 100,
        classes: ['tooltip-' + color]
      }
    },
    requestReportPreset(selection) {
      this.requestedPresetId = selection.id
    },
    clearReportPreset() {
      this.cachedPreset.id = 'default'
      this.cachedPreset.name = 'Default'
      this.cachedPreset.data = JSON.parse(JSON.stringify(this.defaultPreset))
    },
    copyPresetLinkToClipboard() {
      let reportUrl = `${window.location.protocol}//${window.location.hostname}/admin/reports/${this.reportPresetFilter.id}`
      this.$copyText(reportUrl).then(
        () => {
          this.$toastr.s('Link copied to clipboard.', 'Success!')
        },
        () => {
          this.$toastr.e('Copy failed.', 'Error!')
        }
      )
    },
    printColumnsForQuery() {
      const output = this.showColumnsFilter.map(value => value.id).join(' ')
      return output
    },
    prepareLoad(options = {}) {
      this.clearValidationErrors()
      this.responseMessage = null
      let getNewVuetableColumns = this.createVuetableColumns(
        this.masterColumnData
      )
      this.updateCachedTableData({
        fields: getNewVuetableColumns,
        filters: this.filters,
        sortOrder: JSON.parse(
          JSON.stringify(
            this.sortByFilter.map(o => {
              return o.value
            })
          )
        ),
        configFilter: this.configFilter
      })

      this.activeOptions = options ? options : {}
      console.log('setting show columns filters')
      let showColumnsFilters = this._.cloneDeep(this.showColumnsFilter)
      console.log('setting queryVariables')
      console.log(this.cachedTableData.filters)
      this.queryVariables = {
        filters: this.clearUnusedFilters(this.cachedTableData.filters),
        sortOrder: this._.cloneDeep(this.cachedTableData.sortOrder),
        options: this._.cloneDeep(this.cachedTableData.configFilter).map(
          option => option.id
        ),
        fields: showColumnsFilters.map(value => value.id)
      }
      console.log(this.gqlColumns)
    },
    loadRows(options = {}) {
      this.prepareLoad(options)
      this.broadcastReport()
    },
    clearUnusedFilters(input) {
      let filters = this._.cloneDeep(input)
      for (let filter in filters) {
        if (
          filter == 'dateTimeRange' ||
          filter == 'dateRange' ||
          (filter != 'dateRange' &&
            filter != 'dateTimeRange' &&
            Array.isArray(filters[filter].value) &&
            filters[filter].value.length > 0) ||
          (filter != 'dateRange' &&
            filter != 'dateTimeRange' &&
            !Array.isArray(filters[filter].value) &&
            filters[filter].value)
        ) {
          //
        } else {
          delete filters[filter]
        }
      }
      return filters
    },
    sortByKeys(object) {
      const keys = Object.keys(object)
      const sortedKeys = this._.sortBy(keys)
      return this._.fromPairs(this._.map(sortedKeys, key => [key, object[key]]))
    }
  }
}
</script>
<style>
.disabled-and-faded {
  opacity: 0.4;
  pointer-events: none;
}
.datetimepicker.visible {
  z-index: 99999 !important;
}
.date-time-picker {
  font-size: 0.925rem;
}
.date-time-picker .btn {
  font-weight: 500;
}
.shortcuts-container button.shortcut-button {
  background-color: #f8f8f8;
  margin-bottom: 2px !important;
  border: none !important;
}
.shortcuts-container button.shortcut-button:first-child {
}
</style>
