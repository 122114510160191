var render = function render(){
  var _vm$alfListings, _vm$alfListings$data, _vm$alfListings2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.redirect ? _c('div', [_c('portal', {
    attrs: {
      "to": "top-bar-left-header"
    }
  }, [_vm._v(" " + _vm._s(_vm.redirect.id) + " - " + _vm._s(_vm.savedRedirect.subdomain) + "." + _vm._s(_vm.savedRedirect.domain) + " "), _c('Tag', {
    staticClass: "ml-3"
  }, [_vm._v(" " + _vm._s(_vm.redirect.status))]), ((_vm$alfListings = _vm.alfListings) === null || _vm$alfListings === void 0 ? void 0 : (_vm$alfListings$data = _vm$alfListings.data) === null || _vm$alfListings$data === void 0 ? void 0 : _vm$alfListings$data.length) > 0 && ((_vm$alfListings2 = _vm.alfListings) === null || _vm$alfListings2 === void 0 ? void 0 : _vm$alfListings2.data[0].destination) == '127.0.0.1' ? _c('div', {
    staticClass: "inline-block ml-3"
  }, [_c('Tag', {
    attrs: {
      "color": "indigo"
    }
  }, [_vm._v("Desktop Killed")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "flex flex-row align-items-center mb-2"
  }, [_vm.savedRedirect.isAutoAbyssed ? _c('div', [_c('YButton', {
    attrs: {
      "color": "gray"
    },
    on: {
      "click": _vm.resetAutoAbyssed
    }
  }, [_vm._v(" Undo Auto Abyss ")])], 1) : _c('div', [_c('YButton', {
    attrs: {
      "color": _vm.savedRedirect.isAbyssed ? 'green' : 'red'
    },
    on: {
      "click": _vm.abyssRedirect
    }
  }, [_vm._v(_vm._s(_vm.savedRedirect.isAbyssed ? 'Undo Abyss' : 'Abyss'))])], 1), _c('YButton', {
    staticClass: "mx-2",
    attrs: {
      "color": 'blue'
    },
    on: {
      "click": _vm.openGenerateSubdomainModal
    }
  }, [_vm._v("New Subdomain")])], 1), _c('div', {
    staticClass: "flex flex-row"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('Tabs', {
    attrs: {
      "show-type": "show",
      "route": _vm.tabRoute
    }
  }, [_c('Tab', {
    attrs: {
      "name": "Details",
      "active": true
    }
  }, [_c('Widget', {
    staticClass: "rounded-t-none border-t-0"
  }, [_c('WidgetBody', {
    staticClass: "bg-gray-100 flex justify-between p-4"
  }, [_c('div'), _c('div', [_c('YButton', {
    staticClass: "btn btn-blue",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.saveRedirect,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.saveRedirect.apply(null, arguments);
      }
    }
  }, [_vm._v("Save")])], 1)]), _c('WidgetBody', {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: _vm.visibilityChanged,
      expression: "visibilityChanged"
    }]
  }, [_c('div', [_c('div', {
    staticClass: "mb-1"
  }, [_c('div', {
    staticClass: "text-xs text-indigo-600"
  }, [_vm._v("Campaign")]), _c('router-link', {
    staticClass: "link py-1 inline-block",
    attrs: {
      "to": {
        name: 'Campaign',
        params: {
          id: _vm.redirect.campaign.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.redirect.campaign.label) + " ")])], 1), _c('div', {
    staticClass: "mb-1"
  }, [_c('div', {
    staticClass: "text-xs text-indigo-600"
  }, [_vm._v("Publisher")]), _c('router-link', {
    staticClass: "link py-1 inline-block",
    attrs: {
      "to": {
        name: 'User',
        params: {
          id: _vm.redirect.user.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.redirect.user.label) + " ")])], 1), _c('div', {
    staticClass: "mb-2 mt-6"
  }, [_c('YCheckbox', {
    staticClass: "mb-1",
    attrs: {
      "label": " Don't Use Status Cake Tests",
      "type": "checkbox"
    },
    model: {
      value: _vm.redirect.ignoreStatusCake,
      callback: function ($$v) {
        _vm.$set(_vm.redirect, "ignoreStatusCake", $$v);
      },
      expression: "redirect.ignoreStatusCake"
    }
  })], 1), _c('div', {
    staticClass: "mt-4 mb-1"
  }, [_c('Superselect', {
    attrs: {
      "title": "Domain",
      "multiple": false,
      "options": _vm.domainOptions || []
    },
    model: {
      value: _vm.redirectDomain,
      callback: function ($$v) {
        _vm.redirectDomain = $$v;
      },
      expression: "redirectDomain"
    }
  })], 1), _c('div', {
    staticClass: "mb-2"
  }, [_c('YCheckbox', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.domainChanged,
      expression: "domainChanged"
    }],
    attrs: {
      "label": "Send Domain Update Notification Email",
      "type": "checkbox"
    },
    model: {
      value: _vm.shouldSendDomainReplacementEmailOption,
      callback: function ($$v) {
        _vm.shouldSendDomainReplacementEmailOption = $$v;
      },
      expression: "shouldSendDomainReplacementEmailOption"
    }
  })], 1), _c('br'), _c('div', {
    staticClass: "text-xs text-indigo-600"
  }, [_vm._v("Subdomain History")]), _c('SubdomainHistory', {
    attrs: {
      "redirect": _vm.redirect
    }
  })], 1)])], 1)], 1), _c('Tab', {
    attrs: {
      "name": "Stats",
      "active": true
    }
  }, [_c('simple-report', {
    staticClass: "col-span-4 mt-4",
    attrs: {
      "filters": _vm.reportFilters
    }
  }, [_c('h4', {
    staticClass: "widget-title",
    attrs: {
      "slot": "table-header"
    },
    slot: "table-header"
  }, [_vm._v("Daily Summary")])])], 1), _c('Tab', {
    attrs: {
      "name": "Payouts"
    }
  }, [_vm.redirect && _vm.redirect.payouts ? _c('Payouts', {
    attrs: {
      "payouts": _vm.redirect.payouts,
      "redirectId": parseInt(_vm.id),
      "userId": parseInt(_vm.redirect.user.id),
      "level": "redirect"
    }
  }) : _vm._e()], 1), _c('Tab', {
    attrs: {
      "name": "Postbacks"
    }
  }, [_c('PostbacksTable', {
    attrs: {
      "redirectId": parseInt(_vm.id),
      "userId": parseInt(_vm.redirect.user.id)
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": _vm.ruleTotalTabName
    }
  }, [_c('RulesTableWrapper', {
    attrs: {
      "default-filter-values": {
        redirects: [_vm.redirect]
      },
      "rulesParentComponent": "publisher_rule",
      "hide-filters": ['types', 'redirects', 'campaigns', 'publishers', 'sources']
    },
    on: {
      "totalRulesTab": _vm.totalRulesTab
    },
    scopedSlots: _vm._u([{
      key: "beforeTable",
      fn: function () {
        return [_c('div', {
          staticClass: "flex justify-between items-center mt-4 px-4 py-4 rounded-lg bg-gray-200"
        }, [_c('div', {
          staticClass: "text-muted"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.selectedCount) + " " + _vm._s(_vm.selectedCount == 1 ? 'item' : 'items') + " selected ")])]), _c('div', {
          staticClass: "flex gap-4"
        }, [_c('YButton', {
          attrs: {
            "color": "red",
            "disabled": !_vm.rowsAreSelected
          },
          on: {
            "click": _vm.openDeleteRulesModal
          }
        }, [_vm._v("Delete")]), _c('YButton', {
          attrs: {
            "color": "blue",
            "disabled": _vm.rowsAreSelected
          },
          on: {
            "click": _vm.openCreateRulesModal
          }
        }, [_vm._v("Create Rules")])], 1)])];
      },
      proxy: true
    }], null, false, 704410060)
  })], 1)], 1)], 1)]), _c('portal', {
    attrs: {
      "to": "sidebar-right"
    }
  }, [_c('SideBarRightItem', {
    attrs: {
      "title": "Traffic Sources"
    }
  }, [_c('EditableValue', {
    ref: "trafficSourcesEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.savedRedirect.tags.filter(function (tag) {
        return tag.type === 'trafficType';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.id,
          type: 'trafficType',
          taggableType: 'redirect',
          tags: '{value}'
        }
      }
    },
    on: {
      "saved": _vm.refreshAssignedTags
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "orange",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "value": slotProps.value,
            "options": _vm.savedRedirect.user.tags.filter(function (tag) {
              return tag.type === 'trafficType';
            })
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }], null, false, 1100818958)
  })], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }