<template>
  <div v-if="user">
    <portal to="top-bar-left-header">
      <div class="flex items-center">
        <div
          class="mr-2 rounded-full h-2 w-2 inline-flex md:hidden text-opacity-0"
          :class="
            `bg-${TextColorMap[user.status]}-500 hover:bg-${
              TextColorMap[user.status]
            }-600`
          "
        ></div>
        <div class="x-topbar-username break-all">
          {{ user.label }}
        </div>
      </div>
    </portal>
    <portal to="top-bar-left-header-after">
      <div class="flex items-center gap-1 ml-2">
        <Tag
          v-if="user.tags.some(tag => tag.name == 'auto-rejected')"
          color="darkRed"
          >auto-rejected</Tag
        >
        <Tag
          class="hidden md:inline-flex"
          @click="openSetStatusWidget()"
          :key="user.status"
          >{{ user.status }}</Tag
        >
        <Tag
          v-if="user.roles.some(role => role.name == 'all-in-user')"
          color="darkBlue"
          >All-In User</Tag
        >

        <Tag v-if="user.isQuarantined" color="orange"
          >Quarantined<span class="bg-white rounded-lg px-1 ml-1 -mr-1">{{
            user.quarantine.level
          }}</span></Tag
        >
      </div>
    </portal>
    <div class="flex flex-row align-items-center mb-2">
      <div></div>
      <!-- <editable-user-status
        ref="editableUserStatus"
        :id="parseInt(user.id)"
        class="pl-4"
        @success="onUserStatusUpdate"
      >
        <span class="ts-3">
          <Tag :text="user.status ? user.status.capitalize() : null"></Tag>
        </span>
      </editable-user-status>-->
      <!-- <div class="ml-auto right-side-column">
        <y-form-group label="Account Manager" class="mb-0">
          <editable-user-account-manager
            ref="editableAccountManager"
            :id="parseInt(user.id)"
            class="mb-4"
          >
            <span class="ts-5 fw-500" v-if="user.accountManager">{{
              user.accountManager.name
            }}</span>
            <span v-else class="text-muted">No Account Manager Assigned</span>
          </editable-user-account-manager>
        </y-form-group>
      </div>-->
    </div>
    <div class="flex flex-row"></div>
    <div class="row">
      <div class="col">
        <Tabs show-type="show" :route="tabRoute">
          <Tab name="Overview" :active="true">
            <Overview :filters="dailyGraphFilters" :user="user"> </Overview>
          </Tab>
          <Tab name="Stats">
            <simple-report :filters="reportFilters" class="col-span-4 mt-4">
              <h4 slot="table-header" class="widget-title">Daily Summary</h4>
            </simple-report>
          </Tab>
          <Tab name="Details">
            <Widget class="rounded-t-none border-t-0">
              <WidgetBody>
                <Tabs show-type="show">
                  <Tab name="Primary" class="mt-2" :active="true">
                    <PrimaryContact :user="user"></PrimaryContact>
                  </Tab>
                  <Tab v-if="user.billingContact" name="Billing">
                    <BillingContact :user="user"></BillingContact>
                  </Tab>
                  <Tab v-if="isPublisher" name="Details">
                    <Details :user="user"></Details>
                  </Tab>
                  <Tab v-if="isPublisher" name="Pay Details">
                    <iframe
                      v-if="user.tipaltiDetails.publisherDetails"
                      :src="user.tipaltiDetails.publisherDetails"
                      height="800"
                      style="border: none"
                      class="w-full"
                    ></iframe>
                  </Tab>
                  <Tab v-if="isPublisher" name="Wise Details">
                    <WiseDetails :user="user"></WiseDetails>
                  </Tab>
                  <Tab v-if="isPublisher" name="Pay History">
                    <iframe
                      v-if="user.tipaltiDetails.paymentHistoryLink"
                      :src="user.tipaltiDetails.paymentHistoryLink"
                      height="800"
                      style="border: none"
                      class="w-full"
                    ></iframe>
                  </Tab>
                  <Tab name="Password">
                    <div class="py-2">
                      <YButton color="blue" @click="openUpdatePasswordModal"
                        >Change Password</YButton
                      >
                    </div>
                  </Tab>
                </Tabs>
              </WidgetBody>
            </Widget>
          </Tab>
          <Tab name="Communications">
            <div class="pt-3">
              <Lead :id="user.id"></Lead>
            </div>
          </Tab>
          <Tab name="Payouts">
            <Payouts
              :payouts="user.payouts"
              :userId="parseInt(id)"
              level="user"
              :userRoles="user.roles"
            ></Payouts>
          </Tab>
          <Tab name="Postbacks">
            <Postbacks :userId="parseInt(id)"></Postbacks>
          </Tab>
          <Tab name="Redirects">
            <RedirectsTable
              :override-filters="{ user: { value: [{ id: id }] } }"
            >
            </RedirectsTable>
          </Tab>
          <Tab name="History">
            <UserHistory
              :user="user"
              :userActivity="userActivity"
            ></UserHistory>
          </Tab>
          <Tab name="Rules" v-if="isPublisher">
            <template v-slot:aside
              ><Tag>{{ rulesTotalCount }}</Tag></template
            >
            <RulesTableWrapper
              :default-filter-values="{ publishers: [user] }"
              rulesParentComponent="publisher_rule"
              :hide-filters="[
                'redirects',
                'campaigns',
                'publishers',
                'sources'
              ]"
              @totalRulesTab="totalRulesTab"
            >
              <template v-slot:beforeTable>
                <div
                  class="flex justify-between items-center mt-4 px-4 py-4 rounded-lg bg-gray-200 "
                >
                  <div class="text-muted">
                    <span>
                      {{ selectedCount }}
                      {{ selectedCount == 1 ? 'item' : 'items' }} selected
                    </span>
                  </div>
                  <div class="flex gap-4">
                    <YButton
                      color="red"
                      :disabled="!rowsAreSelected"
                      @click="openDeleteRulesModal"
                      >Delete</YButton
                    >
                    <YButton
                      color="blue"
                      @click="openCreateRulesModal"
                      :disabled="rowsAreSelected"
                      >Create Rules</YButton
                    >
                  </div>
                </div>
              </template>
            </RulesTableWrapper>
          </Tab>
          <Tab name="Quarantine">
            <Quarantine
              v-if="user.isQuarantined && user.caps.length > 0"
              class="my-4"
              :quarantine="user.quarantine"
              :current-payout="
                user.caps.filter(
                  cap => cap.type === 'payout' && cap.frequency == 'monthly'
                )[0].currentValue
              "
              @deleted="refetch"
            />
            <div class="my-4  text-center" v-else>
              <span class="italic text-gray-600">User is not quarantined</span>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
    <portal to="sidebar-right">
      <UserRightSideBar :user="user" />
    </portal>
  </div>
  <div v-else>
    <Spinner class="text-green-500" :size="10" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Postbacks from '@/views/Admin/Postbacks/Postbacks.vue'
import PrimaryContact from '@/views/Admin/User/PrimaryContact.vue'
import BillingContact from '@/views/Admin/Shared/BillingContact.vue'
import Details from '@/views/Admin/User/Details'
import WiseDetails from '@/views/Admin/User/WiseDetails'
import Payouts from '@/views/Admin/Payouts/Payouts.vue'
import RedirectsTable from '@/views/Admin/Redirects/RedirectsTable.vue'
import USER_QUERY from '@/graphql/User/UserQuery.gql'
import ME_QUERY from '@/graphql/User/MeQuery.gql'
import USER_ACTIVITY_QUERY from '@/graphql/Activity/UserActivityQuery.gql'
import UpdatePasswordWidget from '@/views/Admin/User/UpdatePasswordWidget'
import StringToCssColor from '@/assets/js/StringToCssColor'
import TextColorMap from '@/assets/js/TextColorMap'
import RulesTableWrapper from '@/views/Admin/Rules/RulesTableWrapper'
import CreateRules from '@/views/Admin/Rules/CreateRules'
import DeleteRules from '@/views/Admin/Rules/DeleteRules'
import Overview from '@/views/Admin/User/Overview'
import SetDomainStatusWidget from '@/views/Admin/Status/SetDomainStatusWidget.vue'
import SimpleReport from '@/views/Admin/Reports/SimpleReport'
import UserHistory from '@/views/Admin/User/UserHistory.vue'
import Lead from '@/views/Admin/Leads/Lead.vue'
import Quarantine from '@/views/Admin/User/Quarantine.vue'
import UserRightSideBar from '@/views/Admin/User/UserRightSideBar.vue'

export default {
  components: {
    Quarantine,
    Postbacks,
    PrimaryContact,
    BillingContact,
    Details,
    WiseDetails,
    RedirectsTable,
    Payouts,
    RulesTableWrapper,
    Overview,
    SimpleReport,
    UserHistory,
    Lead,
    UserRightSideBar
  },
  apollo: {
    me: {
      query: ME_QUERY,
      result() {
        this.meLoaded = true
      }
    },
    user: {
      query: USER_QUERY,
      variables() {
        return {
          id: this.id
        }
      },
      watchLoading(isLoading, countModifier) {
        this.$store.dispatch('updateGlobalLoader', countModifier)
      },
      result(results) {
        this.userLoaded = true
        this.tagSelection = results.data.user.tags.map(tag => tag.name)
      },
      deep: false
    },
    userActivity: {
      query: USER_ACTIVITY_QUERY,
      variables() {
        return { filters: { causerIdOrSubjectId: this.user.id } }
      },
      skip() {
        return !this.user
      }
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    urlAction: {
      type: String,
      default: null
    },
    tab: {
      type: String,
      default: 'overview'
    }
  },
  data() {
    return {
      TextColorMap,

      userActivity: [],
      activeTab: this.tab,
      dailyGraphFilters: {
        publisher: {
          value: [{ id: this.id }],
          modifiers: {}
        }
      },
      me: null,
      userLoaded: false,
      meLoaded: false,
      reportFilters: {
        publisher: {
          value: [{ label: this.id, id: this.id }],
          modifiers: { exclude: false, edit: true }
        }
      },
      rulesTotalCount: 0
    }
  },
  watch: {
    userAndMeLoaded(newValue) {
      if (newValue == true) {
        if (
          this.urlAction &&
          this.urlAction == 'approve' &&
          this.user.status !== 'active'
        ) {
          this.openSetStatusWidget('active', true)
          if (!this.user.accountManager) {
            this.openSyncAccountManagerWidget(true)
          }
        }
        if (
          this.urlAction &&
          this.urlAction == 'reject' &&
          this.user.status !== 'rejected'
        ) {
          this.openSetStatusWidget('rejected')
        }
      }
    }
  },
  updated() {},
  computed: {
    ...mapState('rule', ['selectedRowIds']),
    userAndMeLoaded() {
      return this.userLoaded && this.meLoaded ? true : false
    },
    tabRoute() {
      return { name: 'User', params: { id: this.id, tab: this.tab } }
    },
    isPublisher() {
      return this.user.roles.filter(role => role.name == 'publisher').length > 0
    },
    createdAtDate() {
      return this.user ? this.$luxon(this.user.createdAt) : undefined
    },
    lastLoginDate() {
      if (this.user) {
        if (this.user.lastLogin) {
          return this.$luxon(this.user.lastLogin.createdAt)
        } else {
          return 'No logins in last 30 days'
        }
      } else {
        return undefined
      }
    },
    lastLoginIp() {
      if (this.user && this.user.lastLogin) {
        return JSON.parse(this.user.lastLogin.data).ip
      } else {
        return undefined
      }
    },
    selectedCount() {
      return this.selectedRowIds.length
    },
    rowsAreSelected() {
      return this.selectedRowIds.length > 0
    }
  },
  mounted() {
    this.$events.listen('refetchPayouts', () => {
      this.$apollo.queries.user.refetch()
    })
    this.$events.listen('refreshPostbacks', () => {
      this.$apollo.queries.user.refetch()
    })
    this.$events.listen('refreshCommunications', () => {
      this.$apollo.queries.user.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('refetchPayouts')
    this.$events.remove('refreshPostbacks')
  },
  methods: {
    refetch() {
      this.$apollo.queries.user.refetch()
    },
    totalRulesTab(value) {
      this.rulesTotalCount = value.total
    },
    openCreateRulesModal() {
      this.$modal.show(
        CreateRules,
        {
          defaults: {
            type: {
              name: 'publisher',
              label: 'Publisher'
            },
            ruleables: [this.user]
          },
          hide: ['ruleables'],
          origin: 'publisher_rule'
        },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openDeleteRulesModal() {
      this.$modal.show(
        DeleteRules,
        { selectedRowIds: this.selectedRowIds },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 650,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openUpdatePasswordModal() {
      this.$modal.show(
        UpdatePasswordWidget,
        {
          userId: parseInt(this.id)
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true,
          classes: ['overflow-visible-important']
        }
      )
    },
    openSetStatusWidget(newStatus, autoSubmit = false) {
      this.$modal.show(
        SetDomainStatusWidget,
        {
          modelName: 'User',
          modelIds: [parseInt(this.id)],
          currentStatus: this.user.statusInfo,
          newStatus: newStatus ? { name: newStatus } : undefined,
          enumName: 'UserStatusEnum',
          autoSubmit: autoSubmit
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true,
          classes: ['overflow-visible-important']
        }
      )
    },

    toTitleCase(string) {
      return string.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    },
    onUserStatusUpdate() {
      // if (this.user.status == "active" && !this.user.accountManager) {
      //   this.$nextTick(() => {
      //     this.$refs.editableAccountManager.value = {
      //       id: authUserId
      //     };
      //     this.$refs.editableAccountManager.submit();
      //   });
      // }
    },
    approveUser() {
      if (this.user.status == 'active') {
        return
      }
      this.$nextTick(() => {
        this.$refs.editableUserStatus.value = 'active'
        this.$refs.editableUserStatus.submit()
      })
    },
    rejectUser() {
      if (this.user.status != 'pending') {
        return
      }
      this.$nextTick(() => {
        this.$refs.editableUserStatus.value = 'rejected'
        this.$refs.editableUserStatus.submit()
      })
    },
    getColorClassFromString(string) {
      let stringToColor = new StringToCssColor()
      return stringToColor.get(string)
    }
  }
}
</script>
<style scoped>
@media (max-width: 640px) {
  .x-topbar-username {
    max-width: 240px;
  }
}
</style>
