var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "uppercase text-xs font-semibold text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.ruleableItem.ruleableType) + " ")]), _vm.ruleableItem.ruleableType == 'redirect' ? _c('div', [_vm.ruleableItem.ruleable ? [_c('div', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": {
        name: 'Redirect',
        params: {
          id: _vm.ruleableItem.ruleableId
        }
      }
    }
  }, [[_vm._v(_vm._s(_vm.ruleableItem.ruleable.label))]], 2)], 1), _c('span', {
    staticClass: "italic text-xs text-gray-500"
  }, [_vm._v(_vm._s(_vm.ruleableItem.ruleable.user.label))])] : [_c('i', {
    staticClass: "text-gray-500"
  }, [_vm._v("No record found for ID " + _vm._s(_vm.ruleableItem.ruleableId))])]], 2) : _vm._e(), _vm.ruleableItem.ruleableType == 'campaign' ? _c('div', [_vm.ruleableItem.ruleable ? _c('router-link', {
    staticClass: "link",
    attrs: {
      "to": {
        name: 'Campaign',
        params: {
          id: _vm.ruleableItem.ruleableId
        }
      }
    }
  }, [[_vm._v(_vm._s(_vm.ruleableItem.ruleable.label))]], 2) : [_c('i', {
    staticClass: "text-gray-500"
  }, [_vm._v("No record found for ID " + _vm._s(_vm.ruleableItem.ruleableId))])]], 2) : _vm._e(), _vm.ruleableItem.ruleableType == 'publisher' ? _c('div', [_vm.ruleableItem.ruleable ? _c('router-link', {
    staticClass: "link",
    attrs: {
      "to": {
        name: 'User',
        params: {
          id: _vm.ruleableItem.ruleableId
        }
      }
    }
  }, [[_vm._v(_vm._s(_vm.ruleableItem.ruleable.label))]], 2) : [_c('i', {
    staticClass: "text-gray-500"
  }, [_vm._v("No record found for ID " + _vm._s(_vm.ruleableItem.ruleableId))])]], 2) : _vm._e(), _vm.ruleableItem.ruleableType == 'source' ? _c('div', [_vm.ruleableItem.ruleableId ? _c('router-link', {
    staticClass: "link",
    attrs: {
      "to": {
        name: 'Source',
        params: {
          id: _vm.ruleableItem.ruleableId
        }
      }
    }
  }, [[_vm._v(_vm._s(_vm.ruleableItem.ruleableId))]], 2) : _vm._e()], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }