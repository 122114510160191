import { render, staticRenderFns } from "./CampaignStatusToggleButton.vue?vue&type=template&id=83b8eeb2&scoped=true"
import script from "./CampaignStatusToggleButton.vue?vue&type=script&lang=js"
export * from "./CampaignStatusToggleButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83b8eeb2",
  null
  
)

export default component.exports