<template>
  <div class="w-full">
    <WidgetHeader @close="() => $emit('close')">Create Rules</WidgetHeader>
    <div class="p-4">
      <div class="text-md font-semibold text-gray-600 mb-2 uppercase">
        When traffic is coming from...
      </div>
      <template v-if="!hide.includes('ruleables')">
        <Superselect
          title="Type"
          v-model="type"
          :multiple="false"
          track-by="name"
          label="label"
          :options="typeOptions"
          :allow-empty="false"
          class="mb-1"
        ></Superselect>
        <Superselect
          v-if="type.name === 'campaign'"
          :key="type + 'Options'"
          title="Campaigns"
          v-model="ruleables"
          :modifiers="{ edit: false }"
          :multiple="true"
          track-by="id"
          label="label"
          :close-on-select="false"
          :query="CAMPAIGN_OPTIONS_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              label: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
              }
            }
          }"
        ></Superselect>
        <Superselect
          v-if="type.name === 'redirect'"
          title="Redirects"
          v-model="ruleables"
          :modifiers="{ edit: false }"
          :multiple="true"
          track-by="id"
          label="label"
          :close-on-select="false"
          :options="redirectOptions.data || []"
          :loading="$apolloData.queries.redirectOptions.loading"
        ></Superselect>
        <Superselect
          v-if="type.name === 'publisher'"
          title="Publishers"
          v-model="ruleables"
          :modifiers="{ edit: false }"
          :multiple="true"
          track-by="id"
          label="label"
          :close-on-select="false"
          :query="USER_OPTIONS_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              label: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
              }
            }
          }"
        ></Superselect>
        <Superselect
          v-if="type.name === 'source'"
          title="Sources"
          track-by="id"
          label="id"
          :modifiers="{ edit: false }"
          :multiple="true"
          :taggable="true"
          v-model="ruleables"
        />
      </template>
      <!-- <div class="text-md font-semibold text-gray-600 mb-2 uppercase">
        When traffic is...
      </div> -->
      <TagsSelect
        title="Countries"
        v-model="countryTags"
        :filter-types="['countryCode']"
        :modifiers="countryTagsModifiers"
        :show-options-type-label="false"
        :close-on-select="false"
        :allow-bulk-paste="true"
        class="mt-1 mb-1"
      ></TagsSelect>
      <TagsSelect
        title="Mobile / Desktop"
        v-model="mobileDesktopTags"
        :modifiers="mobileDesktopTagsModifiers"
        :filter-types="['mobileDesktop']"
        :show-options-type-label="false"
      ></TagsSelect>
    </div>
    <div class="p-4">
      <div class="text-md font-semibold text-gray-600 mb-2 uppercase">
        <Tag
          style="width: 7rem; text-align:center;"
          :color="filterablesModifiers.exclude === true ? 'red' : 'grey'"
          :class="
            filterablesModifiers.exclude === true
              ? ['shadow-md']
              : ['opacity-50']
          "
          class="text-xs rounded-r-none"
          tag-content-class="w-full"
          @click="() => (filterablesModifiers.exclude = true)"
          >Block</Tag
        ><Tag
          :color="filterablesModifiers.exclude === false ? 'blue' : 'grey'"
          :class="
            filterablesModifiers.exclude === false
              ? ['shadow-md']
              : ['opacity-50']
          "
          style="width: 7rem; text-align:center;"
          class="text-xs rounded-l-none"
          tag-content-class="w-full"
          @click="() => (filterablesModifiers.exclude = false)"
          >Only Allow</Tag
        >
      </div>
      
      <Superselect
       v-if="type.name === 'publisher' || type.name === 'redirect'"
        title="Filter Type"
        v-model="filterType"
        track-by="name"
        label="label"
        :multiple="false"
        :allow-empty="false"
        :options="filterPublisherRedirectTypeOptions"
        class="mb-1"
      ></Superselect>
      <Superselect
       v-else
        title="Filter Type"
        v-model="filterType"
        track-by="name"
        label="label"
        :multiple="false"
        :allow-empty="false"
        :options="filterTypeOptions"
        class="mb-1"
      ></Superselect>
      <Superselect
        v-if="filterType.name === 'offerDestinationType'"
        title="Offer Destination Type"
        v-model="filterables"
        track-by="id"
        label="label"
        :multiple="true"
        :allow-empty="false"
        :options="offerDestinationTypeOptions"
        class="mb-1"
      ></Superselect>
      <Superselect
        v-if="filterType.name === 'payoutType'"
        title="Payout Type"
        v-model="filterables"
        track-by="id"
        label="label"
        :multiple="true"
        :allow-empty="false"
        :options="payoutTypeOptions"
        class="mb-1"
      ></Superselect>
      <Superselect
        v-if="filterType.name === 'offer'"
        title="Offers"
        v-model="filterables"
        :modifiers="filterablesModifiers"
        :multiple="true"
        track-by="id"
        label="label"
        :close-on-select="false"
        :query="OFFER_OPTIONS_QUERY"
        :query-variables="{
          first: 20,
          filters: {
            label: {
              value: ['{input}'],
              modifiers: { matchType: 'contains' }
            }
          }
        }"
        :options="[]"
      ></Superselect>
      <Superselect
        v-else-if="filterType.name === 'advertiser'"
        title="Advertisers"
        v-model="filterables"
        :modifiers="filterablesModifiers"
        :multiple="true"
        track-by="id"
        label="label"
        :close-on-select="false"
        :query="ADVERTISER_OPTIONS_WITH_ACCOUNTS_QUERY"
        :query-variables="{
          first: 20,
          filters: {
            advertiserLabel: {
              value: ['{input}'],
              modifiers: { matchType: 'contains' }
            }
          }
        }"
        :options="[]"
      ></Superselect>
      <template v-else-if="filterType.name === 'advertiserAccount'">
        <Superselect
          title="Advertisers"
          v-model="advertiserWithAccount"
          class="mb-1"
          track-by="id"
          label="label"
          :close-on-select="true"
          :multiple="false"
          :query="ADVERTISER_OPTIONS_WITH_ACCOUNTS_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              advertiserLabel: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
              }
            }
          }"
        ></Superselect>
        <Superselect
          title="Advertiser Accounts"
          v-model="filterables"
          :multiple="true"
          :modifiers="filterablesModifiers"
          track-by="id"
          label="label"
          :close-on-select="false"
          :disabled="!advertiserWithAccount"
          :options="
            advertiserWithAccount
              ? advertiserWithAccount.advertiserAccounts
              : []
          "
        ></Superselect>
      </template>
      <TagsSelect
        v-else-if="filterType.name === 'vertical'"
        title="Verticals"
        v-model="filterables"
        :modifiers="filterablesModifiers"
        :filter-types="['vertical']"
        :show-options-type-label="false"
      ></TagsSelect>
      <Superselect
        v-else-if="filterType.name === 'template'"
        title="Templates"
        v-model="filterables"
        :modifiers="filterablesModifiers"
        :multiple="true"
        track-by="id"
        label="label"
        :close-on-select="false"
        :query="TEMPLATES_QUERY"
        :query-variables="{
          first: 20,
          filters: {
            paths: {
              value: ['{input}'],
              modifiers: { matchType: 'contains' }
            }
          }
        }"
        :options="[]"
      ></Superselect>

      <TagsSelect
        v-else-if="filterType.name === 'offerType'"
        title="Offer Type"
        v-model="filterables"
        :modifiers="filterablesModifiers"
        :filter-types="['offerType']"
        :show-options-type-label="false"
      ></TagsSelect>
    </div>
    <div class="border-t flex justify-between  p-4">
      <div></div>
      <div>
        <YButton
          color="blue"
          @click="saveAndExit"
          :disabled="!hasChanges"
          :is-loading="isSaving"
          >Save and Exit</YButton
        >
      </div>
    </div>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </div>
</template>

<script>
import CREATE_RULES_MUTATION from '@/graphql/Rule/CreateRulesMutation.gql'
import CAMPAIGN_OPTIONS_QUERY from '@/graphql/Campaign/CampaignOptionsQuery.gql'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import OFFER_OPTIONS_QUERY from '@/graphql/Offer/OfferOptionsQuery.gql'
import USER_OPTIONS_QUERY from '@/graphql/User/UserOptionsQuery.gql'
import ADVERTISER_OPTIONS_WITH_ACCOUNTS_QUERY from '@/graphql/Advertiser/AdvertiserOptionsWithAccountsQuery.gql'
import TEMPLATES_QUERY from '@/graphql/Template/TemplatesQuery.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import TagsSelect from '@/views/Admin/Tags/TagsSelect'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    defaults: {
      type: Object,
      default: () => ({})
    },
    hide: {
      type: Array,
      default: () => []
    },
    origin: {
      type: String
    }
  },
  apollo: {
    redirectOptions: {
      query: REDIRECT_OPTIONS_QUERY,
      variables: {
        first: 100000
      }
    }
  },
  components: {
    TagsSelect
  },
  data() {
    let filterTypeOptions = [
      {
        label: 'Offer',
        name: 'offer'
      },
      {
        label: 'Advertiser',
        name: 'advertiser'
      },
      {
        label: 'Advertiser Account',
        name: 'advertiserAccount'
      },
      {
        label: 'Vertical',
        name: 'vertical'
      },
      {
        label: 'Template',
        name: 'template'
      },
      {
        label: 'Offer Type',
        name: 'offerType'
      }
    ]

    let filterPublisherRedirectTypeOptions = [
      {
        label: 'Offer',
        name: 'offer'
      },
      {
        label: 'Advertiser',
        name: 'advertiser'
      },
      {
        label: 'Advertiser Account',
        name: 'advertiserAccount'
      },
      {
        label: 'Vertical',
        name: 'vertical'
      },
      {
        label: 'Template',
        name: 'template'
      },
      {
        label: 'Offer Destination Type',
        name: 'offerDestinationType'
      },
      {
        label: 'Offer Type',
        name: 'offerType'
      },
      // {
      //   label: 'Payout Type',
      //   name: 'payoutType'
      // }
    ]

    let offerDestinationTypeOptions = [
      {
        label:"Template",
        id:"template"
      },
      {
        label:"Direct",
        id:"direct"
      },
    ]

    let payoutTypeOptions = [
      {
        label:"cpa",
        id:"cpa"
      },
      {
        label:"revshare",
        id:"revshare"
      },
      {
        label:"cpc",
        id:"cpc"
      },
      {
        label:"ppm",
        id:"ppm",
      }
    ]

    let typeOptions = [
      {
        label: 'Redirect',
        name: 'redirect'
      },
      {
        label: 'Campaign',
        name: 'campaign'
      },
      {
        label: 'Publisher',
        name: 'publisher'
      },
      {
        label: 'Source',
        name: 'source'
      }
    ]

    return {
      TEMPLATES_QUERY,
      CAMPAIGN_OPTIONS_QUERY,
      USER_OPTIONS_QUERY,
      OFFER_OPTIONS_QUERY,
      ADVERTISER_OPTIONS_WITH_ACCOUNTS_QUERY,
      type: this.defaults.type ?? typeOptions[0],
      ruleables: this.defaults.ruleables ?? [],
      typeOptions: typeOptions,
      redirectOptions: {},
      countryTags: [],
      mobileDesktopTags: [],
      mobileDesktopTagsModifiers: { exclude: false },
      countryTagsModifiers: { exclude: false },
      filterType: filterTypeOptions[0],
      filterTypeOptions: filterTypeOptions,
      filterPublisherRedirectTypeOptions: filterPublisherRedirectTypeOptions,
      offerDestinationTypeOptions: offerDestinationTypeOptions,
      payoutTypeOptions: payoutTypeOptions,
      filterablesModifiers: {
        exclude: true,
        edit: false
      },
      filterables: [],
      advertiserWithAccount: null,
      offers: null,
      isSaving: false
    }
  },
  computed: {
    filterableType() {
      return ['vertical', 'offerType'].includes(this.filterType.name)
        ? 'tag'
        : this.filterType.name
    },
    createRulesInput: {
      get() {
        return {
          origin: this.origin,
          ruleableType: this.type.name,
          ruleableIds: this.ruleables.map(ruleable => ruleable.id),
          ruleablePolarity: true,
          filterableType: this.filterableType,
          filterableIds: this.filterables.map(filterable => filterable.id),
          filterablePolarity: !this.filterablesModifiers.exclude,
          syncTagsWithTypes: [
            {
              type: 'countryCode',
              tags: this.countryTags.map(tag => tag.name),
              isNot: this.countryTagsModifiers.exclude
            },
            {
              type: 'mobileDesktop',
              tags: this.mobileDesktopTags.map(tag => tag.name),
              isNot: this.mobileDesktopTagsModifiers.exclude
            }
          ]
        }
      }
    },
    hasChanges() {
      return (
        JSON.stringify(this.createRulesInput) !==
        JSON.stringify(this.initialCreateRulesInput)
      )
    }
  },
  mounted() {
    this.initialCreateRulesInput = this.createRulesInput
  },
  watch: {
    type() {
      this.ruleables = []
    },
    advertiserWithAccount() {
      this.filterables = []
    },
    filterType() {
      this.filterables = []
      this.advertiserWithAccount = null
    },
    isSaving(value) {
      this.$store.dispatch('updateGlobalLoader', value ? 1 : -1)
    }
  },
  beforeDestroy() {
    if (this.isSaving) {
      this.$store.dispatch('updateGlobalLoader', -1)
    }
  },
  methods: {
    saveAndExit() {
      this.isSaving = true
      this.clearValidationErrors()
      this.createRules()
        .then(() => {
          this.$toastr.s('Rules Created', 'Success!')
          this.$events.emit('rulesCreated')
          this.$tabEvent.emit('rulesCreated')
          this.$tabEvent.emit('refreshAlgorithmsData')
          this.$emit('close')
        })
        .catch(error => {
          console.log('caught error')
          this.isSaving = false
          this.setValidationErrors(error)
        })
    },
    async createRules() {
      this.isSaving = true
      return this.$apollo
        .mutate({
          mutation: CREATE_RULES_MUTATION,
          variables: {
            input: this.createRulesInput
          }
        })
        .then(() => {
          this.isSaving = false
        })
    }
  }
}
</script>

<style></style>
