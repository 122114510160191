<template>
  <div v-if="localAdvertiser" class="mt-4">
    <div class="grid grid-cols-3 gap-4 ">
      <!-- primary contact -->
      <div class="col-span-1 ">
        <div class="advertiser-details-card">
          <div class="advertiser-details-title m-2">
            <h3>Primary Contact</h3>
          </div>
          <div class="advertiser-details-table">
            <YInput
              v-model="localAdvertiser.primaryContact.company"
              label="Company Name"
            />
            <YInput
              v-model="localAdvertiser.primaryContact.firstName"
              label="First Name"
            />
            <YInput
              v-model="localAdvertiser.primaryContact.lastName"
              label="Last Name"
            />
            <YInput v-model="localAdvertiser.email" label="Email" disabled />
            <div class="flex gap-4 items-center">
              <YInput
                v-model="messengerDetails.handle"
                label="Handle"
                class="w-full"
              />
              <div class="w-1/2 flex items-center justify-between gap-2">
                <Superselect
                  v-model="messengerDetails.app"
                  title="App"
                  :multiple="false"
                  placeholder="Select"
                  :options="['skype', 'telegram', 'slack']"
                  class="w-full"
                />
                <Icon
                  :name="messengerDetails.app ?? ''"
                  class="w-8 h-8   text-gray-600 inline"
                />
              </div>
            </div>
            <YInput
              v-model="localAdvertiser.primaryContact.homepage"
              label="Homepage"
            />
          </div>

          <div class="w-full flex justify-end">
            <YButton
              color="link"
              class="mt-2"
              @click="openSecondaryContactModal"
              >Merge User</YButton
            >
          </div>
        </div>

        <div class="mt-4 pb-6 border-b">
          <YButton
            color="blue"
            :isLoading="isUpdating"
            @click="updateUser"
            class="w-3/5"
            >Update User Details</YButton
          >
        </div>

        <div
          v-if="filteredSecondaryContacts.length > 0"
          class="advertiser-details-card mt-8  bg-gray-100"
        >
          <div class="advertiser-details-title m-2">
            <h3>Secondary Contacts</h3>
          </div>
          <div>
            <dl class="sm:divide-y sm:divide-gray-200">
              <div
                v-for="contact in filteredSecondaryContacts"
                :key="contact.id"
                class="items-center grid-cols-2"
              >
                <div v-for="[key, value] in Object.entries(contact)" :key="key">
                  <span v-if="key !== '__typename'">
                    <dt class="font-medium text-gray-500">
                      {{
                        key
                          .replace(/([A-Z])/g, ' $1')
                          .replace(/^./, str => str.toUpperCase())
                      }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <span v-if="Array.isArray(value)">
                        {{ value.join(', ') }}
                      </span>
                      <span v-else-if="key == 'country'">
                        {{ value.name }}
                      </span>
                      <span v-else>
                        {{ value }}
                      </span>
                    </dd></span
                  >
                </div>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <!-- billing contact -->
      <div class="col-span-1 ">
        <div class="advertiser-details-card">
          <div class="advertiser-details-title m-2">
            <h3>Billing Contact</h3>
          </div>
          <div class="advertiser-details-table border-b pb-4">
            <YInput
              v-model="localAdvertiser.billingContact.name"
              label="Billing Name"
            />
            <YInput
              v-model="localAdvertiser.billingContact.email"
              label="Billing Email"
            />
            <div class="flex justify-between items-center gap-4">
              <Superselect
                title="Country"
                v-model="localAdvertiser.billingContact.country"
                :multiple="false"
                track-by="code"
                label="label"
                :options="countries"
                class="w-full"
              ></Superselect>
              <Flag
                v-if="localAdvertiser.billingContact?.country"
                v-tooltip="`${localAdvertiser.billingContact.country.label}`"
                :country="localAdvertiser.billingContact.country.code"
                class="rounded-sm overflow-hidden"
                :size="10"
              ></Flag>
            </div>
            <YInput
              v-model="localAdvertiser.billingContact.address"
              label="Address"
            />
            <div class="flex gap-6 justify-between">
              <YInput
                v-model="localAdvertiser.billingContact.city"
                label="City"
                class="w-1/2"
              />
              <YInput
                v-model="localAdvertiser.billingContact.region"
                label="Region"
                class="w-1/2"
              />
            </div>
            <YInput
              v-model="localAdvertiser.billingContact.postalCode"
              label="Postal / Zip"
              class="w-1/2"
            />
          </div>
          <div class="advertiser-details-table pt-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Payment Info
            </h3>
            <div class="flex justify-between gap-6">
              <Superselect
                title="Payment Frequency"
                v-model="localAdvertiser.billingContact.paymentFrequency"
                :multiple="false"
                :options="paymentFrequencyOptions"
                class="w-full"
              ></Superselect>
              <Superselect
                title="Payment Terms"
                v-model="localAdvertiser.billingContact.paymentTerms"
                :multiple="false"
                :options="paymentTermsOptions"
                class="w-full"
              ></Superselect>
            </div>
            <div class="flex justify-between gap-6">
              <Superselect
                title="Tax Type"
                v-model="localAdvertiser.billingContact.taxType"
                :multiple="false"
                :options="taxTypeOptions"
                class="w-full"
                :disabled="
                  localAdvertiser.billingContact.country?.code !== 'CA'
                "
              ></Superselect>
              <YInput
                class="w-full"
                label="Tax Number"
                v-model="localAdvertiser.billingContact.taxNumber"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- user provided details  -->
      <div class=" col-span-1 row-span-2 gap-y-4 flex flex-col">
        <div class="bg-white overflow-hidden shadow rounded-lg border">
          <div class="px-4 py-5 sm:px-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              User Provided Details
            </h3>
          </div>

          <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl class="sm:divide-y sm:divide-gray-200">
              <div v-if="parseUserDetails.brands">
                <dt>
                  Brands
                </dt>
                <dd class="">
                  {{ parseUserDetails.brands ?? '' }}
                </dd>
              </div>
              <div v-if="parseUserDetails.licenses">
                <dt>
                  Licenses
                </dt>
                <dd class="">
                  <ul class="flex space-x-4">
                    <li
                      v-for="licence in parseUserDetails.licenses"
                      :key="licence"
                    >
                      {{ licence }}
                    </li>
                  </ul>
                </dd>
              </div>
              <div v-if="parseUserDetails.budgetRange">
                <dt>
                  Budget Range
                </dt>
                <dd class="">
                  {{
                    parseUserDetails.budgetRange
                      ? '$' + parseUserDetails.budgetRange + 'k'
                      : ''
                  }}
                </dd>
              </div>
              <div>
                <dt>
                  Payment Terms
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 ">
                  {{ parseUserDetails.paymentTerms?.term ?? '' }}
                  {{ parseUserDetails.paymentTerms?.length ?? '' }}
                </dd>
              </div>
              <div>
                <dt>
                  Payment Methods
                </dt>
                <span
                  v-for="(method, index) in parseUserDetails.paymentMethods"
                  :key="index"
                  class="mt-1 text-sm text-gray-900 col-span-2 flex gap-8"
                >
                  <dd>{{ method.type }}</dd>
                  <dd>{{ method.details }}</dd>
                </span>
              </div>
              <div v-if="parseUserDetails.paymentCurrency">
                <dt>
                  Currency
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 ">
                  <ul class="flex space-x-4">
                    <li
                      v-for="currency in parseUserDetails.paymentCurrency"
                      :key="currency"
                    >
                      {{ currency }}
                    </li>
                  </ul>
                </dd>
              </div>
              <div v-if="parseUserDetails.commissionStructure?.length > 0">
                <dt>
                  Commission Structure
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 ">
                  <ul class="flex space-x-4">
                    <li
                      v-for="commission in parseUserDetails.commissionStructure"
                      :key="commission"
                    >
                      {{ commission }}
                    </li>
                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div class="bg-white overflow-hidden shadow rounded-lg border">
          <div class="px-4 py-5 sm:px-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Communication Summary
            </h3>
          </div>
          <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl class="sm:divide-y sm:divide-gray-200">
              <div class="flex flex-row items-center">
                <button
                  @click="openLeadPriorityStatusModal(advertiser)"
                  class="hover:bg-gray-200 p-1 rounded"
                >
                  <Icon
                    :name="
                      priorityIconType(advertiser.userDetails.priorityStatus)
                    "
                    :size="6"
                    :class="
                      priorityIconColor(advertiser.userDetails.priorityStatus)
                    "
                  />
                </button>
                Priority Status:
                <span class="capitalize ml-2">
                  {{
                    advertiser.userDetails.priorityStatus
                      ? advertiser.userDetails.priorityStatus
                      : 'Regular'
                  }}</span
                >
              </div>
              <div class="flex flex-col">
                <dt>Most Recent Comment</dt>

                <dd
                  v-if="advertiser.comments?.length > 0"
                  class="flex flex-col"
                >
                  <div class="text-black">
                    {{ advertiser.comments[0].message }}
                  </div>
                  <div
                    v-if="advertiser.comments[0]?.tags[0]?.name"
                    class="flex justify-between mt-4 mb-2"
                  >
                    <Tag>{{ advertiser.comments[0]?.tags[0]?.name }}</Tag>
                  </div>

                  <div class="flex flow-row justify-between items-center">
                    <div class="flex flex-row items-center">
                      <UserAvatar
                        height="8"
                        :id="advertiser.comments[0].commenter.id"
                      />
                      <p class="font-bold text-gray-800 truncate ml-2">
                        {{
                          advertiser.comments[0].commenter.primaryContact
                            .name ?? ''
                        }}
                      </p>
                    </div>
                    <div>
                      <p class="text-gray-500 text-xs ">
                        {{
                          advertiser.comments[0].createdAt
                            | luxon('MMM-dd, yyyy')
                        }}
                      </p>
                    </div>
                  </div>
                </dd>
                <dd v-else>
                  <p class="mb-8 text-xs text-gray-500">No activity</p>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import PriorityStyleMixin from '@/mixins/PriorityStyleMixin.js'
import PriorityStatusModal from '@/views/Admin/User/PriorityStatusModal.vue'
import MergeSecondaryContactModal from '@/views/Admin/Leads/MergeSecondaryContactModal.vue'
import UPDATE_USER_MUTATION from '@/graphql/User/UpdateUserMutation.gql'

const defaultAdvertiser = {
  id: null,
  email: null,
  primaryContact: {
    id: null,
    firstName: null,
    lastName: null,
    //name: null,
    company: null,
    homepage: null
  },
  billingContact: {
    id: null,
    name: null,
    email: null,
    country: null,
    address: null,
    city: null,
    region: null,
    postalCode: null,

    paymentFrequency: null,
    paymentTerms: null,
    taxType: null,
    taxNumber: null
  }
}

export default {
  mixins: [PriorityStyleMixin],
  components: {},
  props: {
    advertiser: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.localAdvertiser = this.mergeDefaults(
      defaultAdvertiser,
      this.advertiser || {}
    )
  },
  updated() {},
  data() {
    return {
      isUpdating: false,
      countries: [],
      paymentFrequencyOptions: [],
      paymentTermsOptions: [],
      taxTypeOptions: [],
      localAdvertiser: this.mergeDefaults(
        defaultAdvertiser,
        this.advertiser || {}
      ),
      messengerDetails: this.advertiser.primaryContact.messenger
        ? JSON.parse(this.advertiser.primaryContact.messenger)
        : { app: '', handle: '' }
    }
  },
  apollo: {
    countries: {
      query: gql`
        {
          countries {
            code
            name
            label
          }
        }
      `
    },
    paymentFrequencyOptions: {
      query: gql`
        {
          paymentFrequencyOptions: __type(name: "ContactPaymentFrequencyEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        if (data) {
          this.paymentFrequencyOptions = data.paymentFrequencyOptions.enumValues.map(
            option => option.name
          )
        }
      }
    },
    paymentTermsOptions: {
      query: gql`
        {
          paymentTermsOptions: __type(name: "ContactPaymentTermsEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        if (data) {
          this.paymentTermsOptions = data.paymentTermsOptions.enumValues.map(
            option => option.name
          )
        }
      }
    },
    taxTypeOptions: {
      query: gql`
        {
          taxTypeOptions: __type(name: "CanadianTaxTypeEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        if (data) {
          this.taxTypeOptions = data.taxTypeOptions.enumValues.map(
            option => option.name
          )
        }
      }
    }
  },
  methods: {
    updateUser() {
      this.isUpdating = true
      this.$store.dispatch('updateGlobalLoader', 1)
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: {
            input: {
              id: this.localAdvertiser.id,
              contacts: {
                update: [
                  {
                    //primaryContact Details
                    id: this.localAdvertiser.primaryContact.id,
                    firstName:
                      this.localAdvertiser.primaryContact.firstName ||
                      undefined,
                    lastName:
                      this.localAdvertiser.primaryContact.lastName || undefined,
                    name:
                      this.localAdvertiser.primaryContact.company || undefined, //legacy advertisers, companyName was stored in name.
                    company:
                      this.localAdvertiser.primaryContact.company || undefined, //company will match across types
                    messenger:
                      this.messengerDetails.app && this.messengerDetails.handle
                        ? JSON.stringify({
                            handle: this.messengerDetails.handle,
                            app: this.messengerDetails.app
                          })
                        : undefined,
                    homepage: this.localAdvertiser.primaryContact.homepage || []
                  },
                  {
                    //billingContact Details
                    id: this.localAdvertiser.billingContact.id,
                    name: this.localAdvertiser.billingContact.name || undefined,
                    company:
                      this.localAdvertiser.primaryContact.company || undefined, //company will match across types
                    email:
                      this.localAdvertiser.billingContact.email || undefined,
                    countryCode: this.localAdvertiser.billingContact.country
                      ? this.localAdvertiser.billingContact.country.code
                      : undefined,
                    address:
                      this.localAdvertiser.billingContact.address || undefined,
                    city: this.localAdvertiser.billingContact.city || undefined,
                    region:
                      this.localAdvertiser.billingContact.region || undefined,
                    postalCode:
                      this.localAdvertiser.billingContact.postalCode ||
                      undefined,
                    paymentFrequency:
                      this.localAdvertiser.billingContact.paymentFrequency ||
                      undefined,
                    paymentTerms:
                      this.localAdvertiser.billingContact.paymentTerms ||
                      undefined,
                    taxType:
                      this.localAdvertiser.billingContact.taxType || undefined,
                    taxNumber:
                      this.localAdvertiser.billingContact.taxNumber || undefined
                  }
                ]
              }
            }
          }
        })
        .then(() => {
          // Result
          this.isUpdating = false
          this.$store.dispatch('updateGlobalLoader', -1)
          this.$toastr.s('Success')
        })
        .catch(error => {
          // Error
          this.isUpdating = false
          this.$store.dispatch('updateGlobalLoader', -1)
          this.setValidationErrors(error)
        })
    },
    openSecondaryContactModal() {
      this.$modal.show(
        MergeSecondaryContactModal,
        { user: this.advertiser },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    mergeDefaults(defaultObj, sourceObj) {
      if (!sourceObj || typeof sourceObj !== 'object') {
        return defaultObj
      }

      return Object.keys(defaultObj).reduce((acc, key) => {
        if (Object.prototype.hasOwnProperty.call(sourceObj, key)) {
          if (key === 'homepage' && Array.isArray(sourceObj[key])) {
            acc[key] = sourceObj[key].join(', ')
          } else if (
            defaultObj[key] &&
            typeof defaultObj[key] === 'object' &&
            !Array.isArray(defaultObj[key])
          ) {
            acc[key] = this.mergeDefaults(defaultObj[key], sourceObj[key])
          } else {
            acc[key] =
              sourceObj[key] !== undefined ? sourceObj[key] : defaultObj[key]
          }
        } else {
          acc[key] = defaultObj[key]
        }
        return acc
      }, {})
    },

    openLeadPriorityStatusModal(data) {
      this.$modal.show(
        PriorityStatusModal,
        { user: data },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    }
  },
  computed: {
    filteredSecondaryContacts() {
      if (!this.advertiser.secondaryContact) {
        return []
      }
      return this.advertiser.secondaryContact.map(contact => {
        // Filter out keys with null values
        const filteredContact = {}
        for (const key in contact) {
          if (contact[key] !== null) {
            if (key === 'homepage') {
              filteredContact[key] = contact[key]
            }
            if (key == 'messenger') {
              const messenger = JSON.parse(contact[key])
              filteredContact['messengerApp'] = messenger.app
              filteredContact['messengerHandle'] = messenger.handle
            } else {
              filteredContact[key] = contact[key]
            }
          }
        }

        return filteredContact
      })
    },
    parseUserDetails() {
      // user provided data from sign up. Cannot be changed by business team, for display purposes only.
      return JSON.parse(this.advertiser.userDetails.meta) || ''
    }
  }
}
</script>
<style>
.advertiser-details-card {
  @apply bg-white overflow-hidden shadow-sm rounded-lg border px-4 pb-4;
}
.advertiser-details-title {
  @apply py-4 flex justify-between items-center;
}
.advertiser-details-title h3 {
  @apply text-lg leading-6 font-medium text-gray-900;
}
.advertiser-details-table {
  @apply grid grid-cols-1 gap-4;
}
dl > div {
  @apply py-4 grid grid-cols-3 gap-4 px-6;
}
dl > div > dt {
  @apply text-sm font-medium text-gray-500;
}
dl > div > dd {
  @apply mt-1 text-sm text-gray-900 col-span-2;
}
</style>
