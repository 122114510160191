<template>
  <YTable
    :data="rules"
    :bottom-loading="bottomLoading"
    :selectable="true"
    :selected.sync="selectedRowIds"
  >
    <template slot="header-row">
      <th>Rule Origin</th>
      <th></th>
      <th></th>
      <th></th>
      <th colspan="3">Traffic Conditions</th>
      <th></th>
    </template>
    <template slot="secondHeaderRow">
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th>Countries</th>
      <th>Mobile / Desktop</th>
      <th colspan="2"></th>
    </template>
    <template slot="row" slot-scope="props">
      <td
        style="text-wrap-mode: no-wrap; padding: 0;"
        class=" text-gray-800 uppercase"
      >
        <span class="text-xxs">{{
          props.rowData.origin ? props.rowData.origin.replace(/_/g, ' ') : ''
        }}</span>
      </td>

      <td v-if="!showCellBasedOnOrigin(props.rowData.origin)">
        <FilterableItem :filterableItem="props.rowData" />
      </td>

      <td v-if="showCellBasedOnOrigin(props.rowData.origin)">
        <RuleableItem :ruleableItem="props.rowData" />
      </td>

      <td style="padding-left: 0">
        <div class="text-xxs font-semibold text-gray-900 uppercase ">
          <Tag :color="tagPolority(props.rowData) ? 'darkRed' : 'blue'">
            {{ tagPolority(props.rowData) ? 'Blocks' : 'Only Allows' }}</Tag
          >
        </div>
      </td>

      <td v-if="showCellBasedOnOrigin(props.rowData.origin)">
        <FilterableItem :filterableItem="props.rowData" />
      </td>

      <td v-if="!showCellBasedOnOrigin(props.rowData.origin)">
        <RuleableItem :ruleableItem="props.rowData" />
      </td>

      <td>
        <div class="w-40">
          <template
            v-for="tagGroup in props.rowData.tagsByType.filter(
              tagGroup => tagGroup.type == 'countryCode'
            )"
          >
            <template v-for="tag in tagGroup.tags">
              <Tag
                :key="tag.id"
                class="m-1"
                :color="tag.isNot ? 'red' : 'green'"
                >{{ tag.name }}</Tag
              >
            </template>
          </template>
        </div>
      </td>

      <td>
        <template
          v-for="tagGroup in props.rowData.tagsByType.filter(
            tagGroup => tagGroup.type == 'mobileDesktop'
          )"
        >
          <template v-for="tag in tagGroup.tags">
            <Tag
              :key="tag.id"
              class="m-1"
              :color="tag.isNot ? 'red' : 'blue'"
              >{{ tag.name }}</Tag
            >
          </template>
        </template>
      </td>
      <td :class="!props.rowData.filterablePolarity ? 'filter-blocked' : ''">
        <span class="uppercase text-xs font-semibold text-gray-600">
          <template
            v-if="
              props.rowData.filterableType == 'tag' && props.rowData.filterable
            "
          >
            {{ props.rowData.filterable.type }}
          </template>
          <template v-if="props.rowData.filterableType == 'offerDestinationType' ">
            <span v-if="props.rowData.filterablePolarity != 1" class="text-red-500">
             Destination - {{ props.rowData.filterableId }} 
            </span>
            <span v-if="props.rowData.filterablePolarity == 1" class="text-blue-500">
             Destination - {{ props.rowData.filterableId }} 
            </span>
          </template>
          <template v-if="props.rowData.filterableType == 'payoutType' ">
            <span v-if="props.rowData.filterablePolarity != 1" class="text-red-500">
             Payout - {{ props.rowData.filterableId }} 
            </span>
            <span v-if="props.rowData.filterablePolarity == 1" class="text-blue-500">
             Payout - {{ props.rowData.filterableId }} 
            </span>
          </template>
          <template v-else>
            {{ props.rowData.filterableType }}
          </template>
        </span>
        <div v-if="props.rowData.filterableType == 'offer'">
          <router-link
            v-if="props.rowData.filterable"
            :to="{
              name: 'Offer Edit',
              params: { id: props.rowData.filterableId }
            }"
            class="link-wrap"
          >
            <template>{{ props.rowData.filterable.label }}</template>
          </router-link>
          <template v-else>
            <i class="text-gray-500"
              >No record found for ID {{ props.rowData.filterableId }}</i
            >
          </template>
        </div>
        <div v-if="props.rowData.filterableType == 'advertiser'">
          <router-link
            v-if="props.rowData.filterable"
            :to="{
              name: 'Advertiser',
              params: { id: props.rowData.filterableId }
            }"
            class="link-wrap"
          >
            <template>{{ props.rowData.filterable.label }}</template>
          </router-link>
          <template v-else>
            <i class="text-gray-500"
              >No record found for ID {{ props.rowData.filterableId }}</i
            >
          </template>
        </div>
        <div v-if="props.rowData.filterableType == 'advertiserAccount'">
          <router-link
            v-if="props.rowData.filterable"
            :to="{
              name: 'Advertiser',
              params: { id: props.rowData.filterable.userId }
            }"
            class="link-wrap"
          >
            <template>{{ props.rowData.filterable.label }}</template>
          </router-link>
          <template v-else>
            <i class="text-gray-500"
              >No record found for ID {{ props.rowData.filterableId }}</i
            >
          </template>
        </div>
        <div v-if="props.rowData.filterableType == 'template'">
          <div
            v-if="props.rowData.filterable"
            :class="
              props.rowData.filterablePolarity
                ? 'text-blue-600'
                : 'text-red-600'
            "
          >
            {{ props.rowData.filterable.path }}
          </div>
          <template v-else>
            <i class="text-gray-500"
              >No record found for ID {{ props.rowData.filterableId }}</i
            >
          </template>
        </div>
        <div v-if="props.rowData.filterableType == 'tag'">
          <template v-if="props.rowData.filterable">
            <Tag :color="props.rowData.filterablePolarity ? 'blue' : 'red'">{{
              props.rowData.filterable.name
            }}</Tag>
          </template>
          <template v-else>
            <i class="text-gray-500"
              >No record found for Tag ID {{ props.rowData.filterableId }}</i
            >
          </template>
        </div>
      </td>

      <td style="width: 4rem;">
        <div class="flex">
          <v-popover class="p-0 flex items-center mr-2" placement="right">
            <a
              class="tooltip-target inline-block text-gray-400 hover:text-gray-500 mt-1"
              ><Icon name="history"
            /></a>

            <!-- This will be the content of the popover -->
            <template slot="popover">
              <div
                v-if="props.rowData.createdBy"
                class="flex flex-col p-2 text-xs"
              >
                <span class="font-bold">
                  {{
                    props.rowData.createdAt
                      | luxon({
                        output: { format: 'yyyy-MM-dd @ h:mm a', zone: 'local' }
                      })
                  }}
                </span>
                <span class="text-gray-500 italic">
                  {{ props.rowData.createdBy.email }}
                </span>
              </div>
              <div v-else class="text-gray-400 p-2">No History</div>
            </template>
          </v-popover>
          <v-popover offset="2" class="p-0" placement="right">
            <YButton
              class="hover:bg-gray-100 focus:bg-gray-100 outline-none-important text-gray-500 hover:text-blue-600"
            >
              <Icon name="dotsVertical" class="w-3 h-3" />
            </YButton>
            <!-- This will be the content of the popover -->
            <template slot="popover">
              <ul>
                <li>
                  <ClickAndReplace :duration="5000">
                    <YButton class="text-red-500 hover:bg-red-100"
                      >Delete</YButton
                    >
                    <template v-slot:replacement>
                      <YButton
                        class="bg-red-500 text-white hover:bg-red-600"
                        @click="deleteRule(props.rowData.id)"
                        :is-loading="isDeleting"
                        >Confirm Delete</YButton
                      >
                    </template>
                  </ClickAndReplace>
                </li>
              </ul>
            </template>
          </v-popover>
        </div>
      </td>
    </template>
  </YTable>
</template>

<script>
import DELETE_RULE_MUTATION from '@/graphql/Rule/DeleteRuleMutation.gql'
import ClickAndReplace from '@/components/ui/ClickAndReplace'
import RuleableItem from '@/views/Admin/Rules/RuleTableRuleableItems.vue'
import FilterableItem from '@/views/Admin/Rules/RuleTableFilterableItem.vue'

export default {
  apollo: {},
  components: {
    ClickAndReplace,
    RuleableItem,
    FilterableItem
  },
  props: {
    rulesParentComponent: {
      type: String
    },
    rules: {
      type: Array,
      default: () => []
    },
    orderBy: {
      type: Array,
      default: () => []
    },
    bottomLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDeleting: false,
      selectedRowIds: []
    }
  },
  updated() {},
  watch: {
    selectedRowIds: {
      handler(newValue) {
        this.$store.dispatch('rule/updateSelectedRowIds', newValue)
      },
      deep: true
    }
  },
  mounted() {
    this.$events.listen('ruleDeleted', () => {
      this.selectedRowIds = []
    })
  },
  methods: {
    showCellBasedOnOrigin(rowData) {
      return rowData == this.rulesParentComponent
    },
    tagPolority(value) {
      return value.ruleablePolarity
        ? !value.filterablePolarity
        : value.ruleablePolarity
    },
    deleteRule(id) {
      this.isDeleting = true
      this.$store.dispatch('updateGlobalLoader', 1)
      this.$apollo
        .mutate({
          mutation: DELETE_RULE_MUTATION,
          variables: {
            id: id
          }
        })
        .then(() => {
          this.isDeleting = false
          // this.$store.dispatch('rule/invalidateCache')
          this.$store.dispatch('updateGlobalLoader', -1)
          this.$toastr.s('Rule Deleted', 'Success!')
          this.$events.emit('ruleDeleted')
          this.$tabEvent.emit('ruleDeleted')
          this.$tabEvent.emit('refreshAlgorithmsData')
        })
        .catch(error => {
          this.isDeleting = false
          this.$store.dispatch('updateGlobalLoader', -1)
          console.error(error)
        })
    }
  },
  beforeDestroy() {
    if (this.isDeleting == true) {
      this.$store.dispatch('updateGlobalLoader', -1)
    }
    this.$store.commit('rule/RESET_SELECTED_ROW_IDS')
  },
  computed: {}
}
</script>

<style lang="postcss" scoped></style>