import {
  mdiBiohazard,
  mdiBell,
  mdiSync,
  mdiPower,
  mdiShield,
  mdiMenu,
  mdiViewDashboard,
  mdiChartArc,
  mdiAccount,
  mdiAccountMultiple,
  mdiAccountMultipleOutline,
  mdiOffer,
  mdiLink,
  mdiLinkPlus,
  mdiAccountBoxMultiple,
  mdiAccountCancel,
  mdiSourcePull,
  mdiTag,
  mdiTagOff,
  mdiTagPlus,
  mdiTagMultiple,
  mdiTagMultipleOutline,
  mdiEarth,
  mdiHelp,
  mdiHelpCircle,
  mdiDotsHorizontal,
  mdiDotsVertical,
  mdiArrowUp,
  mdiArrowDown,
  mdiArrowLeft,
  mdiArrowRight,
  mdiLoading,
  mdiPencil,
  mdiAlert,
  mdiAlertCircleOutline,
  mdiAlertOctagon,
  mdiProgressCheck,
  mdiEye,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiSwapHorizontal,
  mdiLogoutVariant,
  mdiClose,
  mdiCloseThick,
  mdiCancel,
  mdiExclamationThick,
  mdiExclamation,
  mdiPlus,
  mdiPlusBox,
  mdiPlusBoxOutline,
  mdiMinus,
  mdiMinusBoxOutline,
  mdiEqual,
  mdiArrowRightDropCircleOutline,
  mdiArrowDownDropCircleOutline,
  mdiLayers,
  mdiLayersOff,
  mdiRefresh,
  mdiTrashCan,
  mdiTrashCanOutline,
  mdiAccountEdit,
  mdiFileKey,
  mdiPaperclip,
  mdiArrowDownBold,
  mdiContentCopy,
  mdiFindReplace,
  mdiCheckCircle,
  mdiCheck,
  mdiCheckBold,
  mdiDns,
  mdiFilePlus,
  mdiMenuUp,
  mdiMenuRight,
  mdiMenuDown,
  mdiMenuLeft,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiChevronDoubleUp,
  mdiChevronDown,
  mdiSkipPrevious,
  mdiSkipNext,
  mdiSquareEditOutline,
  mdiFileEdit,
  mdiFileEditOutline,
  mdiVideoInputAntenna,
  mdiDesktopMac,
  mdiFormatLetterMatches,
  mdiCalendar,
  mdiContentSave,
  mdiTarget,
  mdiWrench,
  mdiDownload,
  mdiCurrencyUsd,
  mdiCircleOutline,
  mdiCircleMedium,
  mdiClock,
  mdiClockOutline,
  mdiKey,
  mdiKeyPlus,
  mdiOpenInNew,
  mdiStar,
  mdiMessage,
  mdiAccountQuestion,
  mdiChartLine,
  mdiCodeTags,
  mdiLock,
  mdiLockOpen,
  mdiArrowCollapse,
  mdiArrowExpand,
  mdiHistory,
  mdiFileUpload,
  mdiBrain,
  mdiExpandAll,
  mdiRestore,
  mdiHarddisk,
  mdiCodeBraces,
  //Apps
  mdiTelegram,
  mdiSkype,
  mdiEmail,
  mdiFlag,
  mdiFolderImage,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiToggleSwitch,
  mdiToggleSwitchOff,
  mdiFileDocumentMultiple,
  mdiFileReplace,
  mdiFileRemove,
  mdiCash,
  mdiBlockHelper,
  mdiMapMarkerMultiple,
  mdiGrid,
  mdiFormatListBulleted,
  mdiInformationOutline,
  mdiPauseCircle,
  mdiAlertCircle,
  mdiCircle,
  mdiFormatColorFill,
  mdiBullseye,
  mdiMagnify,
  mdiSlack
} from '@mdi/js'

export default {
  magnify: mdiMagnify,
  biohazard: mdiBiohazard,
  blockHelper: mdiBlockHelper,
  harddisk: mdiHarddisk,
  flag: mdiFlag,
  bell: mdiBell,
  sync: mdiSync,
  power: mdiPower,
  shield: mdiShield,
  restore: mdiRestore,
  menu: mdiMenu,
  arrowDownBold: mdiArrowDownBold,
  paperClip: mdiPaperclip,
  fileKey: mdiFileKey,
  accountEdit: mdiAccountEdit,
  trashCan: mdiTrashCan,
  trashCanOutline: mdiTrashCanOutline,
  viewDashboard: mdiViewDashboard,
  chartArc: mdiChartArc,
  account: mdiAccount,
  accountMultiple: mdiAccountMultiple,
  accountMultipleOutline: mdiAccountMultipleOutline,
  accountBoxMultiple: mdiAccountBoxMultiple,
  accountCancel: mdiAccountCancel,
  offer: mdiOffer,
  link: mdiLink,
  linkPlus: mdiLinkPlus,
  sourcePull: mdiSourcePull,
  tag: mdiTag,
  tagOff: mdiTagOff,
  earth: mdiEarth,
  help: mdiHelp,
  helpCircle: mdiHelpCircle,
  dotsHorizontal: mdiDotsHorizontal,
  dotsVertical: mdiDotsVertical,
  arrowUp: mdiArrowUp,
  arrowDown: mdiArrowDown,
  arrowLeft: mdiArrowLeft,
  arrowRight: mdiArrowRight,
  chevronLeft: mdiChevronLeft,
  chevronRight: mdiChevronRight,
  chevronUp: mdiChevronUp,
  chevronDoubleUp: mdiChevronDoubleUp,
  chevronDown: mdiChevronDown,
  skipPrevious: mdiSkipPrevious,
  skipNext: mdiSkipNext,
  loading: mdiLoading,
  pencil: mdiPencil,
  alert: mdiAlert,
  alertCircle: mdiAlertCircle,
  alertCircleOutline: mdiAlertCircleOutline,
  alertOctagon: mdiAlertOctagon,
  eye: mdiEye,
  eyeOutline: mdiEyeOutline,
  eyeOffOutline: mdiEyeOffOutline,
  logout: mdiLogoutVariant,
  swapHorizontal: mdiSwapHorizontal,
  close: mdiClose,
  closeThick: mdiCloseThick,
  exclamationThick: mdiExclamationThick,
  exclamation: mdiExclamation,
  progressCheck: mdiProgressCheck,
  plusBox: mdiPlusBox,
  plusBoxOutline: mdiPlusBoxOutline,
  plus: mdiPlus,
  minus: mdiMinus,
  minusBoxOutline: mdiMinusBoxOutline,
  equal: mdiEqual,
  tagPlus: mdiTagPlus,
  layers: mdiLayers,
  layersOff: mdiLayersOff,
  cancel: mdiCancel,
  arrowRightDropCircleOutline: mdiArrowRightDropCircleOutline,
  arrowDownDropCircleOutline: mdiArrowDownDropCircleOutline,
  refresh: mdiRefresh,
  contentCopy: mdiContentCopy,
  findReplace: mdiFindReplace,
  checkCircle: mdiCheckCircle,
  check: mdiCheck,
  checkBold: mdiCheckBold,
  dns: mdiDns,
  filePlus: mdiFilePlus,
  squareEditOutline: mdiSquareEditOutline,
  fileEdit: mdiFileEdit,
  fileEditOutline: mdiFileEditOutline,
  tagMultiple: mdiTagMultiple,
  tagMultipleOutline: mdiTagMultipleOutline,
  videoInputAntenna: mdiVideoInputAntenna,
  desktopMac: mdiDesktopMac,
  menuUp: mdiMenuUp,
  menuRight: mdiMenuRight,
  menuDown: mdiMenuDown,
  menuLeft: mdiMenuLeft,
  formatLetterMatches: mdiFormatLetterMatches,
  calendar: mdiCalendar,
  contentSave: mdiContentSave,
  target: mdiTarget,
  wrench: mdiWrench,
  download: mdiDownload,
  currencyUsd: mdiCurrencyUsd,
  circleOutline: mdiCircleOutline,
  circleMedium: mdiCircleMedium,
  clock: mdiClock,
  clockOutline: mdiClockOutline,
  key: mdiKey,
  keyPlus: mdiKeyPlus,
  openInNew: mdiOpenInNew,
  star: mdiStar,
  message: mdiMessage,
  accountQuestion: mdiAccountQuestion,
  chartLine: mdiChartLine,
  codeTags: mdiCodeTags,
  lock: mdiLock,
  lockOpen: mdiLockOpen,
  arrowCollapse: mdiArrowCollapse,
  arrowExpand: mdiArrowExpand,
  history: mdiHistory,
  fileUpload: mdiFileUpload,
  brain: mdiBrain,
  expandAll: mdiExpandAll,
  fileDocumentMultiple: mdiFileDocumentMultiple,
  email: mdiEmail,
  folderImage: mdiFolderImage,
  chevronDoubleLeft: mdiChevronDoubleLeft,
  chevronDoubleRight: mdiChevronDoubleRight,
  toggleSwitchOn: mdiToggleSwitch,
  toggleSwitchOff: mdiToggleSwitchOff,
  fileReplace: mdiFileReplace,
  fileRemove: mdiFileRemove,
  finance: mdiCash,
  mapMarkerMultiple: mdiMapMarkerMultiple,
  grid: mdiGrid,
  list: mdiFormatListBulleted,
  information: mdiInformationOutline,
  pause: mdiPauseCircle,
  circle: mdiCircle,
  colorFill: mdiFormatColorFill,
  bullseye: mdiBullseye,
  codeBraces: mdiCodeBraces,
  // Apps
  telegram: mdiTelegram,
  skype: mdiSkype,
  slack: mdiSlack
}
