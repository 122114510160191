<template>
  <div>
    <span class="uppercase text-xs font-semibold text-gray-600">
      {{ ruleableItem.ruleableType }}
    </span>
    <div v-if="ruleableItem.ruleableType == 'redirect'">
      <template v-if="ruleableItem.ruleable">
        <div>
          <router-link
            :to="{
              name: 'Redirect',
              params: { id: ruleableItem.ruleableId }
            }"
            class="link"
          >
            <template>{{ ruleableItem.ruleable.label }}</template>
          </router-link>
        </div>
        <span class="italic text-xs text-gray-500">{{
          ruleableItem.ruleable.user.label
        }}</span>
      </template>
      <template v-else>
        <i class="text-gray-500"
          >No record found for ID {{ ruleableItem.ruleableId }}</i
        >
      </template>
    </div>
    <div v-if="ruleableItem.ruleableType == 'campaign'">
      <router-link
        v-if="ruleableItem.ruleable"
        :to="{
          name: 'Campaign',
          params: { id: ruleableItem.ruleableId }
        }"
        class="link"
      >
        <template>{{ ruleableItem.ruleable.label }}</template>
      </router-link>
      <template v-else>
        <i class="text-gray-500"
          >No record found for ID {{ ruleableItem.ruleableId }}</i
        >
      </template>
    </div>
    <div v-if="ruleableItem.ruleableType == 'publisher'">
      <router-link
        v-if="ruleableItem.ruleable"
        :to="{
          name: 'User',
          params: { id: ruleableItem.ruleableId }
        }"
        class="link"
      >
        <template>{{ ruleableItem.ruleable.label }}</template>
      </router-link>
      <template v-else>
        <i class="text-gray-500"
          >No record found for ID {{ ruleableItem.ruleableId }}</i
        >
      </template>
    </div>
    <div v-if="ruleableItem.ruleableType == 'source'">
      <router-link
        v-if="ruleableItem.ruleableId"
        :to="{
          name: 'Source',
          params: { id: ruleableItem.ruleableId }
        }"
        class="link"
      >
        <template>{{ ruleableItem.ruleableId }}</template>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ruleableItem: Object
  }
}
</script>
