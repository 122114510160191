<template>
  <div
    class="sidebar fixed md:relative h-full z-20 bg-inverse-900 text-gray-200 none flex transition-all duration-500 ease-in-out flex-col overflow-y-auto"
    :class="isCollapsed === true ? ['x-collapsed'] : ['']"
  >
    <!-- logo -->
    <div class="flex">
      <div class="absolute top-0 right-0 sidebar-toggle">
        <button
          class="x-collapse-button focus:outline-none px-1 py-4 bg-inverse-900 hover:bg-inverse-700 text-gray-200 rounded-full"
          @click="toggleSidebar()"
        >
          <Icon name="chevronLeft" size="5" class="x-collapse-icon"></Icon>
        </button>
      </div>
      <a href="#" class="mx-auto block pt-1">
        <span v-if="me && me.roles.some(x => x.name == 'admin')">
          <img :src="YtrackLogo" class="h-12 block mx-auto mb-6 mt-4" />
        </span>
        <span v-if="me && me.roles.some(x => x.name == 'publisher')">
          <img
            src="@/assets/images/logo/logo_ytz.png"
            class="h-12 block mx-auto mb-6 mt-4"
          />
        </span>

        <!--<img :src="YtrackLogo" class="REAL h-12 block mx-auto mb-6 mt-4" />-->
      </a>
    </div>

    <!-- loading -->
    <Spinner v-if="$apollo.queries.me.loading" color="white" :size="8" />

    <!-- admin view -->
    <div class="py-4 px-3 h-screen">
      <ul v-for="(route, index) in listRoutes" :key="index">
        <span v-if="route.child.length > 0 && route.name != 'Settings'">
          <dropdown-menu
            :title="route.meta.publicName || route.name"
            :height="dropdownHeight(route.child)"
            :parent="route"
            :currentRoute="currentRoute.name"
          >
            <router-link
              :to="{ name: child.name }"
              @click.native="collapseSidebar()"
              :target="child.meta.newTab ? '_blank' : ''"
              v-for="(child, index) in route.child"
              :key="index"
            >
              <li
                class="flex items-center justify-between mb-2 cursor-pointer py-2 pl-4 pr-1 rounded-md hover:bg-inverse-700"
                :class="
                  child.name == currentRoute.name
                    ? ['bg-inverse-500']
                    : ['bg-inverse-900']
                "
              >
                <slot>{{ child.name }}</slot>
              </li>
            </router-link>
          </dropdown-menu>
        </span>
        <span v-if="route.name == 'Settings'">
          <dropdown-menu
            :title="route.meta.publicName || route.name"
            :height="dropdownHeight(route.child)"
            :parent="route"
          >
            <router-link
              :to="{ name: child.name }"
              @click.native="collapseSidebar()"
              :target="child.meta.newTab ? '_blank' : ''"
              v-for="(child, index) in route.child"
              :key="index"
            >
              <li
                class="flex items-center justify-between mb-2 cursor-pointer py-2 pl-4 pr-1 rounded-md hover:bg-inverse-700"
                :class="
                  child.name == currentRoute.name
                    ? ['bg-inverse-500']
                    : ['bg-inverse-900']
                "
              >
                <slot>{{ child.name }}</slot>
              </li>
            </router-link>
          </dropdown-menu>
        </span>

        <span v-if="route.child.length == 0">
          <li class="font-semibold text-sm list-none block">
            <router-link
              :to="{ name: route.name }"
              @click.native="collapseSidebar()"
              :target="route.meta.newTab ? '_blank' : ''"
            >
              <div
                class="flex items-center justify-between mb-2 cursor-pointer py-2 pl-4 pr-1 rounded-md hover:bg-inverse-700"
                :class="
                  route.name == currentRoute.name
                    ? ['bg-inverse-500']
                    : ['bg-inverse-900']
                "
              >
                {{ route.meta.publicName || route.name }}
                <div
                  class="w-full flex justify-start ml-3"
                  v-if="route.name == 'yLeader' && notify == true"
                >
                  <div>
                    <Icon
                      name="alert"
                      class="fill-current mx-auto text-pink-500 w-3 h-3"
                    />
                  </div>
                </div>
              </div>
            </router-link>
          </li>
        </span>
      </ul>
    </div>

    <!-- bottom logo -->
    <div class="flex flex-col items-center mb-6 gap-4">
      <span v-if="me && me.roles.some(x => x.name == 'admin')">
        <img
          src="@/assets/images/logo/allin-logo.png"
          class="h-12 w-auto mb-2"
        />
        <img src="@/assets/images/logo/logo_ytz.png" class="h-12 w-auto" />
      </span>
      <span v-if="me && me.roles.some(x => x.name == 'publisher')">
        <img :src="YtrackLogo" class="h-12 block mx-auto mb-6 mt-4" />
      </span>
    </div>
  </div>
</template>

<script>
import ME_QUERY from '@/graphql/User/MeQuery.gql'
import NOTIFICATION_QUERY from '@/graphql/Yleader/YleaderAlertQuery.gql'
import YtrackLogo from '@/assets/images/logo/logo-ytrack-light.svg'
import DropdownMenu from '@/components/layout/SideBarDropdown.vue'

export default {
  components: {
    DropdownMenu
  },

  props: {
    isCollapsed: {
      type: Boolean
    }
  },
  apollo: {
    me: {
      query: ME_QUERY
    },
    YleaderNotificationCount: {
      query: NOTIFICATION_QUERY
    }
  },
  data() {
    return {
      routes: this.$router.options.routes,
      YtrackLogo: YtrackLogo
    }
  },
  methods: {
    dropdownHeight(value) {
      return 'h-' + value.length * 12
    },
    toggleSidebar() {
      this.$emit('toggleSidebar')
    },
    collapseSidebar() {
      if (!this.isCollapsed && window.innerWidth < 640) {
        this.toggleSidebar()
      }
    },
    adminViewListRoutes(routes) {
      let parentRoutes = routes
        .filter(item => item.meta.parentMenuItem)
        .map(v => ({ ...v, child: [] }))

      routes.map(child => {
        parentRoutes.map(parent => {
          if (parent.name == child.meta.parentMenu) {
            parent.child = [...parent.child, child]
          }
        })
      })

      return parentRoutes
    },
    publisherViewListRoutes(routes) {
      //new terms & conditions take effect on target date
      const targetDate = new Date('2024-07-01')
      const currentDate = new Date()
      const isBeforeTargetDate = currentDate < targetDate

      let parentRoutes = routes.map(x => ({ ...x, child: [] }))

      if (
        this.me.userDetails.agreedToTerms ||
        (this.me.userDetails.legacyAgreedToTerms && isBeforeTargetDate)
      ) {
        return parentRoutes
      } else {
        return parentRoutes.filter(
          route =>
            route.meta.alwaysShow == true &&
            (route.meta.scope == 'all' || route.meta.scope == 'publisher') &&
            route.meta.sidebar
        )
      }
    }
  },
  mounted() {
    this.$events.listen('refreshSideBar', () => {
      this.$apollo.queries.YleaderNotificationCount.refetch()
    })
  },
  computed: {
    listRoutes() {
      var roleType = 'publisher'
      if (typeof this.me !== 'undefined') {
        if (this.me.isAnAdmin == true) {
          roleType = 'admin'
        }
      }
      let routes = this.routes.filter(
        route =>
          (route.meta.scope == 'all' || route.meta.scope == roleType) &&
          route.meta.sidebar
      )

      let finalRoutes
      if (roleType == 'admin' && this.me) {
        finalRoutes = this.adminViewListRoutes(routes)
      }
      if (roleType == 'publisher' && this.me) {
        finalRoutes = this.publisherViewListRoutes(routes)
      }
      return finalRoutes
    },
    notify() {
      var notify = false
      if (typeof this.me !== 'undefined') {
        if (this.me.isAnAdmin == true) {
          if (
            typeof this.YleaderNotificationCount !== undefined &&
            this.YleaderNotificationCount !== '0'
          ) {
            notify = true
          }
        }
      }
      return notify
    },
    currentRoute() {
      return this.$route
    },
    isLoading() {
      return this.$store.state.ui.globalLoader
    }
  }
}
</script>

<style scoped>
.sidebar {
  @apply w-56;
}

.x-collapsed {
  @apply -ml-56;
}

.sidebar-toggle {
  margin-top: 0.4rem;
}

.x-collapse-button {
  transition: 0.3s transform;
}

.x-collapse-button .x-collapse-icon {
  @apply opacity-50;
  transition: 0.3s transform;
}

.x-collapse-button:hover .x-collapse-icon {
  @apply opacity-100;
  transform: translateX(-2px);
}
.x-collapsed .x-collapse-button {
  transform: translateX(-0.5rem);
}
</style>
