<template>
  <span
    class="inline-flex items-center editable-component"
    :class="[
      isLoading ? 'loading' : '',
      hasUnsavedChanges ? 'has-unsaved-changes' : ''
    ]"
  >
    <template v-if="defaultSlotVisible">
      <slot
        :value="internalValue"
        :updateInternalValue="updateInternalValue"
        :hasUnsavedChanges="hasUnsavedChanges"
        :focusOnSave="focusOnSave"
        :focus="focus"
        :unfocus="unfocus"
      >
        <span v-if="type == 'dropdown'" ref="dropdown" class="editable-field">
          <Superselect
            :title="title"
            v-model="internalValue"
            :options="dropdownOptions"
            :class="['editable-field', inputClass]"
            :style="inputStyle"
            :multiple="false"
            :trackby="trackby"
            :label="label"
            @change="updateInternalValue"
            @focus="focus"
            @blur="unfocus"
          />
        </span>
        <span
          v-if="type == 'contentEditable'"
          ref="contentEditable"
          contenteditable="true"
          class="outline-none-important focus:shadow-md hover:bg-yellow-200 focus:bg-yellow-200 editable-field"
          :class="[
            valueClass,
            hasUnsavedChanges ? 'bg-yellow-200' : '',
            inputClass
          ]"
          :style="inputStyle"
          @input="handleInput"
          @keydown.enter="keyDownEnter"
          @focus="toggleFocus"
          @blur="toggleFocus"
          v-text="value"
          :placeholder="placeholder"
        ></span>
        <YCheckbox
          v-else-if="type == 'checkbox'"
          ref="checkbox"
          v-model="internalValue"
          @blur="unfocus"
          :style="inputStyle"
          :class="inputClass"
        ></YCheckbox>
        <ckeditor
          v-else-if="type == 'richText'"
          ref="richText"
          :style="inputStyle"
          :class="inputClass"
          :editor="editor"
          v-model="internalValue"
          @blur="unfocus"
        ></ckeditor>
      </slot>
    </template>
    <template v-if="focusSlotVisible">
      <slot
        name="focusSlot"
        :value="internalValue"
        :updateInternalValue="updateInternalValue"
        :hasUnsavedChanges="hasUnsavedChanges"
        :unfocusOnNoChanges="unfocusOnNoChanges"
        :focusOnSave="focusOnSave"
        :focus="focus"
        :unfocus="unfocus"
      >
      </slot>
    </template>
    <sup v-if="!hasUnsavedChanges && editFocusIconEnabled" @click="toggleFocus"
      ><Icon
        name="pencil"
        :size="3"
        class="ml-1 cursor-pointer"
        :class="[focused ? 'text-blue-600' : 'text-blue-300']"
    /></sup>
    <span v-if="hasUnsavedChanges" class="inline-flex">
      <slot name="actions">
        <Spinner v-if="isLoading" :size="6" color="green" class="ml-1" />
        <span
          v-else
          class="inline-flex items-center rounded-lg shadow-md ml-2 overflow-hidden"
        >
          <span
            ref="saveAction"
            tabindex="0"
            class="cursor-pointer px-1 bg-green-300 hover:bg-green-400 focus:bg-green-400 text-green-700 outline-none-important"
            @click="save"
            @keydown.enter="save"
            ><Icon name="contentSave"
          /></span>
          <span
            tabindex="0"
            class="cursor-pointer bg-red-300 hover:bg-red-400 focus:bg-red-400 text-red-700 px-1 outline-none-important"
            @click="cancel"
            @keydown.enter="save"
            ><Icon name="close"
          /></span>
        </span>
      </slot>
    </span>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </span>
</template>

<script>
import md5 from 'blueimp-md5'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import CKEditor from '@ckeditor/ckeditor5-vue'
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon'

export default {
  components: { ckeditor: CKEditor.component },
  mixins: [ValidationErrorsMixin],
  props: {
    dropdownOptions: {
      type: Array
    },
    trackby: {
      type: String
    },
    label: {
      type: String
    },
    mutation: {
      type: Object,
      required: true
    },
    variables: {
      type: Object,

      required: true
    },
    value: {
      validator: prop =>
        typeof prop === 'string' ||
        typeof prop === 'object' ||
        typeof prop === 'boolean' ||
        typeof prop === 'number' ||
        prop === null,
      required: true
    },
    transformValueForQuery: {
      type: Function,
      default: data => data
    },
    sortOrder: {
      validator: function(value) {
        return ['asc', 'desc'].indexOf(value) !== -1
      }
    },
    sortBy: {
      type: String
      // define key to sort by (for use when value is an array of objects)
    },
    valueClass: {
      type: [String, Array]
      // Deprecated: Use inputClass instead
    },
    inputClass: {
      type: [String, Array]
    },
    inputStyle: {
      type: String
    },
    saveOnEnter: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: 'Add Text'
    },
    type: {
      type: String,
      required: true
    },
    isNumberValue: {
      type: Boolean
    },
    title: {
      type: String
    },
    customGlobalEmit: {
      type: String
    }
  },
  data() {
    return {
      internalValue: this.value,
      isLoading: false,
      focused: false,
      focusSlotVisible: false,
      defaultSlotVisible: true,
      editor: BalloonEditor,
      //enableEditFocusIconFor: ['slot', 'contentEditable', 'richText']
      enableEditFocusIconFor: [] // disabled for now
    }
  },
  computed: {
    sort() {
      if (this.sortOrder === undefined && this.sortBy === undefined) {
        return null
      }

      return {
        order: this.sortOrder,
        sortBy: this.sortBy
      }
    },
    uniqueQueryKey() {
      return md5(JSON.stringify(this.mutation) + JSON.stringify(this.variables))
    },
    editFocusIconEnabled() {
      return this.enableEditFocusIconFor.includes(this.type)
    },
    hasUnsavedChanges() {
      if (typeof this.value === 'object') {
        return (
          JSON.stringify(this.transformValueForQuery(this.value)) !=
          JSON.stringify(this.transformValueForQuery(this.internalValue))
        )
      } else {
        return (
          this.transformValueForQuery(this.value) !=
          this.transformValueForQuery(this.internalValue)
        )
      }
    },
    computedVariables() {
      let queryValueVariable = this.transformValueForQuery(this.internalValue)
      let replaceString = '"{value}"'
      let stringifiedValueVariable = this.isNumberValue
        ? Number(JSON.stringify(queryValueVariable).slice(1, -1))
        : JSON.stringify(queryValueVariable)

      if (this.variables) {
        return JSON.parse(
          JSON.stringify(this.variables).replace(
            replaceString,
            stringifiedValueVariable
          )
        )
      } else {
        return null
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (JSON.stringify(this.internalValue) !== JSON.stringify(newValue)) {
          this.internalValue = newValue
        }
      }
    }
  },
  methods: {
    handleInput(e) {
      this.internalValue = e.target.textContent
      this.$emit('internalValue', this.internalValue)
    },
    updateInternalValue(value) {
      // Handle sorting
      if (this.sort) {
        let sortedValues = value.sort((a, b) => {
          if (this.sort.sortBy) {
            return a[this.sort.sortBy] - b[this.sort.sortBy]
          } else {
            return a - b
          }
        })
        if (this.sort.sortOrder == 'desc') {
          sortedValues = sortedValues.reverse()
        }
        this.internalValue = sortedValues
      }

      this.internalValue = value
      this.$emit('internalValue', this.internalValue)
    },
    toggleCheckbox() {
      if (this.$refs.checkbox) {
        this.$refs.checkbox.toggleValue()
      }
    },
    keyDownEnter(e) {
      if (this.saveOnEnter) {
        e.preventDefault()
        if (this.hasUnsavedChanges) {
          this.save()
        } else {
          this.cancel()
        }
      }
    },
    save() {
      this.isLoading = true
      this.$apollo
        .mutate({
          mutation: this.mutation,
          variables: this.computedVariables
        })
        .then(() => {
          this.isLoading = false
          this.unfocus()
          this.$emit('saved', this.internalValue)
          this.$events.fire(this.customGlobalEmit)
        })
        .catch(error => {
          this.isLoading = false
          this.setValidationErrors(error)
        })
    },
    focus() {
      if (this.$refs.contentEditable) {
        this.$refs.contentEditable.focus()
        this.$refs.contentEditable.textContent =
          this.$refs.contentEditable.textContent + ''
      } else if (this.$refs.checkbox) {
        this.$refs.checkbox.$refs.checkbox.focus()
      } else if (this.$refs.richText) {
        this.$refs.richText.$el.focus()
      } else if (this.$scopedSlots.focusSlot) {
        this.focusSlotVisible = true
        this.defaultSlotVisible = false
      }

      this.focused = true
    },
    unfocus() {
      if (this.$refs.input) {
        this.$refs.input.blur()
      }
      this.focusSlotVisible = false
      this.defaultSlotVisible = true

      this.focused = false
      ///
    },
    focusOnSave() {
      this.$nextTick(function() {
        if (this.$refs.saveAction) {
          this.$refs.saveAction.focus()
        }
      })
    },
    unfocusOnNoChanges() {
      if (!this.hasUnsavedChanges) {
        this.unfocus()
      }
    },
    toggleFocus() {
      this.focused ? this.unfocus() : this.focus()
    },
    cancel() {
      this.internalValue = this.value
      if (this.$refs.contentEditable) {
        this.$refs.contentEditable.textContent = this.value
      }
      this.clearValidationErrors()
      this.unfocus()
    },
    reset() {
      this.cancel()
    }
  }
}
</script>

<style scoped>
.editable-field[placeholder]:empty:before {
  content: attr(placeholder);
  opacity: 0.25;
}

.editable-component.loading {
  opacity: 1;
  transition: opacity 0.5s;

  -webkit-animation: OpacityPulse 0.7s ease infinite;
  -moz-animation: OpacityPulse 0.7s ease infinite;
  animation: OpacityPulse 0.7s ease infinite;
}

@-webkit-keyframes OpacityPulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.6;
  }
}
@-moz-keyframes OpacityPulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes OpacityPulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
