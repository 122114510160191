var render = function render(){
  var _vm$messengerDetails$, _vm$localAdvertiser$b, _vm$localAdvertiser$b2, _vm$parseUserDetails$, _vm$parseUserDetails$2, _vm$parseUserDetails$3, _vm$parseUserDetails$4, _vm$parseUserDetails$5, _vm$parseUserDetails$6, _vm$advertiser$commen, _vm$advertiser$commen2, _vm$advertiser$commen3, _vm$advertiser$commen4, _vm$advertiser$commen5, _vm$advertiser$commen6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.localAdvertiser ? _c('div', {
    staticClass: "mt-4"
  }, [_c('div', {
    staticClass: "grid grid-cols-3 gap-4"
  }, [_c('div', {
    staticClass: "col-span-1"
  }, [_c('div', {
    staticClass: "advertiser-details-card"
  }, [_vm._m(0), _c('div', {
    staticClass: "advertiser-details-table"
  }, [_c('YInput', {
    attrs: {
      "label": "Company Name"
    },
    model: {
      value: _vm.localAdvertiser.primaryContact.company,
      callback: function ($$v) {
        _vm.$set(_vm.localAdvertiser.primaryContact, "company", $$v);
      },
      expression: "localAdvertiser.primaryContact.company"
    }
  }), _c('YInput', {
    attrs: {
      "label": "First Name"
    },
    model: {
      value: _vm.localAdvertiser.primaryContact.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.localAdvertiser.primaryContact, "firstName", $$v);
      },
      expression: "localAdvertiser.primaryContact.firstName"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Last Name"
    },
    model: {
      value: _vm.localAdvertiser.primaryContact.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.localAdvertiser.primaryContact, "lastName", $$v);
      },
      expression: "localAdvertiser.primaryContact.lastName"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Email",
      "disabled": ""
    },
    model: {
      value: _vm.localAdvertiser.email,
      callback: function ($$v) {
        _vm.$set(_vm.localAdvertiser, "email", $$v);
      },
      expression: "localAdvertiser.email"
    }
  }), _c('div', {
    staticClass: "flex gap-4 items-center"
  }, [_c('YInput', {
    staticClass: "w-full",
    attrs: {
      "label": "Handle"
    },
    model: {
      value: _vm.messengerDetails.handle,
      callback: function ($$v) {
        _vm.$set(_vm.messengerDetails, "handle", $$v);
      },
      expression: "messengerDetails.handle"
    }
  }), _c('div', {
    staticClass: "w-1/2 flex items-center justify-between gap-2"
  }, [_c('Superselect', {
    staticClass: "w-full",
    attrs: {
      "title": "App",
      "multiple": false,
      "placeholder": "Select",
      "options": ['skype', 'telegram', 'slack']
    },
    model: {
      value: _vm.messengerDetails.app,
      callback: function ($$v) {
        _vm.$set(_vm.messengerDetails, "app", $$v);
      },
      expression: "messengerDetails.app"
    }
  }), _c('Icon', {
    staticClass: "w-8 h-8 text-gray-600 inline",
    attrs: {
      "name": (_vm$messengerDetails$ = _vm.messengerDetails.app) !== null && _vm$messengerDetails$ !== void 0 ? _vm$messengerDetails$ : ''
    }
  })], 1)], 1), _c('YInput', {
    attrs: {
      "label": "Homepage"
    },
    model: {
      value: _vm.localAdvertiser.primaryContact.homepage,
      callback: function ($$v) {
        _vm.$set(_vm.localAdvertiser.primaryContact, "homepage", $$v);
      },
      expression: "localAdvertiser.primaryContact.homepage"
    }
  })], 1), _c('div', {
    staticClass: "w-full flex justify-end"
  }, [_c('YButton', {
    staticClass: "mt-2",
    attrs: {
      "color": "link"
    },
    on: {
      "click": _vm.openSecondaryContactModal
    }
  }, [_vm._v("Merge User")])], 1)]), _c('div', {
    staticClass: "mt-4 pb-6 border-b"
  }, [_c('YButton', {
    staticClass: "w-3/5",
    attrs: {
      "color": "blue",
      "isLoading": _vm.isUpdating
    },
    on: {
      "click": _vm.updateUser
    }
  }, [_vm._v("Update User Details")])], 1), _vm.filteredSecondaryContacts.length > 0 ? _c('div', {
    staticClass: "advertiser-details-card mt-8 bg-gray-100"
  }, [_vm._m(1), _c('div', [_c('dl', {
    staticClass: "sm:divide-y sm:divide-gray-200"
  }, _vm._l(_vm.filteredSecondaryContacts, function (contact) {
    return _c('div', {
      key: contact.id,
      staticClass: "items-center grid-cols-2"
    }, _vm._l(Object.entries(contact), function (_ref) {
      var key = _ref[0],
        value = _ref[1];
      return _c('div', {
        key: key
      }, [key !== '__typename' ? _c('span', [_c('dt', {
        staticClass: "font-medium text-gray-500"
      }, [_vm._v(" " + _vm._s(key.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase();
      })) + " ")]), _c('dd', {
        staticClass: "mt-1 text-sm text-gray-900"
      }, [Array.isArray(value) ? _c('span', [_vm._v(" " + _vm._s(value.join(', ')) + " ")]) : key == 'country' ? _c('span', [_vm._v(" " + _vm._s(value.name) + " ")]) : _c('span', [_vm._v(" " + _vm._s(value) + " ")])])]) : _vm._e()]);
    }), 0);
  }), 0)])]) : _vm._e()]), _c('div', {
    staticClass: "col-span-1"
  }, [_c('div', {
    staticClass: "advertiser-details-card"
  }, [_vm._m(2), _c('div', {
    staticClass: "advertiser-details-table border-b pb-4"
  }, [_c('YInput', {
    attrs: {
      "label": "Billing Name"
    },
    model: {
      value: _vm.localAdvertiser.billingContact.name,
      callback: function ($$v) {
        _vm.$set(_vm.localAdvertiser.billingContact, "name", $$v);
      },
      expression: "localAdvertiser.billingContact.name"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Billing Email"
    },
    model: {
      value: _vm.localAdvertiser.billingContact.email,
      callback: function ($$v) {
        _vm.$set(_vm.localAdvertiser.billingContact, "email", $$v);
      },
      expression: "localAdvertiser.billingContact.email"
    }
  }), _c('div', {
    staticClass: "flex justify-between items-center gap-4"
  }, [_c('Superselect', {
    staticClass: "w-full",
    attrs: {
      "title": "Country",
      "multiple": false,
      "track-by": "code",
      "label": "label",
      "options": _vm.countries
    },
    model: {
      value: _vm.localAdvertiser.billingContact.country,
      callback: function ($$v) {
        _vm.$set(_vm.localAdvertiser.billingContact, "country", $$v);
      },
      expression: "localAdvertiser.billingContact.country"
    }
  }), (_vm$localAdvertiser$b = _vm.localAdvertiser.billingContact) !== null && _vm$localAdvertiser$b !== void 0 && _vm$localAdvertiser$b.country ? _c('Flag', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: `${_vm.localAdvertiser.billingContact.country.label}`,
      expression: "`${localAdvertiser.billingContact.country.label}`"
    }],
    staticClass: "rounded-sm overflow-hidden",
    attrs: {
      "country": _vm.localAdvertiser.billingContact.country.code,
      "size": 10
    }
  }) : _vm._e()], 1), _c('YInput', {
    attrs: {
      "label": "Address"
    },
    model: {
      value: _vm.localAdvertiser.billingContact.address,
      callback: function ($$v) {
        _vm.$set(_vm.localAdvertiser.billingContact, "address", $$v);
      },
      expression: "localAdvertiser.billingContact.address"
    }
  }), _c('div', {
    staticClass: "flex gap-6 justify-between"
  }, [_c('YInput', {
    staticClass: "w-1/2",
    attrs: {
      "label": "City"
    },
    model: {
      value: _vm.localAdvertiser.billingContact.city,
      callback: function ($$v) {
        _vm.$set(_vm.localAdvertiser.billingContact, "city", $$v);
      },
      expression: "localAdvertiser.billingContact.city"
    }
  }), _c('YInput', {
    staticClass: "w-1/2",
    attrs: {
      "label": "Region"
    },
    model: {
      value: _vm.localAdvertiser.billingContact.region,
      callback: function ($$v) {
        _vm.$set(_vm.localAdvertiser.billingContact, "region", $$v);
      },
      expression: "localAdvertiser.billingContact.region"
    }
  })], 1), _c('YInput', {
    staticClass: "w-1/2",
    attrs: {
      "label": "Postal / Zip"
    },
    model: {
      value: _vm.localAdvertiser.billingContact.postalCode,
      callback: function ($$v) {
        _vm.$set(_vm.localAdvertiser.billingContact, "postalCode", $$v);
      },
      expression: "localAdvertiser.billingContact.postalCode"
    }
  })], 1), _c('div', {
    staticClass: "advertiser-details-table pt-4"
  }, [_c('h3', {
    staticClass: "text-lg leading-6 font-medium text-gray-900"
  }, [_vm._v(" Payment Info ")]), _c('div', {
    staticClass: "flex justify-between gap-6"
  }, [_c('Superselect', {
    staticClass: "w-full",
    attrs: {
      "title": "Payment Frequency",
      "multiple": false,
      "options": _vm.paymentFrequencyOptions
    },
    model: {
      value: _vm.localAdvertiser.billingContact.paymentFrequency,
      callback: function ($$v) {
        _vm.$set(_vm.localAdvertiser.billingContact, "paymentFrequency", $$v);
      },
      expression: "localAdvertiser.billingContact.paymentFrequency"
    }
  }), _c('Superselect', {
    staticClass: "w-full",
    attrs: {
      "title": "Payment Terms",
      "multiple": false,
      "options": _vm.paymentTermsOptions
    },
    model: {
      value: _vm.localAdvertiser.billingContact.paymentTerms,
      callback: function ($$v) {
        _vm.$set(_vm.localAdvertiser.billingContact, "paymentTerms", $$v);
      },
      expression: "localAdvertiser.billingContact.paymentTerms"
    }
  })], 1), _c('div', {
    staticClass: "flex justify-between gap-6"
  }, [_c('Superselect', {
    staticClass: "w-full",
    attrs: {
      "title": "Tax Type",
      "multiple": false,
      "options": _vm.taxTypeOptions,
      "disabled": ((_vm$localAdvertiser$b2 = _vm.localAdvertiser.billingContact.country) === null || _vm$localAdvertiser$b2 === void 0 ? void 0 : _vm$localAdvertiser$b2.code) !== 'CA'
    },
    model: {
      value: _vm.localAdvertiser.billingContact.taxType,
      callback: function ($$v) {
        _vm.$set(_vm.localAdvertiser.billingContact, "taxType", $$v);
      },
      expression: "localAdvertiser.billingContact.taxType"
    }
  }), _c('YInput', {
    staticClass: "w-full",
    attrs: {
      "label": "Tax Number"
    },
    model: {
      value: _vm.localAdvertiser.billingContact.taxNumber,
      callback: function ($$v) {
        _vm.$set(_vm.localAdvertiser.billingContact, "taxNumber", $$v);
      },
      expression: "localAdvertiser.billingContact.taxNumber"
    }
  })], 1)])])]), _c('div', {
    staticClass: "col-span-1 row-span-2 gap-y-4 flex flex-col"
  }, [_c('div', {
    staticClass: "bg-white overflow-hidden shadow rounded-lg border"
  }, [_vm._m(3), _c('div', {
    staticClass: "border-t border-gray-200 px-4 py-5 sm:p-0"
  }, [_c('dl', {
    staticClass: "sm:divide-y sm:divide-gray-200"
  }, [_vm.parseUserDetails.brands ? _c('div', [_c('dt', [_vm._v(" Brands ")]), _c('dd', {}, [_vm._v(" " + _vm._s((_vm$parseUserDetails$ = _vm.parseUserDetails.brands) !== null && _vm$parseUserDetails$ !== void 0 ? _vm$parseUserDetails$ : '') + " ")])]) : _vm._e(), _vm.parseUserDetails.licenses ? _c('div', [_c('dt', [_vm._v(" Licenses ")]), _c('dd', {}, [_c('ul', {
    staticClass: "flex space-x-4"
  }, _vm._l(_vm.parseUserDetails.licenses, function (licence) {
    return _c('li', {
      key: licence
    }, [_vm._v(" " + _vm._s(licence) + " ")]);
  }), 0)])]) : _vm._e(), _vm.parseUserDetails.budgetRange ? _c('div', [_c('dt', [_vm._v(" Budget Range ")]), _c('dd', {}, [_vm._v(" " + _vm._s(_vm.parseUserDetails.budgetRange ? '$' + _vm.parseUserDetails.budgetRange + 'k' : '') + " ")])]) : _vm._e(), _c('div', [_c('dt', [_vm._v(" Payment Terms ")]), _c('dd', {
    staticClass: "mt-1 text-sm text-gray-900 sm:mt-0"
  }, [_vm._v(" " + _vm._s((_vm$parseUserDetails$2 = (_vm$parseUserDetails$3 = _vm.parseUserDetails.paymentTerms) === null || _vm$parseUserDetails$3 === void 0 ? void 0 : _vm$parseUserDetails$3.term) !== null && _vm$parseUserDetails$2 !== void 0 ? _vm$parseUserDetails$2 : '') + " " + _vm._s((_vm$parseUserDetails$4 = (_vm$parseUserDetails$5 = _vm.parseUserDetails.paymentTerms) === null || _vm$parseUserDetails$5 === void 0 ? void 0 : _vm$parseUserDetails$5.length) !== null && _vm$parseUserDetails$4 !== void 0 ? _vm$parseUserDetails$4 : '') + " ")])]), _c('div', [_c('dt', [_vm._v(" Payment Methods ")]), _vm._l(_vm.parseUserDetails.paymentMethods, function (method, index) {
    return _c('span', {
      key: index,
      staticClass: "mt-1 text-sm text-gray-900 col-span-2 flex gap-8"
    }, [_c('dd', [_vm._v(_vm._s(method.type))]), _c('dd', [_vm._v(_vm._s(method.details))])]);
  })], 2), _vm.parseUserDetails.paymentCurrency ? _c('div', [_c('dt', [_vm._v(" Currency ")]), _c('dd', {
    staticClass: "mt-1 text-sm text-gray-900 sm:mt-0"
  }, [_c('ul', {
    staticClass: "flex space-x-4"
  }, _vm._l(_vm.parseUserDetails.paymentCurrency, function (currency) {
    return _c('li', {
      key: currency
    }, [_vm._v(" " + _vm._s(currency) + " ")]);
  }), 0)])]) : _vm._e(), ((_vm$parseUserDetails$6 = _vm.parseUserDetails.commissionStructure) === null || _vm$parseUserDetails$6 === void 0 ? void 0 : _vm$parseUserDetails$6.length) > 0 ? _c('div', [_c('dt', [_vm._v(" Commission Structure ")]), _c('dd', {
    staticClass: "mt-1 text-sm text-gray-900 sm:mt-0"
  }, [_c('ul', {
    staticClass: "flex space-x-4"
  }, _vm._l(_vm.parseUserDetails.commissionStructure, function (commission) {
    return _c('li', {
      key: commission
    }, [_vm._v(" " + _vm._s(commission) + " ")]);
  }), 0)])]) : _vm._e()])])]), _c('div', {
    staticClass: "bg-white overflow-hidden shadow rounded-lg border"
  }, [_vm._m(4), _c('div', {
    staticClass: "border-t border-gray-200 px-4 py-5 sm:p-0"
  }, [_c('dl', {
    staticClass: "sm:divide-y sm:divide-gray-200"
  }, [_c('div', {
    staticClass: "flex flex-row items-center"
  }, [_c('button', {
    staticClass: "hover:bg-gray-200 p-1 rounded",
    on: {
      "click": function ($event) {
        return _vm.openLeadPriorityStatusModal(_vm.advertiser);
      }
    }
  }, [_c('Icon', {
    class: _vm.priorityIconColor(_vm.advertiser.userDetails.priorityStatus),
    attrs: {
      "name": _vm.priorityIconType(_vm.advertiser.userDetails.priorityStatus),
      "size": 6
    }
  })], 1), _vm._v(" Priority Status: "), _c('span', {
    staticClass: "capitalize ml-2"
  }, [_vm._v(" " + _vm._s(_vm.advertiser.userDetails.priorityStatus ? _vm.advertiser.userDetails.priorityStatus : 'Regular'))])]), _c('div', {
    staticClass: "flex flex-col"
  }, [_c('dt', [_vm._v("Most Recent Comment")]), ((_vm$advertiser$commen = _vm.advertiser.comments) === null || _vm$advertiser$commen === void 0 ? void 0 : _vm$advertiser$commen.length) > 0 ? _c('dd', {
    staticClass: "flex flex-col"
  }, [_c('div', {
    staticClass: "text-black"
  }, [_vm._v(" " + _vm._s(_vm.advertiser.comments[0].message) + " ")]), (_vm$advertiser$commen2 = _vm.advertiser.comments[0]) !== null && _vm$advertiser$commen2 !== void 0 && (_vm$advertiser$commen3 = _vm$advertiser$commen2.tags[0]) !== null && _vm$advertiser$commen3 !== void 0 && _vm$advertiser$commen3.name ? _c('div', {
    staticClass: "flex justify-between mt-4 mb-2"
  }, [_c('Tag', [_vm._v(_vm._s((_vm$advertiser$commen4 = _vm.advertiser.comments[0]) === null || _vm$advertiser$commen4 === void 0 ? void 0 : (_vm$advertiser$commen5 = _vm$advertiser$commen4.tags[0]) === null || _vm$advertiser$commen5 === void 0 ? void 0 : _vm$advertiser$commen5.name))])], 1) : _vm._e(), _c('div', {
    staticClass: "flex flow-row justify-between items-center"
  }, [_c('div', {
    staticClass: "flex flex-row items-center"
  }, [_c('UserAvatar', {
    attrs: {
      "height": "8",
      "id": _vm.advertiser.comments[0].commenter.id
    }
  }), _c('p', {
    staticClass: "font-bold text-gray-800 truncate ml-2"
  }, [_vm._v(" " + _vm._s((_vm$advertiser$commen6 = _vm.advertiser.comments[0].commenter.primaryContact.name) !== null && _vm$advertiser$commen6 !== void 0 ? _vm$advertiser$commen6 : '') + " ")])], 1), _c('div', [_c('p', {
    staticClass: "text-gray-500 text-xs"
  }, [_vm._v(" " + _vm._s(_vm._f("luxon")(_vm.advertiser.comments[0].createdAt, 'MMM-dd, yyyy')) + " ")])])])]) : _c('dd', [_c('p', {
    staticClass: "mb-8 text-xs text-gray-500"
  }, [_vm._v("No activity")])])])])])])])])]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "advertiser-details-title m-2"
  }, [_c('h3', [_vm._v("Primary Contact")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "advertiser-details-title m-2"
  }, [_c('h3', [_vm._v("Secondary Contacts")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "advertiser-details-title m-2"
  }, [_c('h3', [_vm._v("Billing Contact")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-4 py-5 sm:px-6"
  }, [_c('h3', {
    staticClass: "text-lg leading-6 font-medium text-gray-900"
  }, [_vm._v(" User Provided Details ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-4 py-5 sm:px-6"
  }, [_c('h3', {
    staticClass: "text-lg leading-6 font-medium text-gray-900"
  }, [_vm._v(" Communication Summary ")])]);

}]

export { render, staticRenderFns }