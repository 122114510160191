<template>
  <Widget class="bg-red-600 text-white border-red-700">
    <WidgetHeader class="bg-red-600 text-white"
      ><span class="text-white"
        >Are you sure you want to delete {{ ids.length }} payout<span
          v-if="ids.length > 1"
          >s</span
        >?</span
      ></WidgetHeader
    >
    <WidgetBody>
      <h5 class="text-yellow-400 font-bold">
        This action cannot be undone.
      </h5>
    </WidgetBody>
    <WidgetFooter
      class="bg-dark-transparent-1 flex justify-end gap-6 py-2 px-4"
    >
      <YButton
        class="border hover:bg-red-500 focus:bg-red-700"
        @click="$emit('close')"
        >Cancel</YButton
      >
      <YButton
        class="bg-red-700 hover:bg-red-800 border focus:bg-red-700"
        @click="deletePayout"
        >Delete</YButton
      >
    </WidgetFooter>
  </Widget>
</template>

<script>
import DELETE_PAYOUTS_MUTATION from '@/graphql/Payout/DeletePayoutsMutation.gql'

export default {
  props: {
    ids: {
      type: Array,
      required: true
    }
  },
  components: {},
  apollo: {},
  data() {
    return {
      isSubmitting: false
    }
  },
  watch: {},
  computed: {
    //id() {
    //  return this.payout.id
    //},
    //type() {
    //  return this.payout.type
    //},
    //conversionType() {
    //  return this.payout.conversionType?.name ?? ''
    //},
    //country() {
    //  return this.payout.country?.name ?? 'All Countries'
    //},
    //rate() {
    //  return this.payout.rate
    //}
  },
  methods: {
    deletePayout() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: DELETE_PAYOUTS_MUTATION,
          variables: {
            ids: this.ids
          }
        })
        .then(() => {
          this.$toastr.s('Payout Deleted', 'Success!')
          this.isSubmitting = false
          this.$events.emit('refetchPayouts')
          this.$tabEvent.emit('refetchPayouts')

          this.$emit('success')
          this.$emit('close')
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          console.log(error)
          this.isSubmitting = false
        })
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.text-opacity-light {
  opacity: 0.7;
}
.bg-danger .text-warning {
  color: #ffd800 !important;
}
.bg-dark-transparent-1 {
  background: rgba(0, 0, 0, 0.1);
}
</style>
