<template>
  <div class="block md:flex items-center" role="group">
    <VueCtkDateTimePicker
      id="date-preset-picker"
      v-model="mutableValue"
      format="YYYY-MM-DD hh:mm a"
      formatted="YYYY-MM-DD hh:mm a"
      :range="true"
      :no-value-to-custom-elem="true"
      :no-header="true"
      :no-button="true"
      :auto-close="true"
      color="#0e76bc"
      button-color="#41b883"
      :custom-shortcuts="ranges"
      ref="input"
    >
      <YButton
        type="button"
        class="btn-inverse mb-4 md:mb-0 mr-0 flex items-center justify-center"
      >
        <Icon name="calendar" :size="4" class="inline-flex" />
        <span class="inline ml-2">Date Range</span>
      </YButton>
    </VueCtkDateTimePicker>
    <div class="flex items-center flex-wrap md:flex-no-wrap">
      <VueCtkDateTimePicker
        v-model="startTime"
        locale="en"
        label="Start Date"
        :no-button-now="true"
        format="YYYY-MM-DD hh:mm a"
        formatted="llll"
        color="#0e76bc"
        button-color="#41b883"
        :key="startTimeKey"
      >
        <YButton
          class="btn-white mb-2 md:mb-0 md:ml-2 nowrap outline-none-important hover:bg-gray-100 rounded md:rounded-r-none"
        >
          Start Time
        </YButton>
      </VueCtkDateTimePicker>
      <VueCtkDateTimePicker
        v-model="endTime"
        locale="en"
        label="End Date"
        :no-button-now="true"
        format="YYYY-MM-DD hh:mm a"
        formatted="llll"
        color="#0e76bc"
        button-color="#41b883"
        :key="endTimeKey"
      >
        <YButton
          class="btn btn-white nowrap outline-none-important rounded md:rounded-l-none"
        >
          End Time
        </YButton>
      </VueCtkDateTimePicker>
    </div>
  </div>
</template>

<script>
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

export default {
  components: {},
  props: {
    value: {
      type: Object
    },
    ranges: {
      type: Array,
      default: function() {
        let customRanges = [
          { key: 'today', label: 'Today', value: 'day', isSelected: true },
          {
            key: 'yesterday',
            label: 'Yesterday',
            value: '-day',
            isSelected: false
          },
          {
            key: 'weekToDate',
            label: 'Week to Date',
            value: 'week',
            isSelected: false
          },
          {
            key: 'monthToDate',
            label: 'Month to Date',
            value: 'month',
            isSelected: false
          },
          {
            key: 'last7Days',
            label: 'Last 7 days',
            value: 7,
            isSelected: false
          },
          {
            key: 'last14Days',
            label: 'Last 14 days',
            value: 14,
            isSelected: false
          },
          {
            key: 'last30days',
            label: 'Last 30 days',
            value: 30,
            isSelected: false
          },
          {
            key: 'lastWeek',
            label: 'Last Week',
            value: '-week',
            isSelected: false
          },
          {
            key: 'lastMonth',
            label: 'Last Month',
            value: '-month',
            isSelected: false
          },
          { key: 'thisYear', label: 'Year to Date', value: 'year' },
          {
            key: 'currentQuarter',
            label: 'Current Quarter',
            value: 'quarter'
          },
          {
            key: 'previousYear',
            label: 'Previous Year',
            value: '-year',
            isSelected: false
          }
        ]
        return customRanges
      }
    }
  },

  data() {
    return {
      startTimeKey: 'startTimeKey',
      endTimeKey: 'endTimeKey'
    }
  },
  computed: {
    startTime: {
      get: function() {
        return this.mutableValue.start
      },
      set: function(value) {
        this.mutableValue.start = value
      }
    },
    endTime: {
      get: function() {
        return this.mutableValue.end
      },
      set: function(value) {
        this.mutableValue.end = value
      }
    },
    mutableValue: {
      get() {
        return this.value
      },
      set(value) {
        delete value.shortcut
        return this.updateValue(value)
      }
    }
  },
  watch: {
    mutableValue: function() {
      this.startTimeKey = Math.random()
      this.endTimeKey = Math.random()
    }
  },
  mounted() {},
  updated() {
    console.log('start time', this.startTime, 'end time', this.endTime)
  },
  methods: {
    prepareComponent() {},
    updateValue: function(value) {
      this.$emit('input', value)
    }
  }
}
</script>
<style scoped>
@screen md {
  .date-time-picker {
    width: 100%;
  }
  .date-time-picker > .btn {
    width: 100%;
  }
}
</style>
<style>
.shortcuts-container {
  width: 154px !important;
  max-width: 154px !important;
  min-width: 154px !important;
}
</style>
