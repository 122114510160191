<template>
  <div class="w-full">
    <div class="grid grid-cols-1 sm:grid-cols-5 gap-1">
      <YInput v-model="filters.id.value" label="ID"></YInput>
      <YInput
        v-model="filters.company.value"
        label="Advertiser"
        tooltip="Searches primary, billing, secondary contacts"
      ></YInput>

      <Superselect
        title="Tracking Platform"
        v-model="filters.trackingPlatform.value"
        label="name"
        track-by="name"
        :query="TAGS_QUERY"
        :query-variables="{
          filters: {
            type: { value: ['trackingPlatform'] }
          },
          first: 100
        }"
      ></Superselect>
      <Superselect
        v-model="filters.status.value"
        title="Status"
        :multiple="false"
        placeholder="Select"
        :options="['Active', 'Lead']"
        class="w-full"
      />
      <Superselect
        title="Manager"
        v-model="filters.manager.value"
        :multiple="false"
        track-by="id"
        label="label"
        :query="ACCOUNT_MANAGER_OPTIONS_QUERY"
        :query-variables="{
          filters: {
            label: {
              value: [],
              modifiers: { matchType: 'contains' }
            }
          },
          first: 25
        }"
      ></Superselect>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ACCOUNT_MANAGER_OPTIONS_QUERY from '@/graphql/AccountManager/AccountManagerOptionsQuery.gql'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'

export default {
  data() {
    return {
      ACCOUNT_MANAGER_OPTIONS_QUERY,
      TAGS_QUERY,
      orderBy: {
        column: 'created_at',
        order: 'DESC',
        label: 'Newest'
      },
      orderByOptions: [
        {
          label: 'Newest',
          column: 'created_at',
          order: 'DESC'
        },
        {
          label: 'Oldest',
          column: 'created_at',
          order: 'ASC'
        }
      ]
    }
  },
  methods: {},
  computed: {
    ...mapState({
      filters: state => state.advertiser.filters
    })
  }
}
</script>

<style></style>
