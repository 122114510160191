<template>
  <YTable
    :data="rules"
    :bottom-loading="bottomLoading"
    :selectable="true"
    :selected.sync="selectedRowIds"
  >
    <template slot="header-row">
      <th>Rule Origin</th>
      <th></th>
      <th></th>
      <th></th>
      <th colspan="2">Traffic Conditions</th>
      <th></th>
    </template>
    <template slot="secondHeaderRow">
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th><span class="text-gray-500">Countries</span></th>
      <th><span class="text-gray-500">Mobile / Desktop</span></th>
      <th></th>
    </template>
    <template slot="row" slot-scope="props">
      <td
        style="text-wrap-mode: no-wrap; padding: 0;"
        class=" text-gray-800 uppercase "
      >
        <span class="text-xxs">{{
          props.rowData.origin ? props.rowData.origin.replace(/_/g, ' ') : ''
        }}</span>
      </td>

      <td v-if="!showCellBasedOnOrigin(props.rowData.origin)">
        <RuleableItem :ruleableItem="props.rowData" />
      </td>

      <td v-if="showCellBasedOnOrigin(props.rowData.origin)">
        <FilterableItem :filterableItem="props.rowData" />
      </td>

      <td style="padding-left: 0">
        <div class="text-xxs font-semibold text-gray-900 uppercase ">
          <Tag :color="props.rowData.ruleablePolarity ? 'darkRed' : 'blue'">
            {{ props.rowData.ruleablePolarity ? 'Blocks' : 'Only Allows' }}
          </Tag>
        </div>
      </td>

      <td v-if="showCellBasedOnOrigin(props.rowData.origin)">
        <RuleableItem :ruleableItem="props.rowData" />
      </td>

      <td v-if="!showCellBasedOnOrigin(props.rowData.origin)">
        <FilterableItem :filterableItem="props.rowData" />
      </td>

      <td>
        <template
          v-for="tagGroup in props.rowData.tagsByType.filter(
            tagGroup => tagGroup.type == 'countryCode'
          )"
        >
          <template v-for="tag in tagGroup.tags">
            <Tag
              :key="tag.id"
              class="m-1"
              :color="tag.isNot ? 'red' : 'blue'"
              >{{ tag.name }}</Tag
            >
          </template>
        </template>
      </td>
      <td>
        <template
          v-for="tagGroup in props.rowData.tagsByType.filter(
            tagGroup => tagGroup.type == 'mobileDesktop'
          )"
        >
          <template v-for="tag in tagGroup.tags">
            <Tag
              :key="tag.id"
              class="m-1"
              :color="tag.isNot ? 'red' : 'blue'"
              >{{ tag.name }}</Tag
            >
          </template>
        </template>
      </td>
      <td style="width: 4rem;">
        <div class="flex">
          <v-popover class="p-0 flex items-center mr-2" placement="right">
            <a
              class="tooltip-target inline-block text-gray-400 hover:text-gray-500 mt-1"
              ><Icon name="history"
            /></a>

            <template slot="popover">
              <div
                v-if="props.rowData.createdBy"
                class="flex flex-col p-2 text-xs"
              >
                <span class="font-bold">
                  {{
                    props.rowData.createdAt
                      | luxon({
                        output: { format: 'yyyy-MM-dd @ h:mm a', zone: 'local' }
                      })
                  }}
                </span>
                <span class="text-gray-500 italic">
                  {{ props.rowData.createdBy.email }}
                </span>
              </div>
              <div v-else class="text-gray-400 p-2">No History</div>
            </template>
          </v-popover>
          <v-popover offset="2" class="p-0" placement="right">
            <YButton
              class="hover:bg-gray-100 focus:bg-gray-100 outline-none-important text-gray-500 hover:text-blue-600"
            >
              <Icon name="dotsVertical" class="w-3 h-3" />
            </YButton>

            <template slot="popover">
              <ul>
                <li>
                  <router-link
                    :to="{
                      name: 'Edit Rule',
                      params: { id: props.rowData.id }
                    }"
                  >
                    <YButton
                      class="text-blue-600 hover:text-blue-800 truncate w-full"
                      >Edit</YButton
                    >
                  </router-link>
                </li>
                <li>
                  <ClickAndReplace :duration="5000">
                    <YButton class="text-red-500 hover:bg-red-100"
                      >Delete</YButton
                    >
                    <template v-slot:replacement>
                      <YButton
                        class="bg-red-500 text-white hover:bg-red-600"
                        @click="deleteRule(props.rowData.id)"
                        :is-loading="isDeleting"
                        >Confirm Delete</YButton
                      >
                    </template>
                  </ClickAndReplace>
                </li>
              </ul>
            </template>
          </v-popover>
        </div>
      </td>

      <!--  -->
    </template>
  </YTable>
</template>

<script>
import DELETE_RULE_MUTATION from '@/graphql/Rule/DeleteRuleMutation.gql'
import ClickAndReplace from '@/components/ui/ClickAndReplace'
import RuleableItem from '@/views/Admin/Rules/RuleTableRuleableItems.vue'
import FilterableItem from '@/views/Admin/Rules/RuleTableFilterableItem.vue'

export default {
  apollo: {},
  components: {
    ClickAndReplace,
    RuleableItem,
    FilterableItem
  },
  props: {
    rulesParentComponent: {
      type: String
    },
    rules: {
      type: Array,
      default: () => []
    },
    orderBy: {
      type: Array,
      default: () => []
    },
    bottomLoading: {
      type: Boolean,
      default: false
    },
    hide: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isDeleting: false,
      selectedRowIds: []
    }
  },
  watch: {
    selectedRowIds: {
      handler(newValue) {
        this.$store.dispatch('rule/updateSelectedRowIds', newValue)
      },
      deep: true
    }
  },
  mounted() {
    this.$events.listen('ruleDeleted', () => {
      this.selectedRowIds = []
    })
  },
  methods: {
    showCellBasedOnOrigin(rowData) {
      return rowData == this.rulesParentComponent
    },
    deleteRule(id) {
      this.isDeleting = true
      this.$store.dispatch('updateGlobalLoader', 1)
      this.$apollo
        .mutate({
          mutation: DELETE_RULE_MUTATION,
          variables: {
            id: id
          }
        })
        .then(() => {
          this.isDeleting = false
          this.$store.dispatch('rule/invalidateCache')
          this.$store.dispatch('updateGlobalLoader', -1)
          this.$toastr.s('Rule Deleted', 'Success!')
          this.$events.emit('ruleDeleted')
          this.$events.emit('refreshAlgorithmsData')
          this.$tabEvent.emit('ruleDeleted')
          this.$tabEvent.emit('refreshAlgorithmsData')
        })
        .catch(error => {
          this.isDeleting = false
          this.$store.dispatch('updateGlobalLoader', -1)
          console.error(error)
        })
    }
  },
  beforeDestroy() {
    if (this.isDeleting == true) {
      this.$store.dispatch('updateGlobalLoader', -1)
    }
  },
  computed: {}
}
</script>

<style lang="postcss" scoped></style>
