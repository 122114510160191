<template>
  <div>
    <portal to="top-bar-secondary">
      <div class="flex justify-between p-2">
        <div class="flex items-center">
          <router-link :to="{ name: 'Offers' }" class="outline-none-important">
            <button
              class="
                btn
                text-gray-800
                hover:text-blue-600
                focus:bg-gray-100
                cursor-pointer
                px-4
                flex
                items-center
                outline-none-important
              "
            >
              <Icon name="arrowLeft" class="w-4 h-4" />
              <h3 class="font-extrabold pl-4">Offers</h3>
            </button>
          </router-link>
        </div>
        <div>
          <div v-if="offer" class="flex items-center mr-4">
            <OfferTrafficStatus v-if="id" :id="id"></OfferTrafficStatus>
          </div>
        </div>
      </div>
    </portal>

    <div v-if="showCreateOffer">
      <h4 class="widget-title mb-4 ml-2">
        Create Offer
      </h4>
      <div class="grid grid-cols-3 gap-4" disabled>
        <Superselect
          v-model="destinationType"
          title="Destination Type"
          :options="['direct', 'template']"
          :multiple="false"
          :disabled="action === 'edit' || action === 'payouts'"
        />
        <div v-if="destinationType" class="flex flex-col gap-2">
          <Superselect
            v-if="destinationType == 'direct'"
            v-model="yleaderStatus"
            title="yLeader Status"
            :options="['yLeader', 'regular']"
            :multiple="false"
            :disabled="action === 'edit' || yleaderStatusDisabled"
          />
          <YInput
            v-if="destinationType == 'direct'"
            v-model="destination"
            label="Direct URL"
          />
          <Superselect
            v-if="destinationType == 'template'"
            key="template-select"
            v-model="destinationTemplate"
            title="Template"
            :options="[]"
            track-by="path"
            label="path"
            :multiple="false"
            :query="TEMPLATES_QUERY"
            :query-variables="{}"
            @select="updateDestination"
          />
          <Superselect
            v-if="destinationType == 'template'"
            v-model="isPrelander"
            title="Is Prelander?"
            :options="['Yes', 'No']"
            :multiple="false"
          />
          <Superselect
            v-if="destinationType == 'template'"
            v-model="ignoreTimeout"
            title="Remove Timeout?"
            :options="['Yes', 'No']"
            :multiple="false"
          />
          <Superselect
            v-if="destinationType == 'template'"
            v-model="trackingOverride"
            title="Tracking Override"
            :options="['Yes']"
            :multiple="false"
          />
          <YInput label="Details" v-model="details"></YInput>
          <Superselect
            v-model="domain"
            title="Domain"
            label="id"
            track-by="id"
            :options="domainOptions"
            :multiple="false"
          />
          <Superselect
            title="Advertisers"
            v-model="advertisers"
            :hide-selected="true"
            :multiple="true"
            label="label"
            track-by="id"
            :options="advertiserOptions ? advertiserOptions.data || [] : []"
            :loading="$apollo.queries.advertiserOptions.loading"
          ></Superselect>
          <TagsByTypeSelect
            title="Vertical"
            class="col-span-2 mb-1"
            v-model="verticalTags"
            type="vertical"
            :enable-not-toggles="true"
            :close-on-select="true"
          ></TagsByTypeSelect>
          <TagsByTypeSelect
            title="Mobile / Desktop"
            class="col-span-2 mb-1"
            v-model="mobileDesktopTags"
            :enable-not-toggles="true"
            :close-on-select="true"
            type="mobileDesktop"
          ></TagsByTypeSelect>
          <TagsByTypeSelect
            title="Offer Type"
            class="col-span-2 mb-1"
            v-model="offerTypeTags"
            :enable-not-toggles="true"
            type="offerType"
            :close-on-select="true"
          ></TagsByTypeSelect>
          <YInput
            v-if="destinationType == 'direct'"
            v-model="landingPageName"
            label="Campaign Landing Page Name"
          />
          <TagsByTypeSelect
            title="Country Code"
            class="col-span-2 mb-1"
            v-model="countryCodeTags"
            :enable-not-toggles="true"
            type="countryCode"
            :close-on-select="false"
            :allow-bulk-paste="true"
            :limit="300"
          />
          <TagsByTypeSelect
            title="Region Code"
            class="col-span-2 mb-1"
            v-model="regionCodeTags"
            :enable-not-toggles="true"
            :close-on-select="false"
            :allow-bulk-paste="true"
            :limit="10"
            :options-limit="10"
            :perPage="500"
            elastic-options-field="geo_region"
            :elastic-search="true"
            type="regionCode"
          />
          <TagsByTypeSelect
            title="Traffic Type"
            class="col-span-2 mb-1"
            v-model="trafficTypeTags"
            :enable-not-toggles="true"
            type="trafficType"
            :close-on-select="true"
          ></TagsByTypeSelect>
          <TagsByTypeSelect
            title="Browser"
            class="col-span-2 mb-1"
            v-model="browserTags"
            :enable-not-toggles="true"
            :close-on-select="false"
            :allow-bulk-paste="true"
            :limit="10"
            :options-limit="10"
            :perPage="500"
            elastic-options-field="browser_family"
            :elastic-search="true"
            elastic-normalizer="lowercase"
            type="browser"
          />
          <TagsByTypeSelect
            title="OS"
            class="col-span-2 mb-1"
            v-model="osTags"
            :enable-not-toggles="true"
            :close-on-select="false"
            :allow-bulk-paste="true"
            :limit="10"
            :options-limit="10"
            :perPage="500"
            elastic-options-field="os"
            :elastic-search="true"
            elastic-normalizer="lowercase"
            type="os"
          />
          <TagsByTypeSelect
            title="Languages"
            class="col-span-2 mb-1"
            v-model="languageTags"
            :enable-not-toggles="true"
            :close-on-select="false"
            :allow-bulk-paste="true"
            :limit="10"
            :options-limit="10"
            :perPage="500"
            elastic-options-field="language_short"
            :elastic-search="true"
            type="language"
          />
        </div>
      </div>
    </div>

    <div v-else>
      <div class="mb-4">
        <p class="capitalize font-semibold mb-1">
          {{ destinationType }} - {{ id }}
        </p>
        <p class="capitalize text-lg font-semibold leading-tight">{{ name }}</p>

        <div class="flex items-center gap-6">
          <div
            class="flex gap-1 items-center text-gray-800"
            v-if="generateRedirectLink && encodedId"
          >
            {{ encodedId }}
            <a
              :href="`${generateRedirectLink.url}/o/${encodedId}`"
              class="text-gray-800 text-xs "
              target="_blank"
            >
              <Icon name="openInNew" class="w-3 h-3 " />
            </a>
          </div>
          <div
            v-if="yleaderStatus == 'yLeader'"
            class="flex items-center text-gray-800"
          >
            <Icon name="bullseye" class="mr-1 w-3 h-3" />
            <p>yLeader</p>
          </div>
        </div>
      </div>
      <div>
        <Tabs v-if="offer.id" class="col-span-3" show-type="show">
          <Tab name="Details" :active="true">
            <div class="grid grid-cols-7 gap-4 m-4">
              <div class="flex flex-col col-span-2 gap-2 p-2">
                <Superselect
                  v-model="destinationType"
                  title="Destination Type"
                  :options="['direct', 'template']"
                  :multiple="false"
                  :disabled="action === 'edit' || action === 'payouts'"
                />
                <YInput
                  v-if="destinationType == 'direct'"
                  v-model="destination"
                  label="Direct URL"
                />
                <Superselect
                  v-if="destinationType == 'template'"
                  key="template-select"
                  v-model="destinationTemplate"
                  title="Template"
                  :options="[]"
                  track-by="path"
                  label="path"
                  :multiple="false"
                  :query="TEMPLATES_QUERY"
                  :query-variables="{}"
                  @select="updateDestination"
                />
                <Superselect
                  v-if="destinationType == 'template'"
                  v-model="isPrelander"
                  title="Is Prelander?"
                  :options="['Yes', 'No']"
                  :multiple="false"
                />
                <Superselect
                  v-if="destinationType == 'template'"
                  v-model="ignoreTimeout"
                  title="Remove Timeout?"
                  :options="['Yes', 'No']"
                  :multiple="false"
                />
                <Superselect
                  v-if="destinationType == 'template'"
                  v-model="trackingOverride"
                  title="Tracking Override"
                  :options="['Yes']"
                  :multiple="false"
                />
                <YInput label="Details" v-model="details" class="mb-1"></YInput>
                <Superselect
                  v-model="domain"
                  title="Domain"
                  label="id"
                  track-by="id"
                  :options="domainOptions"
                  :multiple="false"
                />
                <Superselect
                  title="Advertisers"
                  v-model="advertisers"
                  :hide-selected="true"
                  :multiple="true"
                  label="label"
                  track-by="id"
                  :disabled="destinationType == 'template'"
                  :options="
                    advertiserOptions ? advertiserOptions.data || [] : []
                  "
                  :loading="$apollo.queries.advertiserOptions.loading"
                ></Superselect>

                <TagsByTypeSelect
                  title="Vertical"
                  class="col-span-2 mb-1"
                  v-model="verticalTags"
                  type="vertical"
                  :enable-not-toggles="true"
                  :close-on-select="true"
                ></TagsByTypeSelect>
                <TagsByTypeSelect
                  title="Mobile / Desktop"
                  class="col-span-2 mb-1"
                  v-model="mobileDesktopTags"
                  :enable-not-toggles="true"
                  :close-on-select="true"
                  type="mobileDesktop"
                ></TagsByTypeSelect>
                <TagsByTypeSelect
                  title="Offer Type"
                  class="col-span-2 mb-1"
                  v-model="offerTypeTags"
                  :enable-not-toggles="true"
                  type="offerType"
                  :close-on-select="true"
                ></TagsByTypeSelect>
                <YInput
                  label="Campaign Landing Page Name"
                  v-model="landingPageName"
                />
              </div>
              <div class="flex flex-col col-span-2 gap-2 p-2">
                <TagsByTypeSelect
                  title="Country Code"
                  class="col-span-2 mb-1"
                  v-model="countryCodeTags"
                  :enable-not-toggles="true"
                  type="countryCode"
                  :close-on-select="false"
                  :allow-bulk-paste="true"
                  :limit="12"
                />
                <TagsByTypeSelect
                  title="Region Code"
                  class="col-span-2 mb-1"
                  v-model="regionCodeTags"
                  :enable-not-toggles="true"
                  :close-on-select="false"
                  :allow-bulk-paste="true"
                  :limit="10"
                  :options-limit="10"
                  :perPage="500"
                  elastic-options-field="geo_region"
                  :elastic-search="true"
                  type="regionCode"
                />
                <TagsByTypeSelect
                  title="Traffic Type"
                  class="col-span-2 mb-1"
                  v-model="trafficTypeTags"
                  :enable-not-toggles="true"
                  type="trafficType"
                  :close-on-select="true"
                ></TagsByTypeSelect>
                <TagsByTypeSelect
                  title="Browser"
                  class="col-span-2 mb-1"
                  v-model="browserTags"
                  :enable-not-toggles="true"
                  :close-on-select="false"
                  :allow-bulk-paste="true"
                  :limit="10"
                  :options-limit="10"
                  :perPage="500"
                  elastic-options-field="browser_family"
                  :elastic-search="true"
                  elastic-normalizer="lowercase"
                  type="browser"
                />
                <TagsByTypeSelect
                  title="OS"
                  class="col-span-2 mb-1"
                  v-model="osTags"
                  :enable-not-toggles="true"
                  :close-on-select="false"
                  :allow-bulk-paste="true"
                  :limit="10"
                  :options-limit="10"
                  :perPage="500"
                  elastic-options-field="os"
                  :elastic-search="true"
                  elastic-normalizer="lowercase"
                  type="os"
                />
                <TagsByTypeSelect
                  title="Languages"
                  class="col-span-2 mb-1"
                  v-model="languageTags"
                  :enable-not-toggles="true"
                  :close-on-select="false"
                  :allow-bulk-paste="true"
                  :limit="10"
                  :options-limit="10"
                  :perPage="500"
                  elastic-options-field="language_short"
                  :elastic-search="true"
                  type="language"
                />

                <div class="flex flex-col gap-2 p-2">
                  <label class="text-indigo-600 text-xs">Image</label>
                  <EditableImage
                    v-if="offer.id"
                    class="rounded mx-auto"
                    :image-url="offer.imageUrl"
                    :height="40"
                    :width="40"
                    model-class="Offer"
                    :model-id="offer.id"
                    :delete-existing="true"
                    @modelUpdated="updateImage"
                  ></EditableImage>
                </div>
              </div>
              <div
                v-if="destinationType == 'direct'"
                class="flex flex-col gap-2 p-2"
              ></div>
              <div
                v-if="destinationType === 'template'"
                :class="{ 'col-span-3': destinationType === 'template' }"
              >
                <TemplatePreview
                  :key-value-pairs="vars"
                  :path="destination"
                  :auto-refresh="true"
                />
              </div>
              <div
                v-if="destinationType == 'direct'"
                class="col-span-2 bg-gray-100 border rounded-md flex flex-col sidebar-right"
              >
                <SideBarRightItem title="Comments" icon="message">
                  <SideBarComments
                    :comments="offer.comments"
                    :modelId="offer.id"
                    modelClass="Offer"
                  ></SideBarComments
                ></SideBarRightItem>
              </div>
            </div>
          </Tab>
          <Tab v-if="destinationType == 'direct'" name="Attributes">
            <OfferMetaAttributes
              :key="editOffer.id"
              :offerMeta="editOffer.offerMeta"
              :offerTranslations="editOffer.metaTranslations"
              :offerId="editOffer.id"
              :translatableLanguages="translatableLanguages"
            />
          </Tab>
          <Tab v-if="offer.template" name="Properties">
            <div class="my-2 ml-4 ">
              <Tabs>
                <Tab name="Offer Keys" :active="true">
                  <div class="grid grid-cols-3 gap-4">
                    <TemplateVars
                      class="mt-4"
                      :definitions="offer.template.varDefinitions"
                      :initial-inputs="offer.varInputs"
                      :linked-vars="offer.template.linkedVars ?? {}"
                      :var-endings="offer.varsConfig.endings ?? []"
                      @queryStringUpdated="() => {}"
                      @updated="updateVars"
                    >
                    </TemplateVars>
                    <div class="bg-gray-100 col-span-2">
                      <TemplatePreview
                        :key-value-pairs="vars"
                        :path="destination"
                        :auto-refresh="true"
                        :scaleWindow="0.7"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab
                  name="Available Translations"
                  :disabled="editOffer.varsTranslations.length == 0"
                >
                  <OfferTemplateTranslations
                    :offerTranslations="editOffer.varsTranslations"
                    :offerId="editOffer.id"
                    :selectedTranslatableLanguages="
                      selectedTranslatableLanguages.slice().sort()
                    "
                    :offerVars="offer.vars"
                    :definitions="offer.template.varDefinitions"
                  />
                </Tab>

                <Tab name="Languages">
                  <div class="mt-4 flex flex-col gap-3" style="width: 28rem;">
                    <YButton v-if="isSubmitting" disabled color="blue">
                      <Spinner color="white" :size="5" />
                    </YButton>
                    <YButton
                      v-if="!isSubmitting"
                      color="blue"
                      @click="updateOrCreateTranslations"
                      >Create or Update Translations</YButton
                    >
                    <Superselect
                      title="Available Languages"
                      v-model="localSelectedTranslatableLanguages"
                      placeholder="Select Languages"
                      :options="translatableLanguages"
                      :custom-label="customLabelLanguages"
                      track-by="code"
                      :multiple="true"
                      :closeOnSelect="false"
                    ></Superselect>
                    <div class="w-20">
                      <YButton
                        color="link"
                        @click="
                          localSelectedTranslatableLanguages = [
                            ...translatableLanguages
                          ]
                        "
                        >Select All</YButton
                      >
                    </div>
                  </div>
                </Tab>
                <Tab name="VarConfig">
                  <div style="max-width: 24rem">
                    <OfferVarConfiguration
                      :offer="offer"
                      @updated="updateOfferVarsConfig"
                    />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </Tab>

          <Tab v-if="destinationType == 'direct'" name="Payouts">
            <OfferRevenue v-if="offer" :offer="offer" class="mt-4" />
          </Tab>
          <Tab v-if="this.setYleaderStatus() == true" name="yLeader Estimates">
            <OfferYleaderEstimatedRevenues
              v-if="offer"
              :offer="offer"
              class="mt-4"
            />
          </Tab>
          <Tab v-if="destinationType == 'direct'" name="Caps">
            <Caps :cappableId="offer.id" cappableType="Offer" />
          </Tab>
          <Tab v-if="destinationType == 'direct'" name="Cap Defaults">
            <CapDefaults
              v-if="id"
              :cappableId="id"
              cappableType="Offer"
            ></CapDefaults>
          </Tab>
          <Tab name="Daypart">
            <Dayparting :daypartable-id="offer.id" daypartable-type="Offer" />
          </Tab>
          <Tab :name="ruleTotalTabName" v-if="destinationType">
            <OfferPerspectiveRulesTableWrapper
              :default-filter-values="{ offers: [offer] }"
              :hide-all-offer-filters="true"
              ruleOrigin="advertiser_rule"
              rulesParentComponent="advertiser_rule"
              :defaults-for-create="{
                filterType: { name: 'offer', label: 'Offer' },
                filterables: [offer]
              }"
              @totalRulesTab="totalRulesTab"
            />
          </Tab>
          <Tab name="Traffic" v-if="destinationType">
            <OfferTraffic :offer="offer" />
          </Tab>
        </Tabs>
      </div>
    </div>

    <portal to="top-bar-right">
      <div class="flex items-center">
        <YButton
          v-if="requireRetraffic"
          color="blue"
          @click="saveAndTraffic"
          class="mr-1"
          :disabled="!this.hasChanges"
          >Save & Retraffic
        </YButton>
        <YButton
          v-else
          color="blue"
          @click="save"
          class="mr-1"
          :disabled="!this.hasChanges"
          >Save
        </YButton>
        <v-popover offset="0">
          <YButton color="link"><Icon name="dotsVertical"/></YButton>
          <template slot="popover">
            <div class="flex flex-col">
              <YButton color="link " @click="openOfferCloneModal()"
                >Clone</YButton
              >
            </div>
          </template>
        </v-popover>
      </div>
    </portal>
    <portal to="top-bar-left-header">
      {{ pageTitle }}
      <Tag v-if="hasChanges" color="pink" class="ml-2">Unsaved Changes</Tag>
    </portal>
  </div>
</template>
<script>
import OfferTraffic from '@/views/Admin/Offers/OfferTraffic.vue'
import OfferRevenue from '@/views/Admin/Offers/OfferRevenue'
import OfferYleaderEstimatedRevenues from '@/views/Admin/Offers/OfferYleaderEstimatedRevenues'
import Caps from '@/views/Admin/Caps/Caps'
import TagsByTypeSelect from '@/views/Admin/Tags/TagsByTypeSelect'
import OfferTrafficStatus from '@/views/Admin/Offers/OfferTrafficStatus'
//import OfferStatus from '@/views/Admin/Offers/OfferStatus'
import { mapActions } from 'vuex'
import TEMPLATES_QUERY from '@/graphql/Template/TemplatesQuery.gql'
import ADVERTISER_OPTIONS_QUERY from '@/graphql/Advertiser/AdvertiserOptionsQuery.gql'
import DOMAIN_OPTIONS_QUERY from '@/graphql/Domain/DomainOptionsQuery.gql'
import ADVERTISER_OPTIONS_WITH_ACCOUNTS_QUERY from '@/graphql/Advertiser/AdvertiserOptionsWithAccountsQuery.gql'
import CREATE_OFFER_MUTATION from '@/graphql/Offer/CreateOfferMutation.gql'
import UPDATE_OFFER_MUTATION from '@/graphql/Offer/UpdateOfferMutation.gql'
import SYNC_TAGS_WITH_TYPE_MUTATION from '@/graphql/Tag/SyncTagsWithTypeMutation.gql'
import GENERATE_OFFER_NAMES_FOR_MUTATION from '@/graphql/Offer/GenerateOfferNamesForMutation.gql'
import OFFERS_QUERY from '@/graphql/Offer/OffersQuery.gql'
import OFFER_QUERY from '@/graphql/Offer/OfferQuery.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import CapDefaults from '@/views/Admin/Caps/CapDefaults.vue'
import EditableImage from '@/components/utilities/EditableImage'
import OfferPerspectiveRulesTableWrapper from '@/views/Admin/Rules/OfferPerspectiveRulesTableWrapper'
import OfferVarConfiguration from '@/views/Admin/Templates/OfferVarConfiguration'
import TemplatePreview from '@/views/Admin/Templates/TemplatePreview'
import TemplateVars from '@/views/Admin/Templates/TemplateVars'
import TrafficOffer from '@/views/Admin/Offers/TrafficOffer'
import Dayparting from '@/components/ui/Dayparting'
import SET_STATUS_MUTATION from '@/graphql/Status/SetStatusMutation.gql'
import OfferMetaAttributes from '@/views/Admin/Offers/OfferMetaAttributes.vue'
import OfferCloneModal from '@/views/Admin/Offers/OfferClone.vue'
import TEMPLATE_SUPPORTED_LANGUAGES_QUERY from '@/graphql/Template/TemplateSupportedLanguagesQuery.gql'
import TRANSLATABLE_LANGUAGES from '@/graphql/Translation/TranslatableLanguagesQuery.gql'
import OfferTemplateTranslations from '@/views/Admin/Templates/OfferTemplateTranslations.vue'
import SYNC_OFFER_TRANSLATE_LANGS from '@/graphql/Offer/SyncOfferTranslationLangs.gql'
import gql from 'graphql-tag'

export default {
  mixins: [ValidationErrorsMixin],
  components: {
    CapDefaults,
    TagsByTypeSelect,
    OfferRevenue,
    OfferTraffic,
    OfferYleaderEstimatedRevenues,
    Caps,
    OfferTrafficStatus,
    //OfferStatus,
    EditableImage,
    OfferPerspectiveRulesTableWrapper,
    OfferVarConfiguration,
    TemplatePreview,
    TemplateVars,
    Dayparting,
    OfferMetaAttributes,
    OfferTemplateTranslations
  },
  props: {
    id: {
      type: [Number, String]
    },
    action: {
      type: String
    }
  },
  apollo: {
    generateRedirectLink: {
      query: gql`
        query Redirect {
          redirect(id: 19288) {
            url
          }
        }
      `,
      update: data => data.redirect
    },
    templateSupportedLanguages: {
      query: TEMPLATE_SUPPORTED_LANGUAGES_QUERY
    },
    translatableLanguages: {
      query: TRANSLATABLE_LANGUAGES
    },
    domainOptions: {
      query: DOMAIN_OPTIONS_QUERY,
      variables() {
        return {
          first: 999,
          filters: {
            type: { value: ['offer'] }
          }
        }
      },
      result({ data }) {
        this.domainOptions = data.domainOptions.data
      }
    },
    advertiserOptions: {
      query: ADVERTISER_OPTIONS_QUERY,
      variables() {
        return {
          filters: {
            roles: {
              name: { value: 'Advertiser' }
            },
            status: ['Active']
          },
          first: 100000
        }
      },
      loadingKey: 'loadingQueriesCount'
    },
    selectedAdvertisers: {
      query: ADVERTISER_OPTIONS_WITH_ACCOUNTS_QUERY,
      update: data => data.advertiserOptions,
      variables() {
        return {
          filters: {
            id: {
              value:
                this.$store.state.offer.mutateOffer.advertiserIds.length > 0
                  ? this.$store.state.offer.mutateOffer.advertiserIds
                  : [0]
            }
          },
          first:
            this.$store.state.offer.mutateOffer.advertiserIds.length > 0
              ? this.$store.state.offer.mutateOffer.advertiserIds.length
              : 1
        }
      },
      skip() {
        return !this.$store.state.offer.mutateOffer.advertiserIds
      },
      result({ data }) {
        if (data && data.advertiserOptions) {
          this.selectedAdvertisers = data.advertiserOptions.data
        } else {
          this.selectedAdvertisers = null
        }
      },
      loadingKey: 'loadingQueriesCount'
    },
    editOffer: {
      query: OFFER_QUERY,
      update: data => data.offer,
      variables() {
        return {
          id: this.id ? this.id : undefined
        }
      },
      result({ data }) {
        if (this.firstLoad) {
          this.initializeOfferData(data.offer)
        }
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.queriesCount || !this.localId
      }
    }
  },
  updated() {
    if (
      this.templateVarTranslatedLanguages &&
      this.templateVarTranslatedLanguages.length > 0
    ) {
      this.selectedLanguage = this.templateVarTranslatedLanguages[0]
      this.selectedTranslatableLanguages = this.templateVarTranslatedLanguages
    }
  },
  data() {
    return {
      isSubmitting: false,
      TRANSLATABLE_LANGUAGES,
      selectedTranslatableLanguages: [],
      localSelectedTranslatableLanguages: [],
      templateSupportedLanguages: [],
      loadingQueriesCount: 0,
      ruleTotalTabName: null,
      firstLoad: true,
      TEMPLATES_QUERY,
      OFFERS_QUERY,
      vars: {},
      varsConfig: {},
      trafficStatus: null,
      changes: false,
      initialData: null,
      localId: this.id,
      encodedId: null,
      selectedAdvertisers: [],
      isLoading: false,
      previousRoute: null,
      initialTagTypes: [],
      imageUrl: null,
      imageThumbnailUrl: null,
      comments: null,
      destination: null,
      template: null,
      destinationDirect: null,
      destinationTemplate: null,
      editOffer: {},
      yleaderStatus: null,
      status: null,
      createdAt: null,
      trackingOverride: null,
      domain: null,
      domainOptions: [],
      ignoreTimeout: null,
      isPrelander: false
    }
  },
  computed: {
    templateVarTranslatedLanguages() {
      if (this.editOffer.varsTranslations) {
        return this.editOffer.varsTranslations.map(item => item.language)
      }
      return null
    },
    showCreateOffer() {
      return this.action == 'create'
    },
    queriesCount() {
      return this.loadingQueriesCount == 0
    },
    yleaderStatusDisabled() {
      return this.action !== 'create' && this.editOffer.yleader !== null
        ? true
        : false
    },
    requireRetraffic() {
      return this.retrafficTagsHaveChanges && this.offer.status == 'live'
    },
    pageTitle() {
      return this.mutationType == 'update' ? 'Edit Offer' : 'Create Offer'
    },
    mutationType() {
      return this.localId ? 'update' : 'create'
    },
    hasChanges() {
      return (
        JSON.stringify(this.initialData || {}) != JSON.stringify(this.offer)
      )
    },
    retrafficTagsHaveChanges() {
      if (!this.initialData || !this.offer) {
        return false
      }
      return (
        this.hashTagNamesByType(
          this.pluckTrafficTypes(this.initialData?.tagNamesByType)
        ) !=
        this.hashTagNamesByType(
          this.pluckTrafficTypes(this.offer?.tagNamesByType)
        )
      )
    },
    tagsHaveChanges() {
      if (!this.initialData || !this.offer) {
        return false
      }
      return (
        this.hashTagNamesByType(this.initialData?.tagNamesByType) !=
        this.hashTagNamesByType(this.offer?.tagNamesByType)
      )
    },
    name: {
      get() {
        return this.$store.state.offer.mutateOffer.name
      },
      set(value) {
        this.updateMutateOffer({
          value: { name: value }
        })
      }
    },
    destinationType: {
      get() {
        return this.$store.state.offer.mutateOffer.destinationType
      },
      set(value) {
        this.updateMutateOffer({
          value: { destinationType: value }
        })
      }
    },

    details: {
      get() {
        return this.$store.state.offer.mutateOffer.details
      },
      set(value) {
        this.updateMutateOffer({
          value: { details: value }
        })
      }
    },
    landingPageName: {
      get() {
        return this.$store.state.offer.mutateOffer.landingPageName
      },
      set(value) {
        this.updateMutateOffer({
          value: { landingPageName: value }
        })
      }
    },

    tags: {
      get() {
        return this.$store.state.offer.mutateOffer.tags
      },
      set(value) {
        this.updateMutateOffer({
          value: { tags: value }
        })
      }
    },
    tagNamesByType: {
      get() {
        return this.$store.state.offer.mutateOffer.tagNamesByType
      },
      set(value) {
        this.updateMutateOffer({
          value: { tagNamesByType: value }
        })
      }
    },
    trafficTypeTags: {
      get() {
        return this.tagNamesByType?.trafficType
      },
      set(value) {
        this.updateTagNamesByType('trafficType', value)
      }
    },
    verticalTags: {
      get() {
        return this.tagNamesByType?.vertical
      },
      set(value) {
        this.updateTagNamesByType('vertical', value)
      }
    },
    offerTypeTags: {
      get() {
        return this.tagNamesByType?.offerType
      },
      set(value) {
        this.updateTagNamesByType('offerType', value)
      }
    },
    payoutTypeTags: {
      get() {
        return this.tagNamesByType?.payoutType
      },
      set(value) {
        this.updateTagNamesByType('payoutType', value)
      }
    },
    countryCodeTags: {
      get() {
        return this.tagNamesByType?.countryCode
      },
      set(value) {
        this.updateTagNamesByType('countryCode', value)
      }
    },
    regionCodeTags: {
      get() {
        return this.tagNamesByType?.regionCode
      },
      set(value) {
        this.updateTagNamesByType('regionCode', value)
      }
    },
    mobileDesktopTags: {
      get() {
        return this.tagNamesByType?.mobileDesktop
      },
      set(value) {
        this.updateTagNamesByType('mobileDesktop', value)
      }
    },
    browserTags: {
      get() {
        return this.tagNamesByType?.browser
      },
      set(value) {
        this.updateTagNamesByType('browser', value)
      }
    },
    osTags: {
      get() {
        return this.tagNamesByType?.os
      },
      set(value) {
        this.updateTagNamesByType('os', value)
      }
    },
    languageTags: {
      get() {
        return this.tagNamesByType?.language
      },
      set(value) {
        this.updateTagNamesByType('language', value)
      }
    },
    advertisers: {
      get() {
        return this.selectedAdvertisers
      },
      set(value) {
        this.updateMutateOffer({
          value: { advertiserIds: value.map(advertiser => advertiser.id) }
        })
      }
    },
    offer() {
      let offer = {
        id: this.localId,
        encodedId: this.encodedId,
        name: this.name,
        details: this.details,
        landingPageName: this.landingPageName,
        tags: this.tags,
        tagNamesByType: this.tagNamesByType,
        advertisers: this.advertisers,
        imageUrl: this.imageUrl,
        imageThumbnailUrl: this.imageThumbnailUrl,
        comments: this.comments,
        destination: this.destination,
        offers: null,
        template: this.template,
        vars: this.vars,
        varInputs: this.varInputs,
        varsConfig: this.varsConfig,
        destinationType: this.destinationType,
        trafficStatus: this.editOffer.trafficStatus,
        yleader: this.yleaderStatus,
        status: this.status,
        createdAt: this.createdAt,
        trackingIdOverride: this.trackingOverride,
        isPrelander: this.isPrelander,
        ignoreTimeout: this.ignoreTimeout,
        domain: this.domain,
        varsTranslations: this.editOffer.varsTranslations
      }
      return offer
    }
  },
  watch: {
    id: {
      handler(newValue) {
        this.localId = newValue
      }
    },

    isLoading(value) {
      this.$store.dispatch('updateGlobalLoader', value ? 1 : -1)
    },
    destination(value) {
      if (this.destinationType == 'direct') {
        this.destinationDirect = value
      } else if (this.destinationType == 'template') {
        this.destinationTemplate = value ? { path: value } : null
      }
    },
    selectedTranslatableLanguages: {
      handler(newVal) {
        if (typeof newVal == 'undefined' && newVal == null) {
          this.localSelectedTranslatableLanguages = []
        } else {
          this.localSelectedTranslatableLanguages = this.translatableLanguages.filter(
            lang => this.templateVarTranslatedLanguages.includes(lang.code)
          )
        }
      }
    }
  },
  beforeCreate() {
    this.$store.dispatch('offer/clearMutateOffer')
  },

  mounted() {
    this.setInitialData(this.offer)
    this.$tabEvent.on('refreshOffersTable', () => {
      this.$apollo.queries.editOffer.refetch()
    })
    this.$events.listen('offerTrafficked', () => {
      this.$apollo.queries.editOffer.refetch()
    })
    this.$events.listen('refreshParentTable', () => {
      this.$apollo.queries.editOffer.refetch()
    })
  },
  beforeDestroy() {
    if (this.isLoading) {
      this.$store.dispatch('updateGlobalLoader', -1)
    }

    this.$events.remove('offerTrafficked')
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.previousRoute = from
    })
  },

  methods: {
    customLabelLanguages(option) {
      return option.name + ' - ' + option.code.toUpperCase()
    },
    totalRulesTab(value) {
      return (this.ruleTotalTabName = 'Rules (' + value.total + ')')
    },
    ...mapActions('offer', [
      'updateMutateOffer',
      'clearMutateOffer',
      'validateCache',
      'invalidateCache'
    ]),
    updateOrCreateTranslations() {
      this.isSubmitting = true

      let languageCodes = this.localSelectedTranslatableLanguages.map(
        value => value.code
      )

      this.$apollo
        .mutate({
          mutation: SYNC_OFFER_TRANSLATE_LANGS,
          variables: {
            input: {
              id: this.editOffer.id,
              languages: languageCodes
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
        })
        .catch(error => {
          console.log(error)
          this.isSubmitting = false
        })
    },
    setOfferStatus(offerId, status) {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: SET_STATUS_MUTATION,
          variables: {
            input: {
              modelName: 'Offer',
              modelIds: [offerId],
              name: status
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$toastr.s('Status Updated')
          this.$tabEvent.emit('refreshOffersTable')
        })
        .catch(error => {
          this.setValidationErrors(error)
        })
    },
    pluckTrafficTypes(tagNamesByType) {
      return {
        mobileDesktop: tagNamesByType?.mobileDesktop,
        countryCode: tagNamesByType?.countryCode
      }
    },
    hashTagNamesByType(tagNamesByType) {
      const clone = JSON.parse(JSON.stringify(tagNamesByType))
      const item = Object.entries(clone).map(tagGroup => {
        tagGroup[1].tags = tagGroup[1]?.tags?.sort()
        return tagGroup
      })
      return JSON.stringify(item)
    },
    updateOfferVarsConfig(varsConfig) {
      this.varsConfig = varsConfig
      this.varInputs = varsConfig.offer.varInputs
      this.updateVars(varsConfig.offer.vars)
    },
    updateVars(vars) {
      this.vars = vars
    },
    updateImage(offer) {
      this.imageUrl = offer.legacyImageUrl
      this.imageThumbnailUrl = offer.legacyImageThumbnailUrl
    },
    initializeOfferData(offer) {
      this.firstLoad = this.queriesCount
      this.name = offer.name
      this.encodedId = offer.encodedId
      this.details = offer.details
      this.landingPageName = offer.landingPageName
      this.trafficStatus = offer.trafficStatus
      this.advertisers = offer.advertisers
      this.tags = offer.tags
      this.tagNamesByType = offer.tagNamesByType?.reduce(
        (accumulator, tagSet) => {
          return {
            ...accumulator,
            [tagSet.type]: { isNot: tagSet.isNot, tags: tagSet.tags }
          }
        },
        {}
      )
      this.imageUrl = offer.legacyImageUrl
      this.imageThumbnailUrl = offer.imageThumbnailUrl
      this.comments = offer.comments
      this.destination = offer.destination
      this.destinationType = offer.destinationType
      this.yleaderStatus =
        typeof offer.yleader == 'object'
          ? null
          : typeof offer.yleader == 'boolean' && offer.yleader
          ? 'yLeader'
          : 'Regular'
      this.vars = offer.vars
      this.varInputs = offer.varInputs
      this.varsConfig = offer.varsConfig
      this.template = offer.template
      this.status = offer.status
      this.createdAt = offer.createdAt
      this.trackingOverride = offer.trackingIdOverride ? 'Yes' : null
      this.isPrelander = offer.isPrelander ? 'Yes' : 'No'
      this.ignoreTimeout = offer.ignoreTimeout ? 'Yes' : 'No'
      this.domain = offer.domain
      this.setInitialData(this.offer)
    },
    updateOffersFromKeys(value) {
      this.offers = Object.values(value)
    },
    destinationTypeStatusCheck() {
      return (
        (this.destinationType == 'direct' && this.yleaderStatus) ||
        this.destinationType == 'template'
      )
    },
    async save() {
      this.clearValidationErrors()
      if (this.mutationType == 'create') {
        return this.destinationTypeStatusCheck()
          ? this.createOffer()
              .then(data => {
                this.generateOfferNamesFor(data.createOffer.id).then(data => {
                  this.$router.push({
                    name: 'Offer',
                    params: {
                      action: 'edit',
                      id: data.generateOfferNamesFor[0].id
                    }
                  })
                })
              })
              .then(this.$tabEvent.emit('refreshOffersTable'))
          : this.$toastr.e('yLeader Status Required.', 'Direct Type')
      } else if (this.mutationType == 'update') {
        return this.updateOffer()
          .then(data => {
            this.generateOfferNamesFor(data.updateOffer.id)
          })
          .then(this.$tabEvent.emit('refreshOffersTable'))
      }
    },
    openTrafficOfferModal() {
      this.$modal.show(
        TrafficOffer,
        {
          offerId: this.id,
          exitWarning: true
        },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true,
          clickToClose: false
        }
      )
    },
    onChangeDestinationType() {
      if (this.destinationType == 'direct') {
        this.destination = null
      } else if (this.destinationType == 'template') {
        this.destination = this.destinationTemplate
          ? this.destinationTemplate.path
          : null
      } else if (this.destinationType == 'offer') {
        this.destination = this.destinationOffer
          ? this.destinationOffer.path
          : null
      }
    },
    updateDestination(destinationValue) {
      if (this.destinationType == 'direct') {
        this.destination = destinationValue
      } else if (this.destinationType == 'template') {
        this.destination = destinationValue.path
      } else if (this.destinationType == 'offer') {
        this.destination = destinationValue.id
      }
    },
    updateTagNamesByType(field, value) {
      let tagNamesByTypeClone = JSON.parse(JSON.stringify(this.tagNamesByType))
      tagNamesByTypeClone[field] = value ?? undefined
      this.tagNamesByType = tagNamesByTypeClone
    },
    saveAndTraffic() {
      return this.save().then(() => {
        this.openTrafficOfferModal()
      })
    },
    saveAndExit() {
      return this.save().then(() => {
        this.$router.push({ name: 'Offers' })
      })
    },
    setInitialData(data) {
      this.initialData = JSON.parse(JSON.stringify(data))
    },
    setYleaderStatus() {
      return this.destinationType == 'direct' && this.yleaderStatus == 'yLeader'
        ? true
        : this.destinationType == 'direct' && this.yleaderStatus == 'regular'
        ? false
        : undefined
    },
    async createOffer() {
      this.isLoading = true
      let tagsByType = JSON.parse(JSON.stringify(this.tagNamesByType))
      try {
        let createResponse = await this.$apollo.mutate({
          // Query
          mutation: CREATE_OFFER_MUTATION,
          // Parameters
          variables: {
            input: {
              destinationType: this.destinationType,
              destination: this.destination,
              details: this.details,
              landingPageName: this.landingPageName,
              advertisers: {
                sync: this.$store.state.offer.mutateOffer.advertiserIds
              },
              yleader: this.setYleaderStatus(),
              isPrelander: this.isPrelander == 'Yes' ? true : false,
              ignoreTimeout: this.ignoreTimeout == 'Yes' ? true : false,
              domainId: this.domain?.id ?? null
            }
          }
        })
        if (createResponse) {
          let data = createResponse.data
          let mutations = []
          Object.entries(tagsByType).forEach(([type, tagSet]) => {
            mutations.push(
              this.syncTagsWithType(
                data.createOffer.id,
                'offer',
                type,
                tagSet.tags,
                tagSet.isNot
              )
            )
          })
          // Fire logic when all mutations are complete
          this.$toastr.s('Offer Created', 'Success!')
          return Promise.all(mutations).then(() => {
            this.invalidateCache()
            this.isLoading = false
            return data
          })
        }
      } catch (error) {
        console.error(error)
        this.setValidationErrors(error)
        this.isLoading = false
      }
    },
    async updateOffer() {
      this.isLoading = true
      let tagsByType = JSON.parse(JSON.stringify(this.tagNamesByType))
      let syncAdvertiserInput =
        this.destinationType == 'direct'
          ? { sync: this.$store.state.offer.mutateOffer.advertiserIds }
          : undefined
      try {
        let updateResult = await this.$apollo.mutate({
          // Query
          mutation: UPDATE_OFFER_MUTATION,
          // Parameters
          variables: {
            input: {
              id: this.localId,
              details: this.details,
              landingPageName: this.landingPageName,
              destination: this.destination,
              yleader: this.setYleaderStatus(),
              vars: this.vars,
              advertisers: syncAdvertiserInput,
              trackingIdOverride: this.trackingOverride ? this.localId : null,
              isPrelander: this.isPrelander == 'Yes' ? true : false,
              ignoreTimeout: this.ignoreTimeout == 'Yes' ? true : false,
              domainId: this.domain?.id ?? null
            }
          }
        })
        if (updateResult) {
          let data = updateResult.data
          let mutations = []
          Object.entries(tagsByType).forEach(([type, tagSet]) => {
            mutations.push(
              this.syncTagsWithType(
                data.updateOffer.id,
                'offer',
                type,
                tagSet.tags,
                tagSet.isNot
              )
            )
          })
          // Fire logic when all mutations are complete
          return Promise.all(mutations).then(() => {
            this.localId = data.updateOffer.id
            this.setInitialData(this.offer)
            this.$toastr.s('Offer Updated', 'Success!')
            this.isLoading = false
            this.changes = true
            return data
          })
        }
      } catch (error) {
        console.error(error)
        this.setValidationErrors(error)
        this.isLoading = false
      }
    },
    async syncTagsWithType(
      taggableId,
      taggableType,
      type,
      tags,
      isNot = false
    ) {
      return this.$apollo
        .mutate({
          // Query
          mutation: SYNC_TAGS_WITH_TYPE_MUTATION,
          // Parameters
          variables: {
            input: {
              taggableId: taggableId,
              taggableType: taggableType,
              tags: tags,
              type: type,
              isNot: isNot
            }
          }
        })
        .then(() => {
          // Result
          //console.log(data);
        })
        .catch(error => {
          // Error
          console.error(error)
          // We restore the initial user input
          //this.newTag = newTag;
        })
    },
    async generateOfferNamesFor(offerId) {
      return this.$apollo
        .mutate({
          // Query
          mutation: GENERATE_OFFER_NAMES_FOR_MUTATION,
          // Parameters
          variables: {
            id: offerId
          }
        })
        .then(({ data }) => {
          let offer = data.generateOfferNamesFor.filter(
            offer => offer.id == offerId
          )[0]
          this.initializeOfferData(offer)
          this.$tabEvent.emit('refreshOffersTable')
          return data
        })
        .catch(error => {
          // Error
          console.error(error)
          // We restore the initial user input
          //this.newTag = newTag;
        })
    },
    openOfferCloneModal() {
      this.$modal.show(
        OfferCloneModal,
        {
          id: this.id,
          offerData: this.editOffer
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    }
  }
}
</script>
<style scoped>
.x-offer-editable-image-container {
  border-width: 1px;
  border-color: rgba(203, 213, 224, var(--border-opacity));
}
</style>
