var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "uppercase text-xs font-semibold text-gray-600"
  }, [_vm.filterableItem.filterableType == 'tag' && _vm.filterableItem.filterable ? [_vm._v(" " + _vm._s(_vm.filterableItem.filterable.type) + " ")] : [_vm._v(" " + _vm._s(_vm.filterableItem.filterableType) + " ")]], 2), _vm.filterableItem.filterableType == 'offer' ? _c('div', [_vm.filterableItem.filterable ? _c('router-link', {
    staticClass: "link-wrap",
    attrs: {
      "to": {
        name: 'Offer Edit',
        params: {
          id: _vm.filterableItem.filterableId
        }
      }
    }
  }, [[_vm._v(_vm._s(_vm.filterableItem.filterable.label))]], 2) : [_c('i', {
    staticClass: "text-gray-500"
  }, [_vm._v("No record found for ID " + _vm._s(_vm.filterableItem.filterableId))])]], 2) : _vm._e(), _vm.filterableItem.filterableType == 'advertiser' ? _c('div', [_vm.filterableItem.filterable ? _c('router-link', {
    staticClass: "link-wrap",
    attrs: {
      "to": {
        name: 'Advertiser',
        params: {
          id: _vm.filterableItem.filterableId
        }
      }
    }
  }, [[_vm._v(_vm._s(_vm.filterableItem.filterable.label))]], 2) : [_c('i', {
    staticClass: "text-gray-500"
  }, [_vm._v("No record found for ID " + _vm._s(_vm.filterableItem.filterableId))])]], 2) : _vm._e(), _vm.filterableItem.filterableType == 'advertiserAccount' ? _c('div', [_vm.filterableItem.filterable ? _c('router-link', {
    staticClass: "link-wrap",
    attrs: {
      "to": {
        name: 'Advertiser',
        params: {
          id: _vm.filterableItem.filterable.userId
        }
      }
    }
  }, [[_vm._v(_vm._s(_vm.filterableItem.filterable.label))]], 2) : [_c('i', {
    staticClass: "text-gray-500"
  }, [_vm._v("No record found for ID " + _vm._s(_vm.filterableItem.filterableId))])]], 2) : _vm._e(), _vm.filterableItem.filterableType == 'template' ? _c('div', [_vm.filterableItem.filterable ? _c('div', {
    class: _vm.filterableItem.filterablePolarity ? 'text-blue-600' : 'text-red-600'
  }, [_vm._v(" " + _vm._s(_vm.filterableItem.filterable.path) + " ")]) : [_c('i', {
    staticClass: "text-gray-500"
  }, [_vm._v("No record found for ID " + _vm._s(_vm.filterableItem.filterableId))])]], 2) : _vm._e(), _vm.filterableItem.filterableType == 'tag' ? _c('div', [_vm.filterableItem.filterable ? [_c('Tag', {
    attrs: {
      "color": _vm.filterableItem.filterablePolarity ? 'blue' : 'red'
    }
  }, [_vm._v(_vm._s(_vm.filterableItem.filterable.name))])] : [_c('i', {
    staticClass: "text-gray-500"
  }, [_vm._v("No record found for Tag ID " + _vm._s(_vm.filterableItem.filterableId))])]], 2) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }